import React from "react";
import { common, tools } from "helpers";
import { Link } from "react-router-dom";
import whoWeAreBanner from "../../assets/img/banner_who_we_are.png";
import { Helmet } from "react-helmet";
class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translation: common.getTranslation(),
    };
  }

  componentDidMount = () => {
    tools.scrollTop();
  };

  render() {
    const {
      translation
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>About Us</title>
        </Helmet>
        {/* <!-- Breadcrumb Wrap --> */}
        <div className="breadcrumb-wrap about about-wrap">
          <div className="who-we-are-banner">
            <img src={whoWeAreBanner} className="who-we-are-banner-img" alt="banner"/>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1>{translation.who_we_are}</h1>
              </div>
              <div className="col-lg-6">
                <p>
                  {translation.formatString(translation.about_us_content, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Breadcrumb Wrap End --> */}

        {/* <!-- Our Vision Wrap --> */}
        <div className="our-vision-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="vision-content">
                  <p>
                  {translation.formatString(translation.who_are_are_content, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="our-vision primary-bg">
                  <h4 className="mb-5">{translation.vision}</h4>
                  <p>
                    {translation.vision_content}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Our Vision Wrap End --> */}

        {/* <!-- How We Support Wrap --> */}
        <div className="how-we-support-wrap">
          <div className="container-fluid px-0">
            <div className="row align-items-center mx-0">
              <div className="col-sm-4 px-0">
                <img src={common.loadImg("how-we-support.jpg")} className="img-fluid" />
              </div>
              <div className="col-sm-8 px-0">
                <div className="how-we-support">
                  <h4 className="mb-4">
                    {translation.peace_of_mind}</h4>
                  <p>
                    {translation.peace_of_mind_content}
                    </p>
                  <p>
                  {translation.formatString(translation.peace_of_mind_content_1, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}
                    </p>
                  <p>{translation.peace_of_mind_content_2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- How We Support Wrap End --> */}

        {/* <!-- Join Our Community Wrap --> */}
        <div className="join-our-community-wrap alternate tertiary-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>{translation.ace_your_goal}</h3>
              </div>
              <div className="col-lg-6">
                <p className="pl-5">
                {translation.formatString(translation.about_example, <b>{translation.xample} <sup>{translation.tm}</sup></b>)}
                  </p>
              </div>
              <div className="col-lg-6 offset-lg-3">
                <div className="d-flex justify-content-between mt-5">
                  <Link to="/pricing" className="btn bordered-btn">
                    {translation.our_packages}
                    </Link>
                  <Link to="/membership" className="btn">
                    {translation.join_now}
                    </Link>
                </div>
              </div>
            </div>
            {/* <img src={common.loadImg("join-examplelk.png")} className="img-fluid" /> */}
          </div>
        </div>
        {/* <!-- Join Our Community Wrap End --> */}
      </div>
    );
  }
}

export default About;