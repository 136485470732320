import React from "react";
import { common, message, history, tools } from "helpers";
import { apiServices } from "api";
import { Helmet } from "react-helmet";
// includes
import { Handler } from "includes";
let translation = common.getTranslation();
class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			formSubmit: false,
			fields: {
				name: "",
				email: "",
				subject: "",
				message: "",
			},
			errors: {
				name: {
					error: "",
					isReq: translation.name_req,
				},
				email: {
					error: "",
					isReq: translation.email_req,
					isValidEmail: true,
					isValidEmailReq: translation.valid_email,
				},
				subject: {
					error: "",
					isReq: translation.subject_req,
				},
				message: {
					error: "",
					isReq: translation.inquiry_req,
				},
			},
			translation: common.getTranslation(),
		};
	}

	componentDidMount() {
	tools.scrollTop();
	}

	// handler
	onSubmit() {
		this.setSubmit(true);
		let isValid = this.handler.validateForm();
		if (isValid) {
			this.setLoading(true);
			const { fields } = this.state;

			// api call
			let params = {
				name: fields.name,
				email: fields.email,
				subject: fields.subject,
				message: fields.message,
			};

			let data = {
				url: "CONTACT",
				body: params,
				authorization: "none",
			};

			apiServices.call(
				data,
				(res) => {
					let status = common.apiStatus(res);
					if (status == "success") {
						let result = common.apiResult(res);
						common.snack("S", translation.valid_contact);
						history.push("/");
					} else {
						this.setLoading(false);
						if (status == "failure") {
							common.snack("E", translation.invalid_contact);
						}
					}
				},
				(error) => {
					this.setLoading(false);
				},
				(final) => {}
			);
		}
	}

	onEnterPress = (e) => {
		if (e.keyCode == 13 && e.shiftKey == false) {
			e.preventDefault();
			this.onSubmit();
		}
	};

	// support function
	setSubmit(status) {
		this.setState({ formSubmit: status });
	}

	setLoading(status) {
		this.setState({ isLoading: status });
	}

	render() {
		const { isLoading, formSubmit, fields, errors, translation } = this.state;
		return (
			<div className="main-content">
				<Helmet>
					<title>Contact Us</title>
				</Helmet>
				{/* <!-- Contact Wrap --> */}
				<div className="contact-wrap primary-bg">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-5">
								<div className="contact-info">
									<h2 className="pb-3 mb-5">{translation.contact_us}</h2>
									<ul>
										<li>
											<img src={common.loadImg("mail.png")} alt="Mail" />
											<p>
												<a href="mailto:support@xample.lk">
													{translation.info_email}
												</a>
											</p>
										</li>
										<li>
											<img src={common.loadImg("phone.png")} alt="Phone" />
											<p>
												{/* <span>{translation.contact}</span>{" "} */}
												<span><a href="tel:+0117670700">011 7670 700</a></span>
											</p>
										</li>
										<li>
											<img src={common.loadImg("home.png")} alt="Address" />
											<p>
											SALES/BUSINESS DEVELOPMENT OFFICE <br/>No 24 3/2, Level 3, Deal Place Colombo 3 <br />
											SRI LANKA CORPORATE OFFICE <br/> 276/A, Main Street, Battaramulla
											</p>
										</li>
										<li>
											<img src={common.loadImg("home.png")} alt="Address" />
											<p>
											HEAD QUARTERS <br /> Clarendon – Ealing Cross, Office 42, 85 Uxbridge Road, London W5 5TH <br />
											</p>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="contact-map">
									<iframe src="https://www.google.com/maps/d/embed?mid=1fRHL0vTDZh0pTaWUiAQTxmaGhvOrbMPg" width="640" height="480"></iframe>
									{/* <iframe
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31687.079356993232!2d79.94792261021198!3d6.904362935274355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae256d59601df81%3A0x31a1dd96e8d49ba!2sMalabe%2C%20Sri%20Lanka!5e0!3m2!1sen!2sin!4v1611290868298!5m2!1sen!2sin"
										width="100%"
										height="500"
										frameBorder="0"
										allowFullScreen=""
										aria-hidden="false"
										tabIndex="0"
									></iframe> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Contact Wrap End --> */}

				{/* <!-- Inquiry Form Wrap --> */}
				<div className="inquiry-form-wrap pb-3">
					<div className="container-fluid">
						<div className="inquiry-form mb-5">
							<h4 className="pb-2 mb-4">{translation.make_enquiry}</h4>
							<form method="POST">
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group">
											<input
												type="text"
												name="name"
												value={fields.name}
												onChange={(e) => this.handler.onChange(e)}
												className="form-control"
												placeholder=" "
											/>
											{/* <span className="floating-label">{translation.name}</span> */}
											<span className="floating-label">Name</span>
											{errors.name.error != "" && formSubmit && (
												<span className="elk-error">{errors.name.error}</span>
											)}
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group">
											<input
												type="email"
												name="email"
												value={fields.email}
												onChange={(e) => this.handler.onChange(e)}
												className="form-control"
												placeholder=" "
											/>
											{/* <span className="floating-label">
												{translation.email_address}
											</span> */}
											<span className="floating-label">
												Email Address
											</span>
											{errors.email.error != "" && formSubmit && (
												<span className="elk-error">{errors.email.error}</span>
											)}
										</div>
									</div>
								</div>
								<div className="form-group">
									<input
										type="text"
										name="subject"
										value={fields.subject}
										onChange={(e) => this.handler.onChange(e)}
										className="form-control"
										placeholder=" "
									/>
									{/* <span className="floating-label">
										{translation.inquiry_subject}
									</span> */}
									<span className="floating-label">
										Inquiry Subject
									</span>
									{errors.subject.error != "" && formSubmit && (
										<span className="elk-error">{errors.subject.error}</span>
									)}
								</div>
								<div className="form-group">
									<textarea
										name="message"
										className="form-control"
										value={fields.message}
										onChange={(e) => this.handler.onChange(e)}
										rows="4"
										placeholder=" "
									/>
									{/* <span className="floating-label">
										{translation.your_enquiry}
									</span> */}
									<span className="floating-label">
										Your Inquiry
									</span>
									{errors.message.error != "" && formSubmit && (
										<span className="elk-error">{errors.message.error}</span>
									)}
								</div>
								<div className="form-actions text-center mt-4">
									<input
										type="button"
										onClick={() => this.onSubmit()}
										disabled={isLoading}
										className="btn"
										value="Send Inquiry"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
				{/* <!-- Inquiry Form Wrap End --> */}
				<Handler
					ref={(instance) => {
						this.handler = instance;
					}}
					state={this.state}
					setState={(state) => this.setState({ state })}
				/>
			</div>
		);
	}
}

export default Contact;
