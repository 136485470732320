import React from "react";
import { common } from "helpers";
let translation = common.getTranslation();

export default class MyHeaders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header1: ["home_page", "about_us", "analytics", "", "", "", "", "", ""],
      header2: ["index_1", "index_2", "", "", "", "", "", "", ""],
      header3: [
        "sign_in",
        "student_parent_register",
        "parent_register",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      header4: ["", "", "", "", "", "", "", "", ""],
      header5: [
        "contact_us",
        "help_center",
        "support_1",
        "support_2",
        "support_3",
        "",
        "",
        "",
        "",
      ],
      header6: [
        "change_password",
        "forgot_user_name",
        "forgot_password",
        "membership_activation",
        "",
        "",
        "",
        "",
        "",
      ],
      header7: [
        "physics",
        "exam_page",
        "sorry_wrong_answer",
        "test_session_five",
        "test_review",
        "test_completed_1",
        "test_completed_2",
        "search",
        "",
      ],
      header8: [
        "great",
        "support_without_login",
        "coming_soon",
        "limit_exceed",
      ],
      no_header: ["index_3"],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* header 1 */}
        {/* header-fixed */}
        <header className="header-fixed navigation-wrap dark-bg">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                      {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                        {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 2 */}
        <header className="navigation-wrap header-relative">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="form-group has-search ml-5 mb-0">
                <img src={common.loadImg("search-icon.png")} alt="Search" className="icon-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="What are you looking for?"
                />
              </div>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                     {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                        {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 3 */}
        <header className="navigation-wrap header-relative">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                     {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                       {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 4 */}
        <header className="navigation-wrap header-relative">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                     {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.signin}
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 5 */}
        <header className="navigation-wrap header-relative primary-bg">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                     {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                       {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 6 */}
        <header className="navigation-wrap header-relative tertiary-bg">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                      {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                       {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                       {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 7 */}
        <header className="navigation-wrap header-relative gradient-border">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="form-group has-search ml-5 mb-0">
                <img src={common.loadImg("search-icon.png")} alt="Search" className="icon-search" />
                <input
                  type="text"
                  className="form-control"
                  placeholder="What are you looking for?"
                />
              </div>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                     {translation.header_whoweare}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_support}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                       {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                       {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                        {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                        {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                       {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* header 8 */}
        <header className="navigation-wrap header-relative gradient-border alternate">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <a className="navbar-brand" href="index.html" target="_blank">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </a>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className="nav-item">
                    <a className="nav-link" href="about-us.html">
                    {translation.who_we_are}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_analytics}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link dropdown-toggle"
                      data-toggle="dropdown"
                      href="#"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {translation.header_analytics}
                    </a>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu1}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu2}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu3}
                      </a>
                      <a className="dropdown-item" href="#">
                      {translation.sub_menu4}
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      {translation.header_membership}
                    </a>
                  </li>
                  <li className="nav-item nav-btn">
                    <a className="nav-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                      >
                        <g fillRule="evenodd">
                          <g fillRule="nonzero">
                            <g>
                              <path
                                d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                transform="translate(-1267 -25) translate(1267 25)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <div className="user-info">
                        <span>{translation.welcome_back}</span>
                        <br />
                        {translation.john_doe}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
