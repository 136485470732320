import React from "react";
import { render } from "react-dom";
import { SnackbarProvider } from "notistack";

//Import internal components
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CommonSnackbarConfig } from "includes/SnackBar";

//Import CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/react-confirm-alert.css";
import "./assets/css/style.css";
import "./assets/css/common.css";
import "./assets/css/mathml.css";


const renderApp = () =>
  render(
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <CommonSnackbarConfig />
      <App />
    </SnackbarProvider>,
    document.getElementById("root")
  );

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
