import React from "react";
import { Link } from "react-router-dom";
import { Tab, Row, Nav, Col, FormGroup, FormLabel, FormControl } from "react-bootstrap";
// includes
import { common, tools, message, functions } from "helpers";
import { apiServices } from "api";
// pages
import Profile from "./includes/Profile";
import ManageChildren from "./includes/ManageChildren";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class Settings extends React.Component {

  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      isLoading: false,
      userData: common.authInfo(),
    }
  }

  // file upload
  onFileChange = (e) => {
    this.setLoading(true);
    // form
    var formdata = new FormData();
    formdata.append("file", e.target.files[0], "");
    var data = {
      url: 'UPLOAD',
      body: formdata,
      contentType: 3,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        if (status == "success") {
          this.updateProfile(result.image);
        } else {
          this.setLoading(false);
        }
      },
      (error) => {
        this.setLoading(false);
      },
      (final) => { }
    )
  }

  updateProfile(img) {
    const { userData } = this.state;
    // api call
    let params = {
      img
    }

    let data = {
      method: "PUT",
      url: "USER_UPDATE",
      query: common.getStorage("userId"),
      body: params,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status == "success") {
          userData.user.img = img;
          common.setStorage("userData", tools.crypt(userData, true));
          common.snack("S", translation.update_success);
          common.redirect("/settings/profile", 2);
        }
      },
      (error) => { },
      (final) => {
        this.setLoading(false);
      }
    );
  }

  // support
  setLoading(status) {
    this.setState({ isLoading: status })
  }

  render() {
    const { params, isLoading, userData } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <Link to="#" className="custom-link">
         {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
         XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap pb-0">
          <div className="container">
            <div className="card card-4">
              <div className="card-heading primary-bg">
                <h2>{translation.account_settings}</h2>
                {/* <p className="mb-0">
                {translation.lorem_content}
                </p> */}
              </div>

              {/* Tab container start */}
              <Tab.Container defaultActiveKey={params.type || (userData.user.role == 1 ? "child" : "profile")}>
                <div className="card-body">
                  <Row>
                    <Col sm={4}>
                      <div className="settings-left">
                        <div className="user-profile">
                          <img
                            src={common.userImage()}
                            alt="Profile Photo"
                            className="img-fluid"
                          />
                          {isLoading ? (
                            <p className="elk-profile-upload">{translation.uploading}</p>
                          ) : (
                              <FormGroup>
                                <FormLabel htmlFor="fileUpload">
                                  <div className="upload">
                                    <img
                                      className="elk-cp"
                                      src={common.loadImg("upload-photo.png")}
                                      alt="Profile Photo"
                                    />
                                  </div>
                                  <FormControl
                                    id="fileUpload"
                                    className="elk-hide"
                                    type="file"
                                    accept="image/*"
                                    onChange={this.onFileChange}
                                  />
                                </FormLabel>
                              </FormGroup>
                            )}
                        </div>
                        <Nav className="nav-tabs tabs-left">
                          {userData.user.role == 1 && (
                            <Nav.Item>
                              <Nav.Link eventKey="child">{translation.manage_children}</Nav.Link>
                            </Nav.Item>
                          )}
                          <Nav.Item>
                            <Nav.Link eventKey="profile">{translation.profile_settings}</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="signOut" onClick={() => functions.logOut()}>{translation.header_logout}</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col sm={8}>
                      <Tab.Content>
                        {userData.user.role == 1 && (
                          <Tab.Pane eventKey="child">
                            <ManageChildren />
                          </Tab.Pane>
                        )}
                        <Tab.Pane eventKey="profile">
                          <Profile />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </div>
              </Tab.Container>
              {/* Tab Container end */}
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
      </div>
    );
  }
}
export default Settings;
