import React from "react";
import { tools } from "helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class PrivacyPolicy extends React.Component {
  componentDidMount() {
    tools.scrollTop();
  }

  render() {
    return (
      <div className="main-content">
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>
        {/* <!-- Breadcrumb Wrap --> */}
        <div className="breadcrumb-wrap alternate">
          <div className="container-fluid">
            <div className="breadcrumb-text">
              <h1>Privacy Policy</h1>
            </div>
          </div>
        </div>
        {/* <!-- Breadcrumb Wrap End --> */}

        <div className="static-content-wrap">
          <div className="container-fluid">
            <div className="static-content">
              <p>
                It's important to CLIENTS & CONSUMERS PRIVATE LIMITED to help
                our users retain their privacy when they take advantage of all
                the Internet has to offer.
              </p>

              <p>
                Your privacy is important to you and to us. So we'll protect the
                information you share with us. To protect your privacy, CLIENTS
                & CONSUMERS PRIVATE LIMITED follows the principles in accordance
                with worldwide practices for customer privacy and data
                protection.
              </p>
              <ul className="build-list">
                <li>
                  We won’t sell or give away your name, mail address, phone
                  number, email address or any other information to anyone.
                </li>
                <li>
                  We’ll use state-of-the-art security measures to protect your
                  information from unauthorized users.
                </li>
              </ul>

              <h4>NOTICE</h4>

              <p>
                We will ask you when we need information that personally
                identifies you (personal information) or allows us to contact
                you. Generally, this information is requested when you create a
                Registration ID on the site or when you download free software,
                enter a contest, order email newsletters or join a
                limited-access premium site. We use your Personal Information
                for four primary purposes:
              </p>
              <ul className="build-list">
                <li>
                  To make the site easier for you to use by not having to enter
                  information more than once.
                </li>
                <li>
                  To help you quickly find software, services or information.
                </li>
                <li>To help us create content most relevant to you.</li>
                <li>
                  To alert you to product upgrades, special offers, updated
                  information and other new services from CLIENTS & CONSUMERS
                  PRIVATE LIMITED.
                </li>
              </ul>

              <h4>CONSENT</h4>

              <p>
                If you choose not to register or provide personal information,
                you can still use most of <Link to="/">www.xample.lk</Link>. But you will not be
                able to access areas that require registration.
              </p>

              <p>
                If you decide to register, you will be able to select the kinds
                of information you want to receive from us by subscribing to
                various services, like our electronic newsletters. If you do not
                want us to communicate with you about other offers regarding
                CLIENTS & CONSUMERS PRIVATE LIMITED products, programs, events,
                or services by email, postal mail, or telephone, you may select
                the option stating that you do not wish to receive marketing
                messages from CLIENTS & CONSUMERS PRIVATE LIMITED.
              </p>

              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED occasionally allows other
                companies to offer our registered customers information about
                their products and services, using postal mail only. If you do
                not want to receive these offers, you may select the option
                stating that you do not wish to receive marketing materials from
                third parties.
              </p>

              <h4>ACCESS</h4>

              <p>
                We will provide you with the means to ensure that your personal
                information is correct and current. You may review and update
                this information at any time at the Visitor Center. There, you
                can:
              </p>
              <ul className="build-list">
                <li>
                  View and edit personal information you have already given us.
                </li>
                <li>
                  Tell us whether you want us to send you marketing information,
                  or whether you want third parties to send you their offers by
                  postal mail.
                </li>
                <li>
                  Sign up for electronic newsletters about our services and
                  products.
                </li>
                <li>
                  Register. Once you register, you won't need to do it again.
                  Wherever you go on <Link to="/">www.xample.lk</Link>, your information stays
                  with you.
                </li>
              </ul>

              <h4>SECURITY</h4>

              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED has taken strong measures to
                protect the security of your personal information and to ensure
                that your choices for its intended use are honored. We take
                strong precautions to protect your data from loss, misuse,
                unauthorized access or disclosure, alteration, or destruction.
              </p>

              <p>
                We guarantee your e-commerce transactions to be 100% safe and
                secure. When you place orders or access your personal account
                information, you're utilizing secure server software SSL, which
                encrypts your personal information before it's sent over the
                Internet. SSL is one of the safest encryption technologies
                available.
              </p>

              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED strictly protects the
                security of your personal information and honors your choices
                for its intended use. We carefully protect your data from loss,
                misuse, unauthorized access or disclosure, alteration, or
                destruction.
              </p>

              <p>
                Your personal information is never shared outside the company
                without your permission, except under conditions explained
                above. Inside the company, data is stored in password-controlled
                servers with limited access. Your information may be stored and
                processed in DEMOCRATIC SOCIALIST REPUBLIC OF SRI LANKA or any
                other country where CLIENTS & CONSUMERS PRIVATE LIMITED, its
                subsidiaries, affiliates or agents are located.
              </p>

              <p>
                You also have a significant role in protecting your information.
                No one can see or edit your personal information without knowing
                your username and password, so do not share these with others.
              </p>

              <h4>NOTICE TO PARENTS</h4>

              <p>
                Parents or guardians: we want to help you guard your privacy of
                children's privacy. We encourage you to talk to your children
                about safe and responsible use of their Personal Information
                while using the Internet.
              </p>

              <p>
                The CLIENTS & CONSUMERS PRIVATE LIMITED site does not publish
                content that is targeted to children. However, if you are
                concerned about your children providing CLIENTS & CONSUMERS
                PRIVATE LIMITED any personal information without your consent,
                CLIENTS & CONSUMERS PRIVATE LIMITED offers a Kids account. It
                allows parents to give parental consent for the collection, use
                and sharing of children’s (ages 12 and under) personal
                information online.
              </p>

              <h4>ENFORCEMENT</h4>

              <p>
                If for some reason you believe CLIENTS & CONSUMERS PRIVATE
                LIMITED has not adhered to these principles, please notify us by
                email at <a href="mailto:privacy@examplelk.org">privacy@examplelk.org</a>, and we will do our best to
                determine and correct the problem promptly. Be certain the words
                Privacy Policy are in the Subject line.
              </p>

              <h4>ELECTRONIC PRODUCT REGISTRATION</h4>

              <p>
                When you subscribe as a new user, we may ask you to register
                your purchase electronically. When you do, we merge your
                registration information with any information you've already
                left with us (we call that information your personal profile).
                If you haven't previously registered with us, we create a
                personal profile for you from your product registration
                information. If you ever want to review or update that
                information, you can visit the Profile Center, click on Update
                Profile, and edit any of the Personal Information in your
                profile. If you haven't already created a Registration ID, we
                will ask you to do so. This ensures that only you can access
                your information.
              </p>

              <h4>CUSTOMER PROFILES</h4>

              <p>
                As mentioned above, every registered customer has a unique
                personal profile. Each profile is assigned a unique personal
                identification number, which helps us ensure that only you can
                access your profile.
              </p>

              <p>
                When you register, we create your profile, assign a personal
                identification number, then send this personal identification
                number back to your hard drive in the form of a cookie, which is
                a very small bit of code. This code is uniquely yours. It is
                your unique identification to seamless travel across
                <Link to="/"> www.xample.lk</Link>, allowing you to download free software, order
                free newsletters, and visit premium sites without having to fill
                out registration forms with information you've already provided.
                Even if you switch computers, you won't have to re-register –
                just use your Registration ID to identify yourself.
              </p>

              <h4>What we do with the information you share</h4>

              <p>
                When you join us, you provide us with your contact information,
                we use this information to send you updates about your order,
                questionnaires to measure your satisfaction with our service and
                announcements about new and exciting services that we offer.
                When you order from us, we ask for your credit card number and
                billing address. We use this information only to bill you for
                the product(s) you order at that time. For your convenience, we
                do save billing information in case you want to order from us
                again, but we don't use this information again without your
                permission.
              </p>

              <p>
                We occasionally hire other companies to provide limited services
                on our behalf, including packaging, mailing and delivering
                purchases, answering customer questions about products or
                services, sending postal mail and processing event registration.
                We will only provide those companies the information they need
                to deliver the service, and they are prohibited from using that
                information for any other purpose.
              </p>

              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED will disclose your personal
                information, without notice, only if required to do so by law or
                in the good faith belief that such action is necessary to: (a)
                conform to the edicts of the law or comply with legal process
                served on CLIENTS & CONSUMERS PRIVATE LIMITED or the site; (b)
                protect and defend the rights or property of CLIENTS & CONSUMERS
                PRIVATE LIMITED and its family of Websites, and, (c) act in
                urgent circumstances to protect the personal safety of users of
                CLIENTS & CONSUMERS PRIVATE LIMITED, its Websites, or the
                public.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
