export default {
	/*button texts */
	join_now: "Join Now",
	read_more: "Read More",
	our_packages: "Our Packages",
	take_mockexam_now: "Take a Mock Exam Now",

	/* home page 1 */
	welcome: "Welcome",
	registration_now: "register now",
	home_banner_register:
		", Virtual Learning Environment (VLE) for GCE O/L & A/L students.",
	select_account_type: "Select Your Account Type",
	as_a_student: "As a student",
	as_a_parent: "As a parent",
	name: "Name",
	your_email_address: "Your Email Address",
	agree_terms_conditions:
		"By Signing up you are agreeing to our Terms and Conditions",

	banner_title: "DO YOUR O/L & A/L EXAMS WITH CONFIDENCE LIKE AN EXPERT",
	banner_content:
		"Welcome to our complete and comprehensive online O/L & A/L MCQ testing and training platform. " +
		"The platform is built with over 120,000 O/L and A/L exam questions & answers with explanations. It is designed " +
		"based on the principle of the 4 steps of learning, to boost confidence and competence.",

	/* home page 2 */
	what_is_example: "What is Xample?",
	about_example_1:
		"The {0} is a Cloud - based online Testing & Learning platform that you can sign in " +
		"from anywhere, with your computer or mobile device. " +
		"It is a very powerful tool made for children to succeed in their GCE O/L and A/L Exams. ",
	about_example_1_1:
		"The {0} is built with a very rich bank of GCE O/L & A/L exam papers from the past 20 years. " +
		"The platform contains mock exams, video tutorials and even allows students to interact with a panel of " +
		"qualified teachers in an exciting learning environment.",

	about_example_2:
		"The {0} will help you to identify the area of the student's weaknesses and " +
		"the system will allow them to build a plan, setting Goals & Objectives to become an expert. " +
		"{0} is built by our expert IT engineers based on the principles of the {1}",
	four_steps_of_learning: "4 Steps of Learning.",

	about_example_3:
		"The 4 Steps of Learning is a very powerful tool that can improve the competence level " +
		"of any learner from not knowing something to {0}",
	becoming_an_expert: "becoming an expert and doing it with their eyes closed.",

	/* home page 3 */
	example_offer_title: "WHAT XAMPLE CAN OFFER",
	example_offer_subtitle:
		"AN EASY STEP BY STEP PLAN & SYSTEM FOR GUARANTEED SUCCESS.",
	example_offer_content:
		"The key to success in a highly competitive environment is having a plan and a perfect system " +
		"to follow, or another way to say it, being smart, bear in mind, being smart is not always about working hard. " +
		"The {0} is made for you to become smart! You only need to trust the system & follow the instructions that come with " +
		"the product. {1}",
	example_offer_content1:
		"Do your O/L or A/L exams with your eyes closed like an expert.",

	setting_goals_and_objectives_title: "Setting goals and objectives",
	setting_goals_and_objectives:
		"The {0} will be able to create an action plan by setting goals and " +
		"objectives in the system and it is a very straightforward step by step process.",

	realtime_competency_title: "Real-time Competency Exam",
	realtime_competency:
		"Students can register and participate in real - time exams " +
		"competing with other registered students on a monthly and quarterly basis to increase competency level and experience.",

	progress_performance_title: "Progress and Performance Report",
	progress_performance:
		"Easy-to-understand progress reports for the students and their " +
		"parents on the child's competency level. Track down performance to identify areas of study that need more attention.",

	subject_module_languages_title: "Subjects/Modules and Languages",
	subject_module_languages:
		"Xample comprises all subjects and modules of O/Ls & A/Ls for you to choose " +
		"from in your preferred language - English, Sinhala, or Tamil.",

	/* home page 4 */
	digital_papers: "Digital Exam Papers",
	mediums_subjects: "Mediums and Subjects",
	excel_in_test: "Excel in tests. practice easy",

	/* home page 5 */
	available_medium: "Available Medium",
	available_medium_content:
		"The most effective “Testing & Revision Tool” is available in all three languages – English, Sinhala, and Tamil.",
	available_subjects: "Available Subjects",
	available_subjects_content:
		"With over 12+ subjects to choose from, you can become proficient to succeed in exams.",
	guarantee_success_title: "WE GUARANTEE YOUR SUCCESS! ",
	guarantee_success_title1: " WITH A MINIMUM COMMITMENT OF 3 MONTHS",
	guarantee_success_content:
		"For {0} in your exams, you must be able to commit to spending time and agree on an action plan " +
		"by setting goals and objectives in the system and it is a very straightforward  step by step process.",
	guaranteed_success: "guaranteed success",
	step1: "Step 1:",
	step2: "Step 2:",
	step3: "Step 3:",
	step4: "Step 4:",
	step1_content: "Understanding your competency level.",
	step2_content:
		"Agreeing on a time period of a minimum of 3 months to learn and practice.",
	step3_content: "Follow the action plan on a daily, weekly or monthly basis.",
	step4_content: "Commit to your plan!",

	/* home page 6 */
	simple_easy: "It's Simple and Easy",
	simple_easy_content_1:
		"An enjoyable online experience that takes you to the highest levels of proficiency for the " +
		"GCE O/L and A/L examinations through thousands of questions, all-inclusive study notes, and advanced analytical reports.",
	simple_easy_content_2:
		"Join now to enjoy a Seamless Experience of Testing and Learning with a very powerful tool " +
		"that can improve the competence level of any learner from not knowing something to {0}",
	simple_easy_content_3:
		"doing your O/L or A/L exams with your eyes closed like an expert.",

	as_simple_as: "THE SIMPLEST WAY TO SUCCEED IN EXAMS",
	content1_title: "Available in all 3 Mediums",
	content1_body:
		"The Xample is available in all 3 languages: Sinhala, Tamil and English covering all topics of each " +
		"syllabus and the student can choose the preferred medium to study when signing up",

	content2_title: "Time – Based and Practice - Based Exams",
	content2_body:
		"Students can choose between practice or time based tests. You can take as many practice tests without the " +
		"countdown and the system will give you an explanation to wrong answers until you reach Conscious Competent Level.",
	content2_body1:
		"Whereas you can attempt as many time-based tests as possible until you become Unconsciously Competent.",

	content3_title: "A library of Past Papers and Model Papers",
	content3_body:
		"The Xample has a collection of past GCE O/L & A/L Exam papers and model Exam papers for each subject " +
		"with over 120,000 MCQs in all 3 Languages",

	content4_title: "Tracing your progression",
	content4_body:
		"With our easy-to-use analytics, you can track down your attempted tests and how well you have performed " +
		"within a specific time frame. Any tests that you have left off without completing can be resumed at any time.",

	content5_title: "Easy progress tracking",
	content5_body:
		"A parent can know the progress of their children on attempted and completed tests on how well they have performed.",

	content6_title: "Multiple student accounts",
	content6_body:
		"A parent account can manage multiple child accounts, manage invoices and payments and viewing and " +
		"comparing their progress on separate views.",

	/* home page 8 */
	promotional_offer:
		"ACE YOUR GCE O/L & A/L EXAM WITH ‘XAMPLE’ PROMOTIONAL OFFER OF 50% OFF",
	join_now_content:
		"Join us now to enjoy a Seamless Experience of Testing and Learning with a very powerful tool. " +
		"{0} improves the competency level of any learner from not knowing something to doing their O/L or A/L exams with eyes closed like an expert.",

	/* home page 9 */
	msg_from_community_1: "Message from our {0} community",
	msg_from_community_2:
		"Our Growing Community consists of {0} students from around Sri Lanka",
	msg_from_community_3: "Exams Taken on Xample {0} Platform",

	/* Home page popup */
	take_mock_now: "Take a Mock Exam Now",
	mockexam_pop_reg_message: "Please sign in or register for the Mock exam.",

	/* ABOUT US */
	/*who we are*/

	who_we_are: "Who we are",

	about_us_content:
		"The {0} is one-of-the most effective “Testing & Revision Tool” designed for its premium " +
		"quality by Novelwall UK, one - of - the most trusted brands in the education industry in the United Kingdom, and a joint " +
		"venture with ATOM, a top versatile service brand in the IT & engineering industry from India.",
	who_are_are_content:
		"{0} is a joint venture between Novelwall UK and ATOM India and we have a team of the industry’s " +
		"most experienced professionals of diverse ethnicity with over 100 years of combined experience in the education, " +
		"marketing and I.T industries. Novelwall was established in 2010 in the midst of a recession with three dedicated " +
		"idealists with no founding client. Today Novelwall is one of the top marketing firms in the education industry trusted " +
		"by their delighted clients and consumers.",

	/*vision*/
	vision: "OUR VISION",
	vision_content:
		"Becoming the most desirable service provider in the education industry, giving you the care, attention and " +
		"trust to grow, develop and succeed.",

	peace_of_mind: "GIVING YOU THE PEACE OF MIND",
	peace_of_mind_content:
		"We all can agree on the ugly truth about the GCE, O/L & A/L preparation nightmare, of our children, " +
		"parents and teachers, right? Regardless, years of hard work and sleepless nights of endless, teaching, learning & testing, " +
		"is just about to decide as to how well a child has done at their O/L or A/L exam. On the other hand, our world is evolving. " +
		"Technologies is getting more advanced, so our lifestyles are also becoming increasingly complex and driven more " +
		"towards virtual environments like Facebook, internet banking or more and more online shopping. Nevertheless, " +
		"we should stay on par with the emerging trends, new needs & wants and be competitive amongst society.",
	peace_of_mind_content_1:
		"We engineered & designed {0} for you with our industry experts with questions and answers " +
		"from the past 20 years’ worth of GCE O/L & A/L exams. We engineered {0} Based on the principles of the 4 Steps of " +
		"Learning, a very powerful tool that can improve the competence level of any learner from not knowing something to becoming " +
		"an expert and doing it with their eyes closed.",
	peace_of_mind_content_2:
		"We are thankful and indebted to all the top qualified teachers and subject experts from the " +
		"industry for their guidance and immense support to make our Virtual Learning Environment (VLE) a success.",

	ace_your_goal: "ACE YOUR GCE O/L & A/L EXAM WITH ‘XAMPLE’",
	about_example:
		"The {0} is built with a collection of GCE O/L & A/L exam papers from the past 20 years. The platform " +
		"contains over 120,000 mock exam Questions & Answers with explanations and video tutorials. The {0} is made for you " +
		"to become smarter! You only need to trust the system & follow the instructions that come with the product. The Xample " +
		"will allow students to build a plan, setting Goals & Objectives to become an expert and students can register and " +
		"participate on real - time Exams competing with other registered students.",

	/* analytics */
	analytics_title: "Analytics",
	analytics_content:
		"At {0}, we are passionate about creating and supporting the best educational methodology " +
		"possible. We have developed groundbreaking products used by millions of learners in the past, from children to adults. " +
		"We would like to make ourselves count to make learning effective. We are deeply committed to solving the real-world " +
		"challenges faced by students, teachers and parents.",

	myprogress_title: "My Progress",
	myprogress_content:
		"Your progress will be assessed according to the standard Sri Lankan O/L and A/L exam scoring system. " +
		"You can track your progress from the beginning whilst attempting a test and the outcome of your progress is based on the " +
		"number of hours and effort that you have put in.",
	myprogress_content1:
		"{0} has a very advanced reporting system that records your performance based on any subject, " +
		"term, or module, and it provides you with a progress report in order for you to improve on your weaker areas.",

	setgoals_title: "Set Goals",
	setgoals_content:
		"{0} will let you know your competency level based on your performance and from there you can set " +
		"your goals to improve on your weaker areas. Here you must commit yourself to practice the minimum required hours on the " +
		"time-based and practice-based tests for a minimum of 90 days period.",
	setgoals_content1:
		"You can select specific modules that you want to work on therefore you don’t need to do the entire " +
		"subject itself. You will be able to set goals based on modules, only focusing and putting more effort on areas that you want " +
		"to gain more competence.",
	setgoals_content2:
		"Once the goals have been created based on your timetable, the system will alert and remind you of " +
		"your tasks at hand through text or email.",

	viewgoals_title: "View Goals",
	viewgoals_content:
		"{0} is able to calculate and compile questions based upon your previous performances and the goals " +
		"that you have set that you want to achieve. You will be able to appraise and compare your progression at any given time " +
		"from your initial tests to how close you are to the desired grade that you want.",
	viewgoals_content1:
		"Once you have reached the competency level, the system will encourage you to keep going or you can " +
		"move on to a new module/subject to work on. If you are not in your expected scoring zone, the system will suggest " +
		"increasing the test hours or the study hours or recommendations to take video tutorials or one on one tutorial classes.",
	twelve_hrs_per_week: "12 hrs per week",


	/*what-example-can-offer*/
	what_is: "what are",
	steps_for_learning: "THE 4 STEPS FOR LEARNING?",
	four_steps_for_learning_content:
		"The 4 Steps of learning, also known as the ‘4 stages of competence’, were invented by " +
		"Noel Burch in 1970s at Gordon Training International to improve and increase employee’s efficiency and competence at " +
		"work. In the modern world, the 4 steps of learning are being used in top universities such as Oxford and Harvard on " +
		"their deliverables to improve the knowledge and competence of their students and it is believed that the 4 steps of " +
		"learning are one of the most effective methods ever invented to improve competence.",
	four_steps_for_learning_content1:
		"Whilst engineering Example, our experts have adopted the principle of the 4 steps of " +
		"learning to give you exposure to global influence used at top Universities in the world, which can help our " +
		"students to adopt and boost their confidence to grow, develop and Succeed.",

	stage1: "Stage 1: ",
	stage1_title: "“UNCONSCIOUS INCOMPETENCE”",
	stage1_content:
		"When it comes to unconscious incompetence, people have an inept response for those that do not know " +
		"better to say “I know, I know”. This is usually a response from children, a child usually does not know better when it " +
		"comes to doing something that may lead to injury. Their curiosity at an early stage of life can lead them into trouble. " +
		"A parent from experience will tell the child to stop doing what they are doing which is reckless, such as climbing a fence " +
		"that has no safe landing area, and they will respond in a manner that they know best “I know. I know!” and continue where " +
		"they left off and eventually hurt themselves and cry.",
	stage1_content1: "“Didn’t I tell you so??”",
	stage1_content2:
		"Another great example of this is a person who doesn’t know the complexity of what they are talking about. " +
		"Have you ever had a moment in your life where you watched a performer doing something on TV or live and said, “That's " +
		"simple, I can do that!” only to fail miserably?",
	stage1_content3:
		"At this stage, it is very important for a student to understanding that “Yes you may be good, but " +
		"knowing more is better” and not to be arrogant, or else it will prevent a learner from growing.",

	stage2: "Stage 2: ",
	stage2_title: "“Conscious incompetence”",
	stage2_content:
		"The beginning of growth, this step of learning is for a student to identify areas they will want to work on in order for them to be able to get better at a module or subject that they are weak at. This will all come down to being able to set goals and objectives. There is always a starting point for everyone to be ahead or on top of their game, whether an athlete, a scholar or just a person who wants to be able to drive.",
	stage2_content1: "Let’s take the very example of driving.",
	stage2_content2:
		"For you to drive (The Goal), you first need to identify what you need to be able to do to drive (The Objectives). There are many variables that you will need to understand and practice for you to drive. understand the rotation of the steering wheel, shifting gears alongside mastering the motion of the clutch, applying the brakes and so on.",
	stage2_content3:
		"To a beginner, this can be a very unnerving moment brought with other emotions such as fear or excitement or a combination of both.",
	stage2_content4:
		"This feeling is very common for anyone who thrives to become good or the best in a particular field, skill, or professional is expected at the early steps of learning. To summarise, for a person to be good and thrive to be the best in what they are doing they will need to take into all accounts the areas that they need to train and work on for a set period of time.",

	stage3: "Stage 3: ",
	stage3_title: "“Conscious competence”",//"“Cognitive efficiency”",
	stage3_content:
		"With the continuation of the example of “Learning to drive”, for a person to be a competent driver they must learn from the first-hand experience behind the steering wheel. All this is good with the aid of an instructor who can guide them in the right direction, what if the person who is guiding you is a loved one? What would happen if during your early lessons in driving that person is constantly bombarding you with criticism? “You are going too fast??” “Stop lifting your foot on the clutch too quickly you will stall??” “Keep your eye on the road”.",
	stage3_content1:
		"The outcome will certainly be one or a combination of confusion, frustration added to the point of wanting to just get out of the car feeling defeated, the need to just give up. This stage of learning is the most demanding and gruelling, it can either make or break a person for them to surpass their limitations.",
	stage3_content2:
		"It is perfectly normal for anyone thriving to become top of the game in any field to think this. Anything can happen during the period of learning to become great, friends and family even colleagues can affect and break your focus by the development of doubt during the stages of learning.",
	stage3_content3: "“I still don’t get it!”",
	stage3_content4: "“Maybe I should just give up?”",
	stage3_content5:
		"These feelings will always creep into anyone, whether they are a beginner or a person at the highest echelon in the field. At this stage, to grow and become better, one must be able to practice and practice more enduring the growing pains and doubts to a learner to start by hearting and getting used to the process.",

	stage4: "Stage 4: ",
	stage4_title: "“Unconscious Competence”",//"“Ignorance and dexterity”",
	stage4_content:
		"After a period of time whilst practicing, a student to be able to get to the top of any subject doing it with their eyes closed, let us conclude the scenario of passing your driving test. After a while from trial by fire and error, you continue to drive despite all the distractions that you will face, everything will start to make sense and click-in. Your technical errors will be limited, and you will slowly make fewer and fewer mistakes and you will start to enjoy yourself and eventually you obtain your driver's license.",
	stage4_content1:
		"Driving now has become almost second nature to you like breathing, once you have it you will always have it. That is the beauty of human development from where a person begins and where they will reach once they understand the full essence of the steps of learning.",

	setting_goals_and_objectives1:
		"To guarantee success for your exams, you must be able to commit to spending time and agree on an action plan by setting goals and objectives in the system and it is a very straightforward step-by-step process.",
	setting_goals_and_objectives2:
		"To have a good expected result, you must set goals and objectives, for example, if you want to have an A you must set goals and objectives towards getting an A. The objectives will be:",
	setting_goals_and_objectives3:
		"You would need to first identify what you know and where you are at, this is where you will go through the mock exam and once completed Xample will analyse your answers, and give you a full report. Set a routine on a set time to spend at least 1 hour a day on the practice exam. You can do this at your convenience whilst travel or home at your leisure. Have a routine weekly to attempt a Real-time Exam at least once a week. Register and participate in our monthly and quarterly real-time competency evaluation tests and competitions. Both parents & Students can set Goals & Objectives and measure success from your individual Log-ins.",

	/* register */
	register_as_a: "Register as a {0}",
	register_parent_banner_content:
		"Discover how your child can enjoy learning the concepts, assessing them, improving the scores, and excelling in the tests.",
	register_student_banner_conetnt:
		"Discover how to enjoy learning concepts, assessing them, improving scores, and excelling in exams.",
	payment_processing: "Payment processing please wait...",
	donot_refresh_page: "(Do not refresh your page)",
	subscription_plan_type: "Subscription plan type",
	select_subscription: "Select Subscription",
	lang_subjects: "Language & Subjects",
	select_lang: "Select Language",
	subjects_included: "Subjects Included",
	no_of_children: "Number of Children",
	add_child: "Add Child",
	child: "Child",
	child_fullname: "Child Full Name",
	your_fullname: "Your Full Name",
	agreeing_terms: "By clicking Join you are agreeing to Xample’s",
	terms_and_conditions: "Terms and Conditions",
	price_details: "Price Details",
	all_subjects_included: "(All Subjects Included)",
	price: "Price",
	discount: "Discount",
	total: "Total",
	need_help: "Need Help?",
	email_us_on: "Email us on",
	call_us_on: "Call us on",
	month: "1 Month",
	threeMonth: "3 Months",
	sixMonth: "6 Months / Bi Annually",
	year: "12 Months / 1 Year / Annually",
	threeMonth_text: "3 Months",
	sixMonth_text: "6 Months",
	year_text: "Annually",
	click_here: "Click Here",

	/*sign-in*/
	signin_exam_portal: "Sign in to your exam portal",
	sigin_exam_portal_content:
		"{0} will lead you to the mastery of GCE O/L and A/L examinations through thousands of questions, comprehensive study notes, and powerful analysis.",
	are_you_new: "Are you New Here?",
	are_you_new_content:
		'{0} is a simple-to-use yet powerful "Testing & Revision Tool" that will help you ace your GCE O/L or A/L exams. Getting registered is as easy as choosing your preferred user type and creating a secured account. Achieve guaranteed success by practicing under a real-time setup to perfect your exam answering techniques, studying comprehensive study notes applied to each question, and knowing your strengths and weaknesses to excel in nationally competitive examinations.',
	signin: "Sign in",
	processing: "Processing...",
	parent: "Parent",
	student: "Student",
	teacher: "Teacher",
	provide_info: "Please provide the following information to proceed",
	enter_email: "Enter Your Email",
	enter_password: "Enter Your Password",
	forgot_password: "Forgot Password?",
	click_to_reset: "Click Right Here to Reset",
	join_as_now: "Join Now As a {0}",
	parent_as: "Parent",
	student_as: "Student",
	confirmation_message: " Confirmation Message",
	verification_message: "Verify Your Username",
	dialog_content:
		" You are already logged in from another device. Press Continue if you wish to clear the existing session and continue on this device.",

	/* change password */
	change_password: "Change Your Password?",
	enter_old_password: "Enter Old Password",
	enter_new_password: "Enter New Password",
	repeat_password: "Repeat Password",
	reset_password: "Reset Password?",
	retype_password: "Retype Password",
	isReq_old: "Old Password is required!",
	isReq_new: "New Password is required!",
	isReq_confirm: "Confirm Password is required!",

	/* reset password */
	isMinLengthReq: "Password must be at least 6 characters",
	matchPasswordReq: "New Password & Confirm Password need to be match",

	/* forgot username */
	forgot_username: "Forgot Your Username?",
	enter_email_tosend_username:
		" Enter Your Email address and we’ll send you a username to Your email.",
	cancel: "Cancel",

	/* membership activation */
	membership_activation: "Membership Activation",
	provide_info_to_proceed:
		"Please provide the following information to proceed",
	click_here_for_help: "Click Here for Help",
	activation_key: "Enter the activation key sent to your email",

	/* contact */
	contact_us: "Contact Us",
	make_enquiry: "Make an inquiry",
	email_address: "Email Address",
	inquiry_subject: "Inquiry Subject",
	your_enquiry: "Your Inquiry",

	/* Dashboard */
	/* My Test */
	mytests: "My Tests",
	tests: "Tests",
	hai: "Hi!",
	what_test_today: "What would you like to test today?",
	select_grade_sub_term: "Please select your grade, subject and term.",
	score: "Score",
	time_spent: "Time Spent ",
	duration: "Duration",
	questions: "Questions",
	today_goal: "Today Goal",
	test_session: "Test Session",
	correct: "Correct",
	wrong: "Wrong",
	unaswered: "Unanswered",
	marks: "Marks",
	actual_marks: "Actual Marks",
	continue: "Continue",
	in_progress: "In Progress",
	completed: "Completed",
	select_year: "Select Year",
	one_mock_exam_available_1: "You have 01",
	one_mock_exam_available_2: "mock exam available",
	resume_test: "Please click resume button to continue your test",
	no_question_available: "Sorry no question available",
	goto_mytests: "Go to My Tests",
	your_score: "Your Score",
	total_questions: "Total Questions",
	xample_plaform_is_a: " Xample plaform is a",
	novelwall: "Novelwall",
	product: "product",
	goal: "Goal",
	goals: "Goals",
	allowed_only_one_test_per_day: "Allowed only one test per day",
	please_upgrade_your_plan_attend_more_test: "Please upgrade your plan to attend more test.",
	cannot_continue_prev_test: "You cannot continue the previous test",
	please_upgrade_to_continue: "Please upgrade your plan to continue this test.",
	freemium_version_content: "One Past Paper out of all subjects (not each) is available in the Freemium Version.",
	free_plan_eligible_only_below_test: "Your are now free / trial plan so you are eligible only to attend the below tests.",
	please_upgrade_attend_all_test: "Please upgrade your plan to attend all test.",
	you_have_reached_daily_questions: "You have reached your daily 10 questions or 20 minutes of user time.",
	please_upgrade_get_full_access: "Please upgrade to get the full access.",
	your_plan_has_expired: "Your plan has been expired!",
	overall_time_based: "Overall (Time Based)",

	/* home defalut */
	prev: "Prev",
	next: "Next",
	register: "Register",
	xample: "Xample",
	tm: "TM",

	/* My Progress */
	myprogress: "My Progress",
	progress: "Progress",
	usage: "Usage",
	answered_question: "Answered Questions",
	min: "Min",

	/* My Goals */
	mygoals: "My Goals",

	/* Analytics */
	go_to_analytics: "Go to Analytics",
	analytics: "Analytics",
	topic_wise: "Topic Test",
	overall_Test: "Overall Test",
	overall: "Overall",
	mock_test: "Paper Test",
	contest: "Contest",
	topper: "Topper",
	target_area: "Target Area",
	test_scores: "Test Scores",
	revision_questions: "Revision Questions",
	reset: "Reset",
	today: "Today",
	this_week: "This week",
	last_seven_days: "Last 7 Days",
	this_month: "This Month",
	last_thirty_days: "Last 30 Days",
	this_year: "This year",
	sorry_no_records_found: "SORRY NO RECORD FOUND",
	contest_toppers_list: "Contest Toppers List",
	contest_toppers: "Contest Toppers",
	medium_single: "Medium",
	rank: "Rank",
	time_based: "Time Based",

	/* Membership page */
	join_community: "Join Our Community Now",
	join_community_content:
		"Boost your knowledge and experience with past papers and model papers, along with advanced analytical reporting, in an environment monitored and controlled.",
	as_a_student: "As a Student",
	as_a_parent: "As a Parent",

	/* Support */
	support_title: "Support",
	ur_tic_info: "Your Ticket Information",
	go_back: "Go Back",
	create_ticket: "Create Ticket",
	reset_search: "Reset Search",
	view: "View",
	my_ticket: "My Tickets",

	/* Validation */
	email_req: "Email is required!",
	valid_email: "Please enter a valid email",
	pwd_req: "Password is required!",
	pwd_min_len: "Password must be at least 6 characters",
	already_logged_in_1:
		"You are already logged in from another device. Press Continue if you wish to clear the existing session and continue on this device",
	already_logged_in_message:
		"Already you are logged in different browser/system! Please logout from logged in browser/system and logged in here.",
	name_req: "Name is required!",
	subject_req: "Subject is required!",
	inquiry_req: "Inquiry is required!",
	type_req: "Type is required!",
	verification: "Your example account is ready.  Please verify your username  to start enjoying xample.lk",

	/* header title */
	header_whoweare: "Who we are",
	header_analytics: "Analytics",
	header_mytest: "My Tests",
	header_dashboard: "Dashboard",
	header_support: "Support",
	header_pricing: "Pricing",
	header_welcome: "Welcome Back",
	header_settings: "Settings",
	header_payment: "Payment History",
	header_logout: "Logout",
	header_membership: "Membership",
	welcome_back: "Welcome Back",

	/*includes / RivisionQuestions */
	sessions: "Sessions",
	all_sessions: "All Sessions",
	select_option: "Select",
	answer_type: "Answer Type",
	co_answer: "Correct Answer",
	wr_answer: "Wrong Answer",
	view_explanation: " View Explanation",
	load_more: "Load More",

	/*includes / TargetArea */
	question_log: "Question Log",

	/*includes / TestScores */
	score: "Score",
	mastery: "Mastery",
	excellence: "Excellence",
	practiced: "Practiced",
	no_practice: "No Practice",
	loading: "Loading...",
	action: "Action",
	progress: "Progress",

	/* includes / usage */
	ans_question: "Answered Questions",
	time_spent: "Time Spent",
	made_progress_in_test: "Made Progress in Test",
	practice_test_sessions: "Practice Sesstions & Tests",

	/* mocktest */
	mock_subject: "Subject",
	mock_grade: "Grade",
	mock_term: "Term",
	mock_topic: "Topic",
	mock_mode: "Mode",
	mock_paper: "Paper",
	mock_question_year: "Question Year",
	mock_date_range: "Date Range",
	all: "All",
	model_paper: "Model Paper",
	past_paper: "Past Paper",
	questions_answered: "Questions Answered",
	last_practiced: "Last Practiced",
	paper_type: "Paper Type",
	mock_performance: "Mock exam performance - Time Based",
	mock_exam_sessions: "Mock exam sessions",

	/* overalltest */
	overall_test_scores: "Overall Test Scores",
	overall_test_sessions: "Overall test sessions",
	overall_child: "Child",
	all_time: "All Time",
	overall_test: "Test",
	exam_type: "Exam Type",

	/* faq */
	question_1: "What do Xample subjects include?",
	answer_1:
		"Each Xample subject is created, owned and managed by the instructor(s). " +
		"The foundation of each Xample subject include practicing the exams as in Time Based, " +
		"Practice-Based, Model-Based and Mock Exams.",
	question_2: "How do I take a Xample subject?",
	answer_2:
		"Xample subjects are entirely on-demand. You can begin the subjects whenever you like, " +
		"and there are no deadlines to complete them.",
	question_3:
		"Do I have to start my Xample subjects at a certain time? And how long do I have to complete it?",
	answer_3:
		"As noted above, there are no deadlines to begin or complete the subjects. " +
		"Even after you complete the subjects you will continue to have access to it, " +
		"provided that your accounts are in good standing, and Xample continues to have a " +
		"license to the subjects. To learn more about our Lifetime Access policy, please click here.",
	question_4: "Does Xample have a dashboard or reporting system?",
	answer_4:
		"Xample LMS provides advanced analysis and reports for tracking Learner progress, " +
		"suggestion for goal settings, once set the goals to keep you on track to get guaranteed “A” with your commitment.",
	question_5: "Are Xample programs applicable for all classes",
	answer_5:
		"Xample learning programs are for students from Grade 10 to Class 13, which is for students " +
		"who are sitting for Ordinary Level  and Advanced Level exams.",
	question_6:
		"Can I use Xample platform by connecting to a laptop/ desktop for a bigger screen?",
	answer_6:
		"Yes, we do have screen mirroring/casting features enabled for our LMS.",
	question_7: "When do I start getting charged?",
	answer_7:
		"This will be when you want to enjoy the full features of the Xample LMS at the end of your 14-days trial.",
	dummy_content:
		" Lorem ipsum dolor sit amet, consectetur adipiscing elit.Duis vel malesuada leo," +
		" quis aliquet massa. Lorem ipsum dolor sit amet, consectetur adipiscing elit." +
		"Duis vel malesuada leo, quis aliquet massa.Lorem ipsum dolor sit amet," +
		" consectetur adipiscing elit. Duis vel malesuada leo,quis aliquet massa.",
	update_my_profile: " How can i edit and update my Profile?",
	subscription_status: "How to Check my Subscription Status?",
	my_account: "My Account",
	payments_and_subscriptions: "Payments and subscriptions",
	test_related: "Test Related",
	feedback_and_relationships: "Feedback and relationships",

	/* footer */
	copy_rights_reserved: "Copyrights reserved @{0}",
	copy_rights_content: "{0} is a premier testing and revision tool created by Novelwall UK, one of the most trusted brands in the UK education industry, and ATOM, one of the top service brands in India's IT and engineering industry. A complete Virtual Learning Environment (VLE) for O/L and A/L MCQ testing and training, {0} includes more Than 120,000 O/L and A/L exam questions and answers accompanied by detailed explanations.",
	a_produt_of: "A Product of",
	terms_conditions: "Terms & Conditions",
	privacy_policy: "Privacy Policy",
	help_center: "Help Center",
	follow_us: "Follow Us on",
	copy_rights: "All Rights Reserved For Novelwall PVT ltd 2020.",

	/*support request*/
	signin_req: "Signing in",
	viewsubmit_req: "Viewing and Submitting Questions",
	anayltics_req: "Analytics",
	state_req: "State Standards",
	roaster_req: "Roster",
	other_req: "Other",
	issue_here: "Enter Your Issue Here",
	submit: "Submit",
	contact_info: "Your Contact Information",
	help_req: "Help Request",

	/* support ticket */
	close_confirmation: "Close Confirmation",
	cancel: "Cancel",
	confirm: "Confirm",
	request_for: "Request for",
	close_ticket: "Close Ticket",
	message_sending: "Message sending...",
	message_failed: "Message failed",
	discussion_board: "Discussion Board",
	close_this_ticket: "Are you sure you want to close this ticket?",

	/* support list */
	search_code: "Search Code",
	code: "Code",
	date: "Date",
	request_for: "Request for",
	description: "Description",
	status: "Status",
	action: "Action",

	/* terms conditions */
	acceptance_terms: "1.ACCEPTANCE OF TERMS",
	changed_terms: "2. Changed Terms",
	des_of_service: "3. DESCRIPTION OF SERVICES",
	equipment: "4. Equipment",
	user_conduct: "5. User Conduct",
	use_of_services: "6. USE OF SERVICES",
	password_and_security: "7. MEMBER ACCOUNT, PASSWORD, AND SECURITY",
	termination_and_refund: "8. Term (Monthly) Termination and Refunds",
	website_address:
		"10. NOTICE SPECIFIC TO DOCUMENTS AVAILABLE ON THIS YOUR WEBSITE ADDRESS",
	notice_regards:
		"11. NOTICES REGARDING SOFTWARE, DOCUMENTS AND SERVICES AVAILABLE ON THIS SITE",

	/* radio options */
	sorry_wrong_answer: "Sorry! Wrong Answer!",

	/* result */
	congratulation: "CONGRATULATIONS!",
	total_score: "Total Score",
	your_marks: "Your Marks",
	time_spent: "Time spent",
	explore_skills: "Explore Skills to practice",
	go_to_my_tests: "Go to My Tests",

	/* review */
	your_review: "Your Review",
	review: "Review",

	/* test */
	pause: "Pause",
	resume: "Resume",
	duration: "Duration",
	exit: "Exit",
	time_left: "Time Left",

	/* test session */
	start_now: "Start Now",
	start_new: "Start New",
	test_session: " Test Session",
	time_taken: "Time Taken",
	start_new_session: "Start a new session",
	next_session: "I’m Ready For the Next Session",

	/* forgot password */
	forgot_your_password: "Forgot Your Password?",

	/* manage childeren */
	manage_children: "Manage Children",
	s_no: "S.No",
	language: "Language",
	activate: "Activate",
	active: "Active",

	/* profile */
	profile_settings: "Profile Settings",
	user_information: "User Information",
	screen_name: "Screen Name",
	email: "Email",
	select_country: "Select Country",
	select_state: "Select State",
	select_city: "Select City",
	email_and_password: "Email and Password",
	username: "Username",
	password: "Password",
	change_password: "Change Password?",
	school_information: "School Information",

	/* coming soon */
	coming_soon: "Coming Soon",
	working_on: "We are working on it!",

	/* great job */
	you_did_a: "You Did a",
	great_job: "Great Job!",

	/* limit_exceed */
	limit_exceeded: "Your Free Limit has Exceeded",
	register_to_access: "Please Register to Access all the Exams",
	register_now: "Register Now",

	/* without login */
	we_are_sorry: "We are Sorry!",
	contact_support: "Please login to contact support!",
	log_in: "Log in",

	/* contact */
	info_email: "support@xample.lk",
	contact: "XXXXXXXXX",
	address:
		" SALES/BUSINESS DEVELOPMENT OFFICE - No 24 3/2, Level 3, Deal Place Colombo 3",
	address1: "SRI LANKA CORPORATE OFFICE – 276/A, Main Street, Battaramulla",
	head_quarters:
		"HEAD QUARTERS – Clarendon – Ealing Cross, Office 42, 85 Uxbridge Road, London W5 5TH",

	/* my headers */
	john_doe: "John Doe",
	sub_menu1: " Submenu1",
	sub_menu2: "Submenu2",
	sub_menu3: "Submenu3",
	sub_menu4: "Submenu4",

	/* grade_table */
	grade_f: "F - Failed",
	grade_s: "S - Simple Pass",
	grade_c: "C - Credit",
	grade_b: "B - Good",
	grade_a: "A - Distinction",

	/* progress preffered */
	time_spent: "Time Spent",
	questions: "Questions",

	/* set goals */
	per_week: "1hr per week",
	// per_month: "4 hrs per month",
	per_days: "12 hrs per 90 days",
	hr_per_day: " hr per day",
	hrs_per_week: "hrs per week",
	hrs_per_month: "hrs per month",
	set_your_goals: "Set your goals",
	like_to_test_today: "What would you like to test today?",
	please_select_grade: " Please select your grade, subject and term.",
	current_progress: "Current Progress",
	our_goal_sugestion: "Our Goal Sugestion",
	our_goal_sugestions: "Our Goal Sugestions",
	hrs_to_spend: "how many hours you can spend?",
	weekly: "Weekly",
	monthly: "Monthly",
	goal_set: "Goal set for",
	what_are_the_dates: "what are the dates?",

	/* view goals */
	sudgested_goals: "Suggested Goals",
	completed_goals: "Completed Goals",

	/* select language */
	lang_sinhala: "සිංහල",
	lang_tamil: "தமிழ்",
	lang_english: "English",

	/* settings */
	account_settings: "Account Settings",
	lorem_content:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel malesuada leo, quis aliquet massa.",
	uploading: "Uploading...",
	sign_out: "Sign Out",

	/* children activation */
	activate_your_child: "Activate Your Child",
	info_proceed: "Please provide the following information to proceed",
	click_here_for_help: "Click Here for Help",
	child_name: "Child Name",
	child_email: "Enter Child Email",
	child_password: "Enter Child Password",
	retype_child_password: "Retype Child Password",

	/* subject */
	lorem_sub_content:
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,remaining essentially unchanged.",
	take: "Take the",
	// overall_Test: "Overall Test",
	test_option: "Choose the test option",
	mock_exam: "Past Paper",
	overall_test_content:
		"Sed a augue pretium, euismod ex ac, vehicula magna. Vivamus ac risus quis dui lobortis blandit. Nunc auctor nisi sed magna faucibus, eget elementum lacus eleifend.",
	practice_based_test: "Practice Based Test",
	time_based_test: "Time Based Test",
	model_paper: "Model Paper",
	past_paper: "Past Paper",
	practice_based: "Practice Based",
	time_based: "Time Based",

	/* question content */
	question_no: " Question No",

	/* home student */
	your_score: "Your Score",
	edit_profile: " Edit Profile",

	/* home parent */

	/* payment */
	payment_history_renewal: "Payment History",
	back: "Back",
	payment_processing: "Payment processing please wait...",
	refresh_page: "(Do not refresh your page)",
	subscription_plan_type: "Subscription plan type",
	upgrade_plan: "Your are in free / trial plan please upgrade for more features",
	current_plan: "Current Plan - FREE",
	renewal_plan: "Your current plan has been expired please renew.",
	lkr: "LKR",
	select_plan: "Select Plan",

	/* pricing */
	signup: "Signup",
	limited_access: "Limited access to all 4 Subjects",
	access_questions:
		" Access to 10 questions and 20 minutes per subject per day*",
	analytics_sessions: "Analytics up to 4 test sessions a month",
	signup_with: "Signup with",
	novelty: "Novelty",
	access_limited_features_free: " to Access to Limited features for Free",
	advanced: "Advanced",
	advanced_price: "4,000LKR",
	introduction: "Introduction",
	access_all_subjects: "Access to all four subjects",
	time_based_test: " 8 Years or More Time-Based Tests and Model Paper Tests",
	free_customer_support: "8 Live Chat or Toll-Free Customer Support per Month",
	parent_login_access: "Parent Login access to 1 Child Account",
	children_progress_reports: "Children's Progress Reports",
	child_progress_notifi:
		" Parent will receive a Child's or Children's progress notification",
	access_limited_features_advanced:
		"to Access to Limited features for Advanced",
	premium: "Premium",
	premium_live_chat: " 20 Live Chat or Toll-Free Customer Support per Month",
	premium_parent_access: "Parent Login can access 2 or more Child Accounts",
	access_limited_features_premium: "to Access to Limited features for Premium",
	free_text: "Free",
	free: "Free + 01 Mock Exam",
	accessible_for: "Accessible for",
	medium: "Medium(s)",
	selected_medium_only: "Selected Medium Only",
	subjects: "Subjects",
	time_based_online_tests: "Time - Based Online Tests",
	unlimited_sessions: "Unlimited Sessions",
	model_based_online_tests: "Model - Based Online Tests",
	login_devices: "Login Devices at a time",
	technical_support_helpline:
		"Technical Support (Live Chats and Toll free Help line)",
	technical_support_tickets: "Technical Support (Tickets)",
	progress_report: "Progress Report and Goal Settings",
	subject_based: "Subject(s) based",
	terms_and_module_based: "Terms and Module based",
	activity_history: "Activity History",
	set_goals_and_timeline: "Set Goals and Timeline",
	attendance_and_notification: "Attendance and Notification Tracking",
	notifications_and_reminders: "Notifications and Reminders",
	track_goals: "Track Goals",
	quaterly_tests: "Examplelk Quaterly Tests",
	parent_login: "Parent Login",
	access_child_acc: "Access to Child accounts",
	unlimited: "Unlimited",
	manage_child_acc: "Manage Child Accounts",
	progress_attendance_tracking: "Progress and Attendance Tracking",
	notifications_progress: "Notifications in Progress",
	tutorials_support: "Tutorials and Support",
	video_tutorials: "Video Tutorials",
	teacher_support: "One-on-one teacher support",
	ask_teacher: "Ask a teacher (Live Chat)",
	study_groups: "Study Groups",
	challenge_friend: "Challenge a friend",
	community_leader_board: "Community Leader Board",

	signup_14day_trail: "Signup with 14 Days Free Trial",
	get_20perc_discount: "Get 20% Discount",
	when_signup_with: "When signup with",

	/*mygoals report */
	loading_report: "Loading reports please wait..",
	path_commits: "You're on the Right path keeps committing to",
	perfect_score: "same amount hours to keep up the perfect score.",

	// Success/Error Message
	invalid_login: "Please enter a valid username and password",
	login_success: "You are successfully logged in",
	login_failed: "Unable to login please try again",
	invalid_email: "Please enter a valid email",
	email_link_sent: "We have emailed you the password reset link!",
	//valid_email: "We have sent your username to your email!",
	support_error: "Unable to create support ticket",
	support_success: "Ticket has been created successfully",
	invalid_contact: "An error occured while sending the message, please try again.",
	valid_contact: "We received your message! We will get back to you shortly!",
	member_activation: "Activated Successfully.",
	child_updated: "Child updated successfully.",
	invalid_password: "Unable to reset your password",
	valid_password: "Password changed successfully!",
	user_success: "Register Successfully.",
	update_success: "Profile Updated Successfully.",
	update_failed: "Profile Updated Failed!.",
	profile_failed: "Failed to upload profile please try again!",
	activate_code: "Activation Key is required!",
	key_send_failed: "Activation Key send Failed!.",

	//snackbar messages
	session_expired: "Your session has been expired please login again",
	err_login_device: "error Your are logged in another device!",
	logout_success: "Logout successfully",
	select_sub_grade: "Please Select Subject and grade",
	incomplete_start_new: "Please complete the In Progress test and then start new test",
	test_upgrade: "This test need to upgrade",
	select_test: "Please select test to set goal",
	select_hrWeek: "Please select HourPerWeek to set goal",
	select_hrMonth: "Please select HourPerMonth to set goal",
	payment_declined: "Payment has been declined!",
	payment_cancelled: "Payment has been cancelled!",
	payment_error: "Payment error please try again later",
	ticket_closed: "Ticket has been closed",
	payment_renew: "Your payment plan has been expired please renew!",
	today_limit_over: "Today Free plan your limit is over! Please upgrade you plan",
	no_ques_available: "No questions available",
	pls_ans_question: "Please answer the question",
	already_reg_email: "User already registered please try different email.",

	contest_win: "Contest and Win",
	contest_score: "Contest Score",
	contest_performance: "Contest Performance",
	contest_session: "Contest Session",

	// api messages
	enter_username: "Please Enter Your username",
	enter_pwd: "Please Enter Your Password",
	something: "Something went wrong!",
	activation_invalid: "You Activation Key is Invalid!",
	usrname_already_taken: "Username already has been taken!",
	check_email_or_pwd: "Please check your email or password!",
	check_email: "Please check your email Id!",
	username_sent_to_mail: "Your username has been sent to your email Id!",
	activate_account: "Please activate your account!",
	invalid_cred: "Invalid credentials!",
	pwd_success: "Your password successfully updated!",
	pwd_cur_incorrect: "Your current password is incorrect!",
	pwd_incorrect: "Your password is incorrect!",
	pwd_old_incorrect: "Your old password is incorrect!",
	already_logged: "Already you are logged in different browser/system! Please logout from logged in browser/system and logged in here.",
	check_pwd: "Please check your password!",
	check_role: "Kindly check your role Student/Parent!",
	check_usr_pwd: "Please check your username or password!",
	no_data: "No data found.!",
	logged_another: "You are already logged in from another device. Press Continue if you wish to clear the existing session and continue on this device",
	forgot_enter_email: "Enter Your Email address and we’ll send you a reset link to Your email.",
	send: "send",
	loading_msg: "Loading details please wait",
	minutes: "minutes",
	per_month: "per month",
	per_month_text: "{0} per month",
	opps: "Oops! Something went wrong",
	try_again: "Try Again",
	pls_select_plan: "Please select plan",
	pls_select_subscription: "Please select subscription",
	pls_enter_email: "Please enter the email",
	pls_enter_valid_email: "Please enter the valid email",
	pls_enter_child_name: "Please enter the name for child",
	pls_enter_contact_name: "Please enter the full name",
	pls_check_your_terms_conditions: "Please check your terms and conditions",
	novelty_signup_free: "Signup with {0} to redeem further 20%",
	novelty_signup_adv: "Signup with {0} to redeem further 20%",
	novelty_signup_pre: "Signup with {0} to redeem further 20%",

	explanation: "EXPLANATION",

	//subscription mesages
	expire_msg: "Your subscription plan has been expired please renew.",
	renew_now: "Renew now!",
	will_expire: "Your subscription plan will expire in",
	days: "days",
	expire_today: "Your subscription plan today expires at",

	apply: "Apply",

	paper_performance: "Paper performance",
	paper_session: "Paper session",
	paper_score: "Paper Score",

	// join now tooltip content
	join_now_student_tooltip_content: "The Student Login does not enable parents to keep track of their child.",
	join_now_parent_tooltip_content: "Parent Login allows parents to keep track of their child's progress.",

	child_goal: "Today you have a goal",
	parent_goal: "Today child have a goal",
	past_model_paper: "Past/Model Paper",
	critical: "Critical!",
	overall_test_performance: "Overall test performance",
	question_answer: "Questions and Answers",

	// change Email
	change_email: "Change Your Email",
	mobile_req: "Mobile number is required!",
	change_mobile: "Change Your Mobile",
};
