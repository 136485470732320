import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { common, tools } from "helpers";
import GradeTable from "./GradeTable";
import { apiServices } from "api";
let translation = common.getTranslation();

class SetGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      userInfo: this.props.userInfo,
      showSetGoal: false,
      subjectName: "",
      grade: [],
      gradeName: "",
      termsName: "",
      terms: [],
      selectedGradeId: "",
      selectedTermsId: "",
      selectedSubjectId: "",
      goalFields: {
        user: "",
        class: "",
        topic: "",
        skill: "",
        subject: "",
        test: "",
        hourPerDay: 1,
        hourPerWeek: 0,
        hourPermonth: 0,
        selectedDates: [],
        id: 0,
      },
      subject: this.props.subject,
      termTestList: [],
      subTotGoalHourPerWeek: 0,
      GradeScore: {},
      formSubmit: false,
      };
  }

  setGoalByProgress = (data) => {
    const { subject, userInfo } = this.state;
    if (data.classId && data.topicId) {
      let sIdx = subject.findIndex((element) => {
        return data.classId == element.id && data.topicId == element.gradeId;
      });

      this.setState(
        {
          selectedSubjectId: data.classId,
          selectedGradeId: data.topicId,
          subjectName: subject[sIdx].name,
          gradeName: subject[sIdx].gradeName,
          selectedTermsId: subject[sIdx].testList[0].term["id"],
          termsName: subject[sIdx].testList[0].term["name"],
          grade: userInfo.items[data.classId].grade,
          terms: userInfo.items[data.classId].term,
          showSetGoal: false
        },
        () => {
          this.getTermTestNames();
        }
      );
    }
  };

  // event calendar
  checkUsage(info) {
    const { goalFields } = this.state;
    let currentDate = moment(info.date).format("YYYY-MM-DD");
    if (goalFields.selectedDates.includes(currentDate)) {
      return "event-active";
    }
  }

  handleDateClick = (e) => {
    let date = e.dateStr;
    const { goalFields } = this.state;
    let sDates = goalFields.selectedDates;
    if (!sDates.includes(date)) {
      sDates.push(date.toString());
    } else {
      sDates = sDates.filter((item) => item !== date);
    }
    goalFields.selectedDates = sDates;
    this.setState({
      goalFields,
    });
  };

  showSetGoal = (e, test) => {
    e.preventDefault();
    const {
      userId,
      goalFields,
      selectedSubjectId,
      selectedTermsId,
      selectedGradeId,
    } = this.state;
    goalFields.test = test.id;
    goalFields.class = selectedSubjectId;
    goalFields.topic = selectedGradeId;
    goalFields.skill = selectedTermsId;
    goalFields.user = userId;
    if (test.goal) {
      goalFields.id = test.goal.id;
      goalFields.hourPerDay = test.goal.hourPerDay;
      goalFields.hourPerWeek = test.goal.hourPerWeek;
      goalFields.hourPermonth = test.goal.hourPermonth;
      let sDates = [];
      test.goal.selectedDates.map((sDate) => {
        sDates.push(moment(sDate).format("YYYY-MM-DD"));
      });
      goalFields.selectedDates = sDates;
    } else {
      goalFields.id = 0;
      goalFields.hourPerDay = 1;
      goalFields.hourPerWeek = 0;
      goalFields.hourPermonth = 0;
      goalFields.selectedDates = [];
    }
    tools.scrollEnd();
    this.setState({
      showSetGoal: true,
      goalFields,
    });
  };

  onChange(e) {
    const {
      goalFields,
      termTestList,
      selectedGradeId,
      selectedSubjectId,
      selectedTermsId,
      userId,
    } = this.state;
    const { name, value } = e.target;
    goalFields[name] = value;
    this.setState({
      goalFields,
    });
    if (name == "test") {
      {
        termTestList.map((tests) => {
          if (value == tests.id && tests.goal) {

            let goal = tests.goal;
            goalFields.id = goal.id;
            goalFields.class = selectedSubjectId;
            goalFields.topic = selectedGradeId;
            goalFields.skill = selectedTermsId;
            goalFields.user = userId;
            goalFields.hourPerDay = goal.hourPerDay;
            goalFields.hourPerWeek = goal.hourPerWeek;
            goalFields.hourPermonth = goal.hourPermonth;
            let sDates = [];
            goal.selectedDates.map((sDate) => {
              sDates.push(moment(sDate).format("YYYY-MM-DD"));
            });
            goalFields.selectedDates = sDates;
            this.setState({ goalFields });
          } else if (value == tests.id) {
            goalFields.test = tests.id;
            goalFields.class = selectedSubjectId;
            goalFields.topic = selectedGradeId;
            goalFields.skill = selectedTermsId;
            goalFields.user = userId;
            goalFields.id = 0;
            goalFields.hourPerDay = 1;
            goalFields.hourPerWeek = 0;
            goalFields.hourPermonth = 0;
            goalFields.selectedDates = [];
            this.setState({ goalFields });
          }
        });
      }
    }
  }

  onChangeSubjects = (key, item) => {
    if (key !== null) {
      this.setState({
        subjectName: item.name,
        grade: item.grade,
        selectedSubjectId: key,
        terms: item.term,
      });
    } else {
      this.setState({
        subjectName: "",
        grade: [],
        selectedSubjectId: "",
        terms: [],
      });
    }

    this.setState({
      selectedGradeId: "",
      selectedTermsId: "",
      gradeName: "",
      termsName: "",
      status: "empty",
      showSetGoal: false,
    });
  };

  onChangeGrade = (key, name) => {
    if (key !== null) {
      this.setState({
        gradeName: name,
        selectedGradeId: key,
      });
    } else {
      this.setState({
        gradeName: "",
        selectedGradeId: "",
      });
    }
    this.setState({
      selectedTermsId: "",
      termsName: "",
      testInfo: [],
      status: "empty",
      showSetGoal: false,
    });
  };

  onChangeTerm = (key, name) => {
    if (key !== null) {
      this.setState(
        {
          termsName: name,
          selectedTermsId: key,
          showSetGoal: false,
        },
        (_) => {
          this.getTermTestNames();
        }
      );
    } else {
      this.setState({
        termsName: "",
        selectedTermsId: "",
        status: "empty",
        showSetGoal: false,
      });
    }
  };

  getTermTestNames = () => {
    const {
      userId,
      selectedSubjectId,
      selectedGradeId,
      selectedTermsId,
      subject,
    } = this.state;
    const { userInfo } = this.props;
    if (selectedSubjectId && selectedGradeId && selectedTermsId) {
      let sIdx = subject.findIndex((element) => {
        return (
          selectedSubjectId == element.id && selectedGradeId == element.gradeId
        );
      });

      subject[sIdx].testList.map((terms, tIdx) => {
        if (terms.term.id == selectedTermsId) {
          let termTestList = terms.test;

          let GradeScore = {
            totalScore: common.getScoreCategory(subject[sIdx].totalScore),
            totalTimeSpent: subject[sIdx].totalTimeSpent
          };

          this.setState({
            GradeScore,
            termTestList,
            subTotGoalHourPerWeek: subject[sIdx].totGoalHourPerWeek,
          });
        }
      });
    }
  };

  saveGoal = (e) => {
    e.preventDefault();
    const {
      goalFields,
      userId,
      selectedGradeId,
      selectedSubjectId,
      selectedTermsId,
    } = this.state;
    let isValid = true;
    if (goalFields.test == "") {
      common.snack("E",translation.select_test);
      isValid = false;
    } else if (goalFields.hourPerWeek == "" || goalFields.hourPerWeek == 0) {
      common.snack("E",translation.select_hrWeek);
      isValid = false;
    } else if (goalFields.hourPermonth == "" || goalFields.hourPermonth == 0) {
      common.snack("E", translation.select_hrMonth);
      isValid = false;
    }
    if (isValid && goalFields.id && goalFields.id != 0) {
      this.setState({ formSubmit: true });
      //update
      let urlKeys = {
        id: goalFields.id,
      };
      let sDates = [];
      goalFields.selectedDates.map((sDate) => {
        sDates.push(new Date(sDate));
      });

      let jsonBoday = {
        user: userId,
        class: selectedSubjectId,
        topic: selectedGradeId,
        skill: selectedTermsId,
        // subject: goalFields.subject,
        test: goalFields.test,
        isActive: true,
        hourPerDay: goalFields.hourPerDay,
        hourPerWeek: goalFields.hourPerWeek,
        hourPermonth: goalFields.hourPermonth,
        selectedDates: sDates,
      };

      let data = {
        url: "UPDATE_GOAL",
        body: jsonBoday,
        method: "PUT",
        key: urlKeys,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result;
          if (status == "success") {
            result = common.apiResultData(res);
            let msg = common.getLangMessage(result.message);
            common.snack("S", msg);
            this.props.getGoals();
          }
          if (status == "empty") {
            result = common.apiResultData(res);
            let msg = common.getLangMessage(status);
            common.snack("E", msg);
          }
        },
        (error) => { },
        (final) => {
          this.setState({ formSubmit: false });
        }
      );
    } else if (isValid && goalFields.id == 0) {
      this.setState({ formSubmit: true });
      //create
      let jsonBoday = {
        user: userId,
        class: selectedSubjectId,
        topic: selectedGradeId,
        skill: selectedTermsId,
        // subject: goalFields.subject,
        test: goalFields.test,
        isActive: true,
        hourPerDay: goalFields.hourPerDay,
        hourPerWeek: goalFields.hourPerWeek,
        hourPermonth: goalFields.hourPermonth,
        selectedDates: goalFields.selectedDates,
      };
      let data = {
        url: "CREATE_GOAL",
        body: jsonBoday,
        method: "POST",
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result;
          if (status == "success") {
            result = common.apiResultData(res);
            goalFields.id = result.data._id;
            this.setState({
              goalFields
            });
            this.props.getGoals();
            let msg = common.getLangMessage(result.message);
            common.snack("S", msg);
          }
          if (status == "empty") {
            result = common.apiResultData(res);
            let msg = common.getLangMessage(result.message)
            common.snack("E", msg);
          }
        },
        (error) => { },
        (final) => {
          this.setState({ formSubmit: false });
        }
      );
    }
  };

  getDayNames = (dates) => {
    let days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    let selDay = [];
    if (dates && dates.length > 0) {
      dates.map((date) => {
        let d = new Date(date);
        let dayName = days[d.getDay()];
        if (!selDay.includes(dayName)) {
          selDay.push(dayName);
        }
      })
      return selDay.join(',')
    } else {
      return "";
    }
  }

  render() {
    const { userInfo } = this.props;
    const {
      showSetGoal,
      subjectName,
      gradeName,
      termsName,
      grade,
      terms,
      termTestList,
      subTotGoalHourPerWeek,
      selectedGradeId,
      selectedSubjectId,
      selectedTermsId,
      goalFields,
      GradeScore,
      formSubmit
    } = this.state;
    return (
      <div className="tab-body">
        <div className="title-block mb-4">
          {/* <h3>SET GOALS</h3> */}
          <h3>
            {translation.setgoals_title}
          </h3>
        </div>
        {selectedSubjectId && selectedGradeId && selectedTermsId && (
          <div className="set-goals-wrap">
            <h5 className="text-right mb-0">Hours</h5>
            <GradeTable score={GradeScore} />
            <div className="keep-perfect-score d-flex flex-wrap justify-content-center align-items-center mt-5 mb-4">
              <img src={common.loadImg("youreontheright_img.png")} />
              <h6>
                You're on the Right path keeps committing to
                <br /> same amount hours to keep up the perfect score.
              </h6>
            </div>
          </div>
        )}

        <div>
          <div className="goal-suggestions-wrap mt-5 pt-5">
            <div className="title-block mb-3">
              {/* <h3>Our goal suggestions</h3> */}
              <h3>
                {translation.our_goal_sugestions}
              </h3>
            </div>

            <div className="filter-wrap row mb-5">
              <div className="col-md-4">
                <div className="select-box">
                  <div className="select-box__current" tabIndex="1">
                    <div className="select-box__value">
                      <input
                        className="select-box__input"
                        type="radio"
                        id="subject_1"
                        value="2"
                        name="subject"
                        defaultChecked="checked"
                      />
                      <p className="select-box__input-text">
                        {subjectName !== "" ? subjectName : translation.mock_subject}
                      </p>
                    </div>
                    <div className="select-box__icon">
                      <img
                        src={common.loadImg("dropdown-arrow.png")}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <ul className="select-box__list">
                    {userInfo &&
                      userInfo.items &&
                      Object.entries(userInfo.items).map((item, key) => {
                        return (
                          <li
                            key={key}
                            onClick={(e) =>
                              this.onChangeSubjects(item[0], item[1])
                            }
                          >
                            <label
                              className="select-box__option"
                              htmlFor="subject_0"
                              aria-hidden="aria-hidden"
                            >
                              {item[1].name}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="select-box">
                  <div className="select-box__current" tabIndex="2">
                    <div className="select-box__value">
                      <input
                        className="select-box__input"
                        type="radio"
                        id="grade_2"
                        value="2"
                        name="grade"
                        defaultChecked="checked"
                      />
                      <p className="select-box__input-text">
                        {gradeName !== "" ? gradeName : translation.mock_grade}
                      </p>
                    </div>
                    <div className="select-box__icon">
                      <img
                        src={common.loadImg("dropdown-arrow.png")}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <ul className="select-box__list">
                    {grade &&
                      grade.length > 0 &&
                      grade.map((item, key) => {
                        return (
                          <li
                            key={key}
                            onClick={(e) =>
                              this.onChangeGrade(item, userInfo.names[item])
                            }
                          >
                            <label
                              className="select-box__option"
                              htmlFor="grade_0"
                              aria-hidden="aria-hidden"
                            >
                              {userInfo.names[item]}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="select-box">
                  <div className="select-box__current" tabIndex="3">
                    <div className="select-box__value">
                      <input
                        className="select-box__input"
                        type="radio"
                        id="term_0"
                        value="2"
                        name="term"
                        defaultChecked="checked"
                      />
                      <p className="select-box__input-text">
                        {termsName !== "" ? termsName : translation.mock_term}
                      </p>
                    </div>
                    <div className="select-box__icon">
                      <img
                        src={common.loadImg("dropdown-arrow.png")}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <ul className="select-box__list">
                    {terms &&
                      terms.length > 0 &&
                      terms.map((item, key) => {
                        return (
                          <li
                            key={key}
                            onClick={(e) =>
                              this.onChangeTerm(item, userInfo.names[item])
                            }
                          >
                            <label
                              className="select-box__option"
                              htmlFor="term_0"
                              aria-hidden="aria-hidden"
                            >
                              {userInfo.names[item]}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>

            {selectedSubjectId && selectedGradeId && selectedTermsId && (
              <div className="table-responsive mb-3">
                <table className="table progress-table table-borderless">
                  <thead>
                    <tr>
                      <th width="30%"></th>
                      <th>{translation.current_progress}</th>
                      <th>{translation.our_goal_sugestion}</th>
                      <th width="15%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {termTestList.map((tests, idx) => {
                      let tQCount = tests.completed ? tests.question : tests.tQCount;
                      let correct = tests.correct ? tests.correct : 0;
                      let wrong = tests.question
                        ? tests.question - tests.correct
                        : 0;
                      let correctPercentage = Math.round(
                        (correct / tQCount) * 100
                      );
                      let wrongPercentage = Math.round((wrong / tQCount) * 100);
                      let unanswered =
                        100 - (correctPercentage + wrongPercentage);
                      return (
                        <tr key={idx}>
                          <td>{tests.test}</td>
                          <td>
                            <div className="test-session-report">
                              <div className="progress-bar-values d-flex">
                                {correctPercentage > 0 && (
                                  <span
                                    style={{
                                      width: correctPercentage + "%",
                                    }}
                                  >
                                    {correctPercentage}%
                                  </span>
                                )}
                                {wrongPercentage > 0 && (
                                  <span
                                    style={{
                                      width: wrongPercentage + "%",
                                    }}
                                  >
                                    {wrongPercentage}%
                                  </span>
                                )}
                                {unanswered > 0 && (
                                  <span
                                    style={{
                                      width: unanswered + "%",
                                    }}
                                  >
                                    {unanswered}%
                                  </span>
                                )}
                              </div>
                              <div className="progress progress-sm">
                                {correctPercentage > 0 && (
                                  <div
                                    className="progress-bar bg-success"
                                    style={{
                                      width: correctPercentage + "%",
                                    }}
                                  ></div>
                                )}
                                {wrongPercentage > 0 && (
                                  <div
                                    className="progress-bar bg-danger"
                                    style={{
                                      width: wrongPercentage + "%",
                                    }}
                                  ></div>
                                )}
                                {unanswered > 0 && (
                                  <div
                                    className="progress-bar bg-warning"
                                    style={{
                                      width: unanswered + "%",
                                    }}
                                  ></div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="goal-sugestion">
                              <div className="progress progress-sm">
                                <div
                                  className="progress-bar bg-info"
                                  style={{ width: "70%" }}
                                ></div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {tests.goal ? (
                              <h6 className="total-hours">
                                {tests.goal.hourPerDay} {translation.hr_per_day}
                                <br /> {tests.goal.hourPerWeek} {translation.hrs_per_week}
                                <br /> {tests.goal.hourPermonth} {translation.hrs_per_month}
                              </h6>
                            ) : (
                                <h6 className="total-hours">
                                 {translation.per_week}
                                  <br /> {translation.per_month}
                                  <br /> {translation.per_days}
                                </h6>
                              )}
                            {tests.goal ? (
                              <Link
                                to="#"
                                className="btn-sm completed"
                                onClick={(e) => this.showSetGoal(e, tests)}
                              >
                                {translation.view}
                              </Link>
                            ) : (
                                <Link
                                  to="#"
                                  className="btn-sm"
                                  onClick={(e) => this.showSetGoal(e, tests)}
                                >
                                  {translation.set_your_goals}
                                </Link>
                              )}
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td></td>
                      <td>
                        <div className="test-session-report">
                          <div className="status-info mt-2">
                            <label className="success">{translation.correct}</label>
                            <label className="danger">{translation.wrong}</label>
                            <label className="warning">{translation.unanswered}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="goal-sugestion">
                          <h4 className="goal-hours">
                            Total hrs: {subTotGoalHourPerWeek}
                          </h4>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {(!selectedSubjectId || !selectedGradeId || !selectedTermsId) && (
              <div className="subject-block">
                <div className="row">
                  <div className="col-md-4 offset-md-2">
                    <div className="subject-content">
                      <h2>{translation.hai}</h2>
                      <h4>{translation.what_test_today}</h4>
                      <p>
                      {translation.select_grade_sub_term}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={common.loadImg("example_subjects_img.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {showSetGoal && (
            <div>
              <div className="set-goals set-goals-wrap mt-3 pt-5">
                <div className="row">
                  <div className="col-md-6">
                    <h5 className="mb-4">{translation.set_your_goals}</h5>
                    <label>{translation.hrs_to_spend}</label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <select
                        className="form-control"
                        name="test"
                        value={goalFields.test}
                        onChange={(e) => this.onChange(e)}
                      >
                        {termTestList.map((tests, idx) => {
                          return (
                            <option value={tests.id} key={idx}>
                              {tests.test}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="row mb-4">
                      <div className="col-lg-6">
                        <select
                          className="form-control"
                          value={goalFields.hourPerWeek}
                          name="hourPerWeek"
                          onChange={(e) => this.onChange(e)}
                        >
                          <option value="0">{translation.weekly}</option>
                          {[...Array(12)].map((_, index) => (
                            <option key={index} value={index + 1}>{index + 1}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-6">
                        <select
                          className="form-control"
                          value={goalFields.hourPermonth}
                          name="hourPermonth"
                          onChange={(e) => this.onChange(e)}
                        >
                          <option value="0">{translation.monthly}</option>
                          {[...Array(24)].map((_, index) => (
                            <option key={index} value={index + 13}>{index + 13}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label>{translation.what_are_the_dates}</label>
                  </div>
                  <div className="col-md-6">
                    <div className="custom-calendar-2 green-highlights">
                      <FullCalendar
                        id="goalCalendar"
                        defaultView="dayGridMonth"
                        header={{
                          right: "prev,next,title",
                        }}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        dateClick={this.handleDateClick}
                        dayCellClassNames={(info) => this.checkUsage(info)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Set goals End --> */}

              <div className="goal-set-for-week d-flex align-items-center mt-5 mb-4">
                <h5>{translation.goal_set}</h5>
                <p className="mb-0">
                  {goalFields.hourPerDay} {translation.hr_per_day} <span>|</span>{this.getDayNames(goalFields.selectedDates)} <span>|</span>
                  {goalFields.hourPerWeek} {translation.hrs_per_week}
                </p>
                <img src={common.loadImg("tick.png")} />
              </div>
              {/* <!-- Goal set for week End --> */}
              <div className="action-button text-right mt-5">
                <input
                  type="button"
                  className="btn-sm continue"
                  disabled={formSubmit}
                  onClick={(e) => this.saveGoal(e)}
                  value={formSubmit ? "Processing..." : "Continue"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SetGoal;
