import React from "react";
import { Link } from "react-router-dom";
// includes
import { apiServices } from "api";
import { Handler } from "includes";
import { common, history, message, tools } from "helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Helmet } from "react-helmet";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
let translation = common.getTranslation();
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentLoading: false,
      parentLoading: false,
      teacherLoading: false,
      formSubmit: false,
      fields: {
        studentEmailId: "",
        studentPassword: "",
        parentEmailId: "",
        parentPassword: "",
        teacherEmailId: "",
        teacherPassword: "",
        mobile: "",
        email: "",
        activationKey: "",
        password: "",
        confirmPassword: "",
      },
      type: "email",
      errors: {},
      role: "",
      isModelOpen: false,
      isVerifyModelOpen: false,
      isAllowed: false,
      translation: common.getTranslation(),
      userDetails: {},
      acceptVerification: false,
      isLoading: false,
      verificationSubmit: false,
      isActivation: false,
    };
  }

  // handler
  onSubmit(key) {
    const { translation, fields, isAllowed, type } = this.state;
    this.setSubmit(true);
    let error = {};
    if (key == "student") {
      error = {
        studentEmailId: {
          error: "",
          isReq:
            type == "email"
              ? translation.email_req
              : "Mobile Number is required!",
          isValidEmail: type == "email" ? true : false,
          isValidEmailReq: type == "email" ? translation.valid_email : "",
          minLength: type == "mobile" ? 9 : 0,
          maxLength: type == "mobilields.se" ? 9 : 0,
          isMinLengthReq:
            type == "mobile" ? "Mobile number must be a 9 digits" : "",
        },
        studentPassword: {
          error: "",
          minLength: 6,
          isReq: translation.pwd_req,
          isMinLengthReq: translation.pwd_min_len,
        },
      };
    } else if (key == "parent") {
      error = {
        parentEmailId: {
          error: "",
          isReq: translation.email_req,
          isValidEmail: true,
          isValidEmailReq: translation.valid_email,
        },
        parentPassword: {
          error: "",
          minLength: 6,
          isReq: translation.pwd_req,
          isMinLengthReq: translation.pwd_min_len,
        },
      };
    } else {
      error = {
        teacherEmailId: {
          error: "",
          isReq: translation.email_req,
          isValidEmail: true,
          isValidEmailReq: translation.valid_email,
        },
        teacherPassword: {
          error: "",
          minLength: 6,
          isReq: translation.pwd_req,
          isMinLengthReq: translation.pwd_min_len,
        },
      };
    }
    this.setState(
      {
        role: key,
        errors: error,
      },
      (_) => {
        let isValid = this.handler.validateForm();
        if (isValid) {
          this.setLoading(true);
          // api call
          let params = {
            // email:
            //   key == "student" ? fields.studentEmailId : key == "parent" ?  fields.parentEmailId : fields.teacherEmailId,
            password:
              key == "student"
                ? fields.studentPassword
                : key == "parent"
                ? fields.parentPassword
                : fields.teacherPassword,
            type: key,
            isAllowed: isAllowed,
          };
          if (key == "student" && type == "mobile") {
            params["mobile"] = fields.studentEmailId;
          } else {
            params["email"] =
              key == "student"
                ? fields.studentEmailId
                : key == "parent"
                ? fields.parentEmailId
                : fields.teacherEmailId;
          }
          let data = {
            url: "SIGNIN",
            body: params,
            authorization: "none",
          };

          apiServices.call(
            data,
            (res) => {
              let status = common.apiStatus(res);
              let result = common.apiResult(res);

              if (status == "success") {
                // if (result.regType != undefined && result.regType == "email") {
                //   fields["email"] = result.email;
                // } else if (
                //   result.regType != undefined &&
                //   result.regType == "mobile"
                // ) {
                //   fields["mobile"] = result.mobile;
                // }
                this.setState({
                  isAllowed: false,
                  userDetails: result,
                });
                if (result.role == 1) {
                  this.loginData(result, []);
                  common.redirect("/", 1.5);
                } else {
                  common.setStorage("tempToken", result.token);
                  this.getSubject(result);
                }
              } else {
                // common.snack("E", res.data.message);
                if (
                  res.data.message ==
                  "Already you are logged in different browser/system! Please logout from logged in browser/system and logged in here."
                ) {
                  this.setState({
                    isModelOpen: true,
                  });
                }
              }
              this.setLoading(false);
            },
            (error) => {
              this.setLoading(false);
            },
            (final) => {}
          );
        }
      }
    );
  }

  getSubject = (user) => {
    let urlKeys = {
      user: user._id,
    };
    let data = {
      url: "GET_USER_SUBJECT_GRADE",
      method: "GET",
      key: urlKeys,
      authorization: "temp",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          this.loginData(user, result);
          // if (user.isVerify !== undefined && user.isVerify == false) {
          //   this.setState({
          //     isVerifyModelOpen: true,
          //   });
          // } else {
            common.redirect("/", 1.5);
          // }
        }
      },
      (error) => {},
      (final) => {
        this.setLoading(false);
        localStorage.removeItem("tempToken");
      }
    );
  };

  loginData(user, subject) {
    let userData = {
      user: {
        name: user.name,
        screenName: user.screenName,
        email:
          user.role != 2
            ? user.email
            : user.email
            ? user.email
            : user.parentUserId.email,
        mobile: user.mobile, 
        role: user.role,
        position: user.position,
        country: user.country,
        img: user.img,
        userId: user._id,
        parentId: user.parentUserId,
        loginTime: user.loginTime,
        userInfo: user.userInfo,
        isVerify: user.isVerify,
        regType: user.regType
      },
      subject: subject.class,
    };
    common.setUsrLngMedium(user.country);
    common.setStorage("userId", user._id);
    // if (user.isVerify !== undefined && user.isVerify == true) {
      common.setStorage("userToken", user.token);
    // }
    common.setStorage("userData", tools.crypt(userData, true));
    common.snack("S", this.state.translation.login_success);
  }

  // event
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      const { name } = e.target;
      if (name == "studentEmailId" || name == "studentPassword") {
        this.onSubmit("student");
      } else if (name == "parentEmailId" || name == "parentPassword") {
        this.onSubmit("parent");
      } else {
        this.onSubmit("teacher");
      }
    }
  };

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    const { role } = this.state;
    this.setState({
      studentLoading: role == "student" && status,
      parentLoading: role == "parent" && status,
    });
  }

  onChangeHandler = (e, role) => {
    const { name } = e.target;
    const { fields } = this.state;
    if (name == "studentEmailId" || name == "studentPassword") {
      fields["parentEmailId"] = "";
      fields["parentPassword"] = "";
      fields["teacherEmailId"] = "";
      fields["teacherPassword"] = "";
      this.setState({
        parentLoading: false,
        teacherLoading: false,
      });
    } else if (name == "parentEmailId" || name == "parentPassword") {
      fields["studentEmailId"] = "";
      fields["studentPassword"] = "";
      fields["teacherEmailId"] = "";
      fields["teacherPassword"] = "";
      this.setState({
        studentLoading: false,
        teacherLoading: false,
      });
    } else {
      fields["studentEmailId"] = "";
      fields["studentPassword"] = "";
      fields["parentEmailId"] = "";
      fields["parentPassword"] = "";
      this.setState({
        studentLoading: false,
        parentLoading: false,
      });
    }
    this.setState({
      role: role,
      fields,
    });
    this.onChange(e);
  };

  gotoRegister(e, type) {
    e.preventDefault();
    localStorage.removeItem("pricePlan");
    history.push("/pricing/" + type);
  }

  gotoMember(e, type) {
    e.preventDefault();
    if (type == "student") {
      history.push("/register/student");
    } else if (type == "parent") {
      history.push("/register/parent");
    } else {
      history.push("/membership");
    }
  }

  callLogin = () => {
    const { role } = this.state;
    this.setState(
      {
        isModelOpen: false,
        isAllowed: true,
      },
      (_) => {
        this.onSubmit(role);
      }
    );
  };

  callVerify = (e) => {
    e.preventDefault();
    const { errors, userDetails } = this.state;
    if (userDetails && userDetails.regType == "email") {
      errors["email"] = {
        error: "",
        isReq: translation.email_req,
        isValidEmail: true,
        isValidEmailReq: translation.valid_email,
      };
    } else {
      errors["mobile"] = {
        error: "",
        isReq: translation.mobile_req,
      };
    }
    this.setState({
      acceptVerification: true,
      errors,
    });
  };

  closeModal = () => {
    this.setState({
      isModelOpen: false,
    });
  };

  closeVerifyModal = () => {
    this.setState(
      {
        isVerifyModelOpen: false,
        acceptVerification: false,
        isActivation: false,
        verificationSubmit: false,
      },
      (_) => {
        common.removeAuthStorage();
      }
    );
  };

  // handler
  onChange = (event, callback = false) => {
    const { fields } = this.state;
    const { name, value } = event.target;
    fields[name] = value;
    if (name == "mobile") {
      fields[name] = value.substring(0, 9);
    }
    this.setState(fields);
    if (callback) {
      this.callBack(name);
    }
    this.handler.validateForm();
  };

  // Validation
  validateForm() {
    const { fields, errors } = this.state;
    let isValid = true;
    for (var key of Object.keys(errors)) {
      errors[key].error = "";
      if (fields[key] == "" || fields[key] == null) {
        errors[key].error = errors[key].isReq;
        isValid = false;
      } else if (
        errors[key].minLength &&
        fields[key].length < errors[key].minLength
      ) {
        errors[key].error = errors[key].isMinLengthReq;
        isValid = false;
      } else if (
        errors[key].maxLength &&
        fields[key].length > errors[key].maxLength
      ) {
        errors[key].error = errors[key].isMinLengthReq;
        isValid = false;
      } else if (isNaN(fields[key])) {
        if (errors[key].isValidEmail && !tools.ValidateEmail(fields[key])) {
          errors[key].error = errors[key].isValidEmailReq;
          isValid = false;
        }
      } else if (errors[key].matchPassword) {
        if (fields[key] !== fields["password"]) {
          errors[key].error = errors[key].matchPasswordReq;
          isValid = false;
        }
      }
    }
    this.setState({
      errors,
    });
    return isValid;
  }

  onTypeChange = (e) => {
    const { value } = e.target;
    const { fields } = this.state;
    fields["studentEmailId"] = "";
    this.setState({
      type: value,
      errors: {},
      fields,
    });
  };

  onVerifySubmit = () => {
    const { userDetails } = this.state;
    if (userDetails.regType == "email") {
      this.emailSubmit();
    } else if (userDetails.regType == "mobile") {
      this.mobileSubmit();
    }
  };

  emailSubmit = () => {
    const { fields } = this.state;
    this.setState({
      verificationSubmit: true,
    });
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      // api call
      let params = {
        email: fields.email,
      };
      let urlKeys = {
        userId: common.getStorage("userId"),
      };

      let data = {
        method: "PUT",
        url: "SEND_CODE_EMAIL",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResultData(res);
          if (status == "success") {
            common.setStorage("email", tools.crypt(fields.email, true));
            common.setStorage("mobile", "");
            common.snack("S", result.message);
            this.openKeyVerify();
          } else {
            if (status == "failure") {
              common.snack("E", translation.key_send_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.key_send_failed);
        },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  };

  mobileSubmit = () => {
    const { fields } = this.state;
    this.setState({
      verificationSubmit: true,
    });
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      // api call
      let params = {
        mobile: fields.mobile,
      };
      let urlKeys = {
        userId: common.getStorage("userId"),
      };

      let data = {
        method: "PUT",
        url: "SEND_CODE_MOBILE",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResultData(res);
          if (status == "success") {
            common.setStorage("mobile", tools.crypt(fields.mobile, true));
            common.setStorage("email", "");
            common.snack("S", result.message);
            this.openKeyVerify();
          } else {
            if (status == "failure") {
              common.snack("E", translation.update_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.update_failed);
        },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  };

  openKeyVerify = () => {
    const { errors } = this.state;
    errors["activationKey"] = {
      error: "",
      isReq: translation.activate_code,
    };
    errors["password"] = {
      error: "",
      minLength: 6,
      isReq: translation.isReq_new,
      isMinLengthReq: translation.pwd_min_len,
    };
    errors["confirmPassword"] = {
      error: "",
      minLength: 6,
      isReq: translation.isReq_confirm,
      isMinLengthReq: translation.pwd_min_len,
      matchPassword: true,
      matchPasswordReq: translation.matchPasswordReq,
    };
    this.setState({
      verificationSubmit: false,
      isActivation: true,
      errors,
    });
  };

  onKeySubmit(e) {
    e.preventDefault();
    this.setState({
      verificationSubmit: true,
    });
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const { fields } = this.state;
      // api call
      let params = {
        activationKey: fields.activationKey,
        password: fields.password,
        confirmPassword: fields.confirmPassword,
      };

      if(fields.email != ""){
        params["email"] = fields.email
      }

      if(fields.mobile != ""){
        params["mobile"] = fields.mobile
      }

      let data = {
        method: "PUT",
        url: "MEMBERSHIP_ACTIVATE",
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            common.snack("S", translation.member_activation);
            common.setStorage("email", "");
            common.setStorage("mobile", "");
            this.setState({
              isVerifyModelOpen: false,
              acceptVerification: false,
              isActivation: false,
              verificationSubmit: false,
            });
            common.redirect("/sign-in");
          }
        },
        (error) => {},
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  render() {
    const {
      studentLoading,
      parentLoading,
      formSubmit,
      fields,
      errors,
      role,
      isModelOpen,
      translation,
      teacherLoading,
      type,
      isVerifyModelOpen,
      userDetails,
      acceptVerification,
      isLoading,
      verificationSubmit,
      isActivation,
    } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Login</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="card card-3 alternate">
              <div className="card-heading tertiary-bg">
                <h2>{translation.signin_exam_portal}</h2>
                <p className="mb-0">
                  {translation.formatString(
                    translation.sigin_exam_portal_content,
                    <b>
                      {translation.xample} <sup>{translation.tm}</sup>
                    </b>
                  )}
                </p>
              </div>
              <div className="card-body">
                <div className="row mx-0">
                  <div className="col-lg-6 px-0">
                    <div className="card-left primary-bg">
                      <h3 className="mb-4">{translation.are_you_new}</h3>
                      <p className="pt-2">
                        {translation.formatString(
                          translation.are_you_new_content,
                          <b>
                            {translation.xample} <sup>{translation.tm}</sup>
                          </b>
                        )}
                      </p>
                      <div className="card-btn mt-5">
                        <div className="join-now-tooltip">
                          <span className="tooltiptext">
                            {translation.join_now_student_tooltip_content}
                          </span>
                          <a
                            href="#"
                            className="btn bordered-btn"
                            onClick={(e) => this.gotoMember(e, "student")}
                          >
                            {translation.formatString(
                              translation.join_as_now,
                              <span className="tertiary-color">
                                {translation.student_as}
                              </span>
                            )}
                          </a>
                        </div>
                        <div className="join-now-tooltip">
                          <span className="tooltiptext">
                            {translation.join_now_parent_tooltip_content}
                          </span>
                          <a
                            href="#"
                            className="btn bordered-btn mt-3"
                            onClick={(e) => this.gotoMember(e, "parent")}
                          >
                            {translation.formatString(
                              translation.join_as_now,
                              <span className="secondary-color">
                                {translation.parent_as}
                              </span>
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 px-0 signin-block">
                    <div className="card-signin as-student">
                      <h2>
                        {translation.signin} -{" "}
                        <span>{translation.student}</span>
                      </h2>
                      <p className="help mb-2">{translation.provide_info}</p>
                      <form method="POST">
                        <div className="row">
                          <div className="form-group col-lg-12">
                            <label>{"Select Login Type"}</label>
                            <div className="login-as input-group">
                              <input
                                type="radio"
                                id="email"
                                name="type"
                                value="email"
                                checked={type == "email"}
                                onChange={(e) => this.onTypeChange(e)}
                              />
                              &nbsp;&nbsp;&nbsp;
                              <label htmlFor="email">
                                <span>{"Email"}</span>
                              </label>
                              &nbsp;&nbsp;&nbsp;
                              <input
                                type="radio"
                                id="mobile"
                                name="type"
                                value="mobile"
                                checked={type == "mobile"}
                                onChange={(e) => this.onTypeChange(e)}
                              />
                              &nbsp;&nbsp;&nbsp;
                              <label htmlFor="mobile">
                                <span>{"Mobile"}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              {type == "email" && (
                                <>
                                  <input
                                    type="email"
                                    name="studentEmailId"
                                    value={fields.studentEmailId}
                                    onChange={(e) =>
                                      this.onChangeHandler(e, "student")
                                    }
                                    onKeyDown={this.onEnterPress}
                                    className="form-control"
                                    placeholder=" "
                                  />
                                  <span className="floating-label">
                                    {translation.enter_email}
                                  </span>
                                </>
                              )}

                              {type == "mobile" && (
                                <>
                                  {/* <input
                                  type="number"
                                  name="studentEmailId"
                                  value={fields.studentEmailId}
                                  onChange={(e) =>
                                    this.onChangeHandler(e, "student")
                                  }
                                  onKeyDown={this.onEnterPress}
                                  className="form-control"
                                  placeholder=" "
                                /> */}
                                  <Input
                                    type="number"
                                    name="studentEmailId"
                                    value={fields.studentEmailId}
                                    onChange={(e) =>
                                      this.onChangeHandler(e, "student")
                                    }
                                    className="form-control custom-mobile-field"
                                    placeholder=""
                                    startAdornment={
                                      <InputAdornment position="start">
                                        +94
                                      </InputAdornment>
                                    }
                                  />
                                  <span className="floating-label">
                                    Enter Your Mobile Number
                                  </span>
                                </>
                              )}

                              {role == "student" &&
                                errors.studentEmailId &&
                                errors.studentEmailId.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.studentEmailId.error}
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="studentPassword"
                                value={fields.studentPassword}
                                onChange={(e) =>
                                  this.onChangeHandler(e, "student")
                                }
                                onKeyDown={this.onEnterPress}
                                className="form-control"
                                placeholder=" "
                              />
                              {/* <span className="floating-label">
                                {translation.enter_password}
                              </span> */}
                              <span className="floating-label">
                                Enter Your Password
                              </span>
                              {role == "student" &&
                                errors.studentPassword &&
                                errors.studentPassword.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.studentPassword.error}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="form-actions d-flex flex-wrap align-items-center justify-content-between">
                          <div className="reset-links">
                            <p className="mb-0">
                              {translation.forgot_password}{" "}
                              <Link
                                to="/forgot/password"
                                className="link-text primary-color"
                              >
                                {translation.click_to_reset}
                              </Link>
                            </p>
                          </div>

                          <div className="sigin-button">
                            <input
                              type="button"
                              value={
                                !studentLoading
                                  ? translation.signin
                                  : translation.processing
                              }
                              className="btn btn-light-blue"
                              onClick={() => this.onSubmit("student")}
                              disabled={studentLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="card-signin as-parent">
                      <h2>
                        {translation.signin} - <span>{translation.parent}</span>
                      </h2>
                      <p className="help mb-2">{translation.provide_info}</p>
                      <form method="POST">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="email"
                                name="parentEmailId"
                                value={fields.parentEmailId}
                                onChange={(e) =>
                                  this.onChangeHandler(e, "parent")
                                }
                                onKeyDown={this.onEnterPress}
                                className="form-control"
                                placeholder=" "
                              />
                              {/* <span className="floating-label">
                                {translation.enter_email}
                              </span> */}
                              <span className="floating-label">
                                Enter Your Email
                              </span>
                              {role == "parent" &&
                                errors.parentEmailId &&
                                errors.parentEmailId.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.parentEmailId.error}
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="parentPassword"
                                value={fields.parentPassword}
                                onChange={(e) =>
                                  this.onChangeHandler(e, "parent")
                                }
                                onKeyDown={this.onEnterPress}
                                className="form-control"
                                placeholder=" "
                              />
                              {/* <span className="floating-label">
                                {translation.enter_password}
                              </span> */}
                              <span className="floating-label">
                                Enter Your Password
                              </span>
                              {role == "parent" &&
                                errors.parentPassword &&
                                errors.parentPassword.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.parentPassword.error}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="form-actions d-flex flex-wrap align-items-center justify-content-between">
                          <div className="reset-links">
                            <p className="mb-0">
                              {translation.forgot_password}{" "}
                              <Link
                                to="/forgot/password"
                                className="link-text text-white"
                              >
                                {translation.click_to_reset}
                              </Link>
                            </p>
                            {/* <p className="mb-0">Forgot email?{" "}
                              <Link
                                to="/forgot/email"
                                className="link-text text-white"
                              >Click Right Here to Reset</Link>
                            </p> */}
                          </div>

                          <div className="sigin-button">
                            <input
                              type="button"
                              value={
                                !parentLoading
                                  ? translation.signin
                                  : translation.processing
                              }
                              className="btn btn-light-blue"
                              onClick={() => this.onSubmit("parent")}
                              disabled={parentLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* teacher */}
                    <div className="card-signin as-student">
                      <h2>
                        {translation.signin} -{" "}
                        <span>{translation.teacher}</span>
                      </h2>
                      <p className="help mb-2">{translation.provide_info}</p>
                      <form method="POST">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="email"
                                name="teacherEmailId"
                                value={fields.teacherEmailId}
                                onChange={(e) =>
                                  this.onChangeHandler(e, "teacher")
                                }
                                onKeyDown={this.onEnterPress}
                                className="form-control"
                                placeholder=" "
                              />
                              {/* <span className="floating-label">
                                {translation.enter_email}
                              </span> */}
                              <span className="floating-label">
                                Enter Your Email
                              </span>
                              {role == "teacher" &&
                                errors.teacherEmailId &&
                                errors.teacherEmailId.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.teacherEmailId.error}
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="teacherPassword"
                                value={fields.teacherPassword}
                                onChange={(e) =>
                                  this.onChangeHandler(e, "teacher")
                                }
                                onKeyDown={this.onEnterPress}
                                className="form-control"
                                placeholder=" "
                              />
                              {/* <span className="floating-label">
                                {translation.enter_password}
                              </span> */}
                              <span className="floating-label">
                                Enter Your Password
                              </span>
                              {role == "teacher" &&
                                errors.teacherPassword &&
                                errors.teacherPassword.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.teacherPassword.error}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="form-actions d-flex flex-wrap align-items-center justify-content-between">
                          <div className="reset-links">
                            {/*<p className="mb-0">
                              {translation.forgot_password}{" "}
                              <Link
                                to="/forgot/password"
                                className="link-text text-white"
                              >
                                {translation.click_to_reset}
                              </Link>
                            </p>
                             <p className="mb-0">Forgot email?{" "}
                              <Link
                                to="/forgot/email"
                                className="link-text text-white"
                              >Click Right Here to Reset</Link>
                            </p>*/}
                          </div>

                          <div className="sigin-button">
                            <input
                              type="button"
                              value={
                                !teacherLoading
                                  ? translation.signin
                                  : translation.processing
                              }
                              className="btn btn-light-blue"
                              onClick={() => this.onSubmit("teacher")}
                              disabled={teacherLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />

        {/* <!-- Modal --> */}
        <Dialog
          open={isModelOpen}
          // onClose={this.closeModal}
          aria-labelledby="responsive-dialog-title"
          className="explanation-model"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
            {translation.confirmation_message}
          </DialogTitle>
          <DialogContent>
            <div className="card-body">{translation.already_logged_in_1}</div>
            <div className="form-actions d-flex justify-content-around align-items-center mb-5">
              <a
                href="#"
                className="btn bordered-btn-blue"
                onClick={() => this.closeModal()}
              >
                {" "}
                {translation.cancel}
              </a>
              <a
                href="#"
                className="btn bordered-btn-blue"
                onClick={() => this.callLogin()}
              >
                {translation.continue}
              </a>
            </div>
          </DialogContent>
        </Dialog>

        {/* verify modal */}
        <Dialog
          open={isVerifyModelOpen}
          // onClose={this.closeVerifyModal}
          aria-labelledby="responsive-dialog-title"
          className="explanation-model"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
            {translation.verification_message}
          </DialogTitle>
          <DialogContent>
            <div className="card-body">{translation.verification}</div>
            {!isActivation && !acceptVerification && (
              <div className="form-actions d-flex justify-content-around align-items-center mb-5">
                <a
                  href="#"
                  className="btn bordered-btn-blue"
                  onClick={() => this.closeVerifyModal()}
                >
                  {" "}
                  {translation.cancel}
                </a>
                <a
                  href="#"
                  className="btn bordered-btn-blue"
                  onClick={(e) => this.callVerify(e)}
                >
                  {translation.continue}
                </a>
              </div>
            )}
            {!isActivation && acceptVerification && (
              // <div className="card-body">
              <form method="POST" className="form-body">
                {userDetails &&
                  userDetails.regType &&
                  userDetails.regType == "mobile" && (
                    <div className="form-group">
                      <Input
                        type="number"
                        name="mobile"
                        value={fields.mobile}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                        className="form-control custom-mobile-field"
                        placeholder=""
                        inputProps={{
                          maxLength: 9,
                        }}
                        startAdornment={
                          <InputAdornment position="start">+94</InputAdornment>
                        }
                      />
                      <span className="floating-label">
                        Enter Mobile Number
                      </span>
                      {errors.mobile &&
                        errors.mobile.error != "" &&
                        formSubmit && (
                          <span className="elk-error">
                            {errors.mobile.error}
                          </span>
                        )}
                    </div>
                  )}

                {userDetails &&
                  userDetails.regType &&
                  userDetails.regType == "email" && (
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={fields.email}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                        className="form-control"
                        placeholder=" "
                      />
                      <span className="floating-label">
                        Enter New Email Address
                      </span>
                      {errors.email &&
                        errors.email.error != "" &&
                        verificationSubmit && (
                          <span className="elk-error">
                            {errors.email.error}
                          </span>
                        )}
                    </div>
                  )}

                <div className="form-actions d-flex justify-content-between">
                  <input
                    onClick={() => this.closeVerifyModal()}
                    type="button"
                    className="btn bordered-btn"
                    value={translation.cancel}
                    disabled={isLoading}
                  />
                  <input
                    onClick={() => this.onVerifySubmit()}
                    type="button"
                    className="btn btn-light-blue"
                    disabled={isLoading}
                    value={isLoading ? "Processing..." : translation.continue}
                  />
                </div>
              </form>
              // </div>
            )}
            {isActivation && (
              <form method="POST" className="form-body">
                <div className="form-group">
                  <input
                    type="text"
                    name="activationKey"
                    className="form-control"
                    placeholder=" "
                    value={fields.activationKey}
                    onChange={(e) => this.handler.onChange(e)}
                    onKeyDown={this.onEnterPress}
                  />
                  <span className="floating-label">
                    {/* {translation.activation_key}  */}
                    Enter the activation key
                  </span>
                  {errors.activationKey &&
                    errors.activationKey.error != "" &&
                    verificationSubmit && (
                      <span className="elk-error">
                        {errors.activationKey.error}
                      </span>
                    )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder=" "
                        value={fields.password}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                      />
                      <span className="floating-label">
                        {/* {translation.enter_password} */}
                        Enter Your Password
                      </span>
                      {errors.password &&
                        errors.password.error != "" &&
                        verificationSubmit && (
                          <span className="elk-error">
                            {errors.password.error}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder=" "
                        value={fields.confirmPassword}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                      />
                      <span className="floating-label">
                        {/* {translation.retype_password} */}
                        Retype Password
                      </span>
                      {errors.confirmPassword &&
                        errors.confirmPassword.error != "" &&
                        verificationSubmit && (
                          <span className="elk-error">
                            {errors.confirmPassword.error}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="form-actions d-flex justify-content-between">
                  <input
                    type="reset"
                    className="btn bordered-btn"
                    onClick={() => this.closeVerifyModal()}
                    value={translation.cancel}
                  />
                  <input
                    type="button"
                    className="btn btn-light-blue"
                    onClick={(e) => this.onKeySubmit(e)}
                    disabled={isLoading}
                    value={isLoading ? "Processing" : translation.continue}
                  />
                </div>
              </form>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default Login;
