import React from "react";
import { tools,common } from "helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class TermsConditions extends React.Component {
  componentDidMount() {
    tools.scrollTop();
  }

  render() {
    return (
      <div className="main-content">
        <Helmet>
          <title>Terms And Conditions</title>
        </Helmet>
        {/* <!-- Breadcrumb Wrap --> */}
        <div className="breadcrumb-wrap alternate">
          <div className="container-fluid">
            <div className="breadcrumb-text">
              <h1>
                {/* {translation.terms_and_conditions} */}
                Terms and Conditions
              </h1>
            </div>
          </div>
        </div>
        {/* <!-- Breadcrumb Wrap End --> */}

        <div className="static-content-wrap">
          <div className="container-fluid">
            <div className="static-content">
              <h4 className="pt-0">1. ACCEPTANCE OF TERMS</h4>
              <p>
                The services that CLIENTS & CONSUMERS PRIVATE LIMITED provides
                to User is subject to the following Terms of Use ("TOU").
                CLIENTS & CONSUMERS PRIVATE LIMITED reserves the right to update
                the TOU at any time without notice to User. The most current
                version of the TOU can be reviewed by clicking on the "Terms of
                Use" hypertext link located at the bottom of our Web pages.
              </p>
              <ol>
                <li>
                  This Agreement, which incorporates by reference other
                  provisions applicable to use of <Link to="/">www.xample.lk</Link>, including,
                  but not limited to, supplemental terms and conditions set
                  forth hereof ("Supplemental Terms") governing the use of
                  certain specific material contained in <Link to="/">www.xample.lk</Link>, sets
                  forth the terms and conditions that apply to use of 
                  <Link to="/"> www.xample.lk</Link> by User. By using CLIENTS & CONSUMERS
                  PRIVATE LIMITED (other than to read this Agreement for the
                  first time), User agrees to comply with all of the terms and
                  conditions hereof. The right to use <Link to="/">www.xample.lk</Link> is
                  personal to User and is not transferable to any other person
                  or entity. User is responsible for all use of User's Account
                  (under any screen name or password) and for ensuring that all
                  use of User's Account complies fully with the provisions of
                  this Agreement. User shall be responsible for protecting the
                  confidentiality of User's password(s), if any.{" "}
                </li>
                <li>
                  CLIENTS & CONSUMERS PRIVATE LIMITED shall have the right at
                  any time to change or discontinue any aspect or feature of
                  <Link to="/"> www.xample.lk</Link>, including, but not limited to, content,
                  hours of availability, and equipment needed for access or use.{" "}
                </li>
              </ol>
              <h4>{translation.changed_terms}</h4>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED shall have the right at any
                time to change or modify the terms and conditions applicable to
                User's use of <Link to="/">www.xample.lk</Link>, or any part thereof, or to
                impose new conditions, including, but not limited to, adding
                fees and charges for use. Such changes, modifications, additions
                or deletions shall be effective immediately upon notice thereof,
                which may be given by means including, but not limited to,
                posting on <Link to="/">www.xample.lk</Link>, or by electronic or conventional
                mail, or by any other means by which User obtains notice
                thereof. Any use of <Link to="/">www.xample.lk</Link> by User after such notice
                shall be deemed to constitute acceptance by User of such
                changes, modifications or additions.
              </p>
              <h4>{translation.des_of_service}</h4>
              <p>
                Through its Web property, CLIENTS & CONSUMERS PRIVATE LIMITED
                provides User with access to a variety of resources, including
                download areas, communication forums and product information
                (collectively "Services"). The Services, including any updates,
                enhancements, new features, and/or the addition of any new Web
                properties, are subject to the TOU.
              </p>
              <h4>{translation.equipment}</h4>
              <p>
                User shall be responsible for obtaining and maintaining all
                telephone, computer hardware, software and other equipment
                needed for access to and use of <Link to="/">www.xample.lk</Link> and all
                charges related thereto.
              </p>
              <h4>{translation.user_conduct}</h4>
              <ol>
                <li>
                  User shall use <Link to="/">www.xample.lk</Link> for lawful purposes only.
                  User shall not post or transmit through <Link to="/">www.xample.lk</Link> any
                  material which violates or infringes in any way upon the
                  rights of others, which is unlawful, threatening, abusive,
                  defamatory, invasive of privacy or publicity rights, vulgar,
                  obscene, profane or otherwise objectionable, which encourages
                  conduct that would constitute a criminal offense, give rise to
                  civil liability or otherwise violate any law, or which,
                  without CLIENTS & CONSUMERS PRIVATE LIMITED 's express prior
                  approval, contains advertising or any solicitation with
                  respect to products or services. Any conduct by a User that in
                  CLIENTS & CONSUMERS PRIVATE LIMITED 's discretion restricts or
                  inhibits any other User from using or enjoying
                  <Link to="/"> www.xample.lk</Link> will not be permitted. User shall not use
                  <Link to="/"> www.xample.lk</Link> to advertise or perform any commercial
                  solicitation, including, but not limited to, the solicitation
                  of users to become subscribers of other on-line information
                  services competitive with CLIENTS & CONSUMERS PRIVATE LIMITED.
                </li>
                <li>
                  <Link to="/">www.xample.lk</Link> contains copyrighted material, trademarks
                  and other proprietary information, including, but not limited
                  to, text, software, photos, video, graphics, music and sound,
                  and the entire contents of <Link to="/">www.xample.lk</Link> are copyrighted
                  as a collective work under the [YOUR COUNTRY] copyright laws.
                  CLIENTS & CONSUMERS PRIVATE LIMITED owns a copyright in the
                  selection, coordination, arrangement and enhancement of such
                  content, as well as in the content original to it. User may
                  not modify, publish, transmit, participate in the transfer or
                  sale, create derivative works, or in any way exploit, any of
                  the content, in whole or in part. User may download
                  copyrighted material for User's personal use only. Except as
                  otherwise expressly permitted under copyright law, no copying,
                  redistribution, retransmission, publication or commercial
                  exploitation of downloaded material will be permitted without
                  the express permission of CLIENTS & CONSUMERS PRIVATE LIMITED
                  and the copyright owner. In the event of any permitted
                  copying, redistribution or publication of copyrighted
                  material, no changes in or deletion of author attribution,
                  trademark legend or copyright notice shall be made. User
                  acknowledges that it does not acquire any ownership rights by
                  downloading copyrighted material.
                </li>
                <li>
                  User shall not upload, post or otherwise make available on
                  <Link to="/"> www.xample.lk</Link> any material protected by copyright,
                  trademark or other proprietary right without the express
                  permission of the owner of the copyright, trademark or other
                  proprietary right and the burden of determining that any
                  material is not protected by copyright rests with User. User
                  shall be solely liable for any damage resulting from any
                  infringement of copyrights, proprietary rights, or any other
                  harm resulting from such a submission. By submitting material
                  to any public area of <Link to="/">www.xample.lk</Link>, User automatically
                  grants, or warrants that the owner of such material has
                  expressly granted CLIENTS & CONSUMERS PRIVATE LIMITED the
                  royalty-free, perpetual, irrevocable, non-exclusive right and
                  license to use, reproduce, modify, adapt, publish, translate
                  and distribute such material (in whole or in part) worldwide
                  and/or to incorporate it in other works in any form, media or
                  technology now known or hereafter developed for the full term
                  of any copyright that may exist in such material. User also
                  permits any other User to access, view, store or reproduce the
                  material for that User's personal use. User hereby grants
                  CLIENTS & CONSUMERS PRIVATE LIMITED the right to edit, copy,
                  publish and distribute any material made available on
                  <Link to="/"> www.xample.lk</Link> by User.
                </li>
                <li>
                  The foregoing provisions of Section 5 are for the benefit
                  of CLIENTS & CONSUMERS PRIVATE LIMITED, its subsidiaries,
                  affiliates and its third-party content providers and licensors
                  and each shall have the right to assert and enforce such
                  provisions directly or on its own behalf.
                </li>
              </ol>
              <h4>{translation.use_of_services}</h4>
              <p>
                The Services may contain email services, bulletin board
                services, chat areas, news groups, forums, communities, personal
                web pages, calendars, photo albums, file cabinets and/or other
                message or communication facilities designed to enable User to
                communicate with others (each a "Communication Service" and
                collectively "Communication Services"). User agrees to use the
                Communication Services only to post, send and receive messages
                and material that are proper and, when applicable, related to
                the particular Communication Service. By way of example, and not
                as a limitation, User agrees that when using the Communication
                Services, User will not:
              </p>
              <ul className="build-list">
                <li>
                  Use the Communication Services in connection with surveys,
                  contests, pyramid schemes, chain letters, junk email, spamming
                  or any duplicative or unsolicited messages (commercial or
                  otherwise).
                </li>
                <li>
                  Defame, abuse, harass, stalk, threaten or otherwise violate
                  the legal rights (such as rights of privacy and publicity) of
                  others.
                </li>
                <li>
                  Publish, post, upload, distribute or disseminate any
                  inappropriate, profane, defamatory, obscene, indecent or
                  unlawful topic, name, material or information.
                </li>
                <li>
                  Upload, or otherwise make available, files that contain
                  images, photographs, software or other material protected by
                  intellectual property laws, including, by way of example, and
                  not as limitation, copyright or trademark laws (or by rights
                  of privacy or publicity) unless User own or control the rights
                  thereto or have received all necessary consent to do the same.
                </li>
                <li>
                  Use any material or information, including images or
                  photographs, which are made available through the Services in
                  any manner that infringes any copyright, trademark, patent,
                  trade secret, or other proprietary right of any party.
                </li>
                <li>
                  Upload files that contain viruses, Trojan horses, worms, time
                  bombs, cancelbots, corrupted files, or any other similar
                  software or programs that may damage the operation of
                  another's computer or property of another.
                </li>
                <li>
                  Advertise or offer to sell or buy any goods or services for
                  any business purpose, unless such Communication Services
                  specifically allows such messages.
                </li>
                <li>
                  Falsify or delete any copyright management information, such
                  as author attributions, legal or other proper notices or
                  proprietary designations or labels of the origin or source of
                  software or other material contained in a file that is
                  uploaded.
                </li>
                <li>
                  Restrict or inhibit any other user from using and enjoying the
                  Communication Services.
                </li>
                <li>
                  Violate any code of conduct or other guidelines which may be
                  applicable for any particular Communication Service.
                </li>
                <li>
                  Harvest or otherwise collect information about others,
                  including email addresses.
                </li>
                <li>Violate any applicable laws or regulations.</li>
                <li>
                  Create a false identity for the purpose of misleading others.
                </li>
                <li>
                  Use, download or otherwise copy, or provide (whether or not
                  for a fee) to a person or entity any directory of users of the
                  Services or other user or usage information or any portion
                  thereof.
                </li>
              </ul>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED has no obligation to monitor
                the Communication Services. However, CLIENTS & CONSUMERS PRIVATE
                LIMITED reserves the right to review materials posted to the
                Communication Services and to remove any materials in its sole
                discretion. CLIENTS & CONSUMERS PRIVATE LIMITED reserves the
                right to terminate User’s access to any or all of the
                Communication Services at any time, without notice, for any
                reason whatsoever. CLIENTS & CONSUMERS PRIVATE LIMITED reserves
                the right at all times to disclose any information as it deems
                necessary to satisfy any applicable law, regulation, legal
                process or governmental request, or to edit, refuse to post or
                to remove any information or materials, in whole or in part, in
                CLIENTS & CONSUMERS PRIVATE LIMITED's sole discretion.
              </p>
              <p>
                Materials uploaded to the Communication Services may be subject
                to posted limitations on usage, reproduction and/or
                dissemination; User is responsible for adhering to such
                limitations if User downloads the materials.
              </p>
              <p>
                Always use caution when giving out any personally identifiable
                information in any Communication Services. CLIENTS & CONSUMERS
                PRIVATE LIMITED does not control or endorse the content,
                messages or information found in any Communication Services and,
                therefore, CLIENTS & CONSUMERS PRIVATE LIMITED specifically
                disclaims any liability with regard to the Communication
                Services and any actions resulting from User’s participation in
                any Communication Services. Managers and hosts are not
                authorized CLIENTS & CONSUMERS PRIVATE LIMITED spokespersons,
                and their views do not necessarily reflect those of CLIENTS &
                CONSUMERS PRIVATE LIMITED.
              </p>
              <h4>{translation.password_and_security}</h4>
              <p>
                If any of the Services requires User to open an account, User
                must complete the registration process by providing CLIENTS &
                CONSUMERS PRIVATE LIMITED with current, complete and accurate
                information as prompted by the applicable registration form.
                User also will choose a password and a username. User is
                entirely responsible for maintaining the confidentiality of
                User’s password and account. Furthermore, User is entirely
                responsible for any and all activities that occur under User’s
                account. User agrees to notify CLIENTS & CONSUMERS PRIVATE
                LIMITED immediately of any unauthorized use of User’s account or
                any other breach of security. CLIENTS & CONSUMERS PRIVATE
                LIMITED will not be liable for any loss that User may incur as a
                result of someone else using User’s password or account, either
                with or without User’s knowledge. However, User could be held
                liable for losses incurred by CLIENTS & CONSUMERS PRIVATE
                LIMITED or another party due to someone else using User’s
                account or password. User may not use anyone else's account at
                any time, without the permission of the account holder.
              </p>
              <h4>{translation.termination_and_refund}</h4>
              <p>
                The agreement will remain in effect until the expiration,
                termination or renewal of your Subscription, whichever. Is
                earliest. You may terminate a Subscription at any time during
                its Term; however, you must pay all amounts due and owing before
                the termination effective.
              </p>
              <p>
                <b>Trial Period,</b> a subscription having a trial period may be
                terminated anytime without any cancellation fee.
              </p>
              <p>
                If you terminate a Subscription to “Example” within 30 days of
                the date on which the Subscription became effective or was
                renewed, no refunds will be provided and you must pay for the
                initial 30 days of the Subscription, but no payments will be due
                for the remaining portion of the terminated Subscription, if.
                You terminate a Subscription to “Example: Services at any other
                time during the term you must pay for the remainder of the term,
                and no refunds will be provided.
              </p>
              <h4>
                9. NOTICE SPECIFIC TO SOFTWARE AVAILABLE ON THIS YOUR WEBSITE
                ADDRESS
              </h4>
              <p>
                Any software that is made available to download from the
                Services ("Software") is the copyrighted work of CLIENTS &
                CONSUMERS PRIVATE LIMITED and/or its suppliers. Use of the
                Software is governed by the terms of the end user license
                agreement, if any, which accompanies or is included with the
                Software ("License Agreement"). An end user will be unable to
                install any Software that is accompanied by or includes a
                License Agreement, unless he or she first agrees to the License
                Agreement terms.{" "}
              </p>
              <p>
                The Software is made available for download solely for use by
                end users according to the License Agreement. Any reproduction
                or redistribution of the Software not in accordance with the
                License Agreement is expressly prohibited by law and may result
                in severe civil and criminal penalties. Violators will be
                prosecuted to the maximum extent possible.{" "}
              </p>
              <p>
                WITHOUT LIMITING THE FOREGOING, COPYING OR REPRODUCTION OF THE
                SOFTWARE TO ANY OTHER SERVER OR LOCATION FOR FURTHER
                REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PROHIBITED, UNLESS
                SUCH REPRODUCTION OR REDISTRIBUTION IS EXPRESSLY PERMITTED BY
                THE LICENSE AGREEMENT ACCOMPANYING SUCH SOFTWARE. THE SOFTWARE
                IS WARRANTED, IF AT ALL, ONLY ACCORDING TO THE TERMS OF THE
                LICENSE AGREEMENT. EXCEPT AS WARRANTED IN THE LICENSE AGREEMENT,
                CLIENTS & CONSUMERS PRIVATE LIMITED HEREBY DISCLAIMS ALL
                WARRANTIES AND CONDITIONS WITH REGARD TO THE SOFTWARE, INCLUDING
                ALL WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER
                EXPRESS, IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE AND NON-INFRINGEMENT.
              </p>
              <p>
                FOR YOUR CONVENIENCE, CLIENTS & CONSUMERS PRIVATE LIMITED MAY
                MAKE AVAILABLE AS PART OF THE SERVICES OR IN ITS SOFTWARE
                PRODUCTS, TOOLS AND UTILITIES FOR USE AND/OR DOWNLOAD. CLIENTS &
                CONSUMERS PRIVATE LIMITED DOES NOT MAKE ANY ASSURANCES WITH
                REGARD TO THE ACCURACY OF THE RESULTS OR OUTPUT THAT DERIVES
                FROM SUCH USE OF ANY SUCH TOOLS AND UTILITIES. PLEASE RESPECT
                THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS WHEN USING THE TOOLS
                AND UTILITIES MADE AVAILABLE ON THE SERVICES.
              </p>
              <h4>
                {translation.website_address}
              </h4>
              <p>
                Permission to use Documents (such as white papers, press
                releases, datasheets and FAQs) from the Services is granted,
                provided that (1) the below copyright notice appears in all
                copies and that both the copyright notice and this permission
                notice appear, (2) use of such Documents from the Services is
                for informational and non-commercial or personal use only and
                will not be copied or posted on any network computer or
                broadcast in any media, and (3) no modifications of any
                Documents are made. Accredited educational institutions, such as
                universities, private/public colleges, and state community
                colleges, may download and reproduce the Documents for
                distribution in the classroom. Distribution outside the
                classroom requires express written permission. Use for any other
                purpose is expressly prohibited by law and may result in severe
                civil and criminal penalties. Violators will be prosecuted to
                the maximum extent possible.
              </p>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED AND/OR ITS RESPECTIVE
                SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE
                INFORMATION CONTAINED IN THE DOCUMENTS AND RELATED GRAPHICS
                PUBLISHED AS PART OF THE SERVICES FOR ANY PURPOSE. ALL SUCH
                DOCUMENTS AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT
                WARRANTY OF ANY KIND. CLIENTS & CONSUMERS PRIVATE LIMITED AND/OR
                ITS RESPECTIVE SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
                CONDITIONS WITH REGARD TO THIS INFORMATION, INCLUDING ALL
                WARRANTIES AND CONDITIONS OF MERCHANTABILITY, WHETHER EXPRESS,
                IMPLIED OR STATUTORY, FITNESS FOR A PARTICULAR PURPOSE, TITLE
                AND NON-INFRINGEMENT. IN NO EVENT SHALL CLIENTS & CONSUMERS
                PRIVATE LIMITED AND/OR ITS RESPECTIVE SUPPLIERS BE LIABLE FOR
                ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER
                IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION,
                ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF
                INFORMATION AVAILABLE FROM THE SERVICES.
              </p>
              <p>
                THE DOCUMENTS AND RELATED GRAPHICS PUBLISHED ON THE SERVICES
                COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS.
                CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
                CLIENTS & CONSUMERS PRIVATE LIMITED AND/OR ITS RESPECTIVE
                SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT(S)
                AND/OR THE PROGRAM(S) DESCRIBED HEREIN AT ANY TIME.
              </p>
              <h4>
               {translation.notice_regards}
              </h4>
              <p>
                IN NO EVENT SHALL CLIENTS & CONSUMERS PRIVATE LIMITED AND/OR ITS
                RESPECTIVE SUPPLIERS BE LIABLE FOR ANY SPECIAL, INDIRECT OR
                CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM
                LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT,
                NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN
                CONNECTION WITH THE USE OR PERFORMANCE OF SOFTWARE, DOCUMENTS,
                PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR INFORMATION
                AVAILABLE FROM THE SERVICES.
              </p>
              <h4>
                12. MATERIALS PROVIDED TO CLIENTS & CONSUMERS PRIVATE LIMITED OR
                POSTED AT ANY OF ITS YOUR WEBSITE ADDRESSS
              </h4>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED does not claim ownership of
                the materials User provide to CLIENTS & CONSUMERS PRIVATE
                LIMITED (including feedback and suggestions) or post, upload,
                input or submit to any Services or its associated services for
                review by the general public, or by the members of any public or
                private community, (each a "Submission" and collectively
                "Submissions"). However, by posting, uploading, inputting,
                providing or submitting ("Posting") User’s Submission User is
                granting CLIENTS & CONSUMERS PRIVATE LIMITED, its affiliated
                companies and necessary sublicensees permission to use User’s
                Submission in connection with the operation of their Internet
                businesses (including, without limitation, all CLIENTS &
                CONSUMERS PRIVATE LIMITED Services), including, without
                limitation, the license rights to: copy, distribute, transmit,
                publicly display, publicly perform, reproduce, edit, translate
                and reformat User’s Submission; to publish User’s name in
                connection with User’s Submission; and the right to sublicense
                such rights to any supplier of the Services.
              </p>
              <p>
                No compensation will be paid with respect to the use of User’s
                Submission, as provided herein. CLIENTS & CONSUMERS PRIVATE
                LIMITED is under no obligation to post or use any Submission
                User may provide and CLIENTS & CONSUMERS PRIVATE LIMITED may
                remove any Submission at any time in its sole discretion. By
                Posting a Submission User warrants and represents to own or
                otherwise control all of the rights to User’s Submission as
                described in these Terms of Use including, without limitation,
                all the rights necessary for User to provide, post, upload,
                input or submit the Submissions.
              </p>
              <p>
                In addition to the warranty and representation set forth above,
                by Posting a Submission that contain images, photographs,
                pictures or that are otherwise graphical in whole or in part
                ("Images"), User warrant and represent that (a) User is the
                copyright owner of such Images, or that the copyright owner of
                such Images has granted User permission to use such Images or
                any content and/or images contained in such Images consistent
                with the manner and purpose of User’s use and as otherwise
                permitted by these Terms of Use and the Services, (b) User have
                the rights necessary to grant the licenses and sublicenses
                described in these Terms of Use, and (c) that each person
                depicted in such Images, if any, has provided consent to the use
                of the Images as set forth in these Terms of Use, including, by
                way of example, and not as a limitation, the distribution,
                public display and reproduction of such Images. By Posting
                Images, User is granting (a) to all members of User’s private
                community (for each such Images available to members of such
                private community), and/or (b) to the general public (for each
                such Images available anywhere on the Services, other than a
                private community), permission to use User’s Images in
                connection with the use, as permitted by these Terms of Use, of
                any of the Services, (including, by way of example, and not as a
                limitation, making prints and gift items which include such
                Images), and including, without limitation, a non-exclusive,
                world-wide, royalty-free license to: copy, distribute, transmit,
                publicly display, publicly perform, reproduce, edit, translate
                and reformat User’s Images without having User’s name attached
                to such Images, and the right to sublicense such rights to any
                supplier of the Services. The licenses granted in the preceding
                sentences for a Images will terminate at the time User
                completely remove such Images from the Services, provided that,
                such termination shall not affect any licenses granted in
                connection with such Images prior to the time User completely
                remove such Images. No compensation will be paid with respect to
                the use of User’s Images.
              </p>
              <h4>13. Disclaimer of Warranty; Limitation of Liability</h4>
              <ol>
                <li>
                  USER EXPRESSLY AGREES THAT USE OF <Link to="/">www.xample.lk</Link> IS AT
                  USER'S SOLE RISK. NEITHER CLIENTS & CONSUMERS PRIVATE LIMITED,
                  ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS,
                  THIRD PARTY CONTENT PROVIDERS OR LICENSORS WARRANT THAT
                  <Link to="/"> www.xample.lk</Link> WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO
                  THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED
                  FROM USE OF <Link to="/">www.xample.lk</Link>, OR AS TO THE ACCURACY,
                  RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE, OR
                  MERCHANDISE PROVIDED THROUGH <Link to="/">www.xample.lk</Link>.{" "}
                </li>
                <li>
                  <Link to="/">www.xample.lk</Link> IS PROVIDED ON AN "AS IS" BASIS WITHOUT
                  WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                  BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES
                  OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER
                  THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF
                  EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS
                  APPLICABLE TO THIS AGREEMENT.
                </li>
                <li>
                  THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR
                  INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
                  INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
                  TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE,
                  THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF,
                  OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS
                  BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. USER
                  SPECIFICALLY ACKNOWLEDGES THAT CLIENTS & CONSUMERS PRIVATE
                  LIMITED IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL
                  CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF
                  INJURY FROM THE FOREGOING RESTS ENTIRELY WITH USER.
                </li>
                <li>
                  IN NO EVENT WILL CLIENTS & CONSUMERS PRIVATE LIMITED, OR
                  ANY PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR
                  DISTRIBUTING <Link to="/">www.xample.lk</Link> OR THE CLIENTS & CONSUMERS
                  PRIVATE LIMITED SOFTWARE, BE LIABLE FOR ANY DAMAGES,
                  INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL,
                  SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF THE
                  USE OF OR INABILITY TO USE <Link to="/">www.xample.lk</Link>. USER HEREBY
                  ACKNOWLEDGES THAT THE PROVISIONS OF THIS SECTION SHALL APPLY
                  TO ALL CONTENT ON THE SITE.
                </li>
                <li>
                  IN ADDITION TO THE TERMS SET FORTH ABOVE NEITHER, CLIENTS &
                  CONSUMERS PRIVATE LIMITED, NOR ITS AFFILIATES, INFORMATION
                  PROVIDERS OR CONTENT PARTNERS SHALL BE LIABLE REGARDLESS OF
                  THE CAUSE OR DURATION, FOR ANY ERRORS, INACCURACIES,
                  OMISSIONS, OR OTHER DEFECTS IN, OR UNTIMELINESS OR
                  UNAUTHENTICITY OF, THE INFORMATION CONTAINED WITHIN
                  <Link to="/"> www.xample.lk</Link>, OR FOR ANY DELAY OR INTERRUPTION IN THE
                  TRANSMISSION THEREOF TO THE USER, OR FOR ANY CLAIMS OR LOSSES
                  ARISING THEREFROM OR OCCASIONED THEREBY. NONE OF THE FOREGOING
                  PARTIES SHALL BE LIABLE FOR ANY THIRD-PARTY CLAIMS OR LOSSES
                  OF ANY NATURE, INCLUDING, BUT NOT LIMITED TO, LOST PROFITS,
                  PUNITIVE OR CONSEQUENTIAL DAMAGES.
                </li>
                <li>
                  <b>PRIOR TO THE EXECUTION OF A STOCK TRADE, USERS ARE ADVISED
                  TO CONSULT WITH YOUR BROKER OR OTHER FINANCIAL REPRESENTATIVE
                  TO VERIFY PRICING OR OTHER INFORMATION. CLIENTS & CONSUMERS
                  PRIVATE LIMITED, ITS AFFILIATES, INFORMATION PROVIDERS OR
                  CONTENT PARTNERS SHALL HAVE NO LIABILITY FOR INVESTMENT
                  DECISIONS BASED ON THE INFORMATION PROVIDED.</b> NEITHER, CLIENTS
                  & CONSUMERS PRIVATE LIMITED, NOR ITS AFFILIATES, INFORMATION
                  PROVIDERS OR CONTENT PARTNERS WARRANT OR GUARANTEE THE
                  TIMELINESS, SEQUENCE, ACCURACY OR COMPLETENESS OF THIS
                  INFORMATION. ADDITIONALLY, THERE ARE NO WARRANTIES AS TO THE
                  RESULTS OBTAINED FROM THE USE OF THE INFORMATION.
                </li>
                <li>
                  FORCE MAJEURE – NEITHER PARTY WILL BE RESPONSIBLE FOR ANY
                  FAILURE OR DELAY IN PERFORMANCE DUE TO CIRCUMSTANCES BEYOND
                  ITS REASONABLE CONTROL, INCLUDING, WITHOUT LIMITATION, ACTS OF
                  GOD, WAR, RIOT, EMBARGOES, ACTS OF CIVIL OR MILITARY
                  AUTHORITIES, FIRE, FLOODS, ACCIDENTS, SERVICE OUTAGES
                  RESULTING FROM EQUIPMENT AND/OR SOFTWARE FAILURE AND/OR
                  TELECOMMUNICATIONS FAILURES, POWER FAILURES, NETWORK FAILURES,
                  FAILURES OF THIRD PARTY SERVICE PROVIDERS (INCLUDING PROVIDERS
                  OF INTERNET SERVICES AND TELECOMMUNICATIONS). THE PARTY
                  AFFECTED BY ANY SUCH EVENT SHALL NOTIFY THE OTHER PARTY WITHIN
                  A MAXIMUM OF FIFTEEN (15) DAYS FROM ITS OCCURENCE. THE
                  PERFORMANCE OF THS AGREEMENT SHALL THEN BE SUSPENDED FOR AS
                  LONG AS ANY SUCH EVENT SHALL PREVENT THE AFFECTED PARTY FROM
                  PERFORMING ITS OBLIGATIONS UNDER THIS AGREEMENT.
                </li>
              </ol>
              <h4>14. LINKS TO THIRD PARTY SITES</h4>
              <p>
                THE LINKS IN THIS AREA WILL LET YOU LEAVE CLIENTS & CONSUMERS
                PRIVATE LIMITED'S SITE. THE LINKED SITES ARE NOT UNDER THE
                CONTROL OF CLIENTS & CONSUMERS PRIVATE LIMITED AND CLIENTS &
                CONSUMERS PRIVATE LIMITED IS NOT RESPONSIBLE FOR THE CONTENTS OF
                ANY LINKED SITE OR ANY LINK CONTAINED IN A LINKED SITE, OR ANY
                CHANGES OR UPDATES TO SUCH SITES. CLIENTS & CONSUMERS PRIVATE
                LIMITED IS NOT RESPONSIBLE FOR WEBCASTING OR ANY OTHER FORM OF
                TRANSMISSION RECEIVED FROM ANY LINKED SITE. CLIENTS & CONSUMERS
                PRIVATE LIMITED IS PROVIDING THESE LINKS TO YOU ONLY AS A
                CONVENIENCE, AND THE INCLUSION OF ANY LINK DOES NOT IMPLY
                ENDORSEMENT BY CLIENTS & CONSUMERS PRIVATE LIMITED OF THE SITE.
              </p>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED is a distributor (and not a
                publisher) of content supplied by third parties and Users.
                Accordingly, CLIENTS & CONSUMERS PRIVATE LIMITED has no more
                editorial control over such content than does a public library,
                bookstore, or newsstand. Any opinions, advice, statements,
                services, offers, or other information or content expressed or
                made available by third parties, including information
                providers, Users or any other user of <Link to="/">www.xample.lk</Link>, are
                those of the respective author(s) or distributor(s) and not of
                CLIENTS & CONSUMERS PRIVATE LIMITED. Neither CLIENTS & CONSUMERS
                PRIVATE LIMITED nor any third-party provider of information
                guarantees the accuracy, completeness, or usefulness of any
                content, nor its merchantability or fitness for any particular
                purpose.
              </p>
              <p>
                In many instances, the content available through
                <Link to="/"> www.xample.lk</Link> represents the opinions and judgments of the
                respective information provider, User, or other user not under
                contract with CLIENTS & CONSUMERS PRIVATE LIMITED. CLIENTS &
                CONSUMERS PRIVATE LIMITED neither endorses nor is responsible
                for the accuracy or reliability of any opinion, advice or
                statement made on <Link to="/">www.xample.lk</Link> by anyone other than
                authorized CLIENTS & CONSUMERS PRIVATE LIMITED employee
                spokespersons while acting in their official capacities. Under
                no circumstances will CLIENTS & CONSUMERS PRIVATE LIMITED be
                liable for any loss or damage caused by a User's reliance on
                information obtained through <Link to="/">www.xample.lk</Link>. It is the
                responsibility of User to evaluate the accuracy, completeness or
                usefulness of any information, opinion, advice or other content
                available through CLIENTS & CONSUMERS PRIVATE LIMITED. Please
                seek the advice of professionals, as appropriate, regarding the
                evaluation of any specific information, opinion, advice or other
                content.
              </p>
              <h4>15. UNSOLICITED IDEA SUBMISSION POLICY</h4>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED OR ANY OF ITS EMPLOYEES DO
                NOT ACCEPT OR CONSIDER UNSOLICITED IDEAS, INCLUDING IDEAS FOR
                NEW ADVERTISING CAMPAIGNS, NEW PROMOTIONS, NEW PRODUCTS OR
                TECHNOLOGIES, PROCESSES, MATERIALS, MARKETING PLANS OR NEW
                PRODUCT NAMES. PLEASE DO NOT SEND ANY ORIGINAL CREATIVE ARTWORK,
                SAMPLES, DEMOS, OR OTHER WORKS. THE SOLE PURPOSE OF THIS POLICY
                IS TO AVOID POTENTIAL MISUNDERSTANDINGS OR DISPUTES WHEN CLIENTS
                & CONSUMERS PRIVATE LIMITED'S PRODUCTS OR MARKETING STRATEGIES
                MIGHT SEEM SIMILAR TO IDEAS SUBMITTED TO CLIENTS & CONSUMERS
                PRIVATE LIMITED. SO, PLEASE DO NOT SEND YOUR UNSOLICITED IDEAS
                TO CLIENTS & CONSUMERS PRIVATE LIMITED OR ANYONE AT CLIENTS &
                CONSUMERS PRIVATE LIMITED. IF, DESPITE OUR REQUEST THAT YOU NOT
                SEND US YOUR IDEAS AND MATERIALS, YOU STILL SEND THEM, PLEASE
                UNDERSTAND THAT CLIENTS & CONSUMERS PRIVATE LIMITED MAKES NO
                ASSURANCES THAT YOUR IDEAS AND MATERIALS WILL BE TREATED AS
                CONFIDENTIAL OR PROPRIETARY.
              </p>
              <h4>16. Monitoring</h4>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED shall have the right, but
                not the obligation, to monitor the content of <Link to="/">www.xample.lk</Link>,
                including chat rooms and forums, to determine compliance with
                this Agreement and any operating rules established by CLIENTS &
                CONSUMERS PRIVATE LIMITED and to satisfy any law, regulation or
                authorized government request. CLIENTS & CONSUMERS PRIVATE
                LIMITED shall have the right in its sole discretion to edit,
                refuse to post or remove any material submitted to or posted on
                <Link to="/"> www.xample.lk</Link>. Without limiting the foregoing, CLIENTS &
                CONSUMERS PRIVATE LIMITED shall have the right to remove any
                material that CLIENTS & CONSUMERS PRIVATE LIMITED, in its sole
                discretion, finds to be in violation of the provisions hereof or
                otherwise objectionable.
              </p>
              <h4>17. Indemnification</h4>
              <p>
                User agrees to defend, indemnify and hold harmless CLIENTS &
                CONSUMERS PRIVATE LIMITED, its affiliates and their respective
                directors, officers, employees and agents from and against all
                claims and expenses, including attorneys' fees, arising out of
                the use of CLIENTS & CONSUMERS PRIVATE LIMITED by User or User's
                Account.
              </p>
              <h4>18. Termination</h4>
              <p>
                Either CLIENTS & CONSUMERS PRIVATE LIMITED or User may terminate
                this Agreement at any time. Without limiting the foregoing,
                CLIENTS & CONSUMERS PRIVATE LIMITED shall have the right to
                immediately terminate User's Account in the event of any conduct
                by User which CLIENTS & CONSUMERS PRIVATE LIMITED, in its sole
                discretion, considers to be unacceptable, or in the event of any
                breach by User of this Agreement.
              </p>
              <h4>19. Miscellaneous</h4>
              <p>
                This Agreement and any operating rules for <Link to="/">www.xample.lk </Link>
                established by CLIENTS & CONSUMERS PRIVATE LIMITED constitute
                the entire agreement of the parties with respect to the subject
                matter hereof and supersede all previous written or oral
                agreements between the parties with respect to such subject
                matter. This Agreement shall be construed in accordance with the
                laws of the DEMOCRATIC SOCIALIST REPUBLIC OF SRI LANKA, without
                regard to its conflict of laws rules. No waiver by either party
                of any breach or default hereunder shall be deemed to be a
                waiver of any preceding or subsequent breach or default. The
                section headings used herein are for convenience only and shall
                not be given any legal import.
              </p>
              <h4>20. COPYRIGHT NOTICE</h4>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED its logos are trademarks of
                CLIENTS & CONSUMERS PRIVATE LIMITED, All rights reserved. All
                other trademarks appearing on CLIENTS & CONSUMERS PRIVATE
                LIMITED are the property of their respective owners.
              </p>
              <h4>21. TRADEMARKS</h4>
              <p>
                The names of actual companies and products mentioned herein may
                be the trademarks of their respective owners. The example
                companies, organizations, products, domain names, email
                addresses, logos, people and events depicted herein are
                fictitious. No association with any real company, organization,
                product, domain name, email address, logo, person, or event is
                intended or should be inferred.
              </p>
              <p>Any rights not expressly granted herein are reserved.</p>
              <p>
                It's important to CLIENTS & CONSUMERS PRIVATE LIMITED to help
                our users retain their privacy when they take advantage of all
                the Internet has to offer.
              </p>
              <p>
                Your privacy is important to you and to us. So we'll protect the
                information you share with us. To protect your privacy, CLIENTS
                & CONSUMERS PRIVATE LIMITED follows the principles in accordance
                with worldwide practices for customer privacy and data
                protection.
              </p>
              <ul className="build-list">
                <li>
                  We won’t sell or give away your name, mail address, phone
                  number, email address or any other information to anyone.
                </li>
                <li>
                  We’ll use state-of-the-art security measures to protect your
                  information from unauthorized users.
                </li>
              </ul>
              <h4>NOTICE</h4>
              <p>
                We will ask you when we need information that personally
                identifies you (personal information) or allows us to contact
                you. Generally, this information is requested when you create a
                Registration ID on the site or when you download free software,
                enter a contest, order email newsletters or join a
                limited-access premium site. We use your Personal Information
                for four primary purposes:
              </p>
              <ul className="build-list">
                <li>
                  To make the site easier for you to use by not having to enter
                  information more than once.
                </li>
                <li>
                  To help you quickly find software, services or information.
                </li>
                <li>To help us create content most relevant to you.</li>
                <li>
                  To alert you to product upgrades, special offers, updated
                  information and other new services from CLIENTS & CONSUMERS
                  PRIVATE LIMITED.
                </li>
              </ul>
              <h4>CONSENT</h4>
              <p>
                If you choose not to register or provide personal information,
                you can still use most of <Link to="/">www.xample.lk</Link>. But you will not be
                able to access areas that require registration.
              </p>
              <p>
                If you decide to register, you will be able to select the kinds
                of information you want to receive from us by subscribing to
                various services, like our electronic newsletters. If you do not
                want us to communicate with you about other offers regarding
                CLIENTS & CONSUMERS PRIVATE LIMITED products, programs, events,
                or services by email, postal mail, or telephone, you may select
                the option stating that you do not wish to receive marketing
                messages from CLIENTS & CONSUMERS PRIVATE LIMITED.
              </p>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED occasionally allows other
                companies to offer our registered customers information about
                their products and services, using postal mail only. If you do
                not want to receive these offers, you may select the option
                stating that you do not wish to receive marketing materials from
                third parties.
              </p>
              <h4>ACCESS</h4>
              <p>
                We will provide you with the means to ensure that your personal
                information is correct and current. You may review and update
                this information at any time at the Visitor Center. There, you
                can:
              </p>
              <ul className="build-list">
                <li>
                  View and edit personal information you have already given us.
                </li>
                <li>
                  Tell us whether you want us to send you marketing information,
                  or whether you want third parties to send you their offers by
                  postal mail.
                </li>
                <li>
                  Sign up for electronic newsletters about our services and
                  products.
                </li>
                <li>
                  Register. Once you register, you won't need to do it again.
                  Wherever you go on <Link to="/">www.xample.lk</Link>, your information stays
                  with you.
                </li>
              </ul>
              <h4>SECURITY</h4>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED has taken strong measures to
                protect the security of your personal information and to ensure
                that your choices for its intended use are honored. We take
                strong precautions to protect your data from loss, misuse,
                unauthorized access or disclosure, alteration, or destruction.
              </p>
              <p>
                We guarantee your e-commerce transactions to be 100% safe and
                secure. When you place orders or access your personal account
                information, you're utilizing secure server software SSL, which
                encrypts your personal information before it's sent over the
                Internet. SSL is one of the safest encryption technologies
                available.
              </p>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED strictly protects the
                security of your personal information and honors your choices
                for its intended use. We carefully protect your data from loss,
                misuse, unauthorized access or disclosure, alteration, or
                destruction.
              </p>
              <p>
                Your personal information is never shared outside the company
                without your permission, except under conditions explained
                above. Inside the company, data is stored in password-controlled
                servers with limited access. Your information may be stored and
                processed in DEMOCRATIC SOCIALIST REPUBLIC OF SRI LANKA or any
                other country where CLIENTS & CONSUMERS PRIVATE LIMITED, its
                subsidiaries, affiliates or agents are located.
              </p>
              <p>
                You also have a significant role in protecting your information.
                No one can see or edit your personal information without knowing
                your username and password, so do not share these with others.
              </p>
              <h4>NOTICE TO PARENTS</h4>
              <p>
                Parents or guardians: we want to help you guard your privacy of
                children's privacy. We encourage you to talk to your children
                about safe and responsible use of their Personal Information
                while using the Internet.
              </p>
              <p>
                The CLIENTS & CONSUMERS PRIVATE LIMITED site does not publish
                content that is targeted to children. However, if you are
                concerned about your children providing CLIENTS & CONSUMERS
                PRIVATE LIMITED any personal information without your consent,
                CLIENTS & CONSUMERS PRIVATE LIMITED offers a Kids account. It
                allows parents to give parental consent for the collection, use
                and sharing of children’s (ages 12 and under) personal
                information online.
              </p>
              <h4>ENFORCEMENT</h4>
              <p>
                If for some reason you believe CLIENTS & CONSUMERS PRIVATE
                LIMITED has not adhered to these principles, please notify us by
                email at <a href="mailto:privacy@examplelk.org">privacy@examplelk.org</a>, and we will do our best to
                determine and correct the problem promptly. Be certain the words
                Privacy Policy are in the Subject line.
              </p>
              <h4>ELECTRONIC PRODUCT REGISTRATION</h4>
              <p>
                When you subscribe as a new user, we may ask you to register
                your purchase electronically. When you do, we merge your
                registration information with any information you've already
                left with us (we call that information your personal profile).
                If you haven't previously registered with us, we create a
                personal profile for you from your product registration
                information. If you ever want to review or update that
                information, you can visit the Profile Center, click on Update
                Profile, and edit any of the Personal Information in your
                profile. If you haven't already created a Registration ID, we
                will ask you to do so. This ensures that only you can access
                your information.
              </p>
              <h4>CUSTOMER PROFILES</h4>
              <p>
                As mentioned above, every registered customer has a unique
                personal profile. Each profile is assigned a unique personal
                identification number, which helps us ensure that only you can
                access your profile.
              </p>
              <p>
                When you register, we create your profile, assign a personal
                identification number, then send this personal identification
                number back to your hard drive in the form of a cookie, which is
                a very small bit of code. This code is uniquely yours. It is
                your unique identification to seamless travel across
                <Link to="/"> www.xample.lk</Link>, allowing you to download free software, order
                free newsletters, and visit premium sites without having to fill
                out registration forms with information you've already provided.
                Even if you switch computers, you won't have to re-register –
                just use your Registration ID to identify yourself.
              </p>
              <h4>What we do with the information you share</h4>
              <p>
                When you join us, you provide us with your contact information,
                we use this information to send you updates about your order,
                questionnaires to measure your satisfaction with our service and
                announcements about new and exciting services that we offer.
                When you order from us, we ask for your credit card number and
                billing address. We use this information only to bill you for
                the product(s) you order at that time. For your convenience, we
                do save billing information in case you want to order from us
                again, but we don't use this information again without your
                permission.
              </p>
              <p>
                We occasionally hire other companies to provide limited services
                on our behalf, including packaging, mailing and delivering
                purchases, answering customer questions about products or
                services, sending postal mail and processing event registration.
                We will only provide those companies the information they need
                to deliver the service, and they are prohibited from using that
                information for any other purpose.
              </p>
              <p>
                CLIENTS & CONSUMERS PRIVATE LIMITED will disclose your personal
                information, without notice, only if required to do so by law or
                in the good faith belief that such action is necessary to: (a)
                conform to the edicts of the law or comply with legal process
                served on CLIENTS & CONSUMERS PRIVATE LIMITED or the site; (b)
                protect and defend the rights or property of CLIENTS & CONSUMERS
                PRIVATE LIMITED and its family of Websites, and, (c) act in
                urgent circumstances to protect the personal safety of users of
                CLIENTS & CONSUMERS PRIVATE LIMITED, its Websites, or the
                public.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TermsConditions;
