import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
// includes
import { common, tools, history } from "helpers";
import { apiServices } from "api";
import { StatusBar } from "includes";
let translation = common.getTranslation();

class AnalyticsOverAllTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      paging: {
        pageSize: 10,
        pageIndex: 1
      },
      filter: {
        subjectID: "",
        classID: "",
        topicID: "",
        period: 7,
        userId: ""
      },
      sData: {
        overAllChart: {
          labels: [],
          datasets: []

        },
        overAllList: [],
        dateRange: [
          translation.today,
          translation.this_week,
          translation.last_seven_days,
          translation.this_month,
          translation.last_thirty_days,
          translation.this_year,
          translation.all_time,
        ]
      },
      subjects: common.authInfo().subject ? common.authInfo().subject : [],
      userData: common.authInfo(),
    };
  }

  componentDidMount() {
    const { filter, userData } = this.state;
    if (userData.user && userData.user && userData.user.role && userData.user.role == 1) {
        let childrens = this.props.childrens
        if (childrens && childrens.length > 0) {
            this.setState({
                subjects: childrens[0].class
            })
        }
    }

    filter.userId = this.props.selUserId
    this.setState({
      filter: filter
    }, () => {
      this.getOverAllList();
    })
  }

  getOverAllList = () => {
    const { sData, filter, paging } = this.state;
    this.setStatus("process");
    this.rgb = 0;
    this.count = 1;
    // api call
    let param = `?userID=${filter.userId}&subjectID=&classID=${filter.subjectID}&topicID=${filter.topicID}&period=${filter.period}`;

    let data = {
      method: "GET",
      url: "STAT_OVER_ALL_TEST",
      query: param,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        sData.overAllList = result;
        sData.overAllChart = this.getOverAllChart(status, result);
        status = this.dataStatus(status, result);
        this.setState({
          status,
          sData
        })
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => {}
    );
  };

  dataStatus(status, result) {
    if (status == "success") {
      status = "empty";
      Object.keys(result.data).map((item, key) => {
        if (item != "overall") {
          status = "success";
        }
      })
    }
    return status;
  }

  getOverAllChart(status, result) {

    const { subjects } = this.state;

    let chartData = {
      labels: [],
      datasets: []
    }
    let sLen = 0;
    if (status == "success") {
      Object.keys(result.data).map((item, key) => {
        if (item != "overall") {
          let scoreList = [];
          // data
          result.data[item].map((data, dkey) => {
            scoreList.push(data.score);
          })
          if(scoreList.length > sLen){
            sLen = scoreList.length
          }
          // dataset
          chartData.datasets.push({
            label: common.getSubject(item, subjects),
            data: scoreList,
            fill: true,
            backgroundColor: tools.getRGB(this.rgb++),
            borderColor: 'rgba(255, 99, 132, 0.2)',
          })
        }
      })
    }
    [...Array(sLen)].map((sObj, idx) => {
      chartData.labels.push("Session " + (idx + 1));
    })
    return chartData;
  }

  continueTest(obj) {        
    let testURL = `/tests/${obj.class}/overall/${obj.topic}/${obj.session}?mode=true&year=0&paper=false`;
    history.push(testURL);
  }

  // handler
  onChange(e) {
    const { name, value } = e.target;
    const { filter } = this.state;
    filter[name] = value;
    let selIndex = e.target.options.selectedIndex;

    if (name == "subjectID" && value == "") {
      filter["topicID"] = ""
    }

    if (name == "userId") {
      filter["topicID"] = ""
      filter["subjectID"] = ""
      filter["topicID"] = ""
      filter["classID"] = ""
      filter["period"] = 7

      this.setState({
        filter,
        subjects: this.props.childrens[selIndex].class
      }, () => {
        this.getOverAllList();
      })

    } else {
      this.setState({
        filter
      }, () => {
        this.getOverAllList();
      })
    }
  }

  // filter
  resetFilter() {

    const { filter } = this.state;

    this.setState({
      paging: {
        pageSize: 10,
        pageIndex: 1
      },
      filter: {
        subjectID: "",
        topicID: "",
        classID: "",
        period: 7,
        userId: filter.userId
      }
    }, () => {
      this.getOverAllList();
    })
  }

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  loadGradeBlock = () => {
    const { filter, subjects } = this.state;
    if (filter.subjectID != "" && subjects && subjects.length > 0) {
      let filteredGrade = subjects.filter(element => {
        return filter.subjectID == element._id;
      });
      
      return (
        filteredGrade[0] && 
        filteredGrade[0].grade && 
        filteredGrade[0].grade.length > 0 && 
        filteredGrade[0].grade.map((item, key) => (
          <option key={key} value={item._id}>{item.name}</option>
        ))
      )

    } else {
      return
    }
  }

  render() {
    const {
      status,
      sData,
      filter,
      userData,
      subjects
    } = this.state;
    return (
      <div>
        <div
          className="tab-panel-content tab-pane fade show active"
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab-2"
        >
          <div className="tab-filters d-flex flex-wrap justify-content-between align-items-center">
            <h5 className="primary-color mb-0">{translation.overall_test_scores}</h5>
            <div className="filter">
              <form className="form-inline">
              {
                    userData && 
                    userData.user &&
                    userData.user.role &&
                    userData.user.role == 1 && (
                        <div className="form-group">
                            <label>{translation.overall_child}</label>
                            <select 
                                name="userId" 
                                onChange={(e) => this.onChange(e)}
                                value={filter.userId}
                            >
                                {
                                    this.props.childrens.length > 0 && this.props.childrens.map((item, key) => (
                                        <option value={item._id} key={key}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    )
                }
                <div className="form-group">
                  <label>{translation.mock_subject}</label>
                  <select
                    name="subjectID"
                    value={filter.subjectID}
                    onChange={(e) => this.onChange(e)}
                  >
                    <option value="">{translation.all}</option>
                    {subjects.length > 0 &&
                      subjects.map((item, key) => (
                        <option key={key} value={item._id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                    <label>{translation.mock_grade}</label>
                    <select 
                      name="topicID" 
                      value={filter.topicID}
                      onChange={(e) => this.onChange(e)}
                    >
                        <option value="">{translation.all}</option>
                        { this.loadGradeBlock() }
                    </select>
                </div>
                <div className="form-group">
                  <label>{translation.mock_date_range}</label>
                  <select
                    name="period"
                    value={filter.period}
                    onChange={(e) => this.onChange(e)}
                  >
                    {/* <option value="7">{translation.all_time}</option> */}
                    {sData.dateRange.map((item, key) => (
                      <option key={key} value={(key + 1)}>{item}</option>
                    ))}
                  </select>
                </div>
                <input
                  type="reset"
                  value={translation.reset}
                  className="btn-sm"
                  onClick={() => this.resetFilter()}
                />
              </form>
            </div>
          </div>
          <StatusBar status={status} />
          {status == "success" && (
            <div>
              <div className="test-card mb-4">
                <div className="test-header d-flex justify-content-between align-items-center mb-5">
                  {/* <h5 className="mb-0">Overall test performance - Time Based</h5> */}
                  <h5 className="mb-0">{translation.overall_test_performance} - {translation.time_based}</h5>
                </div>
                <div className="test-chart">
                  <Line data={sData.overAllChart} />
                </div>
              </div>

              <div className="test-card">
                <div className="test-header d-flex justify-content-between align-items-center mb-5">
                  {/* <h5 className="mb-0">Overall test sessions</h5> */}
                  <h5 className="mb-0">{translation.overall_test_sessions}</h5>
                </div>

                <div className="table-responsive mt-5">
                  <table className="table table-3">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{translation.mock_subject}</th>
                        {/* <th>Grade</th> */}
                        <th>{translation.mock_grade}</th>
                        <th>{translation.questions_answered}</th>
                        <th>{translation.score}</th>
                        {/* <th>Negative Marks</th> */}
                        <th>{translation.last_practiced}</th>
                        {
                          userData.user &&
                          userData.user.role &&
                          userData.user.role != 1 && (
                            <th className="text-center">{translation.action}</th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(sData.overAllList.data).map((item, key) => (
                        item != "overall" && (
                          sData.overAllList.data[item].map((data, dkey) => (
                            <tr key={dkey}>
                              <td>{this.count++}</td>
                              <td>{common.getSubject(data.class, subjects)}</td>
                              <td>{common.getGrade(data.topic, data.class, subjects)}</td>
                              <td>{data.question}</td>
                              <td>{data.score}</td>
                              {/* <td>{data.nagativeMark}</td> */}
                              <td>{moment(data.date).format("DD MMM YYYY")}</td>
                              {
                                userData.user &&
                                userData.user.role &&
                                userData.user.role != 1 && (
                                  <td className="text-center">
                                  {data.completed ? (
                                      <input type="button" 
                                        className="btn success-btn" 
                                        // value="Completed" 
                                        value={translation.completed}
                                      />
                                    ) : (
                                      <input type="button" 
                                        className="btn primary-btn" 
                                        // value="Continue" 
                                        value={translation.continue}
                                        onClick={() => this.continueTest(data)} />
                                  )}
                                  </td>
                                )
                              }
                            </tr>)))
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-sm-12 text-center">
                  <Link
                    to="#"
                    onClick={() => {
                      this.loadMore();
                    }}
                    className="btn bordered-btn-blue mt-5"
                  >
                    Load More
                </Link>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AnalyticsOverAllTest;
