import React from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Doughnut } from "react-chartjs-2";
// includes
import { apiServices } from "api";
import { StatusBar } from "includes";
import { history, common, tools } from "helpers";
import { envConfig } from "api/config";
// pages
import MyGoalReports from "./MyGoalReports";

class MyProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectId: this.props.selectedSubject !== "" ? this.props.selectedSubject : "all",
      userId: this.props.userId,
      classId: "",
      topicId: "",
      termId: "",
      isStatus: {
        mpList: "",
        mpInfo: "process"
      },
      isView: "progressList",
      sData: {
        myProgressList: [],
        myProgressInfo: [],
        myProgressTermInfo: [],
        usageData: {},
      },
      usageInfo: {
        date: "",
        index: 0
      },
      userInfo: this.props.userInfo,
      userData: common.authInfo(),
      userPlan: this.props.userPlan,
      translation: common.getTranslation()
    };
  }

  // life
  componentDidMount() {
    this.getMyProgressList();
  }

  componentDidUpdate(prevProps){
    const { selectedSubject, userId, userInfo } = this.props;
    if(userId != "" && prevProps.userId !== userId || selectedSubject != "" && prevProps.selectedSubject !== selectedSubject || prevProps.userInfo.length != userInfo.length) {
        this.setState({
          subjectId: selectedSubject,
          userId: userId,
          userInfo: userInfo
        },
        _=>{
          this.getMyProgressList();
        })
      }    
  }

  // api
  getMyProgressList = () => {
    this.setStatus("mpList", "process");
    const { sData, userId, subjectId } = this.state;
    let query = `?userId=${userId}&classId=${subjectId}`;
    let data = {
      method: "GET",
      url: "DB_MY_PROGRESS_LIST",
      query
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.myProgressList = common.apiResultData(res);
        if (status == "success") {
          this.setState({
            status,
            sData
          })
        }
        this.setStatus("mpList", status);
      },
      (error) => {
        this.setStatus("mpList", "error");
      },
      (final) => { }
    );
  }

  getMyProgressInfo = () => {
    const { sData, userId, classId, topicId } = this.state;
    let query = `?userId=${userId}&classId=${classId}&topicId=${topicId}`;
    let data = {
      method: "GET",
      url: "DB_MY_PROGRESS_INFO",
      query
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.myProgressInfo = this.progressResult(common.apiResultData(res));
        if (status == "success") {
          this.setState({
            status,
            sData
          })
        }
        this.setStatus("mpInfo", status);
      },
      (error) => {
        this.setStatus("mpInfo", "error");
      },
      (final) => { }
    );
  }

  // api support
  progressResult(res) {
    const { userInfo, classId, topicId, sData } = this.state;
    let result = {};
    let usageData = {};
    if (res.data) {
      result = {};
      res.data.map((item, key) => {
        // term
        let termExist = item.term in result;
        if (!termExist) {
          Object.assign(result, { [item.term]: {} });
          Object.assign(result[item.term], { tests: {} });
          Object.assign(result[item.term], { pendings: [] });
          Object.assign(result[item.term], { data: { time: 0, question: 0, answered: 0, totalQuestions: 0 } });
        }
        // test
        let testExist = item._id in result[item.term].tests;
        if (!testExist) {
          Object.assign(result[item.term].tests, { [item._id]: {} });
        }
        result[item.term].tests[item._id] = item;
        // data
        result[item.term].data.time += item.time;
        result[item.term].data.question += item.question;
        result[item.term].data.answered += item.answered;
        result[item.term].data.totalQuestions += userInfo.questions[item._id].total * parseInt(item.sessions.length);
        // event
        item.sessions.map((sItem, skey) => {
          let createdLast = moment(sItem.createdLast).format("YYYY-MM-DD");
          let usageExist = createdLast in usageData;
          if (!usageExist) {
            Object.assign(usageData, { [createdLast]: [] });
          }
          usageData[createdLast].push(sItem)
        })
      })
    }

    // user data
    let userClass = userInfo.items[classId];
    userClass.term.map((item, key) => {
      // term
      let termExist = item in result;
      if (!termExist) {
        Object.assign(result, { [item]: {} });
        Object.assign(result[item], { tests: {} });
        Object.assign(result[item], { pendings: [] });
        Object.assign(result[item], { data: null });
      }

      // topic
      userClass.test[topicId][item].map((gItem, gkey) => {
        let testExist = gItem in result[item].tests;
        if (!testExist) {
          result[item].pendings.push(gItem)
        }
      })
    })
    // calendar
    sData.usageData = usageData;
    this.setState({
      sData
    })
    return tools.sortObject(result);
  }

  gradeProgress(data) {
    const { userInfo } = this.state;
    let totalQuestions = 0;
    data.tests.map((item, key) => {
      if(userInfo && userInfo.questions){
        totalQuestions += userInfo.questions[item.split("#")[0]].total;
      }
    })
    let progress = Math.round((data.answered / totalQuestions) * 100);
    return progress;
  }

  termProgress(data) {
    let progress = Math.round((data.answered / data.totalQuestions) * 100);
    return progress;
  }

  testProgress(data) {
    const { userInfo } = this.state;
    let totalQuestions = data.sessions[0].completed ? data.sessions[0].question : userInfo.questions[data._id].total;
    let session = data.sessions[0];
    let answered = Math.round(((session.answered / totalQuestions) * 100));
    let wrong = Math.round(((session.question - session.answered) / totalQuestions) * 100);
    let process = (100 - (answered + wrong));
    let actualMarks = (totalQuestions * envConfig.CORRECT_ANSWER_SCORE);
    let marks = (session.answered * envConfig.CORRECT_ANSWER_SCORE);
    let inProgress = 0;
    let completed = 0;
    data.sessions.map(item => {
      if (item.completed) {
        completed++;
      } else {
        inProgress++;
      }
    })
    let result = { answered, wrong, process, actualMarks, marks, inProgress, completed }
    return result;
  }

  // event calendar
  checkUsage(info) {
    const { sData } = this.state;
    let currentDate = moment(info.date).format("YYYY-MM-DD");
    if (currentDate in sData.usageData) {
      return "event-active";
    }
  }

  getUsage(info) {
    const { sData } = this.state;
    if (info.dateStr in sData.usageData) {
      this.setState({
        usageInfo: {
          date: info.dateStr,
          index: 0
        }
      })
    }
  }

  nextUsage() {
    const { sData, usageInfo } = this.state;
    let date = usageInfo.date;
    let index = usageInfo.index;
    if ((sData.usageData[date].length - 1) == index) {
      index = 0;
    } else {
      index++;
    }
    usageInfo.index = index;
    this.setState({
      usageInfo
    })
  }

  // handler
  onSubjectSelect(subjectId) {
    this.setState({
      subjectId
    }, () => {
      this.getMyProgressList();
    })
  }

  onClassSelect(item) {
    const { userInfo } = this.state;
    let totalQuestions = 0;
    item.tests.map((tItem, tkey) => {
      let questionTotal = userInfo.questions[tItem.split("#")[0]].total;
      let sessionTotal = userInfo.sessions[tItem.split("#")[1]].questions;
      if (sessionTotal > questionTotal) {
        questionTotal = sessionTotal;
      }
      totalQuestions += questionTotal;
    })

    let scoreCard = {
      time: item.time,
      question: item.question,
      answered: item.answered,
      sessions: item.tests.length,
      score: Math.round((item.answered / totalQuestions) * 100),
      completed: Math.round((item.question / totalQuestions) * 100),
      pendings: (100 - (Math.round((item.question / totalQuestions) * 100))),
    };

    this.setState({
      classId: item.class,
      topicId: item.topic,
      isView: "progressInfo",
      scoreCard
    }, () => {
      this.getMyProgressInfo();
    })
  }

  // support
  testRedirect(e, testId) {
    e.preventDefault();
    const { classId, topicId } = this.state;
    history.push(`/tests/${classId}/${testId}/${topicId}?mode=false&year=0&paper=false`);
  }

  sessionRedirect(e, testId, sessionId) {
    e.preventDefault();
    const { classId, topicId } = this.state;
    history.push(`/tests/${classId}/${testId}/${topicId}/${sessionId}?mode=false&year=0&paper=false`);
  }

  // common
  setStatus(type, status) {
    const { isStatus } = this.state;
    isStatus[type] = status;
    this.setState({
      isStatus
    })
  }

  setListView(e) {
    e.preventDefault();
    const { isStatus } = this.state;
    isStatus.mpInfo = "process";
    this.setState({
      isStatus,
      usageInfo: {
        date: "",
        index: 0
      },
      isView: "progressList",
    })
  }

  render() {
    const { isView, isStatus, subjectId, userId, classId, topicId, userData, sData, userInfo, usageInfo, scoreCard, userPlan, translation } = this.state;
    return (
      <div>
        <div className="tab-header">
          <h5 className="mb-0">{translation.myprogress}</h5>
        </div>
        <div className="tab-body">
          {isView == "progressList" ? (
            <div>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                  {userData && userData.user && userData.user.role != 4 &&
                  <li
                    className="nav-item"
                    onClick={() => this.onSubjectSelect("all")}
                  >
                    <a
                      className={
                        subjectId == "all" ? "nav-link active" : "nav-link"
                      }
                      id="sub-tab-5"
                      data-toggle="tab"
                      href="#subTab5"
                      role="tab"
                      aria-controls="subTab5"
                      aria-selected={subjectId == "all" ? "true" : "false"}
                    >
                      {translation.all}
                    </a>
                  </li>
          }
                {userInfo.items && Object.keys(userInfo.items).length > 0 && Object.keys(userInfo.items).map((item, key) => {
                  return (
                    <>
                    {subjectId == item && userData && userData.user && userData.user.role == 4 && 
                  <li
                    className="nav-item"
                    onClick={() => this.onSubjectSelect(item)}
                    key={key}
                  >
                    <a
                      className={
                        subjectId == item ? "nav-link active" : "nav-link"
                      }
                      id={"sub-tab-" + (key + 1)}
                      data-toggle="tab"
                      href={"#subTab" + (key + 1)}
                      role="tab"
                      aria-controls={"subTab" + (key + 1)}
                      aria-selected={subjectId == item ? "true" : "false"}
                    >
                      {userInfo.items && userInfo.items[item].name}
                    </a>
                  </li>
                  }
                  {userData && userData.user && userData.user.role != 4 && 
                  <li
                    className="nav-item"
                    onClick={() => this.onSubjectSelect(item)}
                    key={key}
                  >
                    <a
                      className={
                        subjectId == item ? "nav-link active" : "nav-link"
                      }
                      id={"sub-tab-" + (key + 1)}
                      data-toggle="tab"
                      href={"#subTab" + (key + 1)}
                      role="tab"
                      aria-controls={"subTab" + (key + 1)}
                      aria-selected={subjectId == item ? "true" : "false"}
                    >
                      {userInfo.items && userInfo.items[item].name}
                    </a>
                  </li>
                  }
                  </>
                )})}
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="subTab1"
                  role="tabpanel"
                  aria-labelledby="tab-1"
                >
                  <StatusBar status={isStatus.mpList} />
                  {isStatus.mpList == "success" && (
                    <ul className="progress-list">
                      {sData.myProgressList.data.map((item, key) => {
                        const progress = this.gradeProgress(item);
                        return (
                          <li
                            className="d-flex flex-wrap justify-content-between align-items-stretch elk-cp"
                            key={key}
                            onClick={() => this.onClassSelect(item)}
                          >
                            <div className="progress-subject">
                              <h4 className="mb-0">
                                {userInfo.names && userInfo.names[item.class]}
                              </h4>
                              <h6 className="mb-0">
                                {userInfo.names && userInfo.names[item.topic]}
                              </h6>
                            </div>
                            <div className="time-spent">
                              <p className="mb-0">
                                <span>{translation.time_spent}</span>:{" "}
                                {common.toHHMMSS(
                                  item.weekData == null ? 0 : item.weekData.time
                                )}{" "}
                                hrs (1 Week)
                              </p>
                              <p className="mb-0">
                                <span>{translation.questions}</span>:{" "}
                                {item.weekData == null
                                  ? 0
                                  : item.weekData.question}{" "}
                                {translation.questions}
                              </p>
                            </div>
                            <div className="progress-score">
                              <div className="progress-bar-wrap mt-2">
                                <div className="progress-header d-flex justify-content-between">
                                  <p className="progress-text-sm mb-1">
                                    {translation.score}
                                  </p>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-info"
                                    style={{ width: `${progress}%` }}
                                  >
                                    <span
                                      className="popOver"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                    >
                                      {progress}%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <a
                href="#"
                className="btn-sm"
                onClick={(e) => this.setListView(e)}
              >
                Back
              </a>
              <div>
                <div className="task-heading d-flex flex-wrap justify-content-between mt-5">
                  <h3>{userInfo.names && userInfo.names[classId]}</h3>
                  <div className="progress-score">
                    <div className="progress-bar-wrap mt-2">
                      <div className="progress-header d-flex justify-content-between">
                        <p className="progress-text-sm mb-0">
                          {translation.score}
                        </p>
                      </div>

                      <div className="progress">
                        <div
                          className="progress-bar bg-info"
                          style={{ width: `${scoreCard.score}%` }}
                        >
                          <span
                            className="popOver"
                            data-toggle="tooltip"
                            data-placement="top"
                          >
                            {" "}
                            {scoreCard.score}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-lg-4 text-center">
                    <div className="doughnut-chart">
                      <Doughnut
                        data={{
                          maintainAspectRatio: false,
                          responsive: false,
                          labels: ["Completed", "Progress"],
                          datasets: [
                            {
                              data: [scoreCard.completed, scoreCard.pendings],
                              backgroundColor: ["#4DA246", "#AFC642"],
                            },
                          ],
                        }}
                        options={{
                          cutoutPercentage: 60,
                          legend: {
                            display: false,
                            position: "right",
                          },
                          elements: {
                            arc: {
                              borderWidth: 0,
                            },
                          },
                        }}
                        width={210}
                        height={210}
                      ></Doughnut>
                      <div className="prog_value prog_pers text-center">
                        <span className="percentage-value">
                          {" "}
                          {scoreCard.completed}%
                        </span>
                        <b>{translation.questions}</b>
                        <br /> {translation.completed}
                      </div>
                    </div>
                    {/* <p className="mt-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy</p> */}
                  </div>
                  <div className="col-lg-8">
                    <MyGoalReports
                      userPlan={userPlan}
                      userId={userId}
                      userInfo={userInfo}
                      classId={classId}
                      topicId={topicId}
                      onSetGoal={() =>
                        this.props.onSetGoal({ classId, topicId })
                      }
                    />
                  </div>
                </div>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry's standard dummy text
                  ever since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived not
                  only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged.</p> */}
                <StatusBar status={isStatus.mpInfo} />
                {isStatus.mpInfo != "process" && (
                  <div
                    id="AccordionMyProgress"
                    className="accordion-block alternate mt-4"
                  >
                    <div className="card">
                      <div className="card-header" id="heading-grade">
                        <p className="heading-grade">
                          {userInfo.names && userInfo.names[topicId]}
                        </p>
                      </div>
                    </div>
                    {Object.keys(sData.myProgressInfo).map((item, key) => {
                      let data = sData.myProgressInfo[item];
                      return (
                        <div className="card" key={key}>
                          <div className="card-header" id={`heading-${key}`}>
                            <h5 className="mb-0">
                              <a
                                className="collapsed"
                                role="button"
                                data-toggle="collapse"
                                href={"#collapse-" + key}
                                aria-expanded="false"
                                aria-controls={"collapse-" + key}
                              >
                                {" "}
                                {userInfo.names[item]}
                              </a>
                              {data.data != null && (
                                <div className="progress-score">
                                  <div className="progress-bar-wrap mt-2">
                                    <div className="progress-header d-flex justify-content-between">
                                      <p className="progress-text-sm mb-1">
                                        {translation.progress}
                                      </p>
                                    </div>
                                    <div className="progress">
                                      <div
                                        className="progress-bar bg-info"
                                        style={{
                                          width: `${this.termProgress(
                                            data.data
                                          )}%`,
                                        }}
                                      >
                                        <span
                                          className="popOver"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                        >
                                          {this.termProgress(data.data)}%
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </h5>
                          </div>

                          <div
                            id={"collapse-" + key}
                            className="collapse"
                            data-parent="#AccordionMyProgress"
                            aria-labelledby={"heading-" + key}
                          >
                            <div className="card-body">
                              {Object.keys(data.tests).map((tItem, tkey) => {
                                let tData = data.tests[tItem];
                                let testProgress = this.testProgress(tData);
                                return (
                                  <div
                                    className="test-session-row d-flex flex-wrap justify-content-between"
                                    key={tkey}
                                  >
                                    <div className="test-session-info">
                                      <h6>{userInfo.names[tItem]}</h6>
                                    </div>
                                    <div className="test-session-report">
                                      {/* <div className="progress-bar-values d-flex">
                                      <span style={{ width: `${testProgress.answered}%` }}>{testProgress.answered}%</span>
                                      <span style={{ width: `${testProgress.wrong}%` }}>{testProgress.wrong}%</span>
                                      <span style={{ width: `${testProgress.process}%` }}>{testProgress.process}%</span>
                                    </div> */}
                                      <div className="progress progress-sm mt-3">
                                        <div
                                          className="progress-bar bg-success"
                                          style={{
                                            width: `${testProgress.answered}%`,
                                          }}
                                        ></div>
                                        <div
                                          className="progress-bar bg-danger"
                                          style={{
                                            width: `${testProgress.wrong}%`,
                                          }}
                                        ></div>
                                        <div
                                          className="progress-bar bg-warning"
                                          style={{
                                            width: `${testProgress.process}%`,
                                          }}
                                        ></div>
                                      </div>
                                      <div className="status-review d-flex flex-wrap justify-content-between">
                                        <div className="status-info pr-2 mt-3">
                                          <label className="success">
                                            <strong>
                                              {testProgress.answered}%
                                            </strong>{" "}
                                            {translation.correct}
                                          </label>
                                          <label className="danger">
                                            <strong>
                                              {testProgress.wrong}%
                                            </strong>{" "}
                                            {translation.wrong}
                                          </label>
                                          <label className="warning">
                                            <strong>
                                              {testProgress.process}%
                                            </strong>{" "}
                                            {translation.unaswered}
                                          </label>
                                        </div>
                                        <div className="marks-value">
                                          <label>
                                            {translation.marks}{" "}
                                            <span>{testProgress.marks}</span>
                                          </label>
                                          <label>
                                            {translation.actual_marks}{" "}
                                            <span>
                                              {testProgress.actualMarks}
                                            </span>
                                          </label>
                                          <label>
                                            {translation.in_progress}{" "}
                                            <span>
                                              {testProgress.inProgress}
                                            </span>
                                          </label>
                                          <label>
                                            {translation.completed}
                                            <span>
                                              {testProgress.completed}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {tData.sessions[0].completed ? (
                                      <a
                                        href="#"
                                        onClick={(e) => e.preventDefault()}
                                        className="btn-sm completed"
                                      >
                                        {translation.completed}
                                      </a>
                                    ) : userData.user.role !== 1 &&
                                      userPlan.paymentStatus != "expired" ? (
                                      <a
                                        href="#"
                                        onClick={(e) =>
                                          this.sessionRedirect(
                                            e,
                                            tItem,
                                            tData.sessions[0]._id
                                          )
                                        }
                                        className="btn-sm take-test"
                                      >
                                        {translation.continue}
                                      </a>
                                    ) : (
                                      userData.user.role === 1 && (
                                        <a
                                          href="#"
                                          onClick={(e) => e.preventDefault()}
                                          className="btn-sm take-test"
                                        >
                                          {translation.in_progress}
                                        </a>
                                      )
                                    )}
                                  </div>
                                );
                              })}
                              {data.pendings.map((pItem, pkey) => {
                                return (
                                  <div
                                    className="test-session-row d-flex flex-wrap justify-content-between"
                                    key={pkey}
                                  >
                                    <div className="test-session-info">
                                      <h6>{userInfo.names[pItem]}</h6>
                                    </div>
                                    {userData.user.role !== 1 &&
                                      userPlan.paymentStatus != "expired" && (
                                        <a
                                          href="#"
                                          onClick={(e) =>
                                            this.testRedirect(e, pItem)
                                          }
                                          className="btn-sm start"
                                        >
                                          Start
                                        </a>
                                      )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="card">
                      <div className="card-header" id="heading-calendar">
                        <h5 className="mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            href="#collapse-calendar"
                            aria-expanded="false"
                            aria-controls="collapse-calendar"
                          >
                            {" "}
                            {translation.usage}
                          </a>
                        </h5>
                      </div>
                      <div
                        id="collapse-calendar"
                        className="collapse"
                        data-parent="#AccordionMyProgress"
                        aria-labelledby="heading-4"
                      >
                        <div className="card-body">
                          <div className="progress-calendar">
                            <div className="custom-calendar-header">
                              <div className="row custom-row">
                                <div className="col-sm-4">
                                  <div className="progress-item">
                                    <span>{scoreCard.answered}</span>{" "}
                                    {translation.answered_question}
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="progress-item">
                                    <span>
                                      {" "}
                                      {(scoreCard.time / 60).toFixed(1)}
                                      <sub> {translation.min}</sub>
                                    </span>{" "}
                                    {translation.time_spent}
                                  </div>
                                </div>
                                <div className="col-sm-4">
                                  <div className="progress-item">
                                    <span>{scoreCard.sessions}</span>{" "}
                                    {translation.made_progress_in_test}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="custom-calendar-2">
                              <FullCalendar
                                defaultView="dayGridMonth"
                                header={{
                                  right: "prev,next,title",
                                }}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                weekends={true}
                                dateClick={(date) => this.getUsage(date)}
                                dayCellClassNames={(info) =>
                                  this.checkUsage(info)
                                }
                              />
                            </div>
                            {usageInfo.date && (
                              <div className="test-history-list mt-4">
                                <div className="test-history d-flex align-items-center justify-content-between">
                                  <p className="test-date mb-0">
                                    {" "}
                                    {moment(usageInfo.date).format("D")}
                                    <sub>
                                      {moment(usageInfo.date)
                                        .format("Do")
                                        .replace(/[0-9]/g, "")}
                                      <br />{" "}
                                      {moment(usageInfo.date).format("MMMM")}
                                    </sub>
                                  </p>
                                  <div className="test-details">
                                    <h6>
                                      {moment(
                                        sData.usageData[usageInfo.date][
                                          usageInfo.index
                                        ].createdOn
                                      ).format("MMM, DD hh:mm:ss A")}{" "}
                                      to{" "}
                                      {moment(
                                        sData.usageData[usageInfo.date][
                                          usageInfo.index
                                        ].createdLast
                                      ).format("MMM, DD hh:mm:ss A")}
                                    </h6>
                                    <p className="mb-0">
                                      {" "}
                                      {common.toHHMMSS(
                                        sData.usageData[usageInfo.date][
                                          usageInfo.index
                                        ].time
                                      )}{" "}
                                      hrs active practice{" "}
                                      {
                                        sData.usageData[usageInfo.date][
                                          usageInfo.index
                                        ].question
                                      }{" "}
                                      questions Establishment Of The{" "}
                                      {
                                       userInfo.names && userInfo.names[
                                          sData.usageData[usageInfo.date][
                                            usageInfo.index
                                          ].test
                                        ]
                                      }
                                    </p>
                                  </div>
                                  {sData.usageData[usageInfo.date].length >
                                    1 && (
                                    <img
                                      src={common.loadImg("arrow_up.png")}
                                      className="arrow-icon elk-cp"
                                      onClick={() => this.nextUsage()}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default MyProgress;
