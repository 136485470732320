import React from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
// includes
import { apiServices } from "api";
import { StatusBar, Handler } from "includes";
import { history, common, tools } from "helpers";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

export default class SupportTicket extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.state = {
      params,
      status: "process",
      isLoading: false,
      formSubmit: false,
      fields: {
        message: "",
      },
      errors: {
        message: {
          error: "",
          isReq: "Message is required!",
        },
      },
      sData: {
        ticketInfo: []
      },
      
    };
  }

  componentDidMount = () => {
    this.socketIO();
    this.getTicketInfo();
  };

  componentWillUnmount = () => {
    window.socket.removeListener('userTicket');
  }

  getTicketInfo = () => {
    const { params, sData } = this.state;
    // api call
    let data = {
      method: "GET",
      url: "SUPPORT",
      query: `/${params.id}`,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.ticketInfo = common.apiResultData(res);
        this.setState({
          status,
          sData
        }, () => {
          if (status == "success") {
            this.updateRead();
            this.scrollToBottom();
          }
        })
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  closeTicket() {
    const { params } = this.state;
    // api call
    let data = {
      method: "PUT",
      url: "SUPPORT",
      query: `/${params.id}`,
      body: {
        status: false
      },
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status == "success") {
          common.snack("S", translation.ticket_closed);
          history.push("/support/tickets");
        }
      },
      (error) => { },
      (final) => { }
    );
  }

  updateRead() {
    const { params } = this.state;
    // api call
    let data = {
      method: "PUT",
      url: "SUPPORT_READ",
      query: `/${params.id}`
    };

    apiServices.call(
      data,
      (res) => { },
      (error) => { },
      (final) => { }
    );
  }

  onSubmit = () => {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      const { params, fields, sData } = this.state;
      let qParams = {
        commentedByUser: common.getStorage("userId"),
        message: fields.message
      }
      let mKey = this.setMessage("process", 0);
      // reset scroll
      this.resetForm();
      setTimeout(() => {
        this.scrollToBottom();
      }, 0);
      // api call
      let data = {
        method: "PUT",
        url: "SUPPORT_MESSAGE",
        query: `/${params.id}`,
        body: qParams,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            this.setMessage("success", mKey);
          } else {
            this.setMessage("failure", mKey);
          }
        },
        (error) => {
          this.setMessage("failure", mKey);
        },
        (final) => {
        }
      );
    }
  };

  // close ticket
  onCloseTicket(e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui delete-confirmation text-center">
            <h1>{translation.close_confirmation}</h1>
            <p>{translation.close_this_ticket}</p>
            <div className="d-flex justify-content-center">
              <button className="popup-btn noBtn" onClick={onClose}>
                {translation.cancel}
              </button>
              <button
                className="popup-btn yesBtn"
                onClick={() => {
                  this.closeTicket();
                  onClose();
                }}
              >
                {translation.confirm}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  setMessage(status, key) {
    const { sData, fields } = this.state;
    let data = sData.ticketInfo.data.message[key];
    let total = sData.ticketInfo.data.message.length;
    if (status == "process") {
      sData.ticketInfo.data.message.push({
        commentedByUser: data.commentedByUser,
        commentedByStaff: null,
        isViewedByUser: true,
        isViewedByStaff: false,
        messageDate: moment().toDate(),
        message: fields.message,
        status: "process"
      });
    } else {
      data.status = status;
      sData.ticketInfo.data.message[key] = data;
    }
    this.setState({ sData });
    return total;
  }

  resetForm() {
    const { fields } = this.state;
    fields.message = "";
    this.setState({
      fields,
      formSubmit: false
    })
  }

  scrollToBottom = () => {
    let ele = document.getElementById("support-chat");
    ele.scrollTo(0, ele.scrollHeight);
  }

  // support function
  setStatus(status) {
    this.setState({ status: status });
  }

  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  getStatus(item, type) {
    let name = "";
    let cls = "";
    if (item.status && !item.pickTicket.isPicked) {
      name = "Waiting";
      cls = "bg-warning"
    } else if (item.status) {
      name = "Open";
      cls = "bg-success"
    } else {
      name = "Closed";
      cls = "bg-danger"
    }
    if (type == "name") {
      return name;
    } else {
      return cls;
    }
  }

  // socket
  socketIO() {
    window.socket.on('userTicket', (result) => {
      const { params, status, sData } = this.state;
      if (result.userId == common.getStorage("userId") && result.ticketId == params.id) {
        if (status == "success") {
          sData.ticketInfo.data.message.push(result.data);
          this.setState({
            sData
          }, () => {
            this.scrollToBottom();
            this.updateRead();
          });
        }
      }
    })
  }

  render() {
    const {
      params,
      status,
      isLoading,
      formSubmit,
      fields,
      errors,
      sData
    } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Support Ticket</title>
        </Helmet>
        <Link to="#" className="custom-link">
          Xample platform is a  <span>Novelwall</span> product
          </Link>
        {/* <!-- Support Form Wrap --> */}
        <div className="support-form-wrap">
          <div className="container">
            <div className="row pb-3">
              <div className="col-lg-12">
                <div className="card card-4 mb-5">
                  <div className="card-heading tertiary-bg d-flex justify-content-between align-items-center">
                    <div>
                      <h2>{translation.support_title}</h2>
                      <h4>{translation.ur_tic_info}</h4>
                    </div>
                    <Link to="/support/tickets" className="btn bordered-btn">{translation.go_back}</Link>
                  </div>
                  <StatusBar status={status} />
                  {status == "success" && (
                    <div className="card-body p-0">
                      <div className="table-responsive mb-4">
                        <table className="table table-2 alternate">
                          <tbody>
                            <tr>
                              <td className="w-60">
                                <strong>{translation.request_for}</strong>{" "}
                                <span className="ml-5">{sData.ticketInfo.data.requestFor}</span>
                              </td>
                              <td className="text-center">
                                <strong> </strong>
                                <label className={`btn ml-4 ${this.getStatus(sData.ticketInfo.data, "class")}`}>{this.getStatus(sData.ticketInfo.data, "name")}</label>
                              </td>
                              <td className="text-center">
                                {sData.ticketInfo.data.status && (
                                  <a href="#" className="link-text" onClick={(e) => this.onCloseTicket(e)}>
                                    {translation.close_ticket}
                                  </a>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="discussion-board">
                        <h4>{translation.discussion_board}</h4>
                        <div className="discussion-block mb-5" id="support-chat">
                          {sData.ticketInfo.data.message.map((item, key) => (
                            <div className={item.commentedByUser != null ? "discussion-row d-flex justify-content-end flip-content" : "discussion-row d-flex"} key={key}>
                              <div className="client-img">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="20"
                                  viewBox="0 0 18 20"
                                >
                                  <g fillRule="evenodd">
                                    <g fillRule="nonzero">
                                      <g>
                                        <path
                                          d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                          transform="translate(-1267 -25) translate(1267 25)"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="client-content">
                                {item.commentedByUser != null && (
                                  <div className="text-right">
                                    {item.status == "process" ? (
                                      <span>{translation.message_sending}</span>
                                    ) : item.status == "failure" ? (
                                      <span>{translation.message_failed}</span>
                                    ) : (
                                          <span>
                                            {tools.utcToLocal(
                                              item.messageDate,
                                              "MMMM DD YYYY hh:mm:ss A"
                                            )}
                                          </span>
                                        )}
                                    <h6>{item.commentedByUser.name}</h6>
                                  </div>
                                )}
                                {item.commentedByStaff != null && (
                                  <div>
                                    <h6>
                                      {item.commentedByStaff.name.first}
                                    </h6>
                                    <span>
                                      {tools.utcToLocal(
                                        item.messageDate,
                                        "MMMM DD YYYY hh:mm:ss A"
                                      )}
                                    </span>
                                  </div>
                                )}
                                <p>{item.message}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        {(sData.ticketInfo.data.status && sData.ticketInfo.data.pickTicket.isPicked) && (
                          <div className="discussion-form-wrap">
                            <div className="form-group d-flex mb-0">
                              <input
                                type="text"
                                name="message"
                                className="form-control"
                                placeholder="Type Your Message Here..."
                                value={fields.message}
                                onChange={(e) => this.handler.onChange(e)}
                              />
                              <input
                                type="button"
                                disabled={isLoading}
                                className="btn ml-4"
                                value={!isLoading ? "Send" : "Processing..."}
                                onClick={() => this.onSubmit()}
                              />
                            </div>
                            <div>
                              {errors.message.error != "" && formSubmit && (
                                <p className="elk-error">
                                  {errors.message.error}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Support Form Wrap End --> */}
        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div >
    );
  }
}
