import React from "react";
import { Link } from "react-router-dom";
// includes
import { Handler } from "includes";
import { common, message, history } from "helpers";
import { apiServices } from "api";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        oldpassword: "",
        password: "",
        confirmpassword: "",
      },
      errors: {
        oldpassword: {
          error: "",
          minLength: 6,
          isReq: translation.isReq_old,
          isMinLengthReq: translation.isMinLengthReq,
        },
        password: {
          error: "",
          minLength: 6,
          isReq: translation.isReq_new,
          isMinLengthReq: translation.isMinLengthReq,
        },
        confirmpassword: {
          error: "",
          minLength: 6,
          isReq: translation.isReq_confirm,
          isMinLengthReq: translation.isMinLengthReq,
          matchPassword: true,
          matchPasswordReq: translation.matchPasswordReq,
        },
      },
      userData: common.authInfo(),


    };
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const { fields, userData } = this.state;
      // api call
      let params = {
        username: userData.user.regType && userData.user.regType == "email" ? userData.user.email : userData.user.regType && userData.user.regType == "mobile" ? userData.user.mobile : userData.user.email,
        password: fields.password,
        oldPassword: fields.oldpassword,
        type: userData.user.regType ? userData.user.regType : "email"
      };

      let urlKeys = {
        userId: common.getStorage("userId"),
      }

      let data = {
        method: "PUT",
        url: "CHANGE_PASSWORD",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            common.snack("S", translation.valid_password);
            history.push("/settings/profile");
          } else {
            this.setLoading(false);
            if (status == "failure") {
              common.snack("E", "Error in change password. Please contact support team");
            }
          }
        },
        (error) => { },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // event
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  redirect() {
    history.push("/settings/profile");
  }

  render() {
    const { isLoading, formSubmit, fields, errors } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Change Password</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="card card-2">
                  <div className="card-heading primary-bg">
                    <h2>{translation.change_password}</h2>
                  </div>
                  <div className="card-body">
                    <form method="POST">
                      <div className="form-group">
                        <input
                          type="password"
                          name="oldpassword"
                          value={fields.oldpassword}
                          onChange={(e) => this.handler.onChange(e)}
                          onKeyDown={this.onEnterPress}
                          className="form-control"
                          placeholder=" "
                        />
                        {/* <span className="floating-label">
                          {translation.enter_old_password}
                        </span> */}
                        <span className="floating-label">
                          Enter Old Password
                        </span>
                        {errors.oldpassword.error != "" && formSubmit && (
                          <span className="elk-error">
                            {errors.oldpassword.error}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          value={fields.password}
                          onChange={(e) => this.handler.onChange(e)}
                          onKeyDown={this.onEnterPress}
                          className="form-control"
                          placeholder=" "
                        />
                        {/* <span className="floating-label">
                          {translation.enter_new_password}
                        </span> */}
                        <span className="floating-label">
                          Enter New Password
                        </span>
                        {errors.password.error != "" && formSubmit && (
                          <span className="elk-error">
                            {errors.password.error}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="confirmpassword"
                          value={fields.confirmpassword}
                          onChange={(e) => this.handler.onChange(e)}
                          onKeyDown={this.onEnterPress}
                          className="form-control"
                          placeholder=" "
                        />
                        {/* <span className="floating-label">{translation.repeat_password}</span> */}
                        <span className="floating-label">Repeat Password</span>
                        {errors.confirmpassword.error != "" && formSubmit && (
                          <span className="elk-error">
                            {errors.confirmpassword.error}
                          </span>
                        )}
                      </div>
                      <div className="form-actions d-flex justify-content-between">
                        <input
                          onClick={() => this.redirect()}
                          type="button"
                          className="btn bordered-btn"
                          value="Cancel"
                          disabled={isLoading}
                        />
                        <input
                          onClick={() => this.onSubmit()}
                          type="button"
                          className="btn btn-light-blue"
                          disabled={isLoading}
                          value={isLoading ? "Processing..." : "Change Password"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => { this.handler = instance; }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default ChangePassword;
