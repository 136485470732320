// Live
const SiteUrl = `https://examplelk.org`;

// Local
// const SiteUrl = `http://localhost`;

export const envConfig = {
    API_HOME: `${SiteUrl}:4000`,
    API_CLIENT: `${SiteUrl}:4000/api/v1/web/`,
    API_CHAT: `${SiteUrl}:4007/api/v1/chat/`,
    API_SECURE: `${SiteUrl}:4001/api/v1/secure/`,
    API_UPLOAD: `${SiteUrl}:4002/api/v1/upload`,
    API_POLLY: `${SiteUrl}:4005/api/v1/`,
    API_SOCKET: `${SiteUrl}:4004`,
    NOVELTY_PROMO_CODE_API: "http://noveltyapp.org:5004/common/xample-user/validate-promo-code",
    NOVELTY_DISCOUNT_LINK: "https://www.custream.com/noveltycard",
    CORRECT_ANSWER_SCORE: 2,
    defaultSugHrs: 8,
    minTestAllowedQCount: 1,
    timeDuration: 3600,
    overallTest: {
        time_maths: 25,
        time_other: 40,
        practice_maths: 30,
        practice_other: 50
    },
    topicTest: {
        practice_maths: 15,
        practice_other: 20
    },
    modelTest: {
        time_maths: 25,
        time_other: 40
    },
    payment: {
        month: "1 Month",
        threeMonth: "3 Months",
        sixMonth: "6 Months",
        year: "1 Year",
    }
};

export const scoreRange = {
    Failed: {
        text: "F - Faild",
        min: 0,
        max: 34
    },
    SimplePass: {
        text: "S - Simple Pass",
        min: 35,
        max: 49
    },
    Credit: {
        text: "C - Credit",
        min: 50,
        max: 64
    },
    Good: {
        text: "B - Good",
        min: 65,
        max: 74
    },
    Distinction: {
        text: "A - Distinction",
        min: 75,
        max: 100
    }
}

export const avgHourRange = {
    min: 4,
    max: 16
}