import React from "react";
import { common, tools, history } from "helpers";
import { Link } from "react-router-dom";
import { StatusBar } from "includes";
import { apiServices } from "api";

import { Doughnut } from "react-chartjs-2";
let translation = common.getTranslation();

class TestScores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      page: 1,
      limit: 30,
      status: "",
      enableLoadMore: false,
      isDetailEnable: false,
      skillRecords: [],
      selData: null,
      chartOptions: {
        cutoutPercentage: 75,
        legend: {
          display: false,
          position: "right",
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
      },
      userData: common.authInfo(),
      
};
  }

  componentDidMount() {
    if (this.props.setChildRefreshMethod) {
      this.props.setChildRefreshMethod(this.refresh);
    }
    this.getRecords();
  }

  refresh = () => {
    this.setState(
      {
        page: 1,
        records: [],
      },
      () => {
        this.getRecords();
      }
    );
  };

  loadMore = () => {
    const { page, limit } = this.state;
    this.setState(
      {
        page: page + limit,
      },
      () => {
        this.getRecords();
      }
    );
  };

  onSelectTest = (selObj) => {
    this.setState(
      {
        selData: selObj,
      },
      () => {
        this.getSkillRecords();
      }
    );
  };

  goBackList = () => {
    this.setState({
      selData: null,
      skillRecords: [],
    });
  };

  //Services
  getRecords = () => {
    const { page, limit, records } = this.state;
    this.setState({ status: "process" });

    var queryParam = "?";
    queryParam = queryParam + "userID=" + this.props.selUserId;
    queryParam = queryParam + "&skip=" + page;
    queryParam = queryParam + "&limit=" + limit;
    queryParam = queryParam + "&classID=" + this.props.selSubId;
    queryParam = queryParam + "&topicID=" + this.props.selGradeId;
    queryParam = queryParam + "&period=" + this.props.selDateRangeId;

    let data = {
      method: "GET",
      headerFlag: false,
      url: "STAT_SCORE_TOPIC",
      authorization: "token",
      query: String(queryParam),
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;
        if (status == "success") {
          result = common.apiResult(res);
        }
        if (result && result.length > 0) {
          let mergedata = records.concat(result);
          this.setState({
            records: mergedata,
            enableLoadMore: mergedata.length < res.data.page.count ? true : false,
            status: "success",
          });
        }
        if (status == "empty") {
          this.setState({
            status: "empty",
          });
        }
      },
      (error) => {
        this.setState({ status: "error" });
      },
      (final) => {}
    );
  };

  getSkillRecords = () => {
    const { selData } = this.state;

    if (selData) {
      var queryParam = "?";
      queryParam = queryParam + "userID=" + this.props.selUserId;
      queryParam = queryParam + "&classID=" + selData._id.class._id;
      queryParam = queryParam + "&topicID=" + selData._id.topic._id;
      queryParam = queryParam + "&skillID=" + selData._id.skill._id;

      let data = {
        method: "GET",
        headerFlag: false,
        url: "STAT_SCORE_TOPIC_TEST",
        authorization: "token",
        query: String(queryParam),
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result;
          if (status == "success") {
            result = common.apiResult(res);
          }
          if (result && result.length > 0) {
            this.setState({
              skillRecords: result,
            });
          }
        },
        (error) => {},
        (final) => {}
      );
    }
  };

  renderChartData = (data) => {
    var chartData = {
      maintainAspectRatio: false,
      responsive: false,
      labels: ["Mastery", "Excellence", "Practiced", "No Practice"],
      datasets: [
        {
          data: [
            data.mastery,
            data.excellence,
            data.practiced,
            data.noPractice,
          ],
          backgroundColor: ["#A7DB35", "#4FC7F6", "#FFD200", "#ADB2C7"],
        },
      ],
    };
    return chartData;
  };

  continueTest = (obj) => {
    const { selData } = this.state;
    let testURL = `/tests/${obj.class}/${obj._id.test._id}/${selData._id.topic._id}/${obj.session}?mode=false&year=0&paper=false`;
    history.push(testURL);
  };

  render() {
    const {
      records,
      enableLoadMore,
      status,
      selData,
      skillRecords,
      chartOptions,
      userData,
    } = this.state;

    return (
      <>
        {selData == null && (
          <>
            <div className="row test-scores">
              {records.length > 0 &&
                records.map((data, dataIndex) => (
                  <div className="col-lg-6" key={dataIndex}>
                    <div className="test-col d-flex align-items-center">
                      <div
                        className="doughnut-chart"
                        style={{ width: 100, height: 100 }}
                      >
                        <Doughnut
                          data={this.renderChartData(data)}
                          width={100}
                          height={100}
                          options={chartOptions}
                        ></Doughnut>
                        <div className="prog_value prog_pers text-center">
                          <span>
                            {(data.mastery ? data.mastery : 0) +
                              (data.excellence ? data.excellence : 0) +
                              (data.practiced ? data.practiced : 0)}
                            %
                          </span>
                          {translation.progress}
                        </div>
                      </div>
                      <div className="test-info">
                        <Link 
                          to="#"
                          className="btn-sm"
                          onClick={() => this.onSelectTest(data)}
                        >{data._id.class.name}</Link>
                        <h5 className="mt-3">
                          {data._id.topic.name}
                        </h5>
                        <h5 className="mt-3">
                          {data._id.skill.name}
                        </h5>
                        <Link
                          to="#"
                          className="link"
                          onClick={() => this.onSelectTest(data)}
                        >
                          {translation.score}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <StatusBar status={status} />
            <div className="row">
              <div className="col-sm-12 text-center">
                {status == "success" && enableLoadMore && (
                  <Link
                    to="#"
                    onClick={() => {
                      this.loadMore();
                    }}
                    className="btn bordered-btn-blue mt-4"
                  >
                    {translation.loadMore}
                  </Link>
                )}
              </div>
            </div>
          </>
        )}

        {selData != null && (
          <div className="test-card">
            <div className="test-header d-flex justify-content-between align-items-center mb-5">
              <h5 className="mb-0">Solid State</h5>
              <Link to="#" onClick={this.goBackList} className="btn bordered-btn-blue">
               {translation.go_back}
              </Link>
            </div>

            <div className="test-overview">
              <div className="row">
                <div className="col-lg-4 text-center">
                  <div className="doughnut-chart">
                    <Doughnut
                      data={this.renderChartData(selData)}
                      options={chartOptions}
                      width={210}
                      height={210}
                    ></Doughnut>
                    <div className="prog_value prog_pers text-center">
                      <span>
                        {(selData.mastery ? selData.mastery : 0) +
                          (selData.excellence ? selData.excellence : 0) +
                          (selData.practiced ? selData.practiced : 0)}
                        %
                      </span>
                      {translation.progress}
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="alert alert-success d-flex justify-content-between align-items-center">
                        <div className="alert-content">
                          <h6>{translation.mastery}</h6>
                          <p className="score-percent mb-0">
                            {selData.mastery}%
                          </p>
                        </div>
                        <img src={common.loadImg("batch_2.png")} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="alert alert-info d-flex justify-content-between align-items-center">
                        <div className="alert-content">
                          <h6>{translation.excellence}</h6>
                          <p className="score-percent mb-0">
                            {selData.excellence}%
                          </p>
                        </div>
                        <img src={common.loadImg("batch_1.png")} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="alert alert-warning d-flex justify-content-between align-items-center">
                        <div className="alert-content">
                          <h6>{translation.practiced}</h6>
                          <p className="score-percent mb-0">
                            {selData.practiced}%
                          </p>
                        </div>
                        <img src={common.loadImg("batch_3.png")} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="alert alert-dark d-flex justify-content-between align-items-center">
                        <div className="alert-content">
                          <h6>{translation.noPractice}</h6>
                          <p className="score-percent mb-0">
                            {selData.noPractice}%
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="table-responsive mt-5">
                <table className="table table-3">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{translation.test}</th>
                      <th>{translation.questions_answered}</th>
                      <th>{translation.score}</th>
                      {/* <th>Negative Marks</th> */}
                      <th>{translation.last_practiced}</th>
                      {userData.user &&
                        userData.user.role &&
                        userData.user.role != 1 && (
                          <th className="text-center">{translation.action}</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {skillRecords &&
                      skillRecords.length > 0 &&
                      skillRecords.map((skillObj, skillInd) => (
                        <tr key={skillInd}>
                          <td>{skillInd + 1}.</td>
                          <td>{skillObj._id.test.name}</td>
                          <td>{skillObj.questions}</td>
                          <td>{skillObj.score}</td>
                          {/* <td>{skillObj.nagativeScore}</td> */}
                          <td>
                            {tools.utcToLocal(new Date(skillObj.last), "DD MMMM YYYY")}
                          </td>
                          {userData.user &&
                            userData.user.role &&
                            userData.user.role != 1 && (
                              <td className="text-center">
                                {skillObj.completed ? (
                                  <input
                                    type="button"
                                    className="btn success-btn"
                                    // value="Completed"
                                    value={translation.completed}
                                  />
                                ) : (
                                  <input
                                    type="button"
                                    className="btn secondary-btn"
                                    // value="Continue"
                                    value={translation.continue}
                                    onClick={() => this.continueTest(skillObj)}
                                  />
                                )}
                              </td>
                            )}
                        </tr>
                      ))}
                    {skillRecords.length == 0 && (
                      <tr colSpan="7" className="text-center">
                        <td>{translation.loading}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TestScores;
