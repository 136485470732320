import React from "react";
import { history, common, message } from "helpers";
import { apiServices } from "api";
import { Link } from "react-router-dom";

// includes
import { Handler } from "includes";
import { Helmet } from "react-helmet";
export default class SupportRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        requestFor: [],
        message: "",
      },
      errors: {
        requestFor: {
          error: "",
          isReq: "Please select the request for",
        },
        message: {
          error: "",
          isReq: "Please enter your request message!",
        },
      },
      userData: common.authInfo(),
      translation: common.getTranslation()
    };
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    const { fields, translation } = this.state;
    if (!isValid) {
      this.handler.showError();
    } else {
      this.setLoading(true);
      // api call
      let params = {
        message: {
          message: fields.message,
          commentedByUser: common.getStorage("userId")
        },
        pickTicket: { "isPicked": false },
        requestFor: fields.requestFor.join(",")
      }

      let data = {
        url: "SUPPORT",
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            common.snack("S", translation.support_success);
            history.push("/support/tickets");
          } else {
            this.setLoading(false);
            if (status == "failure") {
              common.snack("E", translation.support_error);
            }
          }
        },
        (error) => {
          this.setLoading(false);
        },
        (final) => { }
      );
    }
  }

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  render() {
    const { userData, isLoading, fields, translation } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Support</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Support Form Wrap --> */}
        <div className="support-form-wrap">
          <div className="container">
            <div className="row pb-3">
              <div className="col-lg-12">
                <div className="card card-4">
                  <div className="card-heading tertiary-bg d-flex justify-content-between align-items-center">
                    <div>
                      <h2>{translation.support_title}</h2>
                      <h4>{translation.contact_info}</h4>
                    </div>
                    <Link to="/support/tickets" className="btn bordered-btn">
                     {translation.my_ticket}
                      </Link>
                  </div>
                  <div className="card-body">
                    <form method="POST">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              value={userData.user.name}
                              placeholder=" "
                              readOnly={true}
                            />
                            {/* <span className="floating-label">{translation.name}</span> */}
                            <span className="floating-label">Name</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              value={userData.user.email}
                              placeholder=" "
                              readOnly={true}
                            />
                            {/* <span className="floating-label">
                              {translation.your_email_address}
                            </span> */}
                            <span className="floating-label">
                              Your Email Address
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row pb-5">
              <div className="col-lg-12">
                <div className="card card-4">
                  <div className="card-heading primary-bg d-flex justify-content-between align-items-center">
                    <div className="card-title">
                      <h2>{translation.help_req}</h2>
                      {/* <p className="mb-0">
                      {translation.dummy_content}
                        </p> */}
                    </div>
                    <Link to="/help-center" className="btn bordered-btn">
                     {translation.help_center}
                      </Link>
                  </div>
                  <div className="card-body">
                    <form method="POST">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="requestFor"
                              className="custom-control-input"
                              id="req1"
                              value={"Signing in"}
                              onChange={(e) => this.handler.onCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="req1"
                            >
                              {translation.signin_req}
                              </label>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="requestFor"
                              className="custom-control-input"
                              id="req2"
                              value={"Viewing and Submitting Questions"}
                              onChange={(e) => this.handler.onCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="req2"
                            >
                              {translation.viewsubmit_req}
                              </label>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="requestFor"
                              className="custom-control-input"
                              id="req3"
                              value={"Analytics"}
                              onChange={(e) => this.handler.onCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="req3"
                            >
                              {translation.anayltics_req}
                              </label>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="requestFor"
                              className="custom-control-input"
                              id="req4"
                              value={"State Standards"}
                              onChange={(e) => this.handler.onCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="req4"
                            >
                              {translation.state_req}
                              </label>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="requestFor"
                              className="custom-control-input"
                              id="req5"
                              value={"Roster"}
                              onChange={(e) => this.handler.onCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="req5"
                            >
                              {translation.roaster_req}
                              </label>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="requestFor"
                              className="custom-control-input"
                              id="req6"
                              value={"Other"}
                              onChange={(e) => this.handler.onCheck(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="req6"
                            >
                              {translation.other_req}
                              </label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              rows="4"
                              className="form-control"
                              value={fields.message}
                              placeholder=" "
                              onChange={(e) => this.handler.onChange(e)}
                            />
                            {/* <span className="floating-label">
                              {translation.issue_here}
                            </span> */}
                            <span className="floating-label">
                              {/* Enter Your Issue Here */}
                              {translation.issue_here}
                            </span>
                          </div>
                        </div>
                        <input
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                          type="button"
                          value={!isLoading ? translation.submit : translation.processing}
                          className="btn mx-auto"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Support Form Wrap End --> */}
        <Handler
          ref={(instance) => { this.handler = instance; }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}