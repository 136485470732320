import LocalizedStrings from "react-localization";
import localizations from "localization";
import en from "localization/en";
import ReactGA from 'react-ga';
import SnackbarUtils from "includes/SnackBar";
import { history } from "./history";
import { tools } from "./tools";
import { scoreRange, envConfig } from "api/config";

export const common = {
  // Language type func //
  getTranslation() {
    let translation = new LocalizedStrings(localizations);
    let value = this.getLanguage();
    if (value) {
      translation.setLanguage(value);
    } else {
      translation.setLanguage("en");
    }
    return translation;
  },

  saveLanguage(value) {
    localStorage.setItem("language", value);
  },

  getLanguage() {
    let value = localStorage.getItem("language");
    if (value) {
      return value;
    }
  },

  // ########## Local Storage functions ##########
  setStorage(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },

  getStorage(key, json = false) {
    let value = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },

  removeAuthStorage() {
    let lang = localStorage.getItem("language");
    let lngPage = localStorage.getItem("lngPage");
    localStorage.clear();
    localStorage.setItem("language", lang);
    localStorage.setItem("lngPage", lngPage);
  },

  // ########## Package functions ##########
  snack(type, msg = "") {
    switch (type) {
      case "S":
        SnackbarUtils.success(msg);
        break;
      case "E":
        SnackbarUtils.error(msg);
        break;
      case "W":
        SnackbarUtils.warning(msg);
        break;
      case "I":
        SnackbarUtils.info(msg);
        break;
      case "T":
        SnackbarUtils.toast(msg);
        break;
      case "C":
        SnackbarUtils.closeAll();
        break;
    }
  },

  // ########## API functions ##########
  apiStatus(res) {
    let status = "success";
    if (res.status == 200) {
      if (res.data.status == "success") {
        if (
          res.data.http_code == 400 ||
          (res.data.data == "" && res.data.data !== false)
        ) {
          status = "empty";
        }
      } else if (res.data.status == "failure") {
        if (typeof res.data.message == "string") {
          if (
            res.data.message !==
            "Already you are logged in different browser/system! Please logout from logged in browser/system and logged in here."
          ) {
            let msg = this.getLangMessage(res.data.message);
            this.snack("E", msg);
          }
        }
        status = "failure";
      }
    } else {
      // this.snack("E", "Unable to connect server please try again");
      status = "error";
    }
    return status;
  },

  apiResult(res) {
    return res.data.data;
  },

  apiResultData(res) {
    return res.data;
  },

  // ########## Auth functions ##########
  authCheck() {
    let token = common.getStorage("userToken") || null;
    let auth = true;
    if (token === null) {
      auth = false;
    }
    return auth;
  },

  authValidate(auth) {
    ReactGA.initialize('UA-214142450-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.ga('send', 'pageview', {
      'title': document.title
    })
    let allow = true;
    if (auth != null) {
      let token = common.getStorage("userToken") || null;
      if (auth === true && token === null) {
        common.removeAuthStorage();
        history.push("/sign-in");
        allow = false;
      } else if (!auth && token != null) {
        history.push("/");
        allow = false;
      }
    }
    if (common.getStorage("userId")) {
      let userData = tools.deCrypt(common.getStorage("userData"), true);
      ReactGA.ga('set', 'userId', common.getStorage("userId"));
      ReactGA.ga('set', 'dimension1', userData.user.email);
    }
    return allow;
  },

  authInfo() {
    if (common.authCheck()) {
      let userData = tools.deCrypt(common.getStorage("userData"), true);
      return userData;
    }
  },

  userImage(type = "original") {
    let userData = common.authInfo();
    let img = userData.user.img[type];
    if (!img) {
      img = common.loadImg("profile-photo.png");
    }
    return img;
  },

  childImage(childData, userId, type = "original") {
    let data;
    childData.data.map((child) => {
      if (child._id == userId) {
        data = child.img;
      }
    });
    let img =
      data && data[type] ? data[type] : common.loadImg("profile-photo.png");
    return img;
  },

  // ########## React & Javascript functions ##########
  loadImg(img) {
    let result = require("assets/img/" + img);
    return result.default;
  },

  removeSpecialChars(text) {
    if (text) {
      text = text.replace(/\[\[☃(.*?)\]\]/gim, "");
    }
    return text;
  },

  padQuestionNumbers(num) {
    return ("0" + num).slice(-2);
  },

  url(url) {
    return process.env.PUBLIC_URL + url;
  },

  redirect(page, time) {
    setTimeout(
      () => {
        window.location.href = common.url(page);
      },
      !time ? 1000 : time * 1000
    );
    return false;
  },

  getSubject(id, subjects = []) {
    let subject = "";
    if (common.authCheck()) {
      let userData = common.authInfo();

      if (subjects.length > 0) {
        subjects.filter((item) => {
          if (item._id == id) {
            subject = item.name;
          }
        });
      } else {
        if (userData.subject && userData.subject.length > 0) {
          userData.subject.filter((item) => {
            if (item._id == id) {
              subject = item.name;
            }
          });
        }
      }
    }
    return subject;
  },

  getGrade(gradeId, subjectId, subjects = []) {
    let gradeName = "";
    if (common.authCheck()) {
      let userData = common.authInfo();
      if (subjects.length > 0) {
        subjects.filter((item) => {
          if (item._id == subjectId) {
            item.grade.filter((grade) => {
              if (grade._id == gradeId) {
                gradeName = grade.name;
              }
            })
          }
        });
      } else {
        if (userData.subject && userData.subject.length > 0) {
          userData.subject.filter((item) => {
            if (item._id == subjectId) {
              item.grade.filter((grade) => {
                if (grade._id == gradeId) {
                  gradeName = grade.name;
                }
              })
            }
          });
        }
      }
    }
    return gradeName;
  },

  getHeader() {
    let header = 1;
    let lang = localStorage.getItem("language");
    if (common.authCheck()) {
      header = 10;
    } else if (!lang) {
      header = 14;
    }
    return header;
  },

  getAuth() {
    let auth = null;
    if (common.authCheck()) {
      auth = true;
    }
    return auth;
  },

  /**
   * To Convert seconds into HH:MM:SS
   * This is ES2015 code, If you find any better ES2016+ kindly replace it.
   */
  toHHMMSS(secs) {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600) % 24;
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;
    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
  },

  toHHMMSSFormat(secs) {
    const sec_num = parseInt(secs, 10);
    const hours = Math.floor(sec_num / 3600) % 24;
    const minutes = Math.floor(sec_num / 60) % 60;
    const seconds = sec_num % 60;
    return (
      this.formatNumber(hours) +
      " hrs " +
      this.formatNumber(minutes) +
      " mins " +
      this.formatNumber(seconds) +
      " secs"
    );
  },

  formatNumber(v) {
    return v < 10 ? "0" + v : v;
  },
  useQuery() {
    return new URLSearchParams(window.location.search);
  },

  getYearList(from, to) {
    let result = [];
    for (let i = from; i <= to; i++) {
      let obj = {
        label: i,
        value: i,
      };
      result.push(obj);
    }
    return result;
  },

  getScoreCategory(score) {
    let status = "Failed";
    if (score >= scoreRange.Failed.min && score <= scoreRange.Failed.max) {
      status = "Failed";
    } else if (
      score >= scoreRange.SimplePass.min &&
      score <= scoreRange.SimplePass.max
    ) {
      status = "SimplePass";
    } else if (
      score >= scoreRange.Credit.min &&
      score <= scoreRange.Credit.max
    ) {
      status = "Credit";
    } else if (score >= scoreRange.Good.min && score <= scoreRange.Good.max) {
      status = "Good";
    } else if (
      score >= scoreRange.Distinction.min &&
      score <= scoreRange.Distinction.max
    ) {
      status = "Distinction";
    }
    return status;
  },

  calSecToHours(sec) {
    const sec_num = parseInt(sec, 10);
    const hrs = Math.ceil(sec_num / 3600) % 24;
    return hrs;
  },

  getQuestionLimit(mode, type, subject) {
    let qCount = 0;
    let testType = "overallTest";
    let mathsArray = ["Maths", "ගණිතය", "கணிதம்"];
    let isMaths = mathsArray.some((obj) => subject.includes(obj));
    if (type == "topic") {
      qCount = isMaths
        ? envConfig.topicTest.practice_maths
        : envConfig.topicTest.practice_other;
      return qCount;
    } else if (type == "overall") {
      testType = "overallTest";
    } else if (type == "model" || type == "pastpaper") {
      testType = "modelTest";
    }
    if (mode) {
      qCount = isMaths
        ? envConfig[testType].time_maths
        : envConfig[testType].time_other;
    } else {
      qCount = isMaths
        ? envConfig[testType].practice_maths
        : envConfig[testType].practice_other;
    }
    return qCount;
  },

  getLangSampleTestURL(userData) {
    let country = userData.user.country;
    let testURL = `/exam/dummy-79522537/overall/multiple-type-questions-97732188?mode=true&year=0&paper=false`;
    if (country == "india") {
      testURL = `/exam/dummy-79522537/overall/multiple-type-questions-97732188?mode=true&year=0&paper=false`;
    } else if (country == "tamil") {
      testURL = `/exam/dummy---tamil-26162527/overall/multiple-type-questions-60664440?mode=true&year=0&paper=false`;
    } else if (country == "singla") {
      testURL = `/exam/ඩමී-20628119/overall/multiple-type-questions-67622615?mode=true&year=0&paper=false`;
    }
    return testURL;
  },

  setUsrLngMedium(lang) {
    if (lang == "india") {
      this.saveLanguage("en");
    } else if (lang == "tamil") {
      this.saveLanguage("ta");
    } else if (lang == "singla") {
      this.saveLanguage("si");
    }
  },
  getLangName(lang) {
    let name = "English";
    switch (lang) {
      case "singla":
        name = "සිංහල";
        break;
      case "tamil":
        name = "தமிழ்";
        break;
      default:
        name = "English";
        break;
    }
    return name;
  },

  getLangMessage(text) {
    let message = text;
    let translation = this.getTranslation();
    Object.keys(en).forEach(function (key) {
      if (en[key] == text) {
        let message = translation[key] ? translation[key] : text;
        return message;
      }
    });
    return message;
  }
};