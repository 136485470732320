import React from "react";
import { Link } from "react-router-dom";
// includes
import { common, message, history } from "helpers";
import { Handler } from "includes";
import { apiServices } from "api";
import { StatusBar } from "includes";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class ChildrenActivate extends React.Component {

  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      params,
      status: "process",
      isLoading: false,
      formSubmit: false,
      fields: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      errors: {
        email: {
          error: "",
          isReq:translation.email_req,
          isValidEmail: true,
          isValidEmailReq: translation.valid_email,
        },
        password: {
          error: "",
          minLength: 6,
          isReq: translation.isReq_new,
          isMinLengthReq: translation.isMinLengthReq,
        },
        confirmPassword: {
          error: "",
          minLength: 6,
          isReq: translation.isReq_confirm,
          isMinLengthReq: translation.isMinLengthReq,
          matchPassword: true,
          matchPasswordReq: translation.matchPasswordReq,
        },
      },
      sData: {
        children: []
      },
    };
  }

  componentDidMount() {
    this.getChildData();
  }

  getChildData = () => {
    const { params, sData } = this.state;
    let urlKeys = {
      parentId: common.getStorage("userId")
    }
    let data = {
      method: "GET",
      url: "GET_CHILD_BY_PARENT",
      key: urlKeys
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.children = common.apiResultData(res);
        if (status == "success") {
          sData.children.data.map(item => {
            if (item._id == params.childId) {
              sData.children = item;
            }
          })
          // check already active
          if (sData.children.isActive) {
            history.push("/settings");
          } else {
            this.setState({
              status,
              sData
            })
          }
        } else {
          history.push("/settings");
        }
      },
      (error) => {
        history.push("/settings");
      },
      (final) => { }
    );
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const { params, fields } = this.state;
      // api call
      let bodyParams = {
        childId: params.childId,
        parentUserId: common.getStorage("userId"),
        email: fields.email,
        password: fields.password
      };

      let data = {
        method: "PUT",
        url: "CHILD_ACTIVATE",
        body: bodyParams,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            let result = common.apiResult(res);
            common.snack("S",translation.member_activation);
            history.push("/settings/");
          } else {
            this.setLoading(false);
            if (status == "failure" || status == "empty") {
              let msg = common.getLangMessage(res.data.message);
              common.snack("E", msg);
            }
          }
        },
        (error) => { },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  redirect = () => {
    history.push("/settings/");
  };

  render() {
    const { sData, isLoading, formSubmit, fields, errors, status } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Children Activate</title>
        </Helmet>
        <Link to="#" className="custom-link">
        {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
        XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="card card-2">
                  <div className="card-heading primary-bg">
                    <h2>{translation.activate_your_child}</h2>
                  </div>
                  <StatusBar status={status} />
                  {status == "success" && (
                    <div className="card-body">
                      <p className="help mb-4">
                       {translation.info_proceed},{" "}
                        <Link to="/help-center">{translation.click_here_for_help}</Link>
                      </p>
                      <form method="POST">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                defaultValue={sData.children.name}
                                readOnly={true}
                              />
                              {/* <span className="floating-label">
                                {translation.child_name}
                              </span> */}
                              <span className="floating-label">
                                Child Name
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder=" "
                                value={fields.email}
                                onChange={(e) => this.handler.onChange(e)}
                              />
                              {/* <span className="floating-label">
                               {translation.child_email}
                            </span> */}
                              <span className="floating-label">
                                Enter Child Email
                              </span>
                              {errors.email.error != "" && formSubmit && (
                                <span className="elk-error">
                                  {errors.email.error}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder=" "
                                value={fields.password}
                                onChange={(e) => this.handler.onChange(e)}
                              />
                              {/* <span className="floating-label">
                               {translation.child_password}
                            </span> */}
                              <span className="floating-label">
                                Enter Child Password
                              </span>
                              {errors.password.error != "" && formSubmit && (
                                <span className="elk-error">
                                  {errors.password.error}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                placeholder=" "
                                value={fields.confirmPassword}
                                onChange={(e) => this.handler.onChange(e)}
                              />
                              {/* <span className="floating-label">
                               {translation.retype_child_password}
                            </span> */}
                              <span className="floating-label">
                                Retype Child Password
                              </span>
                              {errors.confirmPassword.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.confirmPassword.error}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="form-actions d-flex justify-content-between">
                          <input
                            type="reset"
                            className="btn bordered-btn"
                            onClick={() => this.redirect()}
                            value="Cancel"
                          />
                          <input
                            type="button"
                            className="btn btn-light-blue"
                            onClick={() => this.onSubmit()}
                            disabled={isLoading}
                            value={isLoading ? "Processing" : "Submit"}
                          />
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default ChildrenActivate;
