import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { common, history } from "helpers";

function PayhereReponse(props) {
  const params = useParams();
  useEffect(() => {
    if (params.type === "return") {
      common.snack("S", "Payment has been successfull. Activation link has been sent to your registered email address");
      history.push("/membership/activation");
    } else if (params.type === "cancel") {
      common.snack("E", "Payment has been cancelled.");
      history.push("/membership");
    } else {
      history.push("/");
    }
  }, [])

  return null;
}

export default PayhereReponse;