import * as Services from "./services";
import { common, functions } from "helpers";

let refreshCall = false;

const apiServices = {
  // fetch
  call(data = {}, response, error, final) {
    let translation = common.getTranslation();
    getData(data);
    let apiURL = "";
    if (data.type == "third") {
      apiURL = encodeURI(data.url + data.query);
    } else {
      apiURL = getService(data);
    }
    let reqOptions = {
      method: data.method,
      headers: getHeaders(data),
    };
    if (data.body) {
      reqOptions.body = data.body;
    }
    fetch(apiURL, reqOptions)
      .then((response) =>
        response.text().then((data) => ({
          status: response.status,
          data: data,
        }))
      )
      .then((result) => {
        try {
          if (data.contentType == 3) {
            result.data = JSON.parse(result.data);
          } else {
            result.data = JSON.parse(result.data);
            if (result.data.status_code == 1001 || result.data.status_code == 1002) {
              if (refreshCall == false) {
                common.snack("I", translation.session_expired);
              }
              this.callRedirect();
            }
          }
        } catch (e) { }
        if (data.type == "standard") {
          if (result.data.status_code == 1001) {
            if (refreshCall == false) {
              common.snack("I", translation.session_expired);
            }
            this.callRedirect();
          } else {
            response(result);
          }
        } else {
          response(result);
        }
      })
      .catch((err) => {
        error(err);
      })
      .finally(() => final());
  },

  callRedirect() {
    refreshCall = true;
    functions.userOffline();
    common.removeAuthStorage();
    common.redirect("/sign-in", 0.5);
  },
};

function getHeaders(data) {
  let headers = {};
  if (data.contentType === 1) {
    headers = {
      "Content-Type": "application/json",
    };
  } else if (data.contentType === 2) {
    headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  if (data.authorization == "token") {
    //headers.Authorization = "Bearer " + localStorage.getItem("userToken");
    headers.type = "User";
    headers.token = localStorage.getItem("userToken");
  } else if (data.authorization == "temp") {
    //headers.Authorization = "Bearer " + localStorage.getItem("tempToken");
    headers.type = "User";
    headers.token = localStorage.getItem("tempToken");
  } else if (data.authorization == "basic") {
    headers.Authorization =
      "Basic " +
      btoa(data.credentials.username + ":" + data.credentials.password);
  }
  return headers;
}

function getData(data) {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.authorization = data.authorization || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.contentType = data.contentType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.key = data.key || "";
    if (data.body) {
      if (data.contentType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.contentType == 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
}

function getService(data) {
  let url = Services[data.url];
  if (data.key != "") {
    for (var key in data.key) {
      url = url.replace(`:${key}`, data.key[key]);
    }
  }
  return encodeURI(url + data.query)
}

export { apiServices };
