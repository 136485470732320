import React from "react";
import { Link } from "react-router-dom";
import { common } from "helpers";
import { apiServices } from "api";
// pages
import TopicWise from "./includes/TopicWise";
import OverAllTest from "./includes/OverAllTest";
import AnalyticsSampleTest from "./includes/SampleTest";
import AnalyticsSampleTopper from "./includes/SampleTopper";
import MockTest from "./includes/MockTest";
import { StatusBar } from "includes";
import { Helmet } from "react-helmet";
class Analytics extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      selTab: params.tab || "topic",
      selSubTab: params.subTab || "testscore",
      userData: common.authInfo(),
      childrens: [],
      selUserId: "",
      translation: common.getTranslation()
    };
  }

  componentDidMount() {
    const { userData } = this.state;
    if (userData.user && userData.user && userData.user.role && userData.user.role == 1) {
      this.getChildList()
    } else {
      this.setState({
        selUserId: common.getStorage("userId")
      })
    }
  }

  getChildList = () => {
    const { sData } = this.state;
    let urlKeys = {
      parentId: common.getStorage("userId")
    }
    let data = {
      method: "GET",
      url: "GET_CHILD_BY_PARENT",
      key: urlKeys
    };

    apiServices.call(
      data,
      (res) => {
        let result = common.apiResult(res);
        if (result && result.length > 0) {
          this.setState({
            childrens: result,
            selUserId: result[0]._id
          }) 
        }
      },
      (error) => {},
      (final) => { }
    );
  }

  onClickTab = (tab) => {
    this.setState({
      selTab: tab
    })
  }

  renderTabCom = () => {
    const { selTab, selSubTab, childrens, selUserId } = this.state;
    switch (selTab) {
      case "topic":
        return (
          <TopicWise 
            selSubTab={selSubTab} 
            childrens={childrens} 
            selUserId={selUserId}
          />
        )
        break;
      case "overalltest":
        return (
          <OverAllTest 
            selSubTab={selSubTab} 
            childrens={childrens} 
            selUserId={selUserId}
          />
        )
        break;
      case "mocktest":
        return (
          <MockTest 
            selSubTab={selSubTab} 
            childrens={childrens} 
            selUserId={selUserId}
          />
        )
        break;
      case "contest":
          return (
            <AnalyticsSampleTest 
              selSubTab={selSubTab} 
              childrens={childrens} 
              selUserId={selUserId}
            />
          )
          break;
      case "topper":
            return (
              <AnalyticsSampleTopper 
                selUserId={selUserId}
                childrens={childrens} 
              />
            )
            break;

      default: break;
    }
  }

  render() {
    const { selTab, selUserId, translation} = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Analytics</title>
        </Helmet>
        {/* <!-- Analytics Wrap --> */}
        <div className="analytics-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card card-2">
                  <div className="card-heading primary-bg d-flex justify-content-between align-items-center">
                    <div>
                      <h2>{translation.header_analytics} </h2>
                    </div>                    
                  </div>
                  <div className="card-body">
                    <ul
                      className="nav nav-tabs lined justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className={selTab == "topic" ? "nav-link active" : "nav-link"}
                          to="/analytics/topic/testscore"
                          onClick={() => this.onClickTab("topic")}
                        >
                         {translation.topic_wise}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={selTab == "overalltest" ? "nav-link active" : "nav-link"}
                          to="/analytics/overalltest"
                          onClick={() => this.onClickTab("overalltest")}
                        >
                         {translation.overall_Test}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={selTab == "mocktest" ? "nav-link active" : "nav-link"}
                          to="/analytics/mocktest"
                          onClick={() => this.onClickTab("mocktest")}
                        >
                          {translation.mock_test}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={selTab == "contest" ? "nav-link active" : "nav-link"}
                          to="/analytics/contest"
                          onClick={() => this.onClickTab("contest")}
                        >
                         {translation.contest}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={selTab == "topper" ? "nav-link active" : "nav-link"}
                          to="/analytics/topper"
                          onClick={() => this.onClickTab("topper")}
                        >
                         {translation.topper}
                        </Link>
                      </li>
                    </ul>
                    {
                      selUserId !== "" && (
                        <div className="tab-content" id="myTabContent">
                          {this.renderTabCom()}
                        </div>
                      )
                    }
                    {
                      selUserId === "" && (
                        <StatusBar status="process" />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Analytics;
