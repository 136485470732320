import moment from "moment";
import { common } from "helpers";
import { apiServices } from "api";

export const functions = {
  // functional call
  logOut() {
    let data = {
      method: "POST",
      url: "SIGNOUT",
      body: { online: false },
    };

    apiServices.call(
      data,
      (response) => {
        let status = common.apiStatus(response);
        if (status == "success") {
          let msg = "Logout successfully";
          common.snack("S", msg);
          common.removeAuthStorage();
          common.redirect("/sign-in", 1);
        }
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );
  },

  userOffline() {
    let params = {
      userId: common.getStorage("userId"),
      isOnline: "false"
    }

    let data = {
      method: "POST",
      url: "COMMON_USER_ONLINE",
      body: params
    };

    apiServices.call(
      data,
      (response) => { },
      (error) => { },
      (final) => { }
    );
  },

  // payment status
  checkTrial(data) {
    let today = moment().format("MMM DD, YYYY");
    let myDate = moment(data.trialPeriod).format("MMM DD, YYYY");
    if (moment(today).isAfter(myDate)) {
      return "expired";
    } else {
      return "active";
    }
  },

  checkPayment(data) {
    let today = moment().format("MMM DD, YYYY");
    let myDate = moment(data.gracePeriod ?? data.payment.endDate).format("MMM DD, YYYY");
    if (moment(today).isAfter(myDate)) {
      return "expired";
    } else {
      return "active";
    }
  }
};
