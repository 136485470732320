import React from "react";
import QuestionContent from "./includes/QuestionContent";
import WrongAnswer from "./includes/WrongAnswer";
import SuccessMessage from "./includes/SuccessMessage";
import TestBreadCrumb from "./includes/TestBreadCrumb";
import Result from "./includes/Result";
import Review from "./includes/Review";
import { apiServices } from "api";
import { common, history } from "helpers";
import { StatusBar } from "includes";
import { Link } from "react-router-dom";
import { envConfig } from "api/config";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class Exam extends React.Component {
  query = common.useQuery();
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      showQuestion: false,
      sessionId: params.sessionId ? params.sessionId : "",
      editForm: params.sessionId ? true : false,
      testId: params.testId
        ? params.testId == 1
          ? "overall"
          : params.testId == 2
            ? "mocktest"
            : params.testId
        : "",
      subjectId: params.subId ? params.subId : "",
      gradeId: params.gradeId ? params.gradeId : "",
      questionData: {},
      status: "process",
      seconds: 0,
      questionNo: 1,
      result: {
        score: 0,
        CorrectAnswer: 0,
        WrongAnswer: 0,
        NagativeMark: 0,
        totalQuestion: 0,
        time: 0,
      },
      correctAnswer: {},
      selectedAnswer: {},
      showWrongAnswer: false,
      showCorrectAnswer: false,
      showReview: false,
      showResult: false,
      timerEnabled: false,
      modeOfTest: this.query.get("mode") == "true" ? true : false,
      mockTestPaper: this.query.get("paper") == "true" ? true : false,
      year:
        this.query.get("year") != undefined
          ? parseInt(this.query.get("year"))
          : 0,
      sessionListResponse: [],
      totalQuestionsAllowed: 0,
      testName: "",
      questionId: "",
      secondsToAns: 0,
      lastAnsTime: 0,
      userInfo: common.authInfo(),
      relatedTests: [],
      noQuestion: false,
      activeOptions: {},
      questionLoader: false,
      isPause: false,
      userPlan: this.props.userPlan,
      isFreePlan: this.props.userPlan ? this.props.userPlan.plan.planId === "free" : false,
      todayTest: [],
      freePlan: {
        time: 0,
        question: 1,
        completed: false
      },
      translation: common.getTranslation()
    };
  }

  componentDidMount = () => {
    const { userPlan, userInfo, translation } = this.state;
    // Parent not allowed to take test
    if (userInfo.user.role == 1) {
      history.push("/");
    }
    if (userPlan.paymentStatus == "expired") {
      common.snack("E", translation.payment_renew);
      if (userInfo.user.role == 3) {
        history.push("/payment");
      } else {
        history.push("/");
      }
    } else {
      this.callInitialMethods();
    }
  };

  paymentRedirect = () => {
    const { userInfo, translation } = this.state;
    common.snack("I", translation.today_limit_over);
    if (userInfo.user.role == 3) {
      history.push("/payment");
    } else {
      common.setStorage("page", "test");
      history.push("/");
    }
  }


  callInitialMethods = () => {
    const { result } = this.state;
    this.getAllTestSession();
    this.setState({
      status: "process",
    });
    result.totalQuestion = 40;
    this.setState(
      {
        totalQuestionsAllowed: 40,
        result,
      },
      () => {
        this.InitTest();
      }
    );
  }

  componentWillUnmount = () => {
    const { isFreePlan, userPlan } = this.state;
    if (userPlan.paymentStatus !== "expired" && !isFreePlan) {
      this.onExit();
    }
    clearInterval(this.timer);
  };

  getAllTestSession = () => {
    const {
      userInfo,
      testId,
      subjectId,
      gradeId,
      modeOfTest,
      year,
      mockTestPaper,
      editForm,
    } = this.state;
    this.setState({
      status: "process",
    });
    let query =
      `?userID=${common.getStorage("userId")}&countryId=${userInfo.user.country
      }&classID=${subjectId}` +
      `&testID=${testId}&topicID=${gradeId}&mode=${modeOfTest}`;

    if (testId === "mocktest") {
      query = query + `&questionYear=${year}&paper=${mockTestPaper}`;
    }

    let data = {
      method: "GET",
      headerFlag: true,
      url: "GET_ALL_TEST_SESSIONS",
      query: query,
      authorization: "token",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;

        if (status == "success") {
          result = common.apiResult(res);
        }
        if (result && result.length > 0) {
          let sessionCount = 0;
          result.map((val) => {
            if (!val.completed) {
              sessionCount = sessionCount + 1;
            }
          });
          this.setState(
            {
              sessionListResponse: result,
            },
            (_) => {
              if (sessionCount >= 5 && !editForm) {
                this.sessionRedirect();
              }
            }
          );
        }
        // this.setState({
        //   status: "success",
        // });
      },
      (error) => {
        this.setState({
          status: "",
        });
      },
      (final) => { }
    );
  };

  sessionRedirect = () => {
    const {
      subjectId,
      testId,
      gradeId,
      mockTestPaper,
      modeOfTest,
      year,
    } = this.state;
    let paramTestId =
      testId == "mocktest" ? "2" : testId == "overall" ? "1" : testId;
    let otherParam =
      paramTestId == 1 || paramTestId == 2
        ? "?mode=" + modeOfTest + "&year=" + year + "&paper=" + mockTestPaper
        : "";
    // history.push(encodeURI(
    //   "/session/" +
    //   subjectId +
    //   "/" +
    //   paramTestId
    //   +
    //   "/" +
    //   gradeId
    //   + otherParam
    // ))
    common.setStorage("page", "test");
    history.push("/");
  };

  InitTest = () => {
    const { editForm, sessionId } = this.state;
    if (!editForm) {
      if (sessionId == "") {
        let sessionId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            let r = (Math.random() * 16) | 0,
              v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
        this.setState(
          {
            sessionId,
          },
          () => {
            if (sessionId && sessionId != "") {
              this.startTest();
            }
          }
        );
      }
    } else if (editForm) {
      this.GetSessionByTest();
    }
  };

  GetSessionByTest = () => {
    const {
      testId,
      result,
      userInfo,
      sessionId,
      totalQuestionsAllowed,
      freePlan
    } = this.state;

    let queryParam = `?countryId=${userInfo.user.country
      }&testID=${testId}&sessionID=${sessionId}&userID=${common.getStorage(
        "userId"
      )}`;

    let data = {
      method: "GET",
      url: "GET_TEST_SESSION",
      query: queryParam,
      authorization: "token",
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status == "success") {
          let response = common.apiResultData(res);
          response = response.data;
          result.totalQuestion = totalQuestionsAllowed;
          result.CorrectAnswer = response[0].answeredCorrect;
          result.WrongAnswer = response[0].answeredWrong;
          result.NagativeMark = response[0].nagativeScore;
          result.score = response[0].score;
          result.time = response[0].time;
          let totalTime = envConfig.timeDuration;
          freePlan.question = response[0].questionAttempt + 1;
          freePlan.time = totalTime - response[0].lastTime
          this.setState(
            {
              freePlan,
              result,
              modeOfTest: response[0].modeOfTest,
              year: response[0].questionYear,
              questionNo: parseInt(response[0].questionAttempt + 1),
              seconds: parseInt(response[0].lastTime),
              lastAnsTime: parseInt(response[0].lastTime),
            },
            () => {
              this.startTest();
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  startTest = () => {
    const { modeOfTest, seconds, editForm, lastAnsTime } = this.state;
    let duration = 0;
    //set timer count based on practised or time based
    if (modeOfTest && !editForm) {
      duration = envConfig.timeDuration;
    }

    if (editForm) {
      duration = seconds;
    }
    this.setState(
      {
        status: "process",
        timerEnabled: true,
        seconds: duration,
        lastAnsTime: editForm ? lastAnsTime : (modeOfTest ? duration : lastAnsTime)
      },
      () => {
        setTimeout(() => {
          this.startTimer();
        }, 500);

        //call test api to show questions
        this.getQuestionByTest();
      }
    );
  };

  completeTestByTimeAndGetScore(completed, option) {
    const { sessionId, seconds, totalQuestionsAllowed, result, isFreePlan } = this.state;
    if (completed) {
      this.setState({
        status: "process",
        timerEnabled: false,
      });
    }
    let params = {};
    params["session"] = sessionId;
    params["lastTime"] = seconds;
    params["completed"] = completed;
    params["questionCount"] = totalQuestionsAllowed;
    let data = {
      method: "PUT",
      url: "TEST_COMPLETE",
      body: params,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let response;
        if (status == "success") {
          response = common.apiResultData(res);
          if (response.http_code == 211) {
            result.CorrectAnswer = response.data.answered;
            result.WrongAnswer =
              response.data.question - response.data.answered;
            result.score = response.data.score;
            result.NagativeMark = response.data.nagativeScore;
            result.totalQuestion = totalQuestionsAllowed;
            result.time = envConfig.timeDuration;
            if (option == "exit") {
              this.sessionRedirect();
            } else {
              if (completed == true) {
                if (isFreePlan) {
                  this.paymentRedirect();
                }
                else {
                  this.setState({
                    lastAnsTime: envConfig.timeDuration,
                    result,
                    showResult: true,
                    showQuestion: false,
                    showReview: false,
                    showCorrectAnswer: false,
                    showWrongAnswer: false,
                    timerEnabled: false,
                    status: "success",
                  }, () => {
                    clearInterval(this.timer);
                  });
                }
              }
            }
          } else {
            history.push("/");
          }
        } else {
          if (option != "pause") {
            this.sessionRedirect();
          }
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => { }
    );
  }

  submitAnswer = (correctAnswer, selectedAnswer) => {
    const {
      result,
      questionNo,
      totalQuestionsAllowed,
      userInfo,
      modeOfTest,
      testId,
      subjectId,
      gradeId,
      seconds,
      secondsToAns,
      lastAnsTime,
      questionId,
      sessionId,
      year,
      mockTestPaper,
      isFreePlan,
      freePlan,
      userPlan
    } = this.state;
    let secondsTakenToAns;
    if (modeOfTest) {
      secondsTakenToAns = lastAnsTime - seconds;
    } else {
      secondsTakenToAns = seconds - lastAnsTime;
    }
    let params = {};
    params["_id"] = questionId;
    params["testId"] = testId;
    params["classId"] = subjectId;
    params["topicId"] = gradeId;

    params["answer"] = { [selectedAnswer["id"]]: true };
    params["time"] = secondsTakenToAns;
    params["session"] = sessionId;
    params["prevScore"] = result.score;
    if (testId == "mocktest") {
      params["overall"] = true;
      params["subjectOverall"] = false;
      params["year"] = mockTestPaper ? 0 : year;
    } else if (testId == "overall") {
      params["overall"] = false;
      params["subjectOverall"] = true;
    } else {
      params["overall"] = false;
      params["subjectOverall"] = false;
    }

    params["modeOfTest"] = modeOfTest;
    params["lastTime"] = seconds;

    params["country"] = userInfo.user.country;

    let data = {
      body: params,
      url: "CHECK_ANSWER",
      authorization: "token",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let response;
        if (status == "success") {
          response = common.apiResultData(res);
          let answerFlag = response.data.correct;
          result.score = response.score
            ? response.score.totScore
            : result.score;

          if (answerFlag) {
            result.CorrectAnswer = result.CorrectAnswer + 1;
            answerFlag = true;
          } else {
            result.WrongAnswer = result.WrongAnswer + 1;
          }

          if (testId != "mocktest") {
            let totTime = envConfig.timeDuration;
            if (isFreePlan && ((freePlan.question >= userPlan.plan.timeBasedTest.question || freePlan.time >= userPlan.plan.timeBasedTest.min) ||
              (totTime < userPlan.plan.timeBasedTest.min && (this.state.seconds <= 0 && this.state.modeOfTest)))) {
              this.paymentRedirect();
            }
          }

          this.setState(
            {
              correctAnswer,
              selectedAnswer,
              showCorrectAnswer: modeOfTest ? false : answerFlag,
              showWrongAnswer: modeOfTest ? false : !answerFlag,
              //showQuestion: false,
              result,
              secondsToAns,
              lastAnsTime: this.state.seconds,
              questionLoader: modeOfTest ? true : false,
            },
            () => {
              if (modeOfTest) {
                this.nextQuestion();
              }
              if (!modeOfTest && this.state.showCorrectAnswer) {
                clearInterval(this.timer);
                setTimeout(() => {
                  this.startTimer();
                  if (questionNo <= totalQuestionsAllowed) {
                    this.nextQuestion();
                  } else {
                    this.setState({
                      showResult: true,
                      showQuestion: false,
                      showReview: false,
                      showCorrectAnswer: false,
                      showWrongAnswer: false,
                      timerEnabled: false,
                    });
                  }
                }, 5000);
              }
            }
          );
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  nextQuestion = () => {
    const { questionNo, totalQuestionsAllowed, freePlan } = this.state;
    freePlan.question = freePlan.question + 1;
    this.setState(
      {
        questionNo: questionNo + 1,
        freePlan
      },
      () => {
        if (questionNo <= totalQuestionsAllowed) {
          this.getQuestionByTest();
        } else {
          this.setState({
            questionLoader: false,
            showResult: true,
            showQuestion: false,
            showReview: false,
            showCorrectAnswer: false,
            showWrongAnswer: false,
            timerEnabled: false,
          });
        }
      }
    );
  };

  review = (e) => {
    e.preventDefault();
    const { showResult, showWrongAnswer } = this.state;
    if (!showResult && !showWrongAnswer) {
      this.setState({
        showReview: true,
        showQuestion: false,
        showCorrectAnswer: false,
        showWrongAnswer: false,
      });
    }
  };

  continueTest = () => {
    this.setState(
      {
        showCorrectAnswer: false,
        showReview: false,
        showQuestion: true,
      },
      () => {
        //this.getQuestionByTest();
      }
    );
  };

  getQuestionByTest = () => {
    const {
      sessionId,
      subjectId,
      totalQuestionsAllowed,
      testId,
      gradeId,
      year,
      noQuestion,
      modeOfTest,
      translation
    } = this.state;
    if (totalQuestionsAllowed > 0) {
      this.setState({
        //status: "process",
        questionLoader: true,
        activeOptions: {},
        showCorrectAnswer: false,
      });

      let queryParam = `?session=${sessionId}&class=${subjectId}&questionCount=${totalQuestionsAllowed}&topic=${gradeId}&year=${year}`;
      if (noQuestion) {
        queryParam = queryParam + `&noSubject=true`;
      }

      let urlKeys = {
        testId: testId,
      };

      let data = {
        method: "GET",
        url: "GET_QUESTION_BY_TEST",
        key: urlKeys,
        query: queryParam,
        authorization: "token",
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            let response = common.apiResultData(res);
            if (response.http_code == 200) {
              this.setState(
                {
                  questionId: response.data._id,
                  questionData: response.data,
                  activeOptions: {},
                  status: "success",
                  showQuestion: true,
                  showWrongAnswer: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      questionLoader: false,
                    });
                  }, 100);
                }
              );
            } else if (response.http_code == 210) {
              this.setState(
                {
                  //noQuestion: true,
                  //timerEnabled: false,
                  //status: "success",
                },
                () => {
                  this.getQuestionByTest();
                }
              );
              common.snack("W", translation.no_ques_available);
            } else if (response.http_code == 211) {
              //stop the test & show the result
              let timeSpent = response.data.lastTime;
              if (modeOfTest) {
                timeSpent = envConfig.timeDuration - response.data.lastTime;
              }
              let result = {
                score: response.data.score,
                CorrectAnswer: response.data.answered,
                WrongAnswer: response.data.totQuestion - response.data.answered,
                NagativeMark: response.data.nagativeScore,
                totalQuestion: response.data.totQuestion,
                time: timeSpent,
              };
              this.setState({
                status: "success",
                showResult: true,
                showQuestion: false,
                showReview: false,
                showCorrectAnswer: false,
                showWrongAnswer: false,
                timerEnabled: false,
                questionLoader: false,
                result,
              });
            } else if (response.http_code == 400) {
              common.snack("W", translation.no_ques_available);
              history.push("/");
            }
          }
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => { }
      );
    } else {
      this.setState({
        noQuestion: true,
        timerEnabled: false,
        status: "success",
      });
    }
  };


  setGrade = (id) => { };

  answerUpdate = (option) => {
    this.setState({
      activeOptions: option,
    });
  };

  startTimer = () => {
    const { isFreePlan, freePlan, questionNo, activeOptions, userPlan, totalQuestionsAllowed } = this.state;
    this.timer = setInterval(() => {
      if (this.state.timerEnabled) {
        if (isFreePlan) {
          let totTime = envConfig.timeDuration;
          freePlan.time = freePlan.time + 1;
          this.setState({
            freePlan
          }, () => {

            if (
              (freePlan.question > userPlan.plan.timeBasedTest.question || freePlan.time > userPlan.plan.timeBasedTest.min)
              || (totTime < userPlan.plan.timeBasedTest.min && (this.state.seconds == 0 && this.state.modeOfTest))
            ) {
              if (questionNo == 1) {
                //save first question for tracking free plan statistics
                let firstAnswer = {};
                if (Object.keys(this.state.questionData).length > 0) {
                  Object.keys(this.state.questionData.question.widgets).map((item, idx) => {
                    const widgetData = this.state.questionData.question.widgets[item];
                    if (widgetData.type === "radio") {
                      Object.keys(widgetData.content).map((wItem, wIdx) => {
                        if (wIdx == 0) {
                          firstAnswer = widgetData.content[wItem];
                          this.submitAnswer({}, firstAnswer);
                        }
                      });
                    }
                  });
                } else {
                  this.completeTestByTimeAndGetScore(false, "");
                }
              }
            }
          });
        }

        if (this.state.modeOfTest) {
          let updateSeconds = this.state.seconds - 1;
          this.setState({ seconds: updateSeconds });
          if (this.state.seconds <= 0 && !isFreePlan) {
            this.completeTestByTimeAndGetScore(true, "");
          }
        } else {
          let updateSeconds = this.state.seconds + 1;
          this.setState({ seconds: updateSeconds });
        }
      }
    }, 1000);
  };

  timerControl = (val) => {
    this.setState({
      isPause: val,
    });
    if (val) {
      clearInterval(this.timer);
      this.completeTestByTimeAndGetScore(false, "pause");
    } else {
      this.startTimer();
    }
  };

  onExit = () => {
    common.setStorage("page", "test");
    if (!this.state.showResult) {
      this.completeTestByTimeAndGetScore(false, "exit");
    }
  };

  gotoMyTest = (e) => {
    e.preventDefault();
    common.setStorage("page", "test");
    history.push("/analytics/sample");
  }

  render() {
    const {
      showQuestion,
      subjectId,
      testId,
      gradeId,
      seconds,
      questionData,
      status,
      questionNo,
      result,
      correctAnswer,
      selectedAnswer,
      showWrongAnswer,
      showCorrectAnswer,
      showReview,
      showResult,
      relatedTests,
      totalQuestionsAllowed,
      noQuestion,
      timerEnabled,
      testName,
      modeOfTest,
      activeOptions,
      questionLoader,
      isPause,
      year,
      translation
    } = this.state;

    return (
      <div className="main-content alternate">
        <Helmet>
          <title>Exam</title>
        </Helmet>
        <div className="test-banner"></div>

        <div className="question-wrap d-flex">
          <div className="question-block">
            <StatusBar status={status} />

            {status == "success" && !noQuestion && (
              // {/* <!-- Question Content --> */}
              <div className="question-content alternate">
                <div className="question-content-inner">
                  {!showResult && showQuestion && (
                    <div className="button-actions d-flex flex-wrap justify-content-between mb-3">
                      <Link
                        to="#"
                        className="btn"
                        onClick={() => this.timerControl(!isPause)}
                      >
                        {/* {isPause ? "Resume" : "Pause"} */}
                        {isPause ? translation.resume : translation.pause}
                      </Link>
                      <Link
                        to="#"
                        className="btn yellow-btn"
                        onClick={() => this.onExit()}
                      >
                        {/* Exit */}
                        {translation.exit}
                      </Link>
                    </div>
                  )}

                  <div className="question-content-block">
                    {timerEnabled && (
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            {/* CONTEST */}
                            {translation.contest}
                          </li>
                        </ol>
                      </nav>
                    )}
                    {questionLoader && (
                      <div className="page-loader">
                        <img src={common.loadImg("loader-2.gif")} />
                      </div>
                    )}
                    {timerEnabled &&
                      !showReview &&
                      !showCorrectAnswer &&
                      !showWrongAnswer &&
                      Object.keys(questionData).length > 0 && (
                        isPause ? <div className="isPause text-center">{translation.resume_test}</div> : <QuestionContent
                          questionData={questionData}
                          questionNo={questionNo}
                          activeOptions={activeOptions}
                          answerUpdate={this.answerUpdate}
                          submitAnswer={this.submitAnswer}
                          questionLoader={questionLoader}
                          isPause={isPause}
                        />
                      )}
                    {timerEnabled && showWrongAnswer && (
                      <WrongAnswer
                        correctAnswer={correctAnswer}
                        selectedAnswer={selectedAnswer}
                        questionData={questionData}
                        questionNo={questionNo}
                        nextQuestion={this.nextQuestion}
                      />
                    )}
                    {timerEnabled && showCorrectAnswer && <SuccessMessage />}
                    {timerEnabled && showReview && !questionLoader && (
                      <Review result={result} continue={this.continueTest} />
                    )}
                    {showResult && !showQuestion && (
                      <Result
                        result={result}
                        subjectId={subjectId}
                        gradeId={gradeId}
                        relatedTests={relatedTests}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {noQuestion && (
              <div className="elkStatusBar">
                <img
                  src={common.loadImg("norecordsfound.png")}
                  alt={"Loader"}
                  className="img-fluid"
                />
                <p>{translation.no_question_available}</p>
                <div className="text-center mt-4">
                  <Link to="#" onClick={(e) => this.gotoMyTest(e)} className="btn">
                    Go to Analytics
                  </Link>
                </div>
              </div>
            )}
            {/* <!-- Question Content End --> */}
          </div>

          {!noQuestion && (
            <div className="time-block">
              <div className="time-counting tertiary-bg mb-4">
                {/* <span>{modeOfTest ? "Time Left" : "Duration"}</span> */}
                <span>{modeOfTest ? "Time Left" : translation.duration}</span>
                <h6 className="mb-0">
                  {status == "success" || timerEnabled
                    ? seconds >= 0 && !showResult
                      ? common.toHHMMSS(seconds)
                      : "00:00:00"
                    : "00:00:00"}
                </h6>
              </div>
              {!modeOfTest && !showResult && !showWrongAnswer && !isPause && (
                <input
                  type="button"
                  onClick={(e) => {
                    this.review(e);
                  }}
                  //disabled={!showQuestion}
                  value={translation.review}
                  className="btn secondary-btn mx-2"
                />
              )}
              {!modeOfTest && (
                <div className="result-summary">
                  <div className="result-col d-flex align-items-center justify-content-between">
                    <p>{translation.your_score}</p>
                    <h6 className="score">{result.score}</h6>
                  </div>
                  {/* <div className="result-col d-flex align-items-center justify-content-between">
                    <p>Answered Questions</p>
                    <h6 className="score">
                      {result.CorrectAnswer + result.WrongAnswer}
                    </h6>
                  </div> */}
                  <div className="result-col d-flex align-items-center justify-content-between">
                    <p>{translation.total_questions}</p>
                    <h6 className="score">
                      {totalQuestionsAllowed === 0
                        ? "0"
                        : totalQuestionsAllowed}
                    </h6>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Exam;
