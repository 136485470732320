import React from "react";
import { common, tools } from "helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class AnalyticsInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      translation: common.getTranslation(),
    };
  }

  componentDidMount = () => {
    tools.scrollTop();
  };

  render() {
    const {
      translation
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>Analytics Info</title>
        </Helmet>
        {/* <!-- Breadcrumb Wrap --> */}
        <div className="breadcrumb-wrap about analytics-info">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1>{translation.analytics_title}</h1>
                <p>
                  {translation.formatString(translation.analytics_content, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}
                </p>
              </div>
              {/* <div className="col-lg-6">
                <p>
                  {translation.formatString(translation.analytics_content, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}
                </p>
              </div> */}
            </div>
          </div>
        </div>
        {/* <!-- Breadcrumb Wrap End --> */}

        {/* <!-- Student Performance Wrap --> */}
        <div className="student-performance-wrap">
          <div className="container-fluid px-0">
            <div className="row align-items-center mx-0">
              <div className="col-sm-6 px-0">
                <div className="analytics-content pl-180">
                  <h4 className="mb-4">{translation.myprogress_title}</h4>
                  <p>{translation.myprogress_content}</p>
                  <p>{translation.formatString(translation.myprogress_content1, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}</p>
                </div>
              </div>
              <div className="col-sm-6 px-0">
                <img src={common.loadImg("student-performance.png")} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Student Performance Wrap End --> */}

        {/* <!-- Student Performance Wrap --> */}
        <div className="student-performance-wrap">
          <div className="container-fluid px-0">
            <div className="row align-items-center mx-0">
              <div className="col-sm-6 px-0">
                <img src={common.loadImg("valuable-information.png")} className="img-fluid" />
              </div>
              <div className="col-sm-6 px-0">
                <div className="analytics-content pr-180">
                  <h4 className="mb-4">{translation.setgoals_title}</h4>
                  <p>{translation.formatString(translation.setgoals_content, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}</p>
                  <p>{translation.setgoals_content1}</p>
                  <p>{translation.setgoals_content2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Student Performance Wrap End --> */}

        {/* <!-- Student Performance Wrap --> */}
        <div className="student-performance-wrap">
          <div className="container-fluid px-0">
            <div className="row align-items-center mx-0">
              <div className="col-sm-6 px-0">
                <div className="analytics-content pl-180">
                  <h4 className="mb-4">{translation.viewgoals_title}</h4>
                  <p>{translation.formatString(translation.viewgoals_content, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}</p>
                  {/* <p>{translation.viewgoals_content1}</p> */}
                  <p>{translation.formatString(translation.viewgoals_content1, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}</p>
                  {/* <h4 className="mb-4">
                  Track Usage</h4>
                  <p>
                    Sed a augue pretium, euismod ex ac, vehicula magna. Vivamus
                    ac risus quis dui lobortis blandit. Nunc auctor nisi sed
                    magna faucibus, eget elementum lacus eleifend. Maecenas
                    mattis erat in velit vehicula malesuada. Pellentesque turpis
                    lorem, dapibus et risus vitae, ultrices sodales tellus.
                    Phasellus aliquet odio eu finibus malesuada. Nunc dolor
                    sapien, vulputate quis ipsum ac, ultrices efficitur augue.
                    Mauris porttitor vel ex quis venenatis.
                  </p> */}
                </div>
              </div>
              <div className="col-sm-6 px-0">
                <img src={common.loadImg("student-performance-yellow.png")} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Student Performance Wrap End --> */}


        {/* <!-- Join Our Community Wrap --> */}
        <div className="join-our-community-wrap alternate tertiary-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>{translation.ace_your_goal}</h3>
              </div>
              <div className="col-lg-6">
                <p className="pl-5">
                {translation.formatString(translation.about_example, <b>{translation.xample} <sup>{translation.tm}</sup></b> )}
                </p>
              </div>
              <div className="col-lg-6 offset-lg-3">
                <div className="d-flex justify-content-between mt-5">
                  <Link to="/pricing" className="btn bordered-btn">
                    {translation.our_packages}
                    </Link>
                  <Link to="/membership" className="btn">
                    {translation.join_now}
                    </Link>
                </div>
              </div>
            </div>
            {/* <img src={common.loadImg("join-examplelk.png")} className="img-fluid" /> */}
          </div>
        </div>
        {/* <!-- Join Our Community Wrap End --> */}
      </div>
    );
  }
}

export default AnalyticsInfo;