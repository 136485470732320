import React from "react";
import { common } from "helpers";
import RadioOptions from "./RadioOptions";

class WrongAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: props.questionData ? props.questionData : {},
      activeOptions: {},
      questionNo: props.questionNo ? props.questionNo : 1,
      correctAnswer: props.correctAnswer,
      selectedAnswer: props.selectedAnswer,
    };
  }
  
  componentDidMount = () => {
    window.vcm.formula_call();
  }

  nextQuestion = (e) => {
    e.preventDefault();
    this.props.nextQuestion();
  };

  render() {
    const {
      questionData,
      activeOptions,
      questionNo,
      correctAnswer,
      selectedAnswer,
    } = this.state;
    const question = questionData.question;
    const explanation = questionData.explanation
      ? questionData.explanation.content
      : "";
    return (
      <div>
        <div className="question-panel pt-4 pb-5">
          <form method="POST">
            <p className="ques-no">
              Question No <span>{common.padQuestionNumbers(questionNo)}</span>
            </p>
            <h6
              className="ques mb-4"
              dangerouslySetInnerHTML={{
                __html: common.removeSpecialChars(question.content),
              }}
            ></h6>
            {question.widgets &&
              Object.keys(question.widgets).length > 0 &&
              Object.keys(question.widgets).map((item, idx) => {
                const widgetData = question.widgets[item];
                return (
                  <div className="row" key={question.widgets[item]}>
                    {widgetData.type === "radio" &&
                      Object.keys(widgetData.content).length > 0 &&
                      Object.keys(widgetData.content).map((wItem, wIdx) => {
                        const answerData = widgetData.content[wItem];
                        return (
                          <RadioOptions
                            type="wrong"
                            key={wIdx}
                            answerData={answerData}
                            wIdx={wIdx}
                            activeOptions={activeOptions}
                            correctAnswer={correctAnswer}
                            selectedAnswer={selectedAnswer}
                          />
                        );
                      })}
                  </div>
                );
              })}
          </form>
        </div>
        {explanation != "" && (
          <div className="explanation-block">
            <h6>Explanation -</h6>
            <p className="mb-0" dangerouslySetInnerHTML={{
                __html: common.removeSpecialChars(explanation),
              }}></p>
          </div>
        )}

        <div className="btn-action px-80">
          <input
            type="button"
            onClick={(e) => this.nextQuestion(e)}
            className="btn btn-green mt-5"
            value="Okay, Got it!"
          />
        </div>
      </div>
    );
  }
}
export default WrongAnswer;
