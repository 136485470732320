import React from "react";
import { common } from "helpers";
import RadioOptions from "./RadioOptions";
let translation = common.getTranslation();
class QuestionContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionData: props.questionData ? props.questionData : {},
      activeOptions: Object.keys(props.activeOptions).length > 0 ? props.activeOptions : {},
      questionNo: props.questionNo ? props.questionNo : 1,
      formSumbit: false,
    };
  }
  componentDidMount = () => {
    window.vcm.formula_call();
  }
  answerUpdate = (option) => {
    this.setState(
      {
        activeOptions: option,
      },
      () => {
        this.props.answerUpdate(option);
      }
    );
  };

  submitAnswer = (e) => {
    e.preventDefault();
    const { activeOptions } = this.state;
    if (Object.keys(activeOptions).length > 0) {
      this.setState({ formSumbit: true });
      const question = this.state.questionData.question;
      Object.keys(question.widgets).map((item, idx) => {
        const widgetData = question.widgets[item];
        if (widgetData.type === "radio") {
          Object.keys(widgetData.content).map((wItem, wIdx) => {
            const answerData = widgetData.content[wItem];
            if (answerData.correct) {
              this.props.submitAnswer(answerData, this.state.activeOptions);
            }
          });
        }
      });
    } else {
      common.snack("E", translation.pls_ans_question);
    }
  };

  componentDidUpdate = (prevState)=>{
    if(prevState.questionData != this.state.questionData){    
      window.vcm.formula_call();
      this.setState({
        questionData: this.props.questionData,
        formSumbit: false,
        questionNo: this.props.questionNo,
        activeOptions: this.props.activeOptions
      });
    }    
  }

  render() {
    const { questionData, activeOptions, questionNo, formSumbit } = this.state;
    const { isPause } = this.props;
    const question = questionData.question;
    return (
      <div className="question-panel pt-4 pb-2">
        <form method="POST">
          <p className="ques-no">
            {translation.question_no} <span>{common.padQuestionNumbers(questionNo)}</span>
          </p>
          <h6
            className="ques mb-4"
            dangerouslySetInnerHTML={{
              __html: common.removeSpecialChars(question.content),
            }}
          ></h6>
          {question.widgets &&
            Object.keys(question.widgets).length > 0 &&
            Object.keys(question.widgets).map((item, idx) => {
              const widgetData = question.widgets[item];
              return (
                <div className="row" key={question.widgets[item]}>
                  {widgetData.type === "radio" &&
                    Object.keys(widgetData.content).length > 0 &&
                    Object.keys(widgetData.content).map((wItem, wIdx) => {
                      const answerData = widgetData.content[wItem];
                      return (
                        <RadioOptions
                          key={wIdx}
                          answerData={answerData}
                          wIdx={wIdx}
                          activeOptions={activeOptions}
                          answerUpdate={this.answerUpdate}
                        />
                      );
                    })}
                  <div className="col-lg-12">
                    {!isPause &&
                      <input
                        type="button"
                        onClick={(e) => this.submitAnswer(e)}
                        className="btn btn-green"
                        // value={!formSumbit ? "Submit" : "Processing..."}
                        value={!formSumbit ? translation.submit : translation.processing}
                        disabled={formSumbit}
                      />
                    }
                  </div>
                </div>
              );
            })}
        </form>
      </div>
    );
  }
}
export default QuestionContent;
