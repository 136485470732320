import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { common, tools, history, functions } from "helpers";
import { Link } from "react-router-dom";
import { apiServices } from "api";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.page,
      header: props.header,
      headerType: this.getHeaderType(props.header),
      isLoggedIn: common.authCheck(),
      userData: common.authInfo(),
      sData: {
        supportNotify: []
      },
      translation: common.getTranslation(),
      isLanguagePage: localStorage.getItem("lngPage")
    };
  }

  // cycle

  componentDidMount() {
    const { isLoggedIn, userData } = this.state;
    const { userPlan } = this.props;
    if (userPlan.plan.planId == "advanced") {
      window.chat.chat_call(userData.user.name, userData.user.email);
    }
    if (isLoggedIn) {
      this.getSupportNotification();
      this.socketIO();
    }
  }

  componentDidUpdate() {
    const { page, header } = this.state;
    // set header
    if (header != this.props.header) {
      this.setState({
        header: this.props.header,
        headerType: this.getHeaderType(this.props.header),
      });
      setTimeout(() => {
        this.setState({
          isLanguagePage: localStorage.getItem("lngPage")
        });
      }, 200
      );

    }
    // set page
    if (page != this.props.page) {
      this.setState({
        page: this.props.page
      });
    }
  }

  // api
  getSupportNotification = () => {
    const { sData } = this.state;
    // api call
    let data = {
      method: "GET",
      url: "SUPPORT_NOTIFICATION",
      query: `?userId=${common.getStorage("userId")}`,
    };

    apiServices.call(
      data,
      (res) => {
        if (res.data) {
          sData.supportNotify = res.data.data;
          this.setState({
            sData
          })
        }
      },
      (error) => { alert(error) },
      (final) => { }
    );
  };

  getHeaderType = (header) => {
    let headerList = {
      1: "navigation-wrap header-fixed dark-bg enable-sticky",
      2: "navigation-wrap header-relative",
      3: "navigation-wrap header-relative",
      4: "navigation-wrap header-relative",
      5: "navigation-wrap header-relative primary-bg",
      6: "navigation-wrap header-relative dashboard-bg",
      7: "navigation-wrap header-relative gradient-border",
      8: "navigation-wrap header-relative gradient-border alternate",
      9: "navigation-wrap header-relative secondary-bg",
      10: "navigation-wrap header-relative dashboard-bg",
      11: "navigation-wrap header-fixed shadow-bg enable-sticky",
      12: "navigation-wrap header-relative analytics-bg",
      13: "navigation-wrap header-relative settings-bg",
      14: "navigation-wrap header-relative dark-bg lang-header-bg",
    };
    return headerList[header];
  };

  onSearch = (e) => {
    if (e.key === "Enter") {
      history.push("/search/" + e.target.value);
    }
  };

  // socket
  socketIO() {
    // when update read
    window.socket.on('ticketCount', (result) => {
      if (result.userId == common.getStorage("userId")) {
        this.getSupportNotification();
      }
    })

    // when staff sent message
    window.socket.on('ticketReceive', (result) => {
      const { page } = this.state;
      if (page == "support_ticket_info" && result.ticketId != tools.getPath(3)) {
        this.getSupportNotification();
      } else if (result.userId == common.getStorage("userId") && page != "support_ticket_info") {
        this.getSupportNotification();
      }
    })
  }

  setLanguage = (event, selectedLanguage) => {
    event.preventDefault();
    common.saveLanguage(selectedLanguage)
    common.redirect(window.location.pathname);
  }

  render() {
    const { header, headerType, isLoggedIn, userData, sData, translation, isLanguagePage } = this.state;
    const { userPlan } = this.props;

    return (
      <React.Fragment>
        <header className={headerType}>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light align-items-center">
              <Link className="navbar-brand" to="/">
                <img src={common.loadImg("logo.png")} alt="Examplelk" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              {header === 2 ||
                (header === 7 && (
                  <div className="form-group has-search ml-5 mb-0">
                    <img
                      src={common.loadImg("search-icon.png")}
                      alt="Search"
                      className="icon-search"
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for?"
                      onKeyPress={(e) => this.onSearch(e)}
                    />
                  </div>
                ))}

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  {isLoggedIn && (
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/"
                      >
                        {userData &&
                          userData.user &&
                          userData.user.role == 1 ? translation.header_dashboard : translation.header_mytest}
                      </Link>
                    </li>
                  )}
                  {!isLoggedIn && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/about-us">
                        {translation.header_whoweare}
                      </Link>
                    </li>
                  )}
                  {!isLoggedIn && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/analytics-info">
                        {translation.header_analytics}
                      </Link>
                    </li>
                  )}
                  {isLoggedIn && userPlan.paymentStatus === "active" && userPlan.paymentGateway === "payhere" && <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/analytics/topic/testscore" >
                      {translation.header_analytics}
                    </Link>
                  </li>}
                  {isLoggedIn && userPlan.paymentStatus === "active" && userPlan.paymentGateway === "payhere" && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/support/tickets">
                        {translation.header_support}
                      </Link>
                      {sData.supportNotify != "" && (
                        <span className="ticket-unread">{sData.supportNotify[0].unread}</span>
                      )}
                    </li>
                  )}
                  {/* {!isLoggedIn && (
                    <li className="nav-item">
                      <Link className="nav-link" to="/pricing">
                        {translation.header_pricing}
                      </Link>
                    </li>
                  )} */}
                  {((!isLoggedIn && isLanguagePage == 'false') || (isLoggedIn && userData &&
                    userData.user &&
                    userData.user.role == 1)) && (
                      <li className="nav-item-select-lang">
                        <Dropdown>
                          <Dropdown.Toggle variant="" className="select_lang">
                            <span>
                              {
                                localStorage.getItem("language") === "en" &&
                                <span>Eng</span>
                              }
                              {
                                localStorage.getItem("language") === "si" &&
                                <span>Sin</span>
                              }
                              {
                                localStorage.getItem("language") === "ta" &&
                                <span>Tam</span>
                              }
                            </span>
                            <span>
                              <img
                                src={common.loadImg("selectbox_arrow_white.png")}
                                alt=""
                                className="lang-dropdown"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={(e) => this.setLanguage(e, "en")}>
                              {translation.lang_english}
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={(e) => this.setLanguage(e, "si")}>
                              {translation.lang_sinhala}
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={(e) => this.setLanguage(e, "ta")}>
                              {translation.lang_tamil}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    )}
                  {!isLoggedIn && (
                    <li className="nav-item nav-btn signin">
                      <Link className="nav-link" to="/sign-in">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                        >
                          <g fillRule="evenodd">
                            <g fillRule="nonzero">
                              <g>
                                <path
                                  d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                  transform="translate(-1267 -25) translate(1267 25)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <div className="user-info">{translation.signin}</div>
                      </Link>
                    </li>
                  )}
                  {isLoggedIn && (
                    <li className="nav-item nav-btn">
                      <Link
                        className="nav-link"
                        to="#"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                        >
                          <g fillRule="evenodd">
                            <g fillRule="nonzero">
                              <g>
                                <path
                                  d="M17.18 19.738h-2c0-3.374-2.83-6.118-6.311-6.118s-6.31 2.745-6.31 6.118h-2c0-4.478 3.729-8.118 8.311-8.118 4.581 0 8.31 3.64 8.31 8.118zM8.87 10.97c-3.023 0-5.484-2.462-5.484-5.485C3.385 2.461 5.846 0 8.87 0c3.025 0 5.486 2.46 5.486 5.485 0 3.025-2.461 5.485-5.486 5.485zm0-8.97C6.948 2 5.385 3.563 5.385 5.485S6.948 8.97 8.87 8.97c1.923 0 3.486-1.563 3.486-3.485S10.791 2 8.87 2z"
                                  transform="translate(-1267 -25) translate(1267 25)"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                        <div className="user-info">
                          <span>{translation.header_welcome}</span>
                          <br />
                          {userData && userData.user && userData.user.screenName}
                        </div>
                      </Link>
                      <div className="dropdown-menu">
                        <Link className="dropdown-item" to="/settings">
                          {translation.header_settings}
                        </Link>
                        {userData.user.role !== 2 && (
                          <Link className="dropdown-item" to="/payment">
                            {translation.header_payment}
                          </Link>
                        )}
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={(e) => functions.logOut()}
                        >
                          {translation.header_logout}
                        </Link>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export { Header };
