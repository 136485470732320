import React from "react";
import SubjectMenu from "includes/pages/SubjectMenu";
import { common, tools, history } from "helpers";
import { Link } from "react-router-dom";
import { apiServices } from "api";
import { StatusBar } from "includes";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class TestSession extends React.Component {
  query = common.useQuery();
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    this.state = {
      status: "",
      testId: params.testId
        ? params.testId == 1
          ? "overall"
          : params.testId == 2
          ? "mocktest"
          : params.testId
        : "",
      subjectId: params.subId ? params.subId : "",
      gradeId: params.gradeId ? params.gradeId : "",
      modeOfTest:
        this.query.get("mode") != undefined ? this.query.get("mode") : false,
      year: this.query.get("year") != undefined ? this.query.get("year") : 0,
      paper: this.query.get("paper") != undefined ? this.query.get("paper") : false,
      sessionListResponse: [],
      userData: common.authInfo(),
      sessionCount: 0
    };
  }

  componentDidMount = () => {
    const { userData } = this.state;
    //Parent not allowed to take test
    if (userData.user.role === 1) {
      history.push("/settings");
    }
    this.getAllTestSession();
  };

  getAllTestSession = () => {
    const { userData, testId, subjectId, gradeId, modeOfTest, year, paper } = this.state;
    this.setState({
      status: "process",
    });
    let query =
      `?userID=${common.getStorage("userId")}&countryId=${userData.user.country}&classID=${subjectId}` +
      `&testID=${testId}&topicID=${gradeId}&mode=${modeOfTest}`;

    if (testId === "mocktest") {
      query = query + `&questionYear=${year}&paper=${paper}`;
    }

    let data = {
      method: "GET",
      headerFlag: true,
      url: "GET_ALL_TEST_SESSIONS",
      query: query,
      authorization: "token",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;

        if (status == "success") {
          result = common.apiResult(res);
        }
        if (result && result.length > 0) {
          let count = 0;
          result.map((val) => {
            if(val.completed == false){
              count = count + 1
            }
          })
          this.setState({
            sessionListResponse: result,
            sessionCount: count
          });
        }
        this.setState({
          status: "success",
        });
      },
      (error) => {
        this.setState({
          status: "",
        });
      },
      (final) => {}
    );
  };

  startTest = (e) => {
    const { testId, subjectId, gradeId, modeOfTest, year, paper, sessionCount } = this.state;
    e.preventDefault();
    let testURL =
      "/tests/" +
      subjectId +
      "/" +
      testId +
      "/" +
      gradeId +
      "?mode=" +
      modeOfTest +
      "&year=" +
      year + "&paper=" + paper;
    if(sessionCount < 5){
      history.push(testURL);
    } else {
      common.snack("E", translation.incomplete_start_new);
    }
  };

  continueTest = (e, subjectId, testId, gradeId, session) => {
    e.preventDefault();
    if (!session.completed) {
      let testURL =
        "/tests/" +
        subjectId +
        "/" +
        testId +
        "/" +
        gradeId +
        "/" +
        session._id +
        "?mode=" +
        session.modeOfTest +
        "&year=" +
        session.questionYear;
        //if(session.questionYear != undefined && session.questionYear != 0){
          testURL = testURL + "&paper=" + this.state.paper;
        //}
      history.push(testURL);
    }
  };

  setGrade = (id) => {};

  render() {
    const {
      sessionListResponse,
      testId,
      subjectId,
      gradeId,
      modeOfTest,
      year,
      status,
    } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Test Session</title>
        </Helmet>
        <div className="question-wrap light-bg">
          {/* <!-- Question Block --> */}
          <div className="question-block">
            <SubjectMenu
              selectedSubject={subjectId}
              selectedGradeId={gradeId}
              setGrade={this.setGrade}
              page="test"
            />
            <StatusBar status={status} />
            {status == "success" && (
              <div className="container">
                <div className="question-content-inner pt-4">
                  <div className="start-new-session d-flex align-items-center justify-content-between primary-bg mb-4">
                    <h6>{translation.start_new_session}</h6>
                    <p className="ml-auto">{translation.next_session}</p>
                    <a
                      href="#"
                      className="btn bordered-btn ml-4"
                      onClick={(e) => this.startTest(e)}
                    >
                      {translation.start_now}
                    </a>
                  </div>
                  {sessionListResponse.length > 0 &&
                    sessionListResponse.map((session, index) => {
                      let timeTaken = (session.totQuestions * 60) - session.lastTime;
                      let correct = Math.round(
                        (session.answered / session.totQuestions) * 100
                      );
                      let wrong = Math.round(
                        ((session.question - session.answered) /
                          session.totQuestions) *
                          100
                      );
                     
                      let unanswered = 100 - (correct + wrong);
                      let testURL =
                        "/tests/" +
                        subjectId +
                        "/" +
                        testId +
                        "/" +
                        gradeId +
                        "/" +
                        session._id +
                        "?mode=" +
                        session.modeOfTest +
                        "&year=" +
                        session.questionYear;
                      return (
                        <div className="session-row pb-4" key={index}>
                          <div className="session-info d-flex align-items-center justify-content-between">
                            <h6>
                             {translation.test_session} {String(sessionListResponse.length - index).padStart(2, "0")}
                            </h6>
                            <p>{tools.formatDate(session.date)}</p>
                            {session.completed ? (
                              <span className="status completed">
                                {translation.completed}
                              </span>
                            ) : (
                              <Link
                                to="#"
                                onClick={(e) =>
                                  this.continueTest(
                                    e,
                                    subjectId,
                                    testId,
                                    gradeId,
                                    session
                                  )
                                }
                                className="status continue"
                              >
                                {translation.continue}
                              </Link>
                            )}
                          </div>

                          <div className="session-report d-flex flex-wrap">
                            <div className="session-col">
                              <div className="progress progress-sm">
                                {correct > 0 && (
                                  <div
                                    className="progress-bar bg-success"
                                    style={{ width: correct + "%" }}
                                  >
                                    {/* <span>{correct}%</span> */}
                                  </div>
                                )}
                                {wrong > 0 && (
                                  <div
                                    className="progress-bar bg-danger"
                                    style={{ width: wrong + "%" }}
                                  >
                                    {/* <span>{wrong}%</span> */}
                                  </div>
                                )}
                                {unanswered > 0 && (
                                  <div
                                    className="progress-bar bg-warning"
                                    style={{ width: unanswered + "%" }}
                                  >
                                    {/* <span>{unanswered}%</span> */}
                                  </div>
                                )}
                              </div>
                              <div className="progress-bar-values d-flex">
                                {correct > 0 && (
                                  <span
                                    className="text-success"
                                    style={{ width: correct + "%" }}
                                  >
                                    {correct}%
                                  </span>
                                )}
                                {wrong > 0 && (
                                  <span
                                    className="text-danger"
                                    style={{ width: wrong + "%" }}
                                  >
                                    {wrong}%
                                  </span>
                                )}
                                {unanswered > 0 && (
                                  <span
                                    className="text-warning"
                                    style={{ width: unanswered + "%" }}
                                  >
                                    {unanswered}%
                                  </span>
                                )}
                              </div>
                              <div className="status-info d-flex justify-content-between">
                                <label className="success">{translation.correct}</label>
                                <label className="danger">{translation.wrong}</label>
                                <label className="warning">{translation.unanswered}</label>
                              </div>
                            </div>
                            <div className="session-col">
                              <div className="mark-col d-flex align-items-center justify-content-between">
                                <h6 className="mb-0">{translation.marks}</h6>
                                <span className="primary-bg">
                                  {session.score}
                                </span>
                              </div>
                              <div className="mark-col d-flex align-items-center justify-content-between">
                                <h6 className="mb-0">{translation.actual_marks}</h6>
                                <span className="secondary-bg">
                                  {session.actualMark}
                                </span>
                              </div>
                              {/* <div className="mark-col d-flex align-items-center justify-content-between">
                                <h6 className="mb-0">(-) Marks</h6>
                                <span className="bg-danger">
                                  {session.nagativeMark}
                                </span>
                              </div> */}
                              {session.modeOfTest && (
                                <div className="mark-col d-flex align-items-center justify-content-between">
                                <h6 className="mb-0">{translation.time_taken}</h6>
                                <span className="">
                                {common.toHHMMSS(timeTaken)}
                                </span>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {sessionListResponse.length === 0 && (
                      <p className="session_warning">You didn't practice this test. Please click "Start Now" to pratice</p>
                    )}
                </div>
              </div>
            )}
          </div>

          {/* <!-- Question Block End --> */}

        </div>
      </div>
    );
  }
}
export default TestSession;
