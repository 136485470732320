import React, { Component, Fragment } from "react";
//import Snowflakes from 'magic-snowflakes';
// includes
import { apiServices } from "api";
import { common, functions } from "helpers";
// pages
import { Header, Footer } from "components";
import { StatusBar } from "includes";
import ElkModel from "../Includes/ElkModel";

// const snowflakes = new Snowflakes({
//   color: '#fff',
// });

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      userPlan: {
        plan: "",
        payment: "",
        paymentStatus: "",
        gracePeriod: null,
      },
      isLoggedIn: common.authCheck(),
      userData: common.authInfo(),
      isSnow: false,
    };
  }

  // life
  componentDidMount() {
    //snowflakes.stop();
    const { isLoggedIn, userData } = this.state;
    if (isLoggedIn) {
      this.getUserPaymentPlan();
    } else {
      this.setStatus("success");
      //Enable snow effect for home & select language pages
      // var today = new Date();
      // var lastDay = new Date(2022, 1, 1);
      // let isShow = today < lastDay;
      // if (userData == undefined && window.location.pathname == "/" && isShow) {
      //   this.setState({ isSnow: true });
      //   snowflakes.start();
      // }
      // setTimeout(() => {
      //   this.setState({
      //     isSnow: false,
      //   });
      //   snowflakes.destroy();
      // }, 180000); 
    }
  }

  // componentDidUpdate() {
  //   const { isSnow } = this.state;
  //   if(isSnow && window.location.pathname != "/"){
  //     snowflakes.hide();
  //   }else if(isSnow && window.location.pathname == "/"){
  //     snowflakes.show();
  //     snowflakes.start();
  //   }
  // }
  // api
  getUserPaymentPlan = () => {
    const { userData } = this.state;
    let queryParam = `?userId=${
      userData.user.role === 2
        ? userData.user.parentId._id
        : userData.user.userId
    }`;
    let data = {
      method: "GET",
      url: "PRICING_PLAN",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          let paymentStatus = "active";
          if (result.paymentGateway === "trial") {
            paymentStatus = functions.checkTrial(result);
          } else if (
            result.paymentGateway === "payhere" &&
            result.payment !== ""
          ) {
            paymentStatus = functions.checkPayment(result);
          }
          // alert(paymentStatus)
          let userPlan = {
            paymentStatus,
            paymentGateway: result.paymentGateway,
            gracePeriod: result.gracePeriod,
            trialPeriod: result.trialPeriod,
            plan: result.plan,
            payment: result.payment,
          };
          this.setState(
            {
              userPlan,
            },
            () => {
              if (result.plan.planId !== "free") {
                this.verifyLogin();
              } else {
                this.setStatus(status);
              }
            }
          );
        } else {
          this.setStatus("error");
        }
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => {}
    );
  };

  // support function
  setStatus(status) {
    this.setState({ status });
  }

  verifyLogin = () => {
    const { userData } = this.state;
    let params = {
      loginTime: userData.user.loginTime,
      userId: userData.user.userId,
    };

    let data = {
      method: "POST",
      url: "VERIFY_LOGIN",
      body: params,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          if (!result.access) {
            //clear session
            this.clearSession();
          }
          this.setStatus(status);
        } else {
          //clear session
          this.clearSession();
        }
      },
      (error) => {
        //clear session
        this.clearSession();
      },
      (final) => {}
    );
  };

  clearSession = () => {
    let translation = common.getTranslation();
    common.snack("E", translation.err_login_device);
    common.removeAuthStorage();
    common.redirect("/");
  };

  render() {
    const { userPlan, status } = this.state;
    const { header, page } = this.props;
    return (
      <div>
        <React.Fragment>
          {page != "test" && status == "success" && (
            <Fragment>
              <Header header={header} page={page} userPlan={userPlan} />
              <ElkModel page={page} userPlan={userPlan} />
            </Fragment>
          )}
          <StatusBar status={status} />
          {status == "success" &&
            React.cloneElement(this.props.children, {
              page,
              userPlan,
            })}
          {page != "test" && <Footer />}
        </React.Fragment>
      </div>
    );
  }
}
export default Home;
