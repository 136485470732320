import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { history } from "helpers";
import { Fragment } from 'react';

function ElkModel(props) {
  const data = props.userPlan;
  const [status, setStatus] = useState(false);
  const [isDialog, setIsDialog] = useState(false);

  useEffect(() => {
    let status = accountStatus();
    setStatus(status);
    if (status !== "active") {
      setIsDialog(true);
    }
  }, [])

  const accountStatus = () => {
    let result = "active";
    let today = moment().format("MMM DD, YYYY");
    let dialogDate = moment(localStorage.getItem("dialogDate") ?? "1970-01-01").format("MMM DD, YYYY")
    if (!moment(dialogDate).isSame(today)) {
      if (data.gracePeriod !== null) {
        result = "grace";
      } else if (data.paymentGateway === "free" && data.plan.planId !== "free") {
        result = "down";
      }
      localStorage.setItem("dialogDate", today);
    }
    return result;
  }

  const dateDiff = () => {
    var a = moment();
    var b = moment(data.gracePeriod);
    return b.diff(a, 'days');
  }

  // support
  const gotoPayment = (e) => {
    e.preventDefault();
    setIsDialog(false);
    history.push("/payment")
  }

  return (
    <div>
      <Dialog
        open={isDialog}
        onClose={() => setIsDialog(false)}
        aria-labelledby="responsive-dialog-title"
        className="explanation-model"
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="card-heading tertiary-bg"
        >
          {status === "grace" && (
            <span>You have received a Grace Period of 10 days</span>
          )}
          {status === "down" && (
            <span>Your Subscription has been downgraded to a Free version</span>
          )}
        </DialogTitle>
        <DialogContent>
          <div className="card-body">
            {status === "grace" && (
              <Fragment>
                <p>Your subscription has expired, but we want to encourage you to keep learning, hence we have granted you a {dateDiff()}-day grace period. </p>
                <p>Please renew your subscription within {dateDiff()} days to continue learning without any disturbance.</p>
              </Fragment>
            )}
            {status === "down" && (
              <p>Your Xample™ account has been downgraded to free membership. You can reactivate your membership at any time by visiting your Xample™ account or by clicking the link below.</p>
            )}
          </div>
          <div className="form-actions d-flex justify-content-around align-items-center mb-5">
            <a href="#" className="btn bordered-btn-blue" onClick={() => setIsDialog(false)}>Cancel</a>
            <a href="#" className="btn bordered-btn-blue" onClick={gotoPayment}>
              {status === "grace" ? "Renew My Subscription" : "Upgrade Now"}
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ElkModel;