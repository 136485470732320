import React from "react";
import { Link } from "react-router-dom";
// includes
import { Handler } from "includes";
import { common, message, history } from "helpers";
import { apiServices } from "api";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        emailId: "",
      },
      errors: {
        emailId: {
          error: "",
          isReq: translation.email_req,
          isValidEmail: true,
          isValidEmailReq: translation.invalid_email,
        },
      },
    };
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();

    if (isValid) {
      this.setLoading(true);
      const { fields } = this.state;
      // api call
      let params = {
        email: fields.emailId,
      };

      let data = {
        url: "FORGOT_PASSWORD",
        body: params,
        authorization: "none",
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            common.snack("S", translation.email_link_sent);
            history.push("/sign-in");
          }
        },
        (error) => { },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // event
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  render() {
    const { isLoading, formSubmit, fields, errors } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="card card-2">
                  <div className="card-heading primary-bg">
                    <h2>{translation.forgot_your_password}</h2>
                    <p className="mb-0">
                      {translation.forgot_enter_email}
                    </p>
                  </div>
                  <div className="card-body">
                    <form method="POST">
                      <div className="form-group">
                        <input
                          type="email"
                          name="emailId"
                          value={fields.emailId}
                          onChange={(e) => this.handler.onChange(e)}
                          onKeyDown={this.onEnterPress}
                          className="form-control"
                          placeholder=" "
                        />
                        <span className="floating-label">
                          {/* {translation.your_email_address} */}
                          Your Email Address
                        </span>
                        {errors.emailId.error != "" && formSubmit && (
                          <span className="elk-error">
                            {errors.emailId.error}
                          </span>
                        )}
                      </div>
                      <div className="form-actions d-flex justify-content-between">
                        <Link to="/sign-in"
                          className="btn bordered-btn"
                        >{translation.cancel}</Link>
                        <input
                          type="button"
                          onClick={() => this.onSubmit()}
                          className="btn btn-light-blue"
                          disabled={isLoading}
                          value={isLoading ? translation.processing : translation.send}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => { this.handler = instance; }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default ForgotPassword;
