import React from "react";
import { apiServices } from "api";
import { common } from "helpers";
let translation = common.getTranslation();
class TestBreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectName: "",
      gradeName: "",
    };
  }
  componentDidMount = () => {
    this.GetSubjectGradeNames("subjectName");
    if (this.props.gradeId != 0) {
      this.GetSubjectGradeNames("gradeName");
    }
  };

  GetSubjectGradeNames = (type) => {
    const { subjectId, gradeId } = this.props;
    let urlKeys = {
      id: type === "subjectName" ? subjectId : gradeId,
    };
    let data = {
      method: "GET",
      url: type === "subjectName" ? "GET_CLASS_BY_ID" : "GET_TOPIC_BY_ID",
      key: urlKeys,
      authorization: "none",
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status === "success") {
          let result = common.apiResult(res);
          if (result) {
            let name = result.name;
            this.setState({
              [type]: name,
            });
          }
        }
      },
      (error) => { },
      (final) => { }
    );
  };

  render() {
    const { testId, testName, year } = this.props;
    const { subjectName, gradeName } = this.state;
    return (
      <nav aria-label="breadcrumb">
        {(subjectName != "" || gradeName != "") && (
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              {subjectName}
            </li>
            {gradeName && <li className="breadcrumb-item">
              {gradeName}
            </li>}
            <li className={testId == "mocktest" && year > 0 ? "breadcrumb-item" : "breadcrumb-item active"} aria-current="page">
              {/* {testId == "overall" ? "Overall" : (testId != "mocktest" ? testName : (year > 0 ? "Past Paper" : "Model Paper"))} */}
              {testId == "overall" ? translation.overall : (testId != "mocktest" ? testName : (year > 0 ? translation.past_paper : translation.model_paper))}
            </li>
            {testId == "mocktest" && year > 0 && <li className="breadcrumb-item active  ">
              {year}
            </li>}
          </ol>
        )}
      </nav>
    );
  }
}
export default TestBreadCrumb;
