import React from "react";
import { common } from "helpers";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class ComingSoon extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Coming Soon</title>
        </Helmet>
        {/* <!-- Main Content --> */}
        <div className="main-content">
          <div className="question-wrap d-flex">
            {/* <!-- Question Block --> */}
            <div className="question-block">
              {/* <!-- Question Content --> */}
              <div className="container">
                <div className="question-content-inner alternate">
                  <div className="content-block d-flex align-items-center justify-content-center text-center">
                    <div className="content-info">
                      <img src={common.loadImg("gears.png")} className="mb-5" alt="Coming Soon" />

                      <h3 className="coming-soon">{translation.coming_soon}</h3>
                      <p className="mb-0">{translation.working_on}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Question Content End --> */}
            </div>
            {/* <!-- Question Block End --> */}            
          </div>
        </div>
        {/* <!-- Main Content End --> */}
      </div>
    );
  }
}

export default ComingSoon;
