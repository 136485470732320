import React from "react";
import { common } from "helpers";
import { Link } from "react-router-dom";
// pages
import ChatWidget from "../Includes/ChatWidget";
let translation = common.getTranslation();

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoggedIn: common.authCheck(),
		};
	}

	render() {
		const { isLoggedIn } = this.state;
		return (
			<footer className={isLoggedIn ? "footer pt-4 pb-3" : "footer"}>
				<div className="container">
					<div className="row">
						<div
							className={
								isLoggedIn ? "col-lg-12 text-center" : "col-lg-12 text-center"
							}
						>
							{!isLoggedIn && (
								<div className="footer-info">
									<a className="footer-logo mt-5 mb-4" href="/">
										<img
											src={common.loadImg("footer-logo.png")}
											alt="Examplelk"
											height="130"
										/>
									</a>
									<p className="mb-4">
										{translation.formatString(
											translation.copy_rights_reserved,
											<>
												{translation.xample} <sup>{translation.tm}</sup>
											</>
										)} <br />
										{translation.formatString(
											translation.copy_rights_content,
											<>
												{translation.xample} <sup>{translation.tm}</sup>
											</>
										)}
										{/* Copyright reserved @Xample<sup>TM</sup> <br /> Xample{" "}
										<sup>TM</sup> is a premier testing and revision tool created
										by Novelwall UK, one of the most trusted brands in the UK
										education industry, and ATOM, one of the top service brands
										in India's IT and engineering industry. A complete Virtual
										Learning Environment (VLE) for O/L and A/L MCQ testing and
										training, Xample<sup>TM</sup> includes more Than 120,000 O/L
										and A/L exam questions and answers accompanied by detailed
										explanations. */}
									</p>
								</div>
							)}

							<ul className="list-inline footer-links">
								<li className="list-inline-item">
									<Link to="/about-us" onClick={this.scrollToTop}>
										{translation.header_whoweare}
									</Link>
								</li>
								<li className="list-inline-item">
									<Link
										to={
											isLoggedIn
												? "/analytics/topic/testscore"
												: "/analytics-info"
										}
									>
										{" "}
										{translation.header_analytics}
									</Link>
								</li>
								{isLoggedIn && (
									<li className="list-inline-item">
										<Link to="/support">{translation.header_support} </Link>
									</li>
								)}
								{!isLoggedIn && (
									<li className="list-inline-item">
										<Link to="/membership">
											{translation.header_membership}
										</Link>
									</li>
								)}
								<li className="list-inline-item">
									<Link to="/terms-conditions">
										{translation.terms_conditions}
									</Link>
								</li>
								<li className="list-inline-item">
									<Link to="/privacy-policy">{translation.privacy_policy}</Link>
								</li>

								<li className="list-inline-item">
									<Link to="/help-center">{translation.help_center}</Link>
								</li>

								{!isLoggedIn && (
									<li className="list-inline-item">
										<Link to="/contact-us">{translation.contact_us}</Link>
									</li>
								)}
							</ul>
							{!isLoggedIn && (
								<span>
									<p className="mb-1">{translation.follow_us}</p>
									<ul className="list-inline social-links mb-5">
										<li className="list-inline-item">
											<a target="_blank" href={"https://www.facebook.com/Xample.lk"}>
												<img src={common.loadImg("fb.png")} alt="" />
											</a>
										</li>
										<li className="list-inline-item">
											<a target="_blank" href={"https://www.instagram.com/xample.lk/"}>
												<img src={common.loadImg("insta.png")} alt="" />
											</a>
										</li>
										<li className="list-inline-item">
											<a target="_blank" href={"https://www.youtube.com/channel/UC55Whdx1J31X_WPyFYUX5tA"}>
												<img src={common.loadImg("youtube.png")} alt="" />
											</a>
										</li>
									</ul>
								</span>
							)}
							<p className="mb-1">{translation.a_produt_of}</p>
							<p>
								<img src={common.loadImg("novelwall-logo.png")} alt="" />
							</p>
							<p className="copy-rights mt-4">
								{/* {translation.copy_rights} */}
								All Rights Reserved For Clients and Consumers (Pvt) Ltd 2021.
							</p>
						</div>
					</div>
				</div>
				{/* {isLoggedIn && (<ChatWidget />)} */}
			</footer>
		);
	}
}
export { Footer };
