import React from "react";
import { common } from "helpers";
import { Link } from "react-router-dom";
import { StatusBar } from "includes";
import { apiServices } from "api";
let translation = common.getTranslation();

class TargetArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            sessions: [],
            page: 1,
            limit: 10,
            status: "",
            enableLoadMore: false
        };
    }

    componentDidMount() {
        if (this.props.setChildRefreshMethod) {
            this.props.setChildRefreshMethod(this.refresh);
        }
        this.getRecords()
    }

    refresh = () => {
        this.setState({
            page: 1,
            records: []
        }, () => {
            this.getRecords()
        })
    }

    loadMore = () => {
        const { page, limit } = this.state;
        this.setState({
            page: (page + limit)
        }, () => {
            this.getRecords()
        })
    }

    onClickQuestionLog = (testObj) => {
        this.props.updateTestId(
            testObj._id.test,
            testObj.class._id,
            "revisionquestion"
        )
    }

    //Services
    getRecords = () => {
        const { page, limit, records } = this.state;
        this.setState({ status: "process" });

        var queryParam = "?";
        queryParam = queryParam + "userID=" + this.props.selUserId;
        queryParam = queryParam + "&skip=" + page;
        queryParam = queryParam + "&limit=" + limit;
        queryParam = queryParam + "&classID=" + this.props.selSubId;
        queryParam = queryParam + "&topicID=" + this.props.selGradeId;
        queryParam = queryParam + "&period=" + this.props.selDateRangeId;

        let data = {
            method: "GET", headerFlag: false,
            url: "STAT_TROUBLESPOT", authorization: "token",
            query: String(queryParam)
        };
        apiServices.call(data,
            (res) => {
                let status = common.apiStatus(res);
                let result;
                if (status == "success") {
                    result = common.apiResult(res);
                }
                if (result && result.length > 0) {
                    let mergedata = records.concat(result);
                    this.setState({
                        records: mergedata,
                        enableLoadMore: mergedata.length < res.data.page.count ? true : false,
                        status: "success"
                    });
                }
                if (status == "empty") {
                    this.setState({
                        status: "empty"
                    });
                }
            },
            (error) => { this.setState({ status: "error", }); },
            (final) => { });
    };

    render() {

        const { records, enableLoadMore, status } = this.state;

        return (
            <>
                <div className="masonry-grid">
                    {
                        records.length > 0 &&
                        records.map((data, dataIndex) => (
                            <div className="grid-item" key={dataIndex}>
                                <div className="test-col d-flex align-items-center">
                                    <div className="test-info">
                                        <Link to="#" className="btn-sm">
                                            {data.class.name}
                                        </Link>
                                        <h5 className="mt-3">
                                            <Link to="#">
                                                {data.topic.name}
                                            </Link>
                                        </h5>
                                        <h6 className="primary-color mb-3">
                                            {data.test.name}
                                        </h6>
                                        <p className="mb-4"
                                            dangerouslySetInnerHTML={{
                                                __html: common.removeSpecialChars(data.questionDetails.question.content)
                                            }}>
                                        </p>
                                        <Link to={"/analytics/topic/revisionquestion"} className="link" onClick={() => this.onClickQuestionLog(data)}>
                                         {translation.question_log}
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <StatusBar status={status} />
                <div className="row">
                    <div className="col-sm-12 text-center">
                        {
                            status == "success" && enableLoadMore && (
                                <Link to="#" onClick={() => { this.loadMore() }} className="btn bordered-btn-blue mt-4">
                                   {translation.load_more}
                                </Link>
                            )
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default TargetArea;