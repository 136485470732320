import React from "react";
import { common, history } from "helpers";
import { envConfig } from "api/config";
import { Link } from "react-router-dom";
let translation = common.getTranslation();
class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: common.authInfo().subject ? common.authInfo().subject : [],
    };
  }
  
  gotoMyTest = (e) => {
    e.preventDefault();
    common.setStorage("page", "test");
    history.push("/");
  }

  render() {
    const { subject } = this.state;
    const { result, seconds, relatedTests, subjectId, gradeId } = this.props;
    let scorePercentage = (result.CorrectAnswer / result.totalQuestion) * 100;
    let scoreLevelText;
    let scoreImage = "practiced.png";
    if (scorePercentage < 35) {
      scoreLevelText = "Failed. Try to put more effort.";
    } else if (scorePercentage >= 35 && scorePercentage < 50) {
      scoreLevelText = "Simple Pass. Try to put more effort.";
    }
     else if (scorePercentage >= 50 && scorePercentage < 65) {
      scoreLevelText = "Credit. Try to put effort.";
    } else if (scorePercentage >= 65 && scorePercentage < 75) {
      scoreImage = "excellence.png";
      scoreLevelText = "Good. Try to put little effort";
    } else if (scorePercentage >= 75) {
      scoreImage = "crown.png";
      scoreLevelText = "Distinction. Keep up the perfect score!";
    } else {
      scoreLevelText = "You are progressing, Try to master the skill.";
    }
    return (
      <div>
        <div className="result-title primary-bg text-center">
          {scorePercentage >= 65 && <img src={common.loadImg(scoreImage)} alt="" />}
          {scorePercentage >= 65 && <h2>{translation.congratulation}</h2>}
          <p className="mb-0">
            {scoreLevelText}
          </p>
        </div>

        <div className="review-report d-flex px-3 mt-0">
          <div className="mark-col d-flex align-items-center justify-content-between">
            <h6 className="mb-0">{translation.total_score}</h6>
            <span className="primary-color font-weight-bold">
              {result.totalQuestion * envConfig.CORRECT_ANSWER_SCORE}
            </span>
          </div>
          <div className="mark-col d-flex align-items-center justify-content-between">
            <h6 className="mb-0">{translation.your_marks}</h6>
            <span className="primary-color font-weight-bold">
              {result.score}
            </span>
          </div>
          <div className="mark-col d-flex align-items-center justify-content-between">
            <h6 className="mb-0">{translation.time_spent}</h6>
            <span className="primary-color font-weight-bold">
              {common.toHHMMSS(result.time)}
            </span>
          </div>
        </div>

        {relatedTests && relatedTests.tests && relatedTests.tests.length > 0 && (
          <div className="table-responsive mb-4">
            <table className="table table-bordered custom-table">
              <thead>
                <tr>
                  <th colSpan="4">{translation.explore_skills}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {relatedTests.tests.map((test, index) => {
                    return (
                      <td key={index}>
                        <span>
                          <a
                            href={
                              "/tests/" +
                              subjectId +
                              "/" +
                              test._id +
                              "/" +
                              gradeId +
                              "?mode=false&year=0&paper=false"
                            }
                          >
                            {test.name}
                          </a>
                        </span>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        )}        
        <div className={relatedTests && relatedTests.tests && relatedTests.tests.length > 0 ? "text-center" : "text-center mt-4"}>
          <Link to="#" onClick={(e)=>this.gotoMyTest(e)} className="btn bordered-btn">
         {translation.go_to_my_tests}
          </Link>
        </div>        
      </div>
    );
  }
}
export default Result;
