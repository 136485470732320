import React from "react";
import { common } from "helpers";
import { Button } from "react-bootstrap";
let translation = common.getTranslation();
export class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: this.props.status,
    };
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    return Object.keys(nextState).length
      ? (nextState.status = nextProps.status)
      : null;
  }


  render() {
    const { status } = this.state;
    return status == "process" ? (
      <div className="elkStatusBar">
        <img
          src={common.loadImg("loader.gif")}
          alt={"Loader"}
          className="img-fluid"
        />
        <p>{translation.loading_msg}...</p>
      </div>
    ) : status == "error" || status == "failure" ? (
      <div className="elkStatusBar">
        <img
          src={common.loadImg("wrong.png")}
          alt={"Loader"}
          className="img-fluid"
        />
        <p>Oops! Something went wrong</p>
        <Button
          variant="config-btn mt-5"
          onClick={() => window.location.reload()}
        >
          Try Again
        </Button>
      </div>
    ) : (
          status == "empty" && (
            <div className="elkStatusBar">
              <img
                src={common.loadImg("norecordsfound.png")}
                alt={"Loader"}
                className="img-fluid"
              />
              <p>{translation.sorry_no_records_found}</p>
            </div>
          )
        );
  }
}
