import React from "react";
import { Link } from "react-router-dom";
import { common, tools } from "helpers";
import { StatusBar } from "includes";

class TodayGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todayGoalList: this.props.todayGoalList,
      userInfo: this.props.userInfo
    };
  }

  getNameById(id) {
    const { userInfo } = this.state;
    return userInfo.names[id];
  }

  GotoMyTest = (subject, test) => {
  let testData = {
      subjectId: subject._id.class,
      gradeId: subject._id.topic,
      termsId: test.term,
      testId: test.id,
      testName: this.getNameById(test.id),
      termName: this.getNameById(test.term),
      subjectName: this.getNameById(subject._id.class),
      gradeName: this.getNameById(subject._id.topic),
    };
    common.setStorage("testData", tools.crypt(testData, true));
    this.props.callMyTest();        
  }  

  render() {
    const { todayGoalList } = this.state;
    return (
      <div className="tab-body">
         <div
          id="accordionViewGoals"
          className="accordion-block with-subject mt-5"
        >
          {todayGoalList.length > 0 &&
            todayGoalList.map((sub, idx) => {
              return (
                <div className="card" key={idx}>
                  <div className="card-header" id={"heading-" + idx}>
                    <h5 className="mb-12">
                      <a
                        role="button"
                        data-toggle="collapse"
                        href={"#collapse-" + idx}
                        aria-expanded="false"
                        aria-controls={"collapse-" + idx}
                      >
                        <div>
                          {this.getNameById(sub._id.class)}
                          <h6 className="mb-0">{this.getNameById(sub._id.topic)}</h6>
                        </div>
                      </a>
                    </h5>
                  </div>
                  <div
                    id={"collapse-" + idx}
                    className="collapse"
                    data-parent="#accordionViewGoals"
                    aria-labelledby={"heading-" + idx}
                  >
                    <div className="card-body">
                      <div className="table-responsive mb-3">
                        <table className="table progress-table table-borderless">
                          <thead>
                            <tr>
                              <th width="100%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sub.test.map((tests, testIdx) => {
                                return (
                                  <tr key={testIdx}>
                                    <td><Link to="#" onClick={(e) => this.GotoMyTest(sub, tests)}>{this.getNameById(tests.id)}</Link></td>
                                  </tr>
                                );
                            })}
                          </tbody>
                        </table>
                      </div>


                    
                    </div>
                  </div>
                </div>
              );
            })}
            {todayGoalList.length==0 && (
              <StatusBar status={"empty"} />
            )}
        </div>
      </div>
    );
  }
}
export default TodayGoal;
