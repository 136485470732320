import React from "react";
// includes
import { common } from "helpers";
import { apiServices } from "api";
import { envConfig } from "api/config";
// pages
import GradeTable from "./includes/GradeTable";
let translation = common.getTranslation();

class MyGoalReports extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userInfo: this.props.userInfo,
      userId: this.props.userId,
      showReport: false,
      viewGoalBySubGrade: [],
      scoreGrade: {
        scoreGrade: "Failed",
        scoreHour: 0,
      },
      userPlan: this.props.userPlan,
    };
  }

  componentDidMount = () => {
    this.formUserSubjects();
  };

  formUserSubjects = () => {
    const { userInfo } = this.state;
    let subjectList = [];
    let subjects = userInfo.items;
    let subKey = Object.keys(subjects);

    subKey.map((subject, idx) => {
      userInfo.items[subject].grade.map((grade) => {
        let termsList = [];
        let gradeList = userInfo.items[subject].test;
        if (gradeList && gradeList[grade]) {
          Object.keys(gradeList[grade]).map((terms) => {
            let testList = [];
            gradeList[grade][terms].map((tests) => {
              testList.push({
                id: tests,
                test: this.getNameById(tests),
                tQCount: this.getQCountById(tests),
              });
            });

            termsList.push({
              term: { id: terms, name: this.getNameById(terms) },
              test: testList,
              totHrPerDay: 0,
              totHrPerWeek: 0,
            });
          });
        }

        subjectList.push({
          id: subject,
          name: this.getNameById(subject),
          gradeId: grade,
          gradeName: this.getNameById(grade),
          testList: termsList,
          totalTimeSpent: 0,
          totalScore: 0,
          totGoalHourPerWeek: 0,
        });
      });
    });
    this.setState(
      {
        viewGoalBySubGrade: subjectList,
      },
      () => {
        this.getTestCurrentProgress();
        this.getTimeSpent();
        this.getGoals();
      }
    );
  };

  getNameById(id) {
    const { userInfo } = this.state;
    return userInfo.names[id];
  }

  getQCountById(id) {
    const { userInfo } = this.state;
    let questions = userInfo.questions;
    return questions[id] ? questions[id].total : 0;
  }

  getTestCurrentProgress = () => {
    const { userId, viewGoalBySubGrade, userInfo } = this.state;

    let queryParam = `?userID=${userId}`;

    let data = {
      method: "GET",
      url: "TEST_CURRENT_PROGRESS",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        let subjectGrade = result.data;

        if (subjectGrade.length > 0) {
          subjectGrade.map((obj, index) => {
            let totalScore = 0;
            let sIdx = viewGoalBySubGrade.findIndex((element) => {
              return (
                obj._id.class == element.id && obj._id.topic == element.gradeId
              );
            });
            if (sIdx != -1) {
              obj.tests.map((test) => {
                let totQuesCount = this.getQCountById(test.test);
                let testScore = test.score > 0 ? test.score : 0;
                let score =
                  (testScore / (totQuesCount * envConfig.CORRECT_ANSWER_SCORE)) *
                  100; //CONFIRM
                totalScore = totalScore + Math.round(score);

                viewGoalBySubGrade[sIdx].testList.map((terms) => {
                  terms.test.map((tests, tIdx) => {
                    if (tests.id == test.test) {
                      terms.test[tIdx] = {
                        id: tests.id,
                        test: tests.test,
                        correct: test.correct,
                        question: test.question,
                        score: test.score,
                        session: test.session,
                        tQCount: tests.tQCount,
                      };
                    }
                  });
                });
              });
              viewGoalBySubGrade[sIdx].totalScore = totalScore;
            }
          });
        }
        this.setState({ viewGoalBySubGrade, showReport: true });
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );
  };

  getTimeSpent = () => {
    const { userId, viewGoalBySubGrade } = this.state;
    let queryParam = `?userID=${userId}`;

    let data = {
      method: "GET",
      url: "TEST_TIMESPENT_INWEEK",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        let subjectGrade = result.data;

        if (subjectGrade.length > 0) {
          subjectGrade.map((obj, index) => {
            let totalTimeSpent = 0;
            let sIdx = viewGoalBySubGrade.findIndex((element) => {
              return (
                obj._id.class == element.id && obj._id.topic == element.gradeId
              );
            });
            if (sIdx != -1) {
              obj.tests.map((test) => {
                if (test.timeSpent) {
                  totalTimeSpent = totalTimeSpent + test.timeSpent;
                }
              });

              viewGoalBySubGrade[sIdx].totalTimeSpent = totalTimeSpent;
            }
          });
        }

        this.setState({
          viewGoalBySubGrade,
        });
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );
  };

  getGoals = () => {
    const { classId, topicId } = this.props;
    const { userId, viewGoalBySubGrade } = this.state;
    let queryParam = `?userID=${userId}`;

    let data = {
      method: "GET",
      url: "GET_GOALS",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);

        let subjectGrade = result.data;

        if (subjectGrade.length > 0) {
          subjectGrade.map((obj, index) => {
            obj.goals.map((goal) => {
              let sIdx = viewGoalBySubGrade.findIndex((element) => {
                return (
                  obj._id.class == element.id &&
                  obj._id.topic == element.gradeId
                );
              });
              if (sIdx != -1) {
                let totGoalHour = 0;
                goal.goals.map((tests) => {
                  viewGoalBySubGrade[sIdx].testList.map((terms, tIdx) => {
                    let totHrPerDay = 0;
                    let totHrPerWeek = 0;
                    //let spentHr = 0;
                    if (terms.term.id == goal._id.skill) {
                      terms.test.map((termtest) => {
                        if (termtest.id == tests.test) {
                          termtest.goal = tests;
                          totHrPerDay = totHrPerDay + tests.hourPerDay;
                          totHrPerWeek = totHrPerWeek + tests.hourPerWeek;
                        }
                      });
                    }
                    totGoalHour = totGoalHour + totHrPerWeek;
                    terms.totHrPerDay = totHrPerDay;
                    terms.totHrPerWeek = totHrPerWeek;
                  });
                });
                viewGoalBySubGrade[sIdx].totGoalHourPerWeek = totGoalHour;
              }
            });
          });
        }

        this.setState(
          {
            viewGoalBySubGrade,
          },
          () => {
            let sIdx = viewGoalBySubGrade.findIndex((element) => {
              return classId == element.id && topicId == element.gradeId;
            });
            if (sIdx != -1) {
              let scoreGrade = {
                scoreGrade: common.getScoreCategory(
                  viewGoalBySubGrade[sIdx].totalScore
                ),
                scoreHour: viewGoalBySubGrade[sIdx].totalTimeSpent,
              };
              this.setState({
                scoreGrade,
                showReport: true,
              });
            }
          }
        );
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );
  };

  render() {
    const { showReport, scoreGrade, userPlan } = this.state;
    return (
      <div>
        {!showReport ? (
          <div className="elkStatusBar elk-pn">
            <img
              src={common.loadImg("loader.gif")}
              alt={"Loader"}
              className="img-fluid"
            />
            <p>{translation.loading_report}</p>
          </div>
        ) : (
          <div>
            <h5 className="text-right mb-0">Hours</h5>
            <GradeTable score={scoreGrade} />
            <div className="set-your-goals d-flex flex-wrap justify-content-between align-items-center mt-3 mb-5">
              <div className="goal-text">
                <img src={common.loadImg("youreontheright_img.png")} />
                <h6 className="mb-0">
                  {translation.path_commits}
                  <br />
                  {translation.perfect_score}
                </h6>
              </div>
              {userPlan && userPlan.plan && userPlan.plan.planId !== "free" && userPlan.paymentStatus !== "expired" && (
                <a
                  href="#"
                  className="btn-sm"
                  onClick={() => this.props.onSetGoal()}
                >
                  {translation.set_your_goals}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default MyGoalReports;
