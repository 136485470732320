// live: examplelk.org
export const payhereConst = {
    sandbox: false,
    host: "examplelk.org",
    merchantId: "217443",
    checkoutReturnUrl: undefined,
    checkoutCancelUrl: undefined,
    checkoutNotifyUrl: "https://examplelk.org:3006/api/v1/webhook/payment/payhere",
    preApproveUrl: "https://www.payhere.lk/pay/preapprove",
    preApproveReturnUrl: "https://examplelk.org/payhere/return",
    preApproveCancelUrl: "https://examplelk.org/payhere/cancel",
    preApproveNotifyUrl: "https://examplelk.org:3006/api/v1/webhook/payment/payhere/automated",
}

// sandbox: localhost
// export const payhereConst = {
//     sandbox: true,
//     host: "localhost",
//     merchantId: "1217211",
//     checkoutReturnUrl: undefined,
//     checkoutCancelUrl: undefined,
//     checkoutNotifyUrl: "https://examplelk.org:3006/api/v1/webhook/payment/payhere",
//     preApproveUrl: "https://sandbox.payhere.lk/pay/preapprove",
//     preApproveReturnUrl: "http://localhost:4200/payhere/return",
//     preApproveCancelUrl: "http://localhost:4200/payhere/cancel",
//     preApproveNotifyUrl: "https://examplelk.org:3006/api/v1/webhook/payment/payhere/automated",
// }

// demo.examplelk.org
// export const payhereConst = {
//     sandbox: true,
//     host: "demo.examplelk.org",
//     merchantId: "1217211",
//     checkoutReturnUrl: undefined,
//     checkoutCancelUrl: undefined,
//     checkoutNotifyUrl: "https://examplelk.org:3006/api/v1/webhook/payment/payhere",
//     preApproveUrl: "https://sandbox.payhere.lk/pay/preapprove",
//     preApproveReturnUrl: "http://demo.examplelk.org/payhere/return",
//     preApproveCancelUrl: "http://demo.examplelk.org/payhere/cancel",
//     preApproveNotifyUrl: "https://examplelk.org:3006/api/v1/webhook/payment/payhere/automated",
// }