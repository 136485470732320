import React from "react";
import { common, tools } from "helpers";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class Faq extends React.Component {
	componentDidMount() {
		tools.scrollTop();
	}

	render() {
		return (
			<div className="main-content">
				<Helmet>
					<title>Help Center</title>
				</Helmet>
				<div className="support-wrap">
					<div className="container">
						<div className="faq-heading pb-4">
							<h2 className="mb-3">
								{/* {translation.help_center} */}
								Help Center
							</h2>
							<div className="form-group has-search">
								<img
									src={common.loadImg("search-icon.png")}
									alt="Search"
									className="icon-search"
								/>
								<input
									type="text"
									className="form-control"
									placeholder="What are you looking for?"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-3 col-sm-6">
								<div className="support-block d-flex align-items-center">
									<img
										src={common.loadImg("my-account.png")}
										alt="My Account"
									/>
									<h6>{translation.my_account}</h6>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6">
								<div className="support-block d-flex align-items-center">
									<img
										src={common.loadImg("credit-card.png")}
										alt="Payment and Subscription"
									/>
									<h6>{translation.payments_and_subscriptions}</h6>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6">
								<div className="support-block d-flex align-items-center">
									<img
										src={common.loadImg("checklist.png")}
										alt="Test Related"
									/>
									<h6>{translation.test_related}</h6>
								</div>
							</div>
							<div className="col-lg-3 col-sm-6">
								<div className="support-block d-flex align-items-center">
									<img
										src={common.loadImg("chat.png")}
										alt="Feedback and Contact"
									/>
									<h6>{translation.feedback_and_relationships}</h6>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* <!-- Inquiry Form Wrap --> */}
				<div className="faq-wrap pb-5">
					<div className="container-fluid">
						<div className="accordion mb-5" id="accordionExample">
							<div className="card">
								<div className="card-head" id="headingOne">
									<h2
										className="mb-0"
										data-toggle="collapse"
										data-target="#collapseOne"
										aria-expanded="true"
										aria-controls="collapseOne"
									>
										{translation.question_1}
									</h2>
								</div>

								<div
									id="collapseOne"
									className="collapse show"
									aria-labelledby="headingOne"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_1}</div>
								</div>
							</div>
							<div className="card">
								<div className="card-head" id="headingTwo">
									<h2
										className="mb-0 collapsed"
										data-toggle="collapse"
										data-target="#collapseTwo"
										aria-expanded="false"
										aria-controls="collapseTwo"
									>
										{translation.question_2}
									</h2>
								</div>
								<div
									id="collapseTwo"
									className="collapse"
									aria-labelledby="headingTwo"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_2}</div>
								</div>
							</div>
							<div className="card">
								<div className="card-head" id="headingThree">
									<h2
										className="mb-0 collapsed"
										data-toggle="collapse"
										data-target="#collapseThree"
										aria-expanded="false"
										aria-controls="collapseThree"
									>
										{translation.question_3}
									</h2>
								</div>
								<div
									id="collapseThree"
									className="collapse"
									aria-labelledby="headingThree"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_3}</div>
								</div>
							</div>
							<div className="card">
								<div className="card-head" id="headingFour">
									<h2
										className="mb-0 collapsed"
										data-toggle="collapse"
										data-target="#collapseFour"
										aria-expanded="false"
										aria-controls="collapseFour"
									>
										{translation.question_4}
									</h2>
								</div>
								<div
									id="collapseFour"
									className="collapse"
									aria-labelledby="headingFour"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_4}</div>
								</div>
							</div>
							<div className="card">
								<div className="card-head" id="headingFive">
									<h2
										className="mb-0 collapsed"
										data-toggle="collapse"
										data-target="#collapseFive"
										aria-expanded="false"
										aria-controls="collapseFive"
									>
										{translation.question_5}
									</h2>
								</div>
								<div
									id="collapseFive"
									className="collapse"
									aria-labelledby="headingFive"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_5}</div>
								</div>
							</div>
							<div className="card">
								<div className="card-head" id="headingSix">
									<h2
										className="mb-0 collapsed"
										data-toggle="collapse"
										data-target="#collapseSix"
										aria-expanded="false"
										aria-controls="collapseSix"
									>
										{translation.question_6}
									</h2>
								</div>
								<div
									id="collapseSix"
									className="collapse"
									aria-labelledby="headingSix"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_6}</div>
								</div>
							</div>
							<div className="card">
								<div className="card-head" id="headingSeven">
									<h2
										className="mb-0 collapsed"
										data-toggle="collapse"
										data-target="#collapseSeven"
										aria-expanded="false"
										aria-controls="collapseSeven"
									>
										{translation.question_7}
									</h2>
								</div>
								<div
									id="collapseSeven"
									className="collapse"
									aria-labelledby="headingSeven"
									data-parent="#accordionExample"
								>
									<div className="card-body">{translation.answer_7}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Inquiry Form Wrap End --> */}
			</div>
		);
	}
}

export default Faq;
