import React from "react";
import { common, tools } from "helpers";
import { Helmet } from "react-helmet";
class WhatExampleCanOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      translation: common.getTranslation(),
    };
  }

  componentDidMount = () => {
    tools.scrollTop();
  };

  render() {
    const {
      translation
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>What Example Can Offer</title>
        </Helmet>
        {/* <!-- Breadcrumb Wrap --> */}
        {/* <div className="breadcrumb-wrap about why-example">
          <div className="container">
            <h1>ACE YOUR GCE OL & AL EXAM WITH ‘EXAMPLE’</h1>

            <p>
              The Example <sup>TM</sup> is built with a collection of GCE OL &
              AL exam papers f rom the past 20 years. The platform contains over
              10000 mock exam Questions & Answers with explanations and video
              tutorials. The Example TM is made for you to become smarter! You
              only need to trust the system & follow the instructions that comes
              with the product. The Example will allow students to build a plan,
              setting Goals & Objectives to became an expert and students can
              register and participate on real time Exams competing with other
              registered students.
            </p>
          </div>
        </div> */}
        <div className="breadcrumb-wrap about analytics-info">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1>{translation.ace_your_goal}</h1>
              </div>
              <div className="col-lg-6">
                <p>
                {translation.formatString(translation.about_us_content, <b>{translation.xample} <sup>{translation.tm}</sup></b>)} 
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Breadcrumb Wrap End --> */}

        {/* <!-- Our Vision Wrap --> */}
        <div className="analytics-content why-example-content my-5 pb-2">
          <div className="container">
            <img
              src={common.loadImg("fourstep-learning.png")}
              className="img-fluid"
            />
            <h4 className="mt-5 mb-4">
              {translation.what_is} <strong> {translation.steps_for_learning}</strong>
              {/* What is <strong>the 4 steps or learning?</strong> */}
            </h4>
            <p>
              {translation.four_steps_for_learning_content}
            </p>
            <p>
              {translation.four_steps_for_learning_content1}
            </p>
            <p></p>

            <h4 className="mt-5 mb-4 stage-title clr-red"><strong>1</strong> <span>{translation.stage1}</span> {translation.stage1_title}</h4>
            <p>
              {translation.stage1_content}
            </p>
            <p>
              <i>{translation.stage1_content1}</i>
            </p>
            <p>
              {translation.stage1_content2}
            </p>
            <p>
              {translation.stage1_content3}
            </p>
            {
              translation.stage1_content4 && 
              <p>
              {translation.stage1_content4}
            </p>
            }

            <h4 className="mt-5 mb-4 stage-title clr-purple"><strong>2</strong> <span>{translation.stage2}</span> {translation.stage2_title}</h4>
            <p>
              {translation.stage2_content}
            </p>
            <p>
              <i>{translation.stage2_content1}</i>
            </p>
            <p>
              {translation.stage2_content2}
            </p>
            <p>
              {translation.stage2_content3}
            </p>
            <p>
              {translation.stage2_content4}
            </p>

            <h4 className="mt-5 mb-4 stage-title clr-blue"><strong>3</strong> <span>{translation.stage3}</span> {translation.stage3_title}</h4>
            <p>
              {translation.formatString(translation.stage3_content, <b>{translation.xample} <sup>{translation.tm}</sup></b>)} 
            </p>
            <p>
            {translation.stage3_content1}
            </p>
            <p>
            {translation.stage3_content2}
            </p>
            <p>
              <i>{translation.stage3_content3}</i>
            </p>
            <p>
              <i>{translation.stage3_content4}</i>
            </p>
            <p>
            {translation.stage3_content5}
            </p>
            {
              translation.stage3_content6 &&
              <p>
              {translation.stage3_content6}
            </p>
            }

            <h4 className="mt-5 mb-4 stage-title clr-green"><strong>4</strong> <span>{translation.stage4}</span> {translation.stage4_title}</h4>
            <p>
            {translation.stage4_content}
            </p>
            <p>
            {translation.stage4_content1}
            </p>

            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-5 mb-4">{translation.setting_goals_and_objectives_title}</h4>
                <p>
                  {translation.setting_goals_and_objectives1}
                </p>
                <p>
                {translation.setting_goals_and_objectives2}
                </p>
                <p>
                {translation.formatString(translation.setting_goals_and_objectives3, <b>{translation.xample} <sup>{translation.tm}</sup></b>)}
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={common.loadImg("setting-goals.png")}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Our Vision Wrap End --> */}
      </div>
    );
  }
}

export default WhatExampleCanOffer;
