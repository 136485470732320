import React from "react";
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from '@material-ui/core/Dialog'
import { common, history, tools } from "helpers";
import { apiServices } from "api";
import NoveltyImage from 'assets/img/novelty-img.png';
import closeImg from "assets/img/revisionques_wrong.png";
import startTrailImg from "assets/img/start-14-days-trail.png";
// Payment card images
import visacardImg from 'assets/img/visa.png';
import mastercardImg from 'assets/img/mastercard.png';
import americanexpressImg from 'assets/img/americanexpress.png';
import discovercardImg from 'assets/img/discover.png';
import dinersclubcardImg from 'assets/img/dinersclub.png';
import geniecardImg from 'assets/img/genie.png';
import ezcashcardImg from 'assets/img/ezcash.png';
import payment1cardImg from 'assets/img/payment1.png';
import close1Img from 'assets/img/close1.png';

let translation = common.getTranslation();
class PricingTrial extends React.Component {
  constructor(props) {
    super(props);
    const { match: { params } } = this.props;
    this.state = {
      params,
      isModal: false,
      fields: {
        subscription: "threeMonth",
      },
      sData: {
        planList: [],
        advancedPrice: "1500",
      },
    };

  }

  componentDidMount() {
    this.getAllPlan();
    tools.scrollTop();
  }

  gotoMember(e, type) {
    e.preventDefault();
    const { params, fields } = this.state;
    common.setStorage("pricePlan", type);
    common.setStorage("subscription", fields.subscription);
    common.setStorage("isTrial", true);
    if (params.type == "student") {
      history.push("/register/student");
    } else if (params.type == "parent") {
      history.push("/register/parent");
    } else {
      history.push("/membership");
    }
  }

  getAllPlan = () => {
    const { sData } = this.state;
    let data = {
      url: "PRICING_PLAN_LIST",
      method: "GET",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          sData.planList = tools.ObjectSet(result, "planId");
          let advprice = sData.planList.advanced.price;
          sData.advancedPrice = advprice["threeMonth"].amount;
        }
        this.setState({
          status,
          sData,
        });
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  // handler
  onChange = (e) => {
    const { fields, sData } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    let advprice = sData.planList.advanced.price;
    sData.advancedPrice = advprice[value].amount;
    this.setState({ fields, sData });
  };

  setModal = (status) => {
    this.setState({ isModal: status });
  }

  render() {
    const { isModal, fields, sData } = this.state;
    return (
      <div>
        {/* <!-- Breadcrumb Wrap --> */}
        <div className="breadcrumb-wrap pricing"></div>
        {/* <!-- Breadcrumb Wrap End --> */}

        <div className="pricing-wrap">
          <div className="container-fluid">
            <div className="pricing-page-title d-flex align-items-center">
              <h2>{translation.header_pricing}</h2>
            </div>
            <div className="pricing-page-title d-flex align-items-center justify-content-end">
              <div className="form-group price-select-container">
                <select
                  name="subscription"
                  className="form-control price-select"
                  value={fields.subscription}
                  onChange={(e) => this.onChange(e)}
                >
                  <option value="threeMonth">{translation.threeMonth}</option>
                  <option value="sixMonth">{translation.sixMonth}</option>
                  <option value="year">{translation.year}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="main-content">
            <div className="container-fluid">
              <div className="pricing-tables">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-5 col-md-6">
                    <div className="pricing-table pricing-table1 advanced">
                      <div className="pricing-header">
                        <h3 className="title">
                          Advanced <small className="strikeout-price">{tools.numberWithCommas(sData.advancedPrice * 2)} LKR</small>
                        </h3>

                        <div className="price">
                          <span className="price-text">
                            {translation.introduction} <br />
                            {translation.price}
                          </span>
                          <span className="value">{tools.numberWithCommas(sData.advancedPrice)}</span>
                          <span className="price-lkr">{translation.lkr}</span>
                          {/* <br/> */}
                          <span className="value-small">
                            {/* <b>/mo.</b> */}
                            <b>/{translation[fields.subscription + "_text"]}</b>

                          </span>
                          <div className="row d-flex align-items-center get-20per-discount">
                            <div className="col-lg-6 col-md-6 col-sm-6 discount-col">
                              <span>
                                {/* Get 20% Discount */}
                                {translation.get_20perc_discount}
                              </span> <br />
                              <span className="when-signin">
                                {/* When signup with */}
                                {translation.when_signup_with}
                              </span>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 discount-logo">
                              <span>
                                <img src={NoveltyImage} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="pricing-content">
                        <li>{translation.access_all_subjects}</li>
                        <li>{translation.time_based_test}</li>
                        <li>{translation.free_customer_support}</li>
                        <li>
                          {/* Parent Login can access to 2 or more Child Accounts */}
                          {translation.parent_login_access}
                        </li>
                        <li>{translation.children_progress_reports}</li>
                        <li>{translation.child_progress_notifi}</li>
                      </ul>
                      <div className="pricing-footer">
                        <a
                          href="#"
                          onClick={() => this.setModal(true)}
                        >
                          <p className="signup-with-14-days">
                            {/* Signup with 14 Days Trail */}
                            {translation.signup_14day_trail}
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6">
                    <div className="pricing-table pricing-table1 premium">
                      <div className="pricing-header">
                        <h3 className="title">
                          Premium
                          {/* {translation.premium} */}
                        </h3>
                      </div>
                      <ul className="pricing-content">
                        <li>{translation.access_all_subjects}</li>
                        <li>{translation.time_based_test}</li>
                        <li>{translation.premium_live_chat}</li>
                        <li>{translation.premium_parent_access}</li>
                        <li>{translation.children_progress_reports}</li>
                        <li>{translation.child_progress_notifi}</li>
                      </ul>
                      <div className="pricing-footer">
                        <a
                          href="#"
                          onClick={(e) => this.gotoMember(e, "premium")}
                          className="not-active-text"
                        >
                          <p className="premium-coming-soon">
                            {/* Coming Soon */}
                            {translation.coming_soon}
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
                <div className="table-responsive my-5">
                  <table className="pricing-info-table pricing-info-table1 w-100">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{translation.advanced}</th>
                        <th>{translation.premium}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colSpan="4">{translation.accessible_for}</th>
                      </tr>
                      <tr>
                        <td>{translation.medium}</td>
                        <td className="price-medium-txt">{translation.selected_medium_only}</td>
                        <td className="price-medium-txt">{translation.selected_medium_only}</td>
                      </tr>
                      <tr>
                        <td>{translation.subjects}</td>
                        <td>{translation.all}</td>
                        <td>{translation.all}</td>
                      </tr>
                      <tr>
                        <td>{translation.time_based_online_tests}</td>
                        <td>{translation.unlimited_sessions}</td>
                        <td>{translation.unlimited_sessions}</td>
                      </tr>
                      <tr>
                        <td>{translation.model_based_online_tests}</td>
                        <td>{translation.unlimited_sessions}</td>
                        <td>{translation.unlimited_sessions}</td>
                      </tr>
                      <tr>
                        <td>{translation.login_devices}</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>{translation.technical_support_helpline}</td>
                        <td>
                          {translation.formatString(translation.per_month_text,
                            8
                          )}
                        </td>
                        <td>
                          {translation.formatString(translation.per_month_text,
                            20
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.technical_support_tickets}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="4">{translation.progress_report}</th>
                      </tr>
                      <tr>
                        <td>{translation.subject_based}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.terms_and_module_based}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.activity_history}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.set_goals_and_timeline}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.attendance_and_notification}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.notifications_and_reminders}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.track_goals}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.quaterly_tests}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="4">{translation.parent_login}</th>
                      </tr>
                      <tr>
                        <td>{translation.access_child_acc}</td>
                        <td>1</td>
                        <td>{translation.unlimited}</td>
                      </tr>
                      <tr>
                        <td>{translation.manage_child_acc}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.progress_attendance_tracking}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.notifications_progress}</td>
                        <td>
                          <span className="active"></span>
                        </td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="4">{translation.tutorials_support}</th>
                      </tr>
                      <tr>
                        <td>{translation.video_tutorials}</td>
                        <td>-</td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.teacher_support}</td>
                        <td>-</td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.ask_teacher}</td>
                        <td>-</td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="4">{translation.study_groups}</th>
                      </tr>
                      <tr>
                        <td>{translation.challenge_friend}</td>
                        <td>-</td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                      <tr>
                        <td>{translation.community_leader_board}</td>
                        <td>-</td>
                        <td>
                          <span className="active"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={isModal}
          aria-labelledby="responsive-dialog-title"
          maxWidth="lg"
          className="payment-popup-container"
          onClose={() => this.setModal(false)}
        >
          {/* <DialogTitle className="card-heading">
              Set backup account
            </DialogTitle> */}
          <DialogContent className="payment-popup-inner">
            <span className="terms-conditions-close payment-popup-close">
              <img
                src={close1Img}
                alt=""
                onClick={() => this.setModal(false)}
              />
            </span>
            <div className="container">
              <div className="row align-items-center">
                <div className="col col-lg-5 trail-payment">
                  <img
                    src={startTrailImg}
                    className="start-trail-img"
                    alt=""
                  />
                  <p className="trail-img-text">
                    <div>Start your</div> <br />
                    <div className="no-txt">14-Days</div> <br />
                    <div>Free Trial</div>
                  </p>
                  <p className="trail-img-text-bottom">
                    <div>Xample accepts major</div> <br />
                    <div>debit and credit cards</div>
                  </p>
                  <p>
                    <button
                      className="trail-payment-cnbtn"
                      onClick={(e) => this.gotoMember(e, "advanced")}
                    >
                      {/* Continue */}
                      {translation.continue}
                    </button>
                  </p>
                </div>
                {/* <div className="col-lg-2 responsive-divider"></div>
                <div className="col col-lg-5 premium-payment">
                  <div className="premium-payment-header">
                    <span className="terms-conditions-close payment-popup-close">
                      <img
                        src={close1Img}
                        alt=""
                        onClick={() => this.setModal(false)}
                      />
                    </span>
                    <div>
                      <img className="payment-example-logo" src={common.loadImg("logo.png")} alt="Examplelk" />
                    </div>
                    <div className="premium-payment-header-right">
                      <p className="payment-method-title">Clients & Consumers...</p>
                      <p className="payment-plan-name">Advanced</p>
                      <p className="payment-plan-price">Rs.50.00</p>
                    </div>
                  </div>
                  <div className="premium-payment-body">
                    <p className="select-payment-method">Select a payment method</p>
                    <p className="credit-debit-card">Credit/Debit Card</p>
                    <ul className="payment-card-type">
                      <li>
                        <img src={visacardImg} alt="visaCard" />
                      </li>
                      <li>
                        <img src={mastercardImg} alt="visaCard" />
                      </li>
                      <li>
                        <img src={americanexpressImg} alt="visaCard" />
                      </li>
                      <li>
                        <img src={discovercardImg} alt="visaCard" />
                      </li>
                      <li>
                        <img src={dinersclubcardImg} alt="visaCard" />
                      </li>
                    </ul>
                    <p className="credit-debit-card">Mobile Wallet</p>
                    <ul className="payment-card-type">
                      <li>
                        <img src={geniecardImg} alt="visaCard" />
                      </li>
                      <li>
                        <img src={ezcashcardImg} alt="visaCard" />
                      </li>
                    </ul>
                    <p className="credit-debit-card">Internet Banking</p>
                    <ul className="payment-card-type">
                      <li>
                        <img src={payment1cardImg} alt="visaCard" />
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default PricingTrial;