import React from "react";
import { common, history } from "helpers";
import { Link } from "react-router-dom";

export default class SubjectMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: common.authInfo().subject ? common.authInfo().subject : [],
      subjectName: "",
      grades: [],
      selectedSubject: props.selectedSubject ? props.selectedSubject : "",
      selectedGrade: props.selectedGradeId ? props.selectedGradeId : "",
      userData: common.authInfo(),
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount = () => {
    const { subject, userData, selectedSubject } = this.state;
    var filteredSubject = userData.subject.filter(function (item) {
      return item._id === selectedSubject;
    });

    if (subject && subject.length > 0) {
      this.setState({
        subjectName: filteredSubject[0].name,
        grades: filteredSubject[0].grade,
        selectedGrade: this.props.selectedGradeId
          ? this.props.selectedGradeId
          : filteredSubject[0].grade[0]._id,
      });
    }
  };

  componentDidUpdate() {
    const { selectedGrade } = this.state;
    if (selectedGrade != this.props.selectedGradeId) {
      this.setState({
        selectedGrade: this.props.selectedGradeId,
      });
    }
  }

  onChangeSubject = (id) => {
    const { subject } = this.state;
    var filteredSubject = subject.filter(function (item) {
      return item._id === id;
    });
    this.setState(
      {
        selectedSubject: id,
        subjectName: filteredSubject[0].name,
        grades: filteredSubject[0].grade,
        selectedGradeId:
          filteredSubject.length > 0 ? filteredSubject[0].grade[0]._id : "",
      },
      () => {
        this.props.onChangeSubject(
          id,
          filteredSubject[0].grade,
          filteredSubject[0].grade[0]._id
        );
      }
    );
  };

  setGrade = (e) => {
    e.preventDefault();
    const { value } = e.target;
    this.setState({ selectedGrade: value }, () => {
      if (this.props.page && this.props.page === "test") {
        history.push("/subject/" + this.state.selectedSubject + "/" + value);
      } else {
        this.props.setGrade(value);
      }
    });
  };

  render() {
    const {
      subject,
      subjectName,
      selectedSubject,
      grades,
    } = this.state;
    return (
      <div>
        <ul className="nav nav-tabs lined text-center">
          {subject &&
            subject.length > 0 &&
            subject.map((item, index) => {
              return (
                <li className="nav-item" key={index}>
                  <Link
                    onClick={(e) => this.onChangeSubject(item._id)}
                    to={"/subject/" + item._id}
                    className={
                      item._id == selectedSubject
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
        </ul>
        {this.props.page == undefined && (
          <div className="topics-filter">
          <div className="container">
            <div className="filter d-flex flex-wrap justify-content-between align-items-center">
              <h5 className="my-0">{subjectName}</h5>
              <form className="form-inline">
                <div className="form-group mb-0">
                  <label>Select Grade</label>
                  <select
                    name="selectedGrade"
                    className="form-control"
                    onChange={(e) => this.setGrade(e)}
                  >
                    {grades &&
                      grades.length > 0 &&
                      grades.map((obj, index) => {
                        return (
                          <option value={obj._id} key={index}>
                            {obj.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}
