import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
// helpers
import { common, history, tools, payhereConst } from "helpers";
import { apiServices } from "api";
import { envConfig } from "api/config";
import { StatusBar } from "includes";
import { Fragment } from "react";
let translation = common.getTranslation();

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      paymentStatus: "process",
      isLoading: false,
      isPaymentLoading: false,
      isPromoLoading: false,
      isAutoRenewal: false,
      formSubmit: false,
      fields: {
        plan: "",
        subscription: "",
      },
      sData: {
        userInfo: [],
        planList: [],
        paymentList: []
      },
      userPlan: this.props.userPlan,
      userData: common.authInfo(),
      isPromoCode: false,
      promoCode: "",
      promoCodeDiscount: 0,
      promoDetails: ""
    };
  }

  // life
  componentDidMount() {
    const { userData, userPlan } = this.state;
    if (userData.user.role === 2) {
      history.push("/");
    } else {
      this.getUserInfo();
      this.paymentAction();
    }
  }

  // api
  getUserInfo = () => {
    const { sData } = this.state;
    let data = {
      method: "GET",
      url: "USER_INFO",
      key: {
        userId: common.getStorage("userId")
      },
    };

    apiServices.call(
      data,
      (result) => {
        let status = common.apiStatus(result);
        if (status == "success") {
          sData.userInfo = common.apiResultData(result).data;
          this.setState({ sData }, () => {
            this.getPaymentHistory();
          });
        } else {
          this.setStatus("error");
        }
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  getPaymentHistory = () => {
    const { sData } = this.state;
    let queryParam = `?userId=${common.getStorage("userId")}`;
    let data = {
      method: "GET",
      url: "PAYMENT_PAYHERE_USER_INFO",
      query: queryParam,
    };
    apiServices.call(
      data,
      (res) => {
        let paymentStatus = common.apiStatus(res);
        sData.paymentList = common.apiResult(res);
        this.setState({
          paymentStatus,
          sData
        }, () => {
          this.getAllPlan();
        })
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  getAllPlan = () => {
    const { sData } = this.state;
    let data = {
      url: "PRICING_PLAN_LIST",
      method: "GET"
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          sData.planList = tools.ObjectSet(result, "planId");
        }
        this.setState({
          status,
          sData
        })
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  // page support
  getPriceDetails() {
    const { fields, sData, promoCodeDiscount } = this.state;
    let result = {
      price: 0,
      discount: 0,
      total: 0,
      promoCode: 0,
      org_price: 0,
      org_discount: 0
    }
    if (fields.plan != "" && fields.plan != "free" && fields.subscription != "") {
      let planData = sData.planList[fields.plan].price[fields.subscription];
      let promoDiscount = (planData.amount * promoCodeDiscount) / 100;
      result = {
        price: tools.currencyFormat(planData.amount),
        discount: tools.currencyFormat(planData.discount),
        promoCode: tools.currencyFormat(promoDiscount),
        total: promoCodeDiscount > 0 ? tools.currencyFormat((planData.amount - planData.discount) - promoDiscount) : tools.currencyFormat(planData.amount - planData.discount),
        amount: promoCodeDiscount > 0 ? ((planData.amount - planData.discount) - promoDiscount) : (planData.amount - planData.discount).toFixed(2),
        org_price: planData.amount,
        org_discount: planData.discount
      }
    }
    return result;
  }

  // handler
  onChange = (e) => {
    const { fields } = this.state;
    const { name, value } = e.target;
    fields[name] = value;
    this.setState({ fields });
  }

  handleCheckboxChange = (event, name) => {
    this.setState({ [name]: event.target.checked });
    if (name == "isPromoCode" && event.target.checked == false) {
      this.setState({
        promoCode: "",
        promoCodeDiscount: 0
      })
    }
  };

  // validate
  validateAll() {
    const { fields } = this.state;
    let error = "";
    // plan and child
    if (fields.plan == "") {
      error = "Please select plan";
    } else if (fields.subscription == "") {
      error = "Please select subscription";
    }
    // response
    if (error) {
      let msg = common.getLangMessage(error)
      common.snack("E", msg);
      return false;
    } else {
      return true;
    }
  }

  // submit handler
  onSubmit() {
    if (this.validateAll()) {
      this.setLoading(true);
      this.payAmount();
    }
  }

  // payment gateway
  payAmount() {
    const { fields, sData, userData, promoDetails } = this.state;
    let priceInfo = this.getPriceDetails();
    var payment = {
      "sandbox": payhereConst.sandbox,
      "merchant_id": payhereConst.merchantId,
      "notify_url": payhereConst.checkoutNotifyUrl,
      "return_url": payhereConst.checkoutReturnUrl,
      "cancel_url": payhereConst.checkoutCancelUrl,
      "order_id": moment().valueOf().toString(),
      "items": sData.planList[fields.plan].name,
      "amount": priceInfo.amount,
      "currency": "LKR",
      "first_name": userData.user.name,
      "last_name": "",
      "email": userData.user.email,
      "phone": "",
      "address": "",
      "city": "",
      "country": "",
      "delivery_address": "",
      "delivery_city": "",
      "delivery_country": "",
      "custom_1": common.getStorage("userId"),
      "custom_2": JSON.stringify({
        subscription: fields.subscription,
        planId: sData.planList[fields.plan]._id,
        planName: sData.planList[fields.plan].name,
        host: payhereConst.host,
        promoId: promoDetails.id ?? "",
        promoCode: promoDetails.code ?? "",
        discount: promoDetails.discountValue ?? "",
        promoType: promoDetails.type ?? ""
      })
    };
    setTimeout(() => {
      window.payhere.startPayment(payment);
    }, 0);
  }

  paymentAction() {
    window.payhere.onCompleted = (orderId) => {
      this.setPaymentLoading(true);
      setTimeout(() => {
        let create = false;
        // check order
        let data = {
          url: "PAYMENT_PAYHERE_INFO",
          query: "?orderId=" + orderId,
          method: "GET",
          authorization: "none"
        };
        apiServices.call(
          data,
          (res) => {
            let status = common.apiStatus(res);
            let result = common.apiResultData(res);
            if (status == "success") {
              if (result.data != null) {
                create = true;
                common.redirect("/payment", 0);
              }
            }
          },
          (error) => { },
          (final) => {
            if (!create) {
              common.snack("E", translation.payment_declined);
              this.setLoading(false);
              this.setPaymentLoading(false);
            }
          }
        );
      }, 1000);
    };

    window.payhere.onDismissed = () => {
      common.snack("E", translation.payment_cancelled);
      this.setLoading(false);
    };

    window.payhere.onError = () => {
      common.snack("E", translation.payment_error);
      this.setLoading(false);
    };
  }

  applyPromoCode = () => {
    const { promoCode } = this.state;
    if (promoCode === "") {
      let msg = common.getLangMessage("Please enter the promocode");
      common.snack("E", msg);
    } else {
      this.promoLoading(true);
      let data = {
        url: "PROMO_CODE",
        query: promoCode,
        method: "GET",
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResult(res);
          if (status == "success") {
            let details = {
              id: result._id,
              code: result.name,
              discountValue: result.discount,
              type: "xample"
            }
            this.setState({
              promoCodeDiscount: result.discount,
              promoDetails: details
            })
            common.snack("S", "Promo code applied successfully");
            this.promoLoading(false);
          } else {
            this.checkToNovelty();
          }
        },
        (error) => {
          this.promoLoading(false);
        },
        (final) => { }
      );
    }
  }

  checkToNovelty = () => {
    const { fields, promoCode } = this.state;
    let data = {
      "email": fields.email,
      "promo_code": promoCode
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(envConfig.NOVELTY_PROMO_CODE_API, requestOptions)
      .then((response) =>
        response.json()
      )
      .then((result) => {
        if (result.status == "FAILURE") {
          common.snack("E", result.message.email);
        } else {
          if (result.data.is_valid_promo_code && result.data.is_valid_user) {
            let details = {
              id: result.data.offer.id,
              code: result.data.promo_code,
              discountValue: 20,
              type: "novelty"
            }
            this.setState({
              promoCodeDiscount: 20,
              promoDetails: details
            })
            common.snack("S", "Promo code applied successfully");
          } else {
            if (!result.data.is_valid_promo_code && !result.data.is_valid_user) {
              common.snack("E", "The provided promo code not valid. Please try again with valid one");
            } else if (!result.data.is_valid_promo_code) {
              common.snack("E", "The provided promo code not valid. Please try again with valid one");
            } else if (!result.data.is_valid_user) {
              common.snack("E", "This provide email address not registered in Novelty to use this promocode");
            }
            this.setState({
              promoCodeDiscount: 0,
              promoDetails: {}
            })
          }
        }
      }).catch((err) => {
        common.snack("E", "Failed to fetch promo code please try again");
      }).finally(() => {
        this.promoLoading(false);
      });
  }

  // auto renewal
  onAutoRenewalPayment(e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui delete-confirmation text-center">
            <h1>Payment Confirmation</h1>
            <p>Are you sure you want to pay immediately?</p>
            <div className="d-flex justify-content-center">
              <button className="popup-btn noBtn" onClick={onClose}>
                No
              </button>
              <button
                className="popup-btn yesBtn"
                onClick={() => {
                  this.autoRenewalPayment();
                  onClose();
                }}
              >
                Yes, Confirm
              </button>
            </div>
          </div>
        );
      },
    });
  }

  onAutoRenewalCancel(e) {
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui delete-confirmation text-center">
            <h1>Cancel Confirmation</h1>
            <p>Are you sure you want to cancel this auto renewal?</p>
            <div className="d-flex justify-content-center">
              <button className="popup-btn noBtn" onClick={onClose}>
                No
              </button>
              <button
                className="popup-btn yesBtn"
                onClick={() => {
                  this.autoRenewalCancel();
                  onClose();
                }}
              >
                Yes, Confirm
              </button>
            </div>
          </div>
        );
      },
    });
  }

  autoRenewalPayment = () => {
    this.autoRenewalLoading(true);
    let data = {
      url: "PAYMENT_PAYHERE_AUTO_MANUAL",
      body: {
        userId: common.getStorage("userId"),
        cardId: "",
        type: "auto"
      },
      method: "POST"
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status == "success") {
          common.snack("S", "Payment has been done");
          common.redirect("/payment", 0);
        } else {
          common.snack("E", "Payment error, please try again");
        }
      },
      (error) => {
        common.snack("E", error.toString());
      },
      (final) => {
        this.autoRenewalLoading(false);
      }
    );
  }

  autoRenewalCancel = () => {
    this.autoRenewalLoading(true);
    let data = {
      url: "PAYMENT_PAYHERE_AUTO_CANCEL",
      body: {
        userId: common.getStorage("userId")
      },
      method: "POST"
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status == "success") {
          common.snack("S", "Autorenewal has been cancelled");
          common.redirect("/payment", 0);
        } else {
          common.snack("E", "Cancellation error, please try again");
        }
      },
      (error) => {
        common.snack("E", error.toString());
      },
      (final) => {
        this.autoRenewalLoading(false);
      }
    );
  }

  // support function
  setStatus(status) {
    this.setState({ status })
  }

  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  setPaymentLoading(status) {
    this.setState({ isPaymentLoading: status })
  }

  promoLoading(status) {
    this.setState({ isPromoLoading: status });
  }

  autoRenewalLoading(status) {
    this.setState({ isAutoRenewal: status });
  }

  render() {
    const { status, paymentStatus, isLoading, isPaymentLoading, userPlan, fields, sData, isPromoCode, isPromoLoading, promoCode, promoCodeDiscount, isAutoRenewal } = this.state;
    return (
      <div className="main-content">
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap pb-0 payment-history-wrap">
          <div className="container">
            <div className="card card-4">
              <div className="card-heading primary-bg d-flex justify-content-between align-items-center">
                <div><h2>{translation.payment_history_renewal}</h2></div>
                <Link to="/" className="btn bordered-btn">
                  {translation.back}
                </Link>
              </div>
              <div className="card-body">
                <StatusBar status={status} />
                {status == "success" && (
                  <Fragment>
                    {/* Card & Subscription */}
                    {(userPlan.paymentStatus !== "active" || userPlan.paymentGateway !== "payhere" || userPlan.gracePeriod !== null) && (
                      <div className="row mx-0">
                        <div className="col-sm-8 px-0">
                          {isPaymentLoading ? (
                            <div className="paymentProcessing elk-rt25">
                              <img src={common.loadImg("payment_loader.gif")} />
                              <h5>{translation.payment_processing}</h5>
                              <p>{translation.refresh_page}</p>
                            </div>
                          ) : isAutoRenewal && (
                            <div className="paymentProcessing elk-rt25">
                              <img src={common.loadImg("payment_loader.gif")} />
                              <h5>One moment please...</h5>
                              <p>{translation.refresh_page}</p>
                            </div>
                          )}

                          <div className={`renewal-card-left${(isPaymentLoading || isAutoRenewal) ? " paymentEvent" : ""}`}>
                            <div>
                              <div className="card-row d-flex justify-content-between align-items-center">
                                <h5>Your Current Plan</h5>
                              </div>
                              <div className="table-responsive">
                                <div>
                                  {userPlan.paymentGateway === "free" ? (
                                    <table className="table table-2">
                                      <thead>
                                        <tr>
                                          <th>Plan</th>
                                          <th>Subscription</th>
                                          <th>Amount</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>FREE</td>
                                          <td>-</td>
                                          <td>-</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  ) : userPlan.paymentGateway === "trial" ? (
                                    <table className="table table-2">
                                      <thead>
                                        <tr>
                                          <th>Plan</th>
                                          <th>Subscription</th>
                                          <th>Amount</th>
                                          <th>Trial End Date</th>
                                          <th>Auto Renewal</th>
                                          <th>Payment</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>14 Days Trial</td>
                                          <td>{envConfig.payment[sData.userInfo.trialPlan.subscription]}</td>
                                          <td>{`${sData.userInfo.trialPlan.currency} ${sData.userInfo.trialPlan.amount}`}</td>
                                          <td>{moment(sData.userInfo.trialPeriod).format("MMM DD, YYYY")}</td>
                                          <td>
                                            <input
                                              className="btn-sm"
                                              onClick={(e) => this.onAutoRenewalCancel(e)}
                                              type="button"
                                              disabled={isAutoRenewal}
                                              value="Cancel"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              className="btn-sm"
                                              onClick={(e) => this.onAutoRenewalPayment(e)}
                                              type="button"
                                              disabled={isAutoRenewal}
                                              value="Pay Now"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  ) : userPlan.paymentGateway === "payhere" && (
                                    <table className="table table-2">
                                      <thead>
                                        <tr>
                                          <th>Plan</th>
                                          <th>Subscription</th>
                                          <th>Amount</th>
                                          <th>Plan Start Date</th>
                                          <th>Plan End Date</th>
                                          <th>Grace Period</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>{userPlan.plan.name}</td>
                                          <td>{envConfig.payment[userPlan.payment.subscription]}</td>
                                          <td>{`${userPlan.payment.payhereCurrency} ${userPlan.payment.payhereAmount}`}</td>
                                          <td>{moment(userPlan.payment.startDate).format("MMM DD, YYYY")}</td>
                                          <td>{moment(userPlan.payment.endDate).format("MMM DD, YYYY")}</td>
                                          <td>{userPlan.gracePeriod ? moment(userPlan.gracePeriod).format("MMM DD, YYYY") : "-"}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="card-row d-flex justify-content-between align-items-center">
                                <h5>{translation.subscription_plan_type}</h5>
                              </div>
                              <React.Fragment>
                                <div className="card-row d-flex justify-content-between align-items-center">
                                  <div className="card-inner-row">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="form-group">
                                          <select
                                            name="plan"
                                            className="form-control"
                                            value={fields.plan}
                                            onChange={(e) => this.onChange(e)}
                                          >
                                            <option value="">{translation.select_plan}</option>
                                            {Object.keys(sData.planList).map((item, key) => {
                                              let data = sData.planList[item];
                                              if (data.planId != "free") {
                                                return (<option value={data.planId} key={key}>{data.name}</option>)
                                              }
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                      {(fields.plan != "" && fields.plan != "free") && (
                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <select
                                              name="subscription"
                                              className="form-control"
                                              value={fields.subscription}
                                              onChange={(e) => this.onChange(e)}
                                            >
                                              <option value="">Select Subscription</option>
                                              {/* <option value="month">{translation.month}</option> */}
                                              <option value="threeMonth">{translation.threeMonth}</option>
                                              <option value="sixMonth">{translation.sixMonth}</option>
                                              <option value="year">{translation.year}</option>
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {fields.plan != "" && fields.plan != "free" && fields.subscription &&
                                  <div>
                                    <div className="form-group custom-control custom-checkbox">
                                      <input
                                        type="checkbox"
                                        name="isPromoCode"
                                        className="custom-control-input"
                                        id="paymentPromoCode"
                                        onChange={(e) => this.handleCheckboxChange(e, "isPromoCode")}
                                        checked={isPromoCode}
                                      />
                                      <label
                                        className="custom-control-label term-cond-label"
                                        htmlFor="paymentPromoCode"
                                      >
                                        Promo Code
                                      </label>
                                    </div>
                                    {isPromoCode &&
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <input
                                              type="text"
                                              name="PromoCode"
                                              value={promoCode}
                                              onChange={(e) => this.setState({ promoCode: e.target.value })}
                                              className="form-control"
                                              placeholder=" "
                                            />
                                            <span className="floating-label">
                                              {"Code"}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <input
                                              className="btn"
                                              onClick={() => this.applyPromoCode()}
                                              type="button"
                                              disabled={isPromoLoading}
                                              value={isPromoLoading ? 'Wait...' : 'Apply'}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  </div>
                                }

                                <div className="form-actions d-flex justify-content-between align-items-center mt-3">
                                  <input
                                    className="btn"
                                    onClick={() => this.onSubmit()}
                                    type="button"
                                    disabled={isLoading || isPromoLoading}
                                    value={isLoading ? "Processing" : ((userPlan.paymentGateway == "free" || userPlan.paymentGateway == "trial") ? "Upgrade Now" : "Renew Now")}
                                  />
                                </div>
                              </React.Fragment>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4 px-0">
                          <div className="renewal-card-right">
                            <div className="plan-block">
                              <div className="price-header">
                                <h5>{translation.price_details}</h5>
                                <p>{translation.all_subjects_included}</p>
                              </div>
                              {[0].map(item => {
                                let priceInfo = this.getPriceDetails();
                                let discountPer = priceInfo.org_discount > 0 ? (priceInfo.org_price / priceInfo.org_discount) : 0;
                                return (<div key={0}>
                                  <div className="price-body">
                                    <div className="price-col d-flex justify-content-between">
                                      <p>{translation.price}</p>
                                      <p className="price-amount">{priceInfo.price} {translation.lkr}</p>
                                    </div>
                                    <div className="price-col d-flex justify-content-between">
                                      <p>{translation.discount}({discountPer} %)</p>
                                      <p className="price-amount">-{priceInfo.discount} {translation.lkr}</p>
                                    </div>
                                    {promoCodeDiscount > 0 &&
                                      <div className="price-col d-flex justify-content-between">
                                        <p>{"Promocode"}{`(${promoCodeDiscount}%)`}</p>
                                        <p className="price-amount">
                                          -{priceInfo.promoCode} {translation.lkr}
                                        </p>
                                      </div>
                                    }
                                  </div>
                                  <div className="price-footer d-flex justify-content-between">
                                    <p>{translation.total}</p>
                                    <p className="price-amount">{priceInfo.total} {translation.lkr}</p>
                                  </div>
                                </div>)
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row mx-0">
                      <div className="col-sm-12 mx-0">
                        <div className="card-row d-flex justify-content-between align-items-center">
                          <h5>Payment History</h5>
                        </div>
                        <StatusBar status={paymentStatus} />
                        {paymentStatus == "success" && (
                          <div className="table-responsive mb-4">
                            <div>
                              <table className="table table-2">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Plan</th>
                                    <th>Amount</th>
                                    <th>Subscription</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sData.paymentList.map((item, key) => {
                                    return (
                                      <tr key={key}>
                                        <td>{++key}</td>
                                        <td>{item.pricingPlan.name}</td>
                                        <td>{item.payhereAmount} {translation.lkr}</td>
                                        <td>{envConfig.payment[item.subscription]}</td>
                                        <td>{moment(item.startDate).format("MMM DD, YYYY")}</td>
                                        <td>{moment(item.endDate).format("MMM DD, YYYY")}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Payment;