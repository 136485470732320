import React from "react";
import SubjectMenu from "includes/pages/SubjectMenu";
import { apiServices } from "api";
import { common, history } from "helpers";
import { StatusBar } from "includes";
import { Link } from "react-router-dom";
// Import Dialod from Material UI
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();

class Subject extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      selectedSubjectId: params.id ? params.id : 0,
      selectedGradeId: params.gradeId ? params.gradeId : 0,
      subjectName: "",
      status: "",
      topics: [],
      chooseOptions: 1,
      isModelOpen: false,
      userData: common.authInfo(),
      selectedYear: new Date().getFullYear() - 1, //Default - set last year
      yearList: common.getYearList(2000, new Date().getFullYear() - 1),
      modalOrPastPaper: false, // true - Model Paper, false - past paper with year
      error: "",
      grade: [],
      gradeName: "",
      termsName: "",
      terms: [],
      isTerms: false,
    };
  }

  /**
   * Component Hooks
   */
  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    const { userData, selectedSubjectId } = this.state;
    //Parent not allowed to access the subject topic list page
    if (userData.user.role === 1) {
      history.push("/settings");
    }
    // else {
    //   var filteredSubject = userData.subject.filter(function (item) {
    //     return item._id === selectedSubjectId;
    //   });

    //   this.setState(
    //     {
    //       selectedGradeId: params.gradeId
    //         ? params.gradeId
    //         : filteredSubject[0].grade[0]._id,
    //       subjectName: filteredSubject[0].name,
    //       grade: filteredSubject[0].grade,
    //       gradeName: filteredSubject[0].grade[0].name
    //     },
    //     () => {
    //       this.getTopics();
    //     }
    //   );
    // }
  };

  onChangeTestSelection = (event, option) => {
    let year = this.state.selectedYear;
    if (option) {
      year = 0;
    } else {
      year = new Date().getFullYear() - 1;
    }
    this.setState({
      modalOrPastPaper: option,
      selectedYear: year,
    });
  };

  handleYearChange = (event) => {
    const { value } = event.target;
    let error = "";
    if (value == 0 && !this.state.modalOrPastPaper) {
      error = "Please select past paper year to start the test";
    }
    this.setState({ selectedYear: value, error });
  };

  setGrade = (id) => {
    this.setState({ selectedGradeId: id }, () => {
      this.getTopics();
    });
  };

  getTopics = () => {
    const { selectedGradeId, selectedSubjectId, userData } = this.state;
    let country = userData.user ? userData.user.country : "india";
    let queryParam = `?country=${country}&class=${selectedSubjectId}&topic=${selectedGradeId}&skip=0&limit=100`;

    this.setState({
      status: "process",
    });
    let data = {
      method: "GET",
      url: "GET_TOPIC_BY_GRADE",
      query: queryParam,
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;

        if (status == "success") {
          result = common.apiResult(res);
        }

        if (result.length > 0) {
          this.setState({
            topics: result,
            status: "success",
            terms: result[0].terms,
            termsName: "",
            isTerms: false,
          });
        } else {
          this.setState({
            status: "empty",
          });
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  testMode = (e, mode) => {
    e.preventDefault();
    let isValid = true;
    const {
      chooseOptions,
      selectedSubjectId,
      selectedGradeId,
      selectedYear,
      modalOrPastPaper,
    } = this.state;
    // chooseOptions 1 - subject overall, 2 - mocktest(past paper with year oe model paper with no year
    // mode true - time based, false - practice based
    let sessionURL = `/session/${selectedSubjectId}/${chooseOptions}/${selectedGradeId}?mode=${mode}`;
    if (chooseOptions === 2) {
      sessionURL =
        sessionURL + `&year=${selectedYear}&paper=${modalOrPastPaper}`;
      if (!modalOrPastPaper && selectedYear == 0) {
        isValid = false;
      }
    }
    if (isValid) {
      history.push(encodeURI(sessionURL));
    } else {
      this.setState({
        error: "Please select past paper year to start the test",
      });
    }
  };

  takeTest = (e) => {
    e.preventDefault();
    this.setState({
      chooseOptions: 1,
      isModelOpen: true,
    });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({
      chooseOptions: 1,
      isModelOpen: false,
    });
  };

  testOption = (e, testOption) => {
    e.preventDefault();
    this.setState({ chooseOptions: testOption, modalOrPastPaper: false });
  };

  onChangeSubject = (selectedSubjectId, grades, selectedGradeId) => {
    const { userData } = this.state;
    var filteredSubject = userData.subject.filter(function (item) {
      return item._id === selectedSubjectId;
    });
    if (selectedSubjectId) {
      this.setState(
        {
          selectedSubjectId,
          selectedGradeId,
          subjectName: filteredSubject[0].name,
        },
        () => {
          this.getTopics();
        }
      );
    }
  };

  onChangeSubjects = (item) => {
    if (item !== null) {
      this.setState({
        subjectName: item.name,
        grade: item.grade,
        selectedSubjectId: item._id,
      });
    } else {
      this.setState({
        subjectName: "",
        grade: [],
        selectedSubjectId: "",
      });
    }

    this.setState({
      selectedGradeId: "",
      gradeName: "",
      termsName: "",
      terms: [],
      topics: [],
      isTerms: false,
    });
  };

  onChangeGrade = (item) => {
    if (item !== null) {
      this.setState(
        {
          gradeName: item.name,
          selectedGradeId: item._id,
        },
        (_) => {
          this.getTopics();
        }
      );
    } else {
      this.setState({
        gradeName: "",
        selectedGradeId: "",
        termsName: "",
        topics: [],
        terms: [],
        isTerms: false,
      });
    }
  };

  onChangeTerm = (item) => {
    const { topics, terms } = this.state;
    if (item !== null) {
      topics[0].terms = [item];
      this.setState({
        termsName: item.term,
        topics: topics,
        isTerms: true,
      });
    } else {
      // topics[0].terms = terms;
      this.setState({
        termsName: "",
        // topics: topics,
        isTerms: false,
      });
    }
  };

  render() {
    const {
      selectedSubjectId,
      selectedGradeId,
      topics,
      status,
      chooseOptions,
      isModelOpen,
      yearList,
      selectedYear,
      subjectName,
      modalOrPastPaper,
      error,
      userData,
      grade,
      gradeName,
      termsName,
      terms,
      isTerms,
    } = this.state;

    return (
      // Main Content
      <div className="main-content alternate">
        <Helmet>
          <title>Subjects</title>
        </Helmet>
        <div className="subject-banner"></div>

        <div className="topics-wrap question-block">
          {/* <SubjectMenu
            selectedSubject={selectedSubjectId}
            selectedGradeId={selectedGradeId}
            setGrade={this.setGrade}
            onChangeSubject={this.onChangeSubject}
          /> */}

          {/* {status == "success" && ( */}
          <div>
            <div className="container">
              <div className="subject-wrap">
                <div className="filter-wrap row mb-3">
                  <div className="col-md-4">
                    <div className="select-box">
                      <div className="select-box__current" tabIndex="1">
                        <div className="select-box__value">
                          <input
                            className="select-box__input"
                            type="radio"
                            id="subject_0"
                            value="1"
                            name="subject"
                            defaultChecked="checked"
                          />
                          <p className="select-box__input-text">
                            {subjectName !== "" ? subjectName : "SUBJECT"}
                          </p>
                        </div>
                        {/* <div className="select-box__value">
                            <input
                              className="select-box__input"
                              type="radio"
                              id="subject_1"
                              value="2"
                              name="subject"
                              checked="checked"
                            />
                            <p className="select-box__input-text">Science</p>
                          </div> */}
                        <div className="select-box__icon">
                          <img
                            src={common.loadImg("dropdown-arrow.png")}
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <ul className="select-box__list">
                        {/* <li
                          onClick={(e) => this.onChangeSubjects(null)}
                        >
                          <label
                            className="select-box__option"
                            htmlFor="subject_0"
                            aria-hidden="aria-hidden"
                          >
                            SUBJECT
                            </label>
                        </li> */}
                        {userData &&
                          userData.subject &&
                          userData.subject.length > 0 &&
                          userData.subject.map((item, key) => {
                            return (
                              <li
                                key={key}
                                onClick={(e) => this.onChangeSubjects(item)}
                              >
                                <label
                                  className="select-box__option"
                                  htmlFor="subject_0"
                                  aria-hidden="aria-hidden"
                                >
                                  {item.name}
                                </label>
                              </li>
                            );
                          })}
                        {/* <li>
                            <label
                              className="select-box__option"
                              htmlFor="subject_0"
                              aria-hidden="aria-hidden"
                            >
                              Maths
                            </label>
                          </li>
                          <li>
                            <label
                              className="select-box__option"
                              htmlFor="subject_1"
                              aria-hidden="aria-hidden"
                            >
                              Science
                            </label>
                          </li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="select-box">
                      <div className="select-box__current" tabIndex="2">
                        <div className="select-box__value">
                          <input
                            className="select-box__input"
                            type="radio"
                            id="grade_0"
                            value="1"
                            name="grade"
                            defaultChecked="checked"
                          />
                          <p className="select-box__input-text">
                            {gradeName !== "" ? gradeName : "GRADE"}
                          </p>
                        </div>
                        {/* <div className="select-box__value">
                            <input
                              className="select-box__input"
                              type="radio"
                              id="grade_1"
                              value="2"
                              name="grade"
                              checked="checked"
                            />
                            <p className="select-box__input-text">Grade 11</p>
                          </div> */}
                        <div className="select-box__icon">
                          <img
                            src={common.loadImg("dropdown-arrow.png")}
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <ul className="select-box__list">
                        {/* <li
                          onClick={(e) => this.onChangeGrade(null)}
                        >
                          <label
                            className="select-box__option"
                            htmlFor="grade_0"
                            aria-hidden="aria-hidden"
                          >
                            GRADE
                            </label>
                        </li> */}
                        {grade &&
                          grade.length > 0 &&
                          grade.map((item, key) => {
                            return (
                              <li
                                key={key}
                                onClick={(e) => this.onChangeGrade(item)}
                              >
                                <label
                                  className="select-box__option"
                                  htmlFor="grade_0"
                                  aria-hidden="aria-hidden"
                                >
                                  {item.name}
                                </label>
                              </li>
                            );
                          })}
                        {/* <li>
                            <label
                              className="select-box__option"
                              htmlFor="grade_0"
                              aria-hidden="aria-hidden"
                            >
                              Grade 10
                            </label>
                          </li>
                          <li>
                            <label
                              className="select-box__option"
                              htmlFor="grade_1"
                              aria-hidden="aria-hidden"
                            >
                              Grade 11
                            </label>
                          </li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="select-box">
                      <div className="select-box__current" tabIndex="3">
                        <div className="select-box__value">
                          <input
                            className="select-box__input"
                            type="radio"
                            id="term_0"
                            value="1"
                            name="term"
                            defaultChecked="checked"
                          />
                          <p className="select-box__input-text">
                            {termsName !== "" ? termsName : "TERM"}
                          </p>
                        </div>
                        {/* <div className="select-box__value">
                            <input
                              className="select-box__input"
                              type="radio"
                              id="term_1"
                              value="2"
                              name="term"
                              checked="checked"
                            />
                            <p className="select-box__input-text">Term 2</p>
                          </div> */}
                        <div className="select-box__icon">
                          <img
                            src={common.loadImg("dropdown-arrow.png")}
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <ul className="select-box__list">
                        {/* <li onClick={(e) => this.onChangeTerm(null)}>
                          <label
                            className="select-box__option"
                            htmlFor="term_0"
                            aria-hidden="aria-hidden"
                          >
                            TERM
                          </label>
                        </li> */}
                        {terms &&
                          terms.length > 0 &&
                          terms.map((item, key) => {
                            return (
                              <li
                                key={key}
                                onClick={(e) => this.onChangeTerm(item)}
                              >
                                <label
                                  className="select-box__option"
                                  htmlFor="term_0"
                                  aria-hidden="aria-hidden"
                                >
                                  {item.term}
                                </label>
                              </li>
                            );
                          })}
                        {/* <li>
                            <label
                              className="select-box__option"
                              htmlFor="term_0"
                              aria-hidden="aria-hidden"
                            >
                              Term 1
                            </label>
                          </li>
                          <li>
                            <label
                              className="select-box__option"
                              htmlFor="term_1"
                              aria-hidden="aria-hidden"
                            >
                              Term 2
                            </label>
                          </li> */}
                      </ul>
                    </div>
                  </div>
                </div>

                <StatusBar status={status} />

                {!isTerms && (
                  <div className="subject-block">
                    <div className="row">
                      <div className="col-md-4 offset-md-2">
                        <div className="subject-content">
                          <h2>{translation.hai}</h2>
                          <h4>{translation.what_test_today}</h4>
                          <p>{translation.select_grade_sub_term}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <img
                          src={common.loadImg("example_subjects_img.png")}
                          className="img-fluid"
                        />
                      </div>
                    </div>

                    <p className="mt-5 text-center">
                   {translation.lorem_sub_content}
                    </p>
                  </div>
                )}

                {isTerms &&
                  topics &&
                  topics.length > 0 &&
                  topics[0].terms &&
                  topics[0].terms.length > 0 && (
                    <div className="subject-block">
                      <ul className="test-items">
                        {topics[0].terms.map((term, index) => {
                          return (
                            <div key={index}>
                              {term.tests.length > 0 &&
                                term.tests.map((test, tIdx) => {
                                  return (
                                    <li key={tIdx}>
                                      <Link
                                        to={encodeURI(
                                          "/session/" +
                                            selectedSubjectId +
                                            "/" +
                                            test._id +
                                            "/" +
                                            selectedGradeId
                                        )}
                                      >
                                        {test.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </div>
                          );
                        })}
                        {/* <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li>
                      <li>
                        <Link to="#">Lorem Ipsum is simply dummy text</Link>
                      </li> */}
                      </ul>

                      <div className="form-action text-center mt-5">
                        <Link
                          to="#"
                          className="btn bordered-btn-blue"
                          onClick={(e) => this.takeTest(e)}
                        >
                          {translation.take}{subjectName !== "" ? subjectName : ""}{" "}
                         {translation.overall_test}
                        </Link>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {/* )} */}
        </div>

        {/* <!-- Modal --> */}
        <Dialog
          open={isModelOpen}
          onClose={this.closeModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
            {"Choose a Test Option Below"}
          </DialogTitle>
          <DialogContent>
            <div className="card-body">
              <h6>{translation.test_option}</h6>
              <div className="form-actions d-flex justify-content-between align-items-center">
                <a
                  href="#"
                  className={
                    chooseOptions == 1
                      ? "test_active btn bordered-btn-blue"
                      : "btn bordered-btn-blue"
                  }
                  onClick={(e) => this.testOption(e, 1)}
                >
                 {translation.overall_test}
                </a>
                <span className="text-uppercase">Or</span>
                <a
                  href="#"
                  className={
                    chooseOptions == 2
                      ? "test_active btn bordered-btn-blue"
                      : "btn bordered-btn-blue"
                  }
                  onClick={(e) => this.testOption(e, 2)}
                >
                  {translation.mock_exam}
                </a>
              </div>

              {chooseOptions == 1 && (
                <div className="card-options mt-5">
                  <h6 className="with-border mb-4">
                    <span>{translation.overall_test}</span>
                  </h6>
                  <h6 className="mb-4">
                   {translation.overall_test_content}
                  </h6>
                  <div className="form-actions d-flex justify-content-between align-items-center">
                    <a
                      href="#"
                      className="btn bordered-btn-blue"
                      onClick={(e) => this.testMode(e, false)}
                    >
                      {translation.practice_based_test}
                    </a>
                    <span className="text-uppercase">Or</span>
                    <a
                      href="#"
                      className="btn bordered-btn-blue"
                      onClick={(e) => this.testMode(e, true)}
                    >
                     {translation.time_based_test}
                    </a>
                  </div>
                </div>
              )}
              {chooseOptions == 2 && (
                <div className="card-options mt-5">
                  <h6 className="with-border mb-4">
                    <span>{translation.mock_exam}</span>
                  </h6>
                  <h6 className="mb-4">
                  {translation.overall_test_content}
                  </h6>
                  <div className="row custom-radio">
                    <div className="col-sm-6">
                      <input
                        type="radio"
                        id="option1"
                        name="modalOrPastPaper"
                        value={true}
                        checked={this.state.modalOrPastPaper}
                        onChange={(e) => this.onChangeTestSelection(e, true)}
                      />
                      <label className="radio-label" htmlFor="option1">
                       {translation.model_paper}
                      </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="radio"
                        value={false}
                        id="option2"
                        name="modalOrPastPaper"
                        checked={!this.state.modalOrPastPaper}
                        onChange={(e) => this.onChangeTestSelection(e, false)}
                      />
                      <label className="radio-label" htmlFor="option2">
                       {translation.past_paper}
                      </label>
                      <select
                        name="selectedYear"
                        className="form-control"
                        value={selectedYear}
                        onChange={(e) => this.handleYearChange(e)}
                        disabled={this.state.modalOrPastPaper}
                      >
                        <option value="0">
                          {/* Select Year */}
                          {translation.select_year}
                        </option>
                        {yearList.map((data, index) => (
                          <option
                            key={index}
                            value={data.value}
                            title={data.value}
                          >
                            {data.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {error && <p className="session_warning mt-2">{error}</p>}
                  <div className="form-actions mt-5 d-flex justify-content-between align-items-center">
                    <a
                      href="#"
                      className="btn bordered-btn-blue"
                      onClick={(e) => this.testMode(e, false)}
                    >
                    {translation.practice_based_test}
                    </a>
                    <span className="text-uppercase">Or</span>
                    <a
                      href="#"
                      className="btn bordered-btn-blue"
                      onClick={(e) => this.testMode(e, true)}
                    >
                      {translation.time_based_test}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
        </Dialog>
      </div>
      // Main Content End
    );
  }
}
export default Subject;
