import React from "react";
// includes
import { common, history } from "helpers";
import { apiServices } from "api";
import { StatusBar } from "includes";
let translation = common.getTranslation();



class ManageSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      sData: {
        children: []
      },
    };
  }

  componentDidMount() {
    this.getChildList();
  }

  getChildList = () => {
    const { sData } = this.state;
    let urlKeys = {
      parentId: common.getStorage("userId")
    }
    let data = {
      method: "GET",
      url: "GET_CHILD_BY_PARENT",
      key: urlKeys
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.children = common.apiResultData(res);
        this.setState({
          status,
          sData
        })
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => { }
    );
  }

  activateChild(id) {
    history.push("/settings/child/activate/" + id);
  }

  render() {
    const { sData, status } = this.state;
    return (
      <div className="card-body">
        {/* <!-- Form Content Wrap --> */}
        <div className="settings-right">
          <div className="tab-pane" id="profile-settings">
            <h5 className="mt-3 mb-4">{translation.manage_children}</h5>
            <div className="table-responsive mb-4">
              <StatusBar status={status} />
              {status == "success" && (
                <table className="table table-2">
                  <thead>
                    <tr>
                      <th>{translation.s_no}</th>
                      <th>{translation.name}</th>
                      <th>{translation.mock_subject}</th>
                      <th>{translation.language}</th>
                      <th className="text-center">{translation.action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sData.children.data.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.name}</td>
                          <td>
                            {item.class.map((subject, ckey) => {
                              return (
                                <span key={ckey}>
                                  {subject.name}
                                  {item.class.length != ckey + 1 && ", "}
                                </span>
                              );
                            })}
                          </td>
                          <td>{item.country.name}</td>
                          <td className="text-center">
                            {!item.isActive ? (
                              <p className="link-text" onClick={() => this.activateChild(item._id)}>{translation.activate}</p>
                            ) : (
                                <p className="elk-active">{translation.active}</p>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
      </div>
    );
  }
}

export default ManageSettings;