import React from "react";
import { Route } from "react-router-dom";
import { common } from "helpers";
//Import Layouts
import Home from "components/Layouts/Home";

export const HomeLayoutRoute = ({
  header,
  auth,
  page,
  component: Component,
  title,
  ...rest
}) => {
  document.title = `Xample - ${title}`;
  return (
    common.authValidate(auth) && (
      <Route
        {...rest}
        render={(matchProps) => (
          <Home header={header} page={page ?? ""}>
            <Component {...matchProps} />
          </Home>
        )}
      />
    )
  );
};