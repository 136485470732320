import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
// includes
import { common, tools, history } from "helpers";
import { apiServices } from "api";
import { StatusBar } from "includes";

let translation = common.getTranslation();

class MockTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      yearList: common.getYearList(2000, new Date().getFullYear() - 1),
      filter: {        
        classID: "",
        paper: "",
        questionYear: "",
        period: 7,
        userId: ""
      },
      sData: {
        mockChart: {
          labels: [],
          datasets: []
        },
        mockList: [],
        dateRange: [
          translation.today,
          translation.this_week,
          translation.last_seven_days,
          translation.this_month,
          translation.last_thirty_days,
          translation.this_year,
          translation.all_time,
        ]
      },
      subjects: common.authInfo().subject ? common.authInfo().subject : [],
      userData: common.authInfo(),
    };
  }

  componentDidMount() {
    const { filter, userData } = this.state;
    if (userData.user && userData.user && userData.user.role && userData.user.role == 1) {
        let childrens = this.props.childrens
        if (childrens && childrens.length > 0) {
            this.setState({
                subjects: childrens[0].class
            })
        }
    }
    filter.userId = this.props.selUserId
    this.setState({
      filter: filter
    }, () => {
      this.getMockList();
    })
  }

  getMockList = () => {
    const { sData, filter, paging } = this.state;
    this.setStatus("process");
    this.rgb = 0;
    this.count = 1;
    
    // api call
    //let param = `?userID=${filter.userId}&subjectID=&classID=${filter.classID}&topicID=${filter.topicID}&period=${filter.period}`;
    let param = `?userID=${filter.userId}&subjectID=&classID=${filter.classID}&period=${filter.period}`;
    if (filter.questionYear !== "") {
      param = param + `&questionYear=${filter.questionYear}`
    }

    let data = {
      method: "GET",
      url: "STAT_MOCK_TEST",
      query: param,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        sData.mockList = result;
        sData.mockChart = this.getMockChart(status, result);
        status = this.dataStatus(status, result);
        this.setState({
          status,
          sData
        })
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  dataStatus(status, result) {
    if (status == "success") {
      status = "empty";
      Object.keys(result.data).map((item, key) => {
        if (item != "overall") {
          status = "success";
        }
      })
    }
    return status;
  }

  getMockChart(status, result) {

    const { subjects } = this.state;

    let chartData = {
      labels: [],
      datasets: []
    }
    let sLen = 0;
    if (status == "success") {
      Object.keys(result.data).map((item, key) => {
        if (item != "overall") {
          let scoreList = [];
          // label
          //chartData.labels.push(common.getSubject(item, subjects));
          // data
          result.data[item].map((data, dkey) => {
            scoreList.push(data.score);
          })
          if(scoreList.length > sLen){
            sLen = scoreList.length
          }
          
          // dataset
          chartData.datasets.push({
            label: common.getSubject(item, subjects),
            data: scoreList,
            fill: true,
            backgroundColor: tools.getRGB(this.rgb++),
            borderColor: 'rgba(255, 99, 132, 0.2)',
          })
        }
      })
    }
    [...Array(sLen)].map((sObj, idx) => {
      chartData.labels.push("Session " + (idx + 1));
    })
    return chartData;
  }

  continueTest(obj) {
    let modelOrPastPaper = obj.questionYear == 0 ? true : false;
    let testURL = `/tests/${obj.class}/mocktest/0/${obj.session}?mode=true&year=${obj.questionYear}&paper=${modelOrPastPaper}`;
    history.push(testURL);
  }

  // handler
  onChange(e) {
    const { name, value } = e.target;
    const { filter, yearList } = this.state;
    filter[name] = value;
    let selIndex = e.target.options.selectedIndex;

    // if (name == "subjectID" && value == "") {
    //   filter["topicID"] = ""
    // }

    if (name == "paper") {
      if (value == "1") {
        filter["questionYear"] = 0
      } else if (value == "2") {
        filter["questionYear"] = yearList[yearList.length - 1].value;
      } else {
        filter["questionYear"] = ""
      }
    }

    if (name == "userId") {
      //filter["topicID"] = ""
      filter["subjectID"] = ""
      filter["questionYear"] = ""
      //filter["topicID"] = ""
      filter["classID"] = ""
      filter["period"] = 7
      this.setState({
        filter,
        subjects: this.props.childrens[selIndex].class
      }, () => {
        this.getMockList();
      })
    } else {
      this.setState({
        filter
      }, () => {
        this.getMockList();
      })
    }

  }

  // filter
  resetFilter() {
    const { filter } = this.state;
    this.setState({
      paging: {
        pageSize: 10,
        pageIndex: 1
      },
      filter: {
        classID: "",
        //topicID: "",
        questionYear: "",
        paper: "",
        period: 7,
        userId: filter.userId
      }
    }, () => {
      this.getMockList();
    })
  }

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  loadGradeBlock = () => {
    const { filter, userData, subjects } = this.state;
    if (filter.classID != "" && subjects && subjects.length > 0) {
      let filteredGrade = subjects.filter(element => {
        return filter.classID == element._id;
      });      
      return (
        filteredGrade[0] && 
        filteredGrade[0].grade && 
        filteredGrade[0].grade.length > 0 && 
        filteredGrade[0].grade.map((item, key) => (
          <option key={key} value={item._id}>{item.name}</option>
        ))
      )

    } else {
      return
    }
  }

  render() {
    const {
      status,
      sData,
      filter,
      userData,
      yearList,
      subjects
    } = this.state;

    return (
      <div>
        <div
          className="tab-panel-content tab-pane fade show active"
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab-2"
        >
          <div className="tab-filters d-flex flex-wrap justify-content-between align-items-center">
            {/* <h5 className="primary-color mb-0">Paper Scores</h5> */}
            <h5 className="primary-color mb-0">{translation.paper_score}</h5>
            <div className="filter">
              <form className="form-inline">
              {
                    userData && 
                    userData.user &&
                    userData.user.role &&
                    userData.user.role == 1 && (
                        <div className="form-group">
                            <label>Child</label>
                            <select 
                                name="userId" 
                                onChange={(e) => this.onChange(e)}
                                value={filter.userId}
                            >
                                {
                                    this.props.childrens.length > 0 && this.props.childrens.map((item, key) => (
                                        <option value={item._id} key={key}>{item.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    )
                }
                <div className="form-group">
                  {/* <label>Subject</label> */}
                  <label>{translation.mock_subject}</label>
                  <select
                    name="classID"
                    value={filter.classID}
                    onChange={(e) => this.onChange(e)}
                  >
                    <option value="">
                      {translation.all}
                    </option>
                    {subjects &&
                      subjects.map((item, key) => (
                        <option key={key} value={item._id}>{item.name}</option>
                      ))}
                  </select>
                </div>
                {/* <div className="form-group">
                  <label>Grade</label>
                  <select
                    name="topicID"
                    value={filter.topicID}
                    onChange={(e) => this.onChange(e)}
                  >
                    <option value="">                 </option>
                    { this.loadGradeBlock() }              
                  </select>
                </div> */}
                <div className="form-group">
                  <label>Paper</label>
                  <select
                    name="paper"
                    value={filter.paper}
                    onChange={(e) => this.onChange(e)}
                  >
                    <option value="">
                      {translation.all}
                    </option>
                    <option value="1"> {translation.model_paper} </option>
                    <option value="2"> {translation.past_paper} </option>
                  </select>
                </div>
                {
                  filter.paper == "2" && (
                    <div className="form-group">
                      <label> {translation.mock_question_year} </label>
                      <select
                        name="questionYear"
                        value={filter.questionYear}
                        onChange={(e) => this.onChange(e)}
                      >
                        {yearList.map((data, index) => (
                          <option
                            key={index}
                            value={data.value}
                            title={data.value}
                          >
                            {data.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )
                }
                <div className="form-group">
                  <label> {translation.mock_date_range} </label>
                  <select
                    name="period"
                    value={filter.period}
                    onChange={(e) => this.onChange(e)}
                  >
                    {/* <option value="7"> {translation.all_time} </option> */}
                    {sData.dateRange.map((item, key) => (
                      <option key={key} value={(key + 1)}>{item}</option>
                    ))}
                  </select>
                </div>
                <input
                  type="reset"
                  value={translation.reset}
                  className="btn-sm"
                  onClick={() => this.resetFilter()}
                />
              </form>
            </div>
          </div>
          <StatusBar status={status} />
          {status == "success" && (
            <div>
              <div className="test-card mb-4">
                <div className="test-header d-flex justify-content-between align-items-center mb-5">
                  <h5 className="mb-0">{translation.paper_performance} - {translation.time_based}</h5>
                </div>
                <div className="test-chart">
                  <Line data={sData.mockChart} />
                </div>
              </div>

              <div className="test-card">
                <div className="test-header d-flex justify-content-between align-items-center mb-5">
                  <h5 className="mb-0">{translation.paper_session}</h5>
                </div>

                <div className="table-responsive mt-5">
                  <table className="table table-3">
                    <thead>
                      <tr>
                        <th></th>
                        {/* <th>Subject</th> */}
                        <th>{translation.mock_subject}</th>
                        {/* <th>Grade</th> */}
                        {/* <th>Questions Answered</th> */}
                        <th>{translation.questions_answered}</th>
                        {/* <th>Score</th> */}
                        <th>{translation.score}</th>
                        {/* <th>Negative Marks</th> */}
                        {/* <th>Last Practiced</th> */}
                        <th>{translation.last_practiced}</th>
                        {/* <th>Exam Type</th> */}
                        <th>{translation.exam_type}</th>
                        {
                          userData.user &&
                          userData.user.role &&
                          userData.user.role != 1 && (
                            // <th className="text-center">Action</th>
                            <th className="text-center">
                              {translation.action}
                            </th>
                          )
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(sData.mockList.data).map((item, key) => (
                        item != "overall" && (
                          sData.mockList.data[item].map((data, dkey) => (
                            <tr key={dkey}>
                              <td>{this.count++}</td>
                              <td>{common.getSubject(data.class,subjects)}</td>
                              {/* <td>{common.getGrade(data.topic, data.class, subjects)}</td> */}
                              <td>{data.question}</td>
                              <td>{data.score}</td>
                              {/* <td>{data.nagativeMark}</td> */}
                              <td>{moment(data.date).format("DD MMM YYYY")}</td>
                              <td>
                                {data.questionYear == 0 && (
                                  // "Model Paper"
                                  translation.model_paper
                                )} 
                                {data.questionYear != 0 && (
                                  <span>
                                    {translation.past_paper} 
                                    <br/>  {data.questionYear}
                                  </span>
                                )}
                                </td>
                                {
                                  userData.user &&
                                  userData.user.role &&
                                  userData.user.role != 1 && (
                                    <td className="text-center">
                                      {data.completed ? (
                                        <input type="button"
                                          className="btn success-btn" 
                                          // value="Completed" 
                                          value={translation.continue}
                                        />
                                      ) : (
                                          <input type="button" 
                                            className="btn primary-btn" 
                                            // value="Continue" 
                                            value={translation.completed}
                                            onClick={() => this.continueTest(data)} 
                                          />
                                        )}
                                    </td>
                                  )
                                }
                            </tr>)))
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-sm-12 text-center">
                  <Link
                    to="#"
                    onClick={() => {
                      this.loadMore();
                    }}
                    className="btn bordered-btn-blue mt-5"
                  >
                    Load More
                </Link>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MockTest;
