import React from "react";
import { Link } from "react-router-dom";
import { common } from "helpers";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class LimitExceeded extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Limit Exceeded</title>
        </Helmet>
        {/* <!-- Main Content --> */}
        <div className="main-content">
          <div className="question-wrap d-flex">
            {/* <!-- Question Block --> */}
            <div className="question-block">
              {/* <!-- Question Content --> */}
              <div className="container">
                <div className="question-content-inner alternate">
                  <div className="content-block d-flex align-items-center justify-content-center text-center">
                    <div className="content-info">
                      <img
                        src={common.loadImg("stopwatch.png")}
                        className="mb-5"
                        alt="Limit Exceeded"
                      />
                      <h3 className="limit-exceeded">
                       {translation.limit_exceeded}
                      </h3>
                      <p className="mb-0">
                      {translation.register_to_access}
                      </p>
                      <Link to="/register" className="btn secondary-btn mt-5">
                       {translation.register_now}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Question Content End --> */}
            </div>
            {/* <!-- Question Block End --> */}
          </div>
        </div>
        {/* <!-- Main Content End --> */}
      </div>
    );
  }
}

export default LimitExceeded;
