import React from "react";
import { common } from "helpers";

class SuccessMessage extends React.Component {
  render() {
    return (
        <div className="question-content-inner alternate">
          <div className="d-flex align-items-center justify-content-center text-center">
            <div className="success-img-wrap">
              <img src={common.loadImg("well-done.png")} alt="" height="550" />
            </div>
          </div>
        </div>
    );
  }
}
export default SuccessMessage;
