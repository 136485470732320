import React from "react";
import { Link } from "react-router-dom";
// includes
import { Handler } from "includes";
import { common, message, history, tools } from "helpers";
import { apiServices } from "api";
import { Helmet } from "react-helmet";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
let translation = common.getTranslation();
class ChangeMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        mobile: "",
      },
      errors: {
        mobile: {
            error: "",
            isReq: translation.mobile_req,
        },
      },
      userData: common.authInfo(),
    };
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const{ fields } = this.state; 
      // api call
      let params = {
        mobile: fields.mobile,
      };
      let urlKeys = {
        userId: common.getStorage("userId"),
      }
      
      let data = {
        method: "PUT",
        url: "CHANGE_MOBILE",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResultData(res);
          if (status == "success") {
            // userData.user.screenName = fields.screenName;
            // common.setStorage("userData", tools.crypt(userData, true));
            common.setStorage("mobile", tools.crypt(fields.mobile, true));
            common.setStorage("email", "")
            common.snack("S", result.message);
            if(result.data !== true){
              history.push("/activation/mobile");
            }
          } else {
            if (status == "failure") {
              common.snack("E", translation.update_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.update_failed);
        },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // event
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  redirect() {
    history.push("/settings/profile");
  }

  render() {
    const { isLoading, formSubmit, fields, errors } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Change Mobile</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="card card-2">
                  <div className="card-heading primary-bg">
                    <h2>{translation.change_mobile}</h2>
                  </div>
                  <div className="card-body">
                    <form method="POST">
                    <div className="form-group">
                      <Input
                        type="number"
                        name="mobile"
                        value={fields.mobile}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                        className="form-control custom-mobile-field"
                        placeholder=""
                        inputProps={{
                          maxLength: 9,
                        }}
                        startAdornment={
                          <InputAdornment position="start">+94</InputAdornment>
                        }
                      />
                      <span className="floating-label">
                        Enter Mobile Number
                      </span>
                      {errors.mobile.error != "" && formSubmit && (
                        <span className="elk-error">{errors.mobile.error}</span>
                      )}
                    </div>
                      
                      <div className="form-actions d-flex justify-content-between">
                        <input
                          onClick={() => this.redirect()}
                          type="button"
                          className="btn bordered-btn"
                          value="Cancel"
                          disabled={isLoading}
                        />
                        <input
                          onClick={() => this.onSubmit()}
                          type="button"
                          className="btn btn-light-blue"
                          disabled={isLoading}
                          value={isLoading ? "Processing..." : "Change Mobile"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => { this.handler = instance; }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default ChangeMobile;
