import React from "react";
// helper
import { common } from "helpers";


// pages
import HomeDefault from "./HomeDefault";
import Dashboard from "./Dashboard";
import SelectLanguage from "pages/SelectLanguage/SelectLanguage";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: common.authCheck(),
      isLanguageSelected: common.getLanguage()
    };
  }

  render() {
    const { isLoggedIn, isLanguageSelected } = this.state;
    return (
      <div>
        {!isLanguageSelected ? (<SelectLanguage />) : (!isLoggedIn ? (<HomeDefault />) : (<Dashboard userPlan={this.props.userPlan} />))}
      </div>
    );
  }
}

export default Home;