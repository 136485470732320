import React from "react";
import { common } from "helpers";
import { Link } from "react-router-dom";
import { StatusBar } from "includes";
import { apiServices } from "api";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
let translation = common.getTranslation();
class RevisionQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      sessions: [],
      page: 1,
      limit: 10,
      status: "",
      isModelOpen: false,
      enableLoadMore: false,
      explanationContent: "",
      selSesIndex: "",
      answerType: "",
    };
  }

  componentDidMount() {
    if (this.props.setChildRefreshMethod) {
      this.props.setChildRefreshMethod(this.refresh);
    }

    this.getRecords();
    this.getSessionRecords();
  }

  refresh = () => {
    this.setState(
      {
        page: 1,
        records: [],
        selSesIndex: "",
      },
      () => {
        this.getRecords();
        this.getSessionRecords();
      }
    );
  };

  loadMore = () => {
    const { page } = this.state;
    this.setState(
      {
        page: page + 1,
      },
      () => {
        this.getRecords();
      }
    );
  };

  //Services
  getRecords = () => {
    const { page, limit, records, selSesIndex, sessions, answerType } =
      this.state;
    this.setState({ status: "process" });

    let sessionId =
      this.props.sessionId !== ""
        ? this.props.sessionId
        : selSesIndex != ""
        ? sessions[selSesIndex]._id
        : "";

    var queryParam = "?";
    queryParam = queryParam + "userID=" + this.props.selUserId;
    queryParam = queryParam + "&skip=" + page;
    queryParam = queryParam + "&limit=" + limit;
    queryParam = queryParam + "&sessionID=" + sessionId;
    queryParam = queryParam + "&answeredType=" + answerType;
    queryParam = queryParam + "&classID=" + this.props.selSubId;
    queryParam = queryParam + "&topicID=" + this.props.selGradeId;
    queryParam = queryParam + "&testID=" + this.props.testId;
    queryParam = queryParam + "&period=" + this.props.selDateRangeId;

    let data = {
      method: "GET",
      headerFlag: false,
      url: "STAT_QUES",
      authorization: "token",
      query: queryParam,
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;
        if (status == "success") {
          result = common.apiResult(res);
        }
        if (result && result.length > 0) {
          let mergedata = records.concat(result);
          this.setState({
            records: mergedata,
            enableLoadMore:
              mergedata.length == res.data.page.count ? false : true,
            status: "success",
          });
        }

        if (status == "empty") {
          this.setState({
            status: "empty",
          });
        }
      },
      (error) => {
        this.setState({ status: "error" });
      },
      (final) => {}
    );
  };

  getSessionRecords = () => {
    const { records } = this.state;

    var queryParam = "?";
    queryParam = queryParam + "userID=" + this.props.selUserId;
    queryParam = queryParam + "&classID=" + this.props.selSubId;
    queryParam = queryParam + "&topicID=" + this.props.selGradeId;
    queryParam = queryParam + "&testID=" + this.props.testId;
    queryParam = queryParam + "&period=" + this.props.selDateRangeId;

    let data = {
      method: "GET",
      headerFlag: false,
      url: "STAT_QUES_LOG_SES_ALL",
      authorization: "token",
      query: queryParam,
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;
        if (status == "success") {
          result = common.apiResult(res);
        }
        if (result.length > 0) {
          var sessionInd = "";
          if (this.props.sessionId !== "") {
            result.forEach((element, eleInd) => {
              if (element._id == this.props.sessionId) {
                sessionInd = eleInd;
              }
            });
          }

          this.setState({
            selSesIndex: sessionInd,
            sessions: result,
          });
        }
      },
      (error) => {},
      (final) => {}
    );
  };

  onChangeSelectOption = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "session":
        this.setState(
          {
            selSesIndex: value,
            records: [],
            page: 1,
          },
          () => {
            this.getRecords();
          }
        );
        break;
      case "answerType":
        this.setState(
          {
            answerType: value,
            records: [],
            page: 1,
          },
          () => {
            this.getRecords();
          }
        );
        break;
      default:
        break;
    }
  };

  openModel = (content) => {
    this.setState({
      isModelOpen: true,
      explanationContent: content,
    });
  };

  closeModal = () => {
    this.setState({
      isModelOpen: false,
    });
  };

  renderAnswersBlock = (dataObject) => {
    var correctAnswer = "-";
    var yourAnswer = "-";
    let widgets = dataObject.question.question.widgets
      ? dataObject.question.question.widgets
      : null;
    let yourAnsKey = Object.keys(dataObject.answer);

    if (widgets) {
      let keys = Object.keys(widgets);
      if (keys && keys.length > 0) {
        let options = widgets[keys[0]].content;
        if (options) {
          for (let objKey in options) {
            let ansKey = Object.keys(options[objKey].content);
            if (options[objKey].correct) {
              // Find Correct Answer
              correctAnswer = options[objKey].content[ansKey[0]].content;
            }
            if (
              yourAnsKey &&
              yourAnsKey.length > 0 &&
              objKey == yourAnsKey[0]
            ) {
              // Find Your Answer
              yourAnswer = options[objKey].content[ansKey[0]].content;
            }
          }
        }
      }
    }

    return (
      <div className="test-answer d-flex">
        <div className="correct-answer">
          <label>Correct Answer</label>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: common.removeSpecialChars(correctAnswer),
            }}
          ></p>
        </div>
        <div className="your-answer">
          <label className="secondary-color">Your Answer:</label>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: common.removeSpecialChars(yourAnswer),
            }}
          ></p>
        </div>
      </div>
    );
  };

  render() {
    const {
      records,
      enableLoadMore,
      status,
      isModelOpen,
      explanationContent,
      sessions,
      selSesIndex,
    } = this.state;

    return (
      <>
        <div className="filter mb-4">
          <form className="form-inline">
            <div className="form-group">
              <label>{translation.sessions}</label>
              <select
                name="session"
                value={selSesIndex}
                onChange={(e) => this.onChangeSelectOption(e)}
              >
                <option value="">{translation.all_sessions}</option>
                {sessions.length > 0 &&
                  sessions.map((sub, subInd) => (
                    <option value={subInd} key={subInd}>
                      SESSION {subInd + 1}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label>{translation.answer_type}</label>
              <select
                name="answerType"
                onChange={(e) => this.onChangeSelectOption(e)}
              >
                <option value="">{translation.select_option}</option>
                <option value="true">{translation.correct}</option>
                <option value="false">{translation.wrong}</option>
              </select>
            </div>
          </form>
        </div>
        <div className="masonry-grid revision">
          {records.length > 0 &&
            records.map((data, dataIndex) => (
              <div className="grid-item" key={dataIndex}>
                <div className="test-col">
                  <div
                    className={
                      data.answered ? "test-info correct" : "test-info wrong"
                    }
                  >
                    <div className="test-links d-flex justify-content-between mb-3">
                      <Link to="#" className="btn-sm">
                        {data.class.name}
                      </Link>
                      <Link
                        to="#"
                        className="link"
                        onClick={() => {
                          this.openModel(data.question.explanation.content);
                        }}
                      >
                        {translation.view_explanation}
                      </Link>
                    </div>
                    <h6
                      className="primary-color mb-0"
                      dangerouslySetInnerHTML={{
                        __html: common.removeSpecialChars(
                          data.question.question.content
                        ),
                      }}
                    ></h6>
                  </div>
                  {this.renderAnswersBlock(data)}
                </div>
              </div>
            ))}
        </div>
        {/* <!-- Modal --> */}
        <Dialog
          open={isModelOpen}
          onClose={this.closeModal}
          aria-labelledby="responsive-dialog-title"
          className="explanation-model"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
            Explanation
          </DialogTitle>
          <DialogContent>
            <div
              className="card-body"
              dangerouslySetInnerHTML={{
                __html: common.removeSpecialChars(explanationContent),
              }}
            ></div>
          </DialogContent>
        </Dialog>
        <StatusBar status={status} />
        <div className="row">
          <div className="col-sm-12 text-center">
            {status == "success" && enableLoadMore && (
              <Link
                to="#"
                onClick={() => {
                  this.loadMore();
                }}
                className="btn bordered-btn-blue mt-4"
              >
                {translation.load_more}
              </Link>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default RevisionQuestions;
