import React from "react";
import { common, tools } from "helpers";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class Membership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    translation: common.getTranslation(),
    }
  }

  componentDidMount() {
    tools.scrollTop();
  }

  render() {
    const { translation } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Membership</title>
        </Helmet>
        <Link to="#" className="custom-link"> 
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        { /* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="card card-3 mb-5">
              <div className="card-heading tertiary-bg">
                <h2>{translation.join_community}</h2>
                <p className="mb-0">{translation.join_community_content}</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-5 text-center">
                    <div className="card-icon mx-auto mb-4">
                      <img src={common.loadImg("student.png")} alt="student" />
                    </div>
                    <h6 className="mb-3">{translation.as_a_student}</h6>
                    <div className="join-now-tooltip">
                      <span className="tooltiptext">
                        {translation.join_now_student_tooltip_content}
                      </span>
                      <Link to="/register/student" className="btn btn-light-blue mt-4">{translation.join_now}</Link>
                    </div>
                  </div>
                  <div className="col-md-2 middle-img">
                    <img src={common.loadImg("mock-img.png")} alt="bitmap" />
                  </div>
                  <div className="col-md-5 text-center">
                    <div className="card-icon mx-auto mb-4">
                      <img src={common.loadImg("parents.png")} alt="parent" />
                    </div>
                    <h6 className="mb-3">{translation.as_a_parent}</h6>
                    <div className="join-now-tooltip">
                      <span className="tooltiptext">
                        {translation.join_now_parent_tooltip_content}
                      </span>
                      <Link to="/register/parent" className="btn btn-light-blue mt-4">{translation.join_now}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { /* <!-- Form Content Wrap End --> */}
      </div>
    );
  }
}
export default Membership;