import React from "react";
import { common } from "helpers";
let translation = common.getTranslation();

class GradeTable extends React.Component {
  constructor(props) {
    super(props);
    let minHours = 4;
    let maxHours = 16;
    let spentHours = this.props.score && this.props.score.scoreHour ? common.calSecToHours(this.props.score.scoreHour) : 0;
    let achievedGrade = this.props.score && this.props.score.scoreGrade ? this.props.score.scoreGrade : "Failed";
    minHours =  spentHours < minHours ? spentHours : minHours;
    maxHours =  spentHours > maxHours ? spentHours : maxHours;
    this.state = {
      min: minHours,
      max: maxHours,
      grade: achievedGrade,
      hours: spentHours,
      goalGrade: "Distinction",
      goalHour: 16
    };
  }

  renderGrade = (gradeName) => {
    const { min, max, grade, hours, goalHour, goalGrade } = this.state;   
    return(
      [...Array(max - min + 1)].map((_, index) => (
        <div
          className="table-row-item"
          data-header={index + min}
          key={index}
        >
          <label className={grade === gradeName && hours === (index+min) ? "modern-radio active" :  "modern-radio" }>
            <input type="radio" name="goal" disabled defaultChecked={goalGrade === gradeName && goalHour === (index+min)}/>
            <span className="shadow-design"></span>
            {goalGrade === gradeName && goalHour === (index+min) && (
              <div className="goal-txt-wrap">
                <span className="char1">Y</span>
                <span className="char2">o</span>
                <span className="char3">u</span>
                <span className="char4">r</span>
                <span className="char5">G</span>
                <span className="char6">o</span>
                <span className="char7">a</span>
                <span className="char8">l</span>
              </div>
            )}
          </label>
        </div>
      ))
    )
  }

  render() {
    const { min, max } = this.state;
    return (
      <div className="flex-table">
        <div className="table-row table-header">
          <div className="table-row-item"></div>
          {[...Array(max - min + 1)].map((_, index) => (
            <div className="table-row-item" key={index}>
              {index + min}
            </div>
          ))}
        </div>
        <div className="row-collection">
          <div className="table-row faild">
            <div className="table-row-item" data-header="Hours">
              <label className="grade-heading">{translation.grade_f}</label>
            </div>
            {this.renderGrade("Failed")}
          </div>

          <div className="table-row pass">
            <div className="table-row-item" data-header="Hours">
              <label className="grade-heading">{translation.grade_s}</label>
            </div>
            {this.renderGrade("SimplePass")}
          </div>

          <div className="table-row credit">
            <div className="table-row-item" data-header="Hours">
              <label className="grade-heading">{translation.grade_c}</label>
            </div>
            {this.renderGrade("Credit")}
          </div>

          <div className="table-row good">
            <div className="table-row-item" data-header="Hours">
              <label className="grade-heading">{translation.grade_b}</label>
            </div>
            {this.renderGrade("Good")}
          </div>

          <div className="table-row distinction">
            <div className="table-row-item" data-header="Hours">
              <label className="grade-heading">{translation.grade_a}</label>
            </div>
            {this.renderGrade("Distinction")}            
          </div>
        </div>
      </div>
    );
  }
}
export default GradeTable;
