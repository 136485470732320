import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "react-router-dom";
// includes
import { apiServices } from "api";
import { envConfig } from "../../api/config";
import { common, tools, history } from "helpers";
import { StatusBar } from "includes";
class MyTests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectName: "",
      grade: [],
      gName: "",
      tName: "",
      gradeName: "",
      termsName: "",
      terms: [],
      selectedGradeId: "",
      selectedTermsId: "",
      selectedSubjectId: "",
      testInfo: [],
      status: "",
      overallArray: [
        { mode: "practice_based", sessions: [] },
        { mode: "time_based", sessions: [] },
      ],
      selectedYear: new Date().getFullYear() - 1,
      yearList: common.getYearList(2000, new Date().getFullYear() - 1),
      isModelOpen: false,
      userPlan: props.userPlan,
      todayTest: [],
      mockTest: [],
      userId: props.userId,
      userData: props.userData,
      userInfo: this.props.userInfo,
      pricingModal: false,
      freeLimit: true,
      freeAlert: "",
      testId: "",
      testName: "",
      translation: common.getTranslation(),
      topics: [],
      topicName: "",
      selectedTopicId: "",
      allTopics: {},
      overallMode: [],
      modeName: "",
      mode: "",
      paperYear: "",
      yearName: "",
      paperYearList: [],
    };
  }

  componentDidMount = () => {
    const { userData, userInfo, userPlan, selectedSubject } = this.props;
    if (
      common.getStorage("page") == "test" &&
      common.getStorage("testData") !== null
    ) {
      let testData = tools.deCrypt(common.getStorage("testData"), true);
      userInfo &&
        userInfo.items &&
        Object.entries(userInfo.items).map((subject) => {
          if (subject[0] == testData.subjectId) {
            this.setState({
              grade: subject[1].grade,
              terms: subject[1].term,
            });
          }
        });
      this.setState(
        {
          selectedSubjectId: testData.subjectId,
          selectedGradeId: testData.gradeId,
          selectedTermsId: testData.termsId,
          termsName: testData.termName,
          subjectName: testData.subjectName,
          gradeName: testData.gradeName,
        },
        (_) => {
          if (testData.gradeId && testData.subjectId && testData.termName) {
            if (testData.termName == "Overall") {
              this.getOverallTest();
            } else {
              this.getTopicTest();
            }
          } else if (testData.termName == "Past Paper") {
            this.getMockTestSession();
          } else if (testData.termName == "Model Paper") {
            this.getModalPaperTestSession();
          }
        }
      );
    }
    if (userPlan && userPlan.plan && userPlan.plan.planId === "free") {
      this.getTodayAttendedTest();
      this.getMockAttendedTest();
    }

    if (userData.user.role == 4 && selectedSubject) {
      userInfo &&
        userInfo.items &&
        Object.entries(userInfo.items).map((item) => {
          if (item[0] == selectedSubject) {
            this.onChangeSubjects(item[0], item[1]);
          }
        });
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedSubject, userId, userInfo, userData } = this.props;
    if (
      (userId != "" && prevProps.userId !== userId) ||
      (selectedSubject != "" &&
        prevProps.selectedSubject !== selectedSubject) ||
      prevProps.userInfo.length != userInfo.length
    ) {
      this.setState({
        selectedSubjectId: selectedSubject,
        userId: userId,
        userInfo: userInfo,
        gName: "",
        tName: "",
        testInfo: [],
      });

      if (userData.user.role == 4 && selectedSubject) {
        userInfo &&
          userInfo.items &&
          Object.entries(userInfo.items).map((item) => {
            if (item[0] == selectedSubject) {
              this.onChangeSubjects(item[0], item[1]);
            }
          });
      }
    }
  }

  getTodayAttendedTest = () => {
    let query = `?userId=${this.state.userId}`;
    let data = {
      method: "GET",
      headerFlag: true,
      url: "DB_MY_TEST_TODAY_TEST_LIST",
      query: query,
      authorization: "token",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = [];
        if (status == "success") {
          result = common.apiResult(res);
        } else if (status == "empty") {
          result = [];
        }
        this.setState({
          todayTest: result,
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  getMockAttendedTest = () => {
    let query = `?userId=${this.state.userId}`;
    let data = {
      method: "GET",
      headerFlag: true,
      url: "DB_MY_TEST_MOCK_TEST_LIST",
      query: query,
      authorization: "token",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = [];
        if (status == "success") {
          result = common.apiResult(res);
        } else if (status == "empty") {
          result = [];
        }
        this.setState({
          mockTest: result,
        });
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  getMockTestSession = () => {
    const {
      selectedSubjectId,
      selectedGradeId,
      overallArray,
      subjectName,
      translation,
      userId,
      paperYear,
    } = this.state;
    const { allStudent } = this.props;
    //if (selectedSubjectId && selectedGradeId) {
    if (selectedSubjectId) {
      this.setState({
        status: "process",
        termsName: "Past Paper",
        tName: translation.mock_test,
      });
      let query = `?userId=${
        userId != "all" ? userId : allStudent
      }&classId=${selectedSubjectId}&topicId=${selectedGradeId}`;
      if (paperYear) {
        query += `&year=${paperYear}`;
      }
      let data = {
        method: "GET",
        headerFlag: true,
        url: "DB_MY_TEST_MOCK_LIST",
        query: query,
        authorization: "token",
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = [];
          let testData = [];
          if (status == "success") {
            result = common.apiResult(res);
            result.length > 0 &&
              result.map((item, key) => {
                let totalQuestions = 0;
                item.sessions &&
                  item.sessions.map((session) => {
                    //totalQuestions += session.tQCount;
                    totalQuestions += common.getQuestionLimit(
                      true,
                      "pastpaper",
                      subjectName
                    );
                  });
                result[key]["totalQuestions"] =
                  totalQuestions / item.sessions.length;
              });
            overallArray[1]["year"] = translation.start_new_session; //"Start a new session";
            result.unshift(overallArray[1]);
            testData = result;
          } else if (status == "empty" && userId !== "all") {
            overallArray[1]["year"] = translation.start_new_session; //"Start a new session";
            testData.push(overallArray[1]);
          }
          if(common.apiResult(res).length > 0 && this.props.studentList.length > 0 ) {
            let arr = this.getArrayDifference(this.props.studentList, testData);
            arr.length > 0 && arr.map(item => {
              let val = {
                _id: item,
                sessions: []
              }
              testData.push(val)
            })            
          } else {
            this.props.studentList.length > 0 && this.props.studentList.map((user) => {
              let val = {
                "sessions": [],
                "_id": user
              }
              testData.push(val);
            })
          }
          this.setState({
            testInfo: testData,
            status: "success",
            isModelOpen: false,
          });
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => {}
      );
    } else {
      common.snack("E", translation.select_sub_grade);
    }
  };

  onChangeSubjects = (key, item) => {
    if (key !== null) {
      this.setState({
        subjectName: item.name,
        grade: item.grade,
        selectedSubjectId: key,
        terms: item.term,
        allTopics: item.test,
      });
    }

    this.setState({
      selectedGradeId: "",
      selectedTermsId: "",
      gradeName: "",
      termsName: "",
      status: "",
    });
  };

  onChangeGrade = (key, name) => {
    const { translation } = this.state;
    if (key !== null) {
      this.setState({
        gradeName: name,
        selectedGradeId: key,
        gName: name,
      });
    }
    if (key == "") {
      this.setState({
        gradeName: "Past/Model Paper",
        gName: translation.past_model_paper,
        selectedGradeId: 0,
      });
    }
    this.setState({
      selectedTermsId: "",
      termsName: "",
      tName: "",
      testInfo: [],
      overallMode: [],
      modeName: "",
      mode: "",
      paperYear: "",
      yearName: "",
      paperYearList: [],
      status: "",
    });
  };

  onChangeTerm = (key, name) => {
    if (key !== null) {
      this.setState(
        {
          termsName: name,
          selectedTermsId: key,
          tName: name,
          topicName: "",
          testInfo: [],
          topics: [],
          overallMode: [],
          modeName: "",
          mode: "",
          paperYear: "",
          yearName: "",
          paperYearList: [],
          status: "",
        },
        (_) => {
          if (this.state.userId !== "all") {
            this.getTopicTest();
          } else {
            let val = [];
            Object.keys(this.state.allTopics).length > 0 &&
              Object.keys(this.state.allTopics).filter((key) => {
                if (key == this.state.selectedGradeId) {
                  Object.keys(this.state.allTopics[key]).filter((item) => {
                    if (item == this.state.selectedTermsId) {
                      val = this.state.allTopics[key][item];
                    }
                  });
                }
              });
            this.setState(
              {
                topics: val,
              },
              (_) => {
                // this.getTopicTest();
              }
            );
          }
        }
      );
    }
  };

  onChangeTopic = (key, name) => {
    if (key !== null) {
      this.setState(
        {
          topicName: name,
          selectedTopicId: key,
          overallMode: [],
          modeName: "",
          mode: "",
          paperYear: "",
          yearName: "",
          paperYearList: [],
          status: "",
        },
        (_) => {
          this.getAllStudentTest();
        }
      );
    }
  };

  onChangeOverall = () => {
    const { userId } = this.state;
    if (userId == "all") {
      let overall = [
        {
          name: "Time based",
          value: true,
        },
        {
          name: "Practise based",
          value: false,
        },
      ];
      this.setState({
        overallMode: overall,
        topics: [],
        topicName: "",
        tName: "Over all",
        testInfo: [],
        modeName: "",
        mode: "",
        paperYear: "",
        yearName: "",
        paperYearList: [],
        status: "",
      });
    } else {
      this.getOverallTest();
    }
  };

  onchangeMockTest = () => {
    const { userId } = this.state;
    if (userId == "all") {
      this.setState({
        overallMode: [],
        topics: [],
        topicName: "",
        tName: "Past Paper",
        testInfo: [],
        termsName: "Past Paper",
        modeName: "",
        mode: "",
        paperYearList: common.getYearList(2000, new Date().getFullYear() - 1),
        status: "",
      });
    } else {
      this.getMockTestSession();
    }
  };

  onChangeYear = (item) => {
    this.setState(
      {
        paperYear: item.value,
        yearName: item.label,
        overallMode: [],
        topics: [],
        topicName: "",
        modeName: "",
        mode: "",
        status: "",
      },
      (_) => {
        this.getMockTestSession();
      }
    );
  };

  onChangeModel = (item) => {
    this.setState(
      {
        mode: item.value,
        modeName: item.name,
        status: "",
      },
      (_) => {
        this.getOverallTest();
      }
    );
  };

  onChangeModalPaper = () => {
    this.setState(
      {
        topics: [],
        topicName: "",
        selectedTopicId: "",
        overallMode: [],
        modeName: "",
        mode: "",
        paperYear: "",
        yearName: "",
        termName: "Model Paper",
        paperYearList: [],
        status: "",
      },
      (_) => {
        this.getModalPaperTestSession();
      }
    );
  };

  getArrayDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        if(object2.hasOwnProperty("_id")){
          return object1._id === object2._id._id;
        }
      });
    });
  }

  getAllStudentTest = () => {
    const {
      userId,
      selectedSubjectId,
      selectedGradeId,
      selectedTermsId,
      subjectName,
      selectedTopicId,
    } = this.state;
    const { userInfo, allStudent } = this.props;
    if (
      selectedSubjectId &&
      selectedGradeId &&
      selectedTermsId &&
      selectedTopicId
    ) {
      this.setState({
        status: "process",
      });
      let url = "DB_MY_TEST_ALL_STUDENT_TOPIC_LIST";
      let queryParam = `?userId=${allStudent}&testId=${selectedTopicId}`;

      let data = {
        method: "GET",
        url: url,
        query: queryParam,
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResult(res);
          let tQues = common.getQuestionLimit(false, "topic", subjectName); //todo envConfig.topicTest.practice.other;
          result.map((obj) => {
            obj.totalQuestions = tQues;
          });
         
          if(result.length > 0 && this.props.studentList.length > 0 ) {
            let arr = this.getArrayDifference(this.props.studentList, result);
            arr.length > 0 && arr.map(item => {
              let val = {
                _id: item,
                sessions: []
              }
              result.push(val)
            }) 
          } else {
            this.props.studentList.length > 0 && this.props.studentList.map((user) => {
              let val = {
                "sessions": [],
                "_id": user
              }
              result.push(val);
            })
          }
          this.setState({
            testInfo: result,
            status: "success",
          });
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => {}
      );
    }
  };

  getTopicTest = () => {
    const {
      userId,
      selectedSubjectId,
      selectedGradeId,
      selectedTermsId,
      subjectName,
    } = this.state;
    const { userInfo } = this.props;
    if (selectedSubjectId && selectedGradeId && selectedTermsId) {
      this.setState({
        status: "process",
      });
      let url = "DB_MY_TEST_TOPIC_LIST";
      let queryParam = `?userId=${userId}&classId=${selectedSubjectId}&topicId=${selectedGradeId}&termId=${selectedTermsId}`;

      let data = {
        method: "GET",
        url: url,
        query: queryParam,
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResult(res);
          let tQues = common.getQuestionLimit(false, "topic", subjectName); //todo envConfig.topicTest.practice.other;
          result.map((obj) => {
            obj.totalQuestions = tQues;
          });
          if (status == "success") {
            userInfo &&
              userInfo.items &&
              Object.entries(userInfo.items).map((subject) => {
                if (subject[0] == selectedSubjectId) {
                  Object.entries(subject[1].test).map((grade) => {
                    if (grade[0] === selectedGradeId) {
                      Object.entries(grade[1]).map((terms) => {
                        if (terms[0] == selectedTermsId) {
                          terms[1].length > 0 &&
                            terms[1].map((topic) => {
                              let filterData =
                                result.length > 0 &&
                                result.filter((item) => item._id == topic);
                              if (filterData.length == 0) {
                                let data = {
                                  _id: topic,
                                  sessions: [],
                                  totalQuestions: tQues,
                                };
                                result.push(data);
                              }
                            });
                        }
                      });
                    }
                  });
                }
              });
          } else if (status === "empty") {
            userInfo &&
              userInfo.items &&
              Object.entries(userInfo.items).map((subject) => {
                if (subject[0] == selectedSubjectId) {
                  Object.entries(subject[1].test).map((grade) => {
                    if (grade[0] === selectedGradeId) {
                      Object.entries(grade[1]).map((terms) => {
                        if (terms[0] == selectedTermsId) {
                          terms[1].length > 0 &&
                            terms[1].map((topic) => {
                              let data = {
                                _id: topic,
                                sessions: [],
                                totalQuestions: tQues,
                              };
                              result.push(data);
                            });
                        }
                      });
                    }
                  });
                }
              });
          }
          this.setState({
            testInfo: result,
            status: "success",
          });
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => {}
      );
    }
  };

  isCompleted(item) {
    let count = 0;
    item &&
      item.sessions &&
      item.sessions.length > 0 &&
      item.sessions.map((session) => {
        if (!session.completed) {
          count = count + 1;
        }
      });
    return count;
  }

  getOverallTest = () => {
    const {
      userId,
      selectedSubjectId,
      selectedGradeId,
      overallArray,
      subjectName,
      translation,
      mode,
    } = this.state;
    const { userInfo, allStudent } = this.props;
    if (selectedSubjectId && selectedGradeId) {
      this.setState({
        status: "process",
        termsName: "Overall",
        tName: translation.overall,
      });
      let url = "DB_MY_TEST_OVERALL_LIST";
      let queryParam = `?userId=${
        userId != "all" ? userId : allStudent
      }&classId=${selectedSubjectId}&topicId=${selectedGradeId}`;
      if (mode !== "") {
        queryParam += `&mode=${mode}`;
      }
      let data = {
        method: "GET",
        url: url,
        query: queryParam,
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResult(res);
          if (status == "success") {
            userInfo &&
              userInfo.items &&
              Object.entries(userInfo.items).map((subject) => {
                if (subject[0] == selectedSubjectId) {
                  overallArray.map((overall) => {
                    let filterData =
                      result.length > 0 &&
                      result.filter((item) => item.mode == overall.mode);
                    if (filterData.length == 0) {
                      overall["totalQuestions"] = common.getQuestionLimit(
                        overall.mode == "time_based",
                        "overall",
                        subjectName
                      );
                      result.push(overall);
                    } else {
                      filterData[0]["totalQuestions"] = common.getQuestionLimit(
                        overall.mode == "time_based",
                        "overall",
                        subjectName
                      );
                      //subject[1].questionsAllowed;
                    }
                  });
                }
              });
          } 
          else if (status === "empty" && userId !== "all") {
            userInfo &&
              userInfo.items &&
              Object.entries(userInfo.items).map((subject) => {
                if (subject[0] == selectedSubjectId) {
                  overallArray.map((res, idx) => {
                    overallArray[idx]["totalQuestions"] =
                      common.getQuestionLimit(
                        res.mode == "time_based",
                        "overall",
                        subjectName
                      );
                    //subject[1].questionsAllowed;
                  });
                }
              });
            result = overallArray;
          }
          if(result.length > 0 && this.props.studentList.length > 0 ) {
            let arr = this.getArrayDifference(this.props.studentList, result);
            arr.length > 0 && arr.map(item => {
              let val = {
                _id: item,
                sessions: []
              }
              result.push(val)
            }) 
          } else {
            this.props.studentList.length > 0 && this.props.studentList.map((user) => {
              let val = {
                "sessions": [],
                "_id": user
              }
              result.push(val);
            })
          }
          this.setState({
            testInfo: result,
            status: "success",
          });
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => {}
      );
    } else {
      common.snack("E", translation.select_sub_grade);
    }
  };

  getModalPaperTestSession = (e) => {
    const {
      selectedSubjectId,
      overallArray,
      subjectName,
      translation,
      userId,
    } = this.state;
    const { allStudent } = this.props;
    // if (selectedSubjectId && selectedGradeId) {
    if (selectedSubjectId) {
      this.setState({
        status: "process",
        termsName: "Model Paper",
        tName: translation.model_paper,
      });
      let query = `?userId=${
        userId != "all" ? userId : allStudent
      }&classId=${selectedSubjectId}`;
      let data = {
        method: "GET",
        headerFlag: true,
        url: "DB_MY_TEST_MODAL_PAPER_LIST",
        query: query,
        authorization: "token",
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = [];
          let testData = [];
          if (status == "success") {
            result = common.apiResult(res);
            result.length > 0 &&
              result.map((item, key) => {
                let totalQuestions = 0;
                item.sessions &&
                  item.sessions.map((session) => {
                    //totalQuestions += session.tQCount;
                    totalQuestions += common.getQuestionLimit(
                      true,
                      "model",
                      subjectName
                    );
                  });
                result[key]["totalQuestions"] =
                  totalQuestions / item.sessions.length;
                result[key]["name"] = translation.start_new_session; //"Start a new session";
              });
            testData = result;
          } else if (status == "empty" && userId !== "all") {
            overallArray[1]["name"] = translation.start_new_session; //"Start a new session";
            testData.push(overallArray[1]);
          }
          if(common.apiResult(res).length > 0 && this.props.studentList.length > 0 ) {
            let arr = this.getArrayDifference(this.props.studentList, testData);
            arr.length > 0 && arr.map(item => {
              let val = {
                _id: item,
                sessions: []
              }
              testData.push(val)
            }) 
          } else {
            this.props.studentList.length > 0 && this.props.studentList.map((user) => {
              let val = {
                "sessions": [],
                "_id": user
              }
              testData.push(val);
            })
          }
          this.setState({
            testInfo: testData,
            status: "success",
          });
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => {}
      );
    } else {
      common.snack("E", translation.select_sub_grade);
    }
  };

  startTest = (e, item) => {
    e.preventDefault();
    const {
      selectedSubjectId,
      selectedGradeId,
      termsName,
      selectedYear,
      translation,
    } = this.state;
    let isAllow = this.checkPlan(item, "");
    if (isAllow) {
      let testURL = "";
      let isMode = false;
      this.setState({
        isModelOpen: false,
      });
      if (item.mode == "time_based") {
        isMode = true;
      }
      if (termsName == "Past Paper") {
        testURL = `/tests/${selectedSubjectId}/mocktest/${selectedGradeId}?mode=true&year=${selectedYear}&paper=false`;
      } else if (termsName == "Model Paper") {
        testURL = `/tests/${selectedSubjectId}/mocktest/${selectedGradeId}?mode=true&year=0&paper=true`;
      } else {
        if (envConfig.minTestAllowedQCount < item.totalQuestions) {
          if (this.isCompleted(item) < 5) {
            //checking in-progress test count
            if (termsName == "Overall") {
              testURL = `/tests/${selectedSubjectId}/overall/${selectedGradeId}?mode=${isMode}&year=0&paper=false`;
            } else {
              testURL =
                "/tests/" +
                selectedSubjectId +
                "/" +
                item._id +
                "/" +
                selectedGradeId +
                "?mode=" +
                isMode +
                "&year=0&paper=false";
            }
          } else {
            common.snack("E", translation.incomplete_start_new);
          }
        } else {
          common.snack("I", translation.no_ques_available);
        }
      }
      history.push(testURL);
    }
  };

  continueTest = (e, item, testId, session, mode) => {
    e.preventDefault();
    const { selectedSubjectId, selectedGradeId, termsName, selectedYear } =
      this.state;
    let isAllow = this.checkPlan(item, session._id);

    if (isAllow) {
      let isMode = false;
      let testURL = "";
      let year = session.questionYear ? session.questionYear : selectedYear;
      if (mode == "time_based") {
        isMode = true;
      }
      if (termsName == "Overall") {
        testURL = `/tests/${selectedSubjectId}/overall/${selectedGradeId}/${session._id}?mode=${isMode}&year=0&paper=false`;
      } else if (termsName == "Past Paper") {
        testURL = `/tests/${selectedSubjectId}/mocktest/${selectedGradeId}/${session._id}?mode=true&year=${year}&paper=false`;
      } else if (termsName == "Model Paper") {
        testURL = `/tests/${selectedSubjectId}/mocktest/${selectedGradeId}/${session._id}?mode=true&year=0&paper=true`;
      } else {
        if (!session.completed) {
          testURL =
            "/tests/" +
            selectedSubjectId +
            "/" +
            testId +
            "/" +
            selectedGradeId +
            "/" +
            session._id +
            "?mode=" +
            isMode +
            "&year=0&paper=false";
        }
      }
      history.push(testURL);
    }
  };

  scoreProgress() {
    const { testInfo } = this.state;
    let totalQuestions = 0;
    let correctAnswer = 0;
    let score = 0;
    testInfo &&
      testInfo.length > 0 &&
      testInfo.map((data) => {
        totalQuestions += data.totalQuestions
          ? data.totalQuestions *
            (data.sessions.length > 0 ? data.sessions.length : 1)
          : 0;
        correctAnswer += data.answered ? data.answered : 0;
      });
    score =
      correctAnswer > 0 && totalQuestions > 0
        ? Math.round((correctAnswer / totalQuestions) * 100)
        : 0;
    return score;
  }

  timeProgress() {
    const { testInfo } = this.state;
    let totalTime = 0;
    testInfo &&
      testInfo.length > 0 &&
      testInfo.map((data) => {
        totalTime += data.time ? Math.abs(data.time) : 0;
      });
    return totalTime;
  }

  questionProgress() {
    const { testInfo } = this.state;
    let question = 0;
    testInfo &&
      testInfo.length > 0 &&
      testInfo.map((data) => {
        question += data.question ? data.question : 0;
      });
    return question;
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({
      isModelOpen: false,
    });
  };

  onChangeTestSelection = (event, option) => {
    let year = new Date().getFullYear() - 1;
    if (this.state.selectedYear) {
      year = this.state.selectedYear;
    }
    this.setState({
      selectedYear: year,
    });
  };

  handleYearChange = (event) => {
    const { value } = event.target;
    let error = "";
    if (value == 0) {
      error = "Please select past paper year to start the test";
    }
    this.setState({ selectedYear: value, error });
  };

  takeMockTest = (e, item) => {
    e.preventDefault();
    const { testInfo, translation } = this.state;
    let isAllow = this.checkPlan(item, "");
    if (isAllow) {
      if (item._id) {
        if (this.isCompleted(item) < 5) {
          this.setState(
            {
              isModelOpen: false,
              selectedYear: item._id,
            },
            (_) => {
              this.startTest(e, testInfo[0]);
            }
          );
        } else {
          common.snack("E", translation.incomplete_start_new);
        }
      } else {
        this.setState({
          isModelOpen: true,
          selectedYear: new Date().getFullYear() - 1,
        });
      }
    }
  };

  componentWillUnmount() {
    const {
      selectedSubjectId,
      selectedGradeId,
      selectedTermsId,
      termsName,
      subjectName,
      gradeName,
    } = this.state;
    let testData = {
      subjectId: selectedSubjectId,
      gradeId: selectedGradeId,
      termsId: selectedTermsId,
      termName: termsName,
      subjectName: subjectName,
      gradeName: gradeName,
    };
    common.setStorage("testData", tools.crypt(testData, true));
    localStorage.removeItem("page");
  }

  // pricing plan
  checkPlan(item = null, session) {
    const { termsName, userPlan, todayTest, mockTest } = this.state;
    let allow = false;
    let freeAlert = "";
    let freeLimit = this.checkLimit(item.totalQuestions);
    if (userPlan.paymentStatus == "active") {
      allow = true;
    } else if (
      (userPlan.paymentGateway == "free" ||
        userPlan.paymentGateway == "trial") &&
      freeLimit
    ) {
      if (["Overall", "Past Paper", "Model Paper"].includes(termsName)) {
        if (item.mode != "practice_based") {
          if (termsName == "Past Paper") {
            if (session == "") {
              if (mockTest == "") {
                allow = true;
              } else {
                freeAlert = "one_mock_test";
              }
            } else {
              // if (mockTest == "") {
              //   freeAlert = "previous_test";
              // } else if (session != mockTest[0].sessions[0]._id) {
              //   freeAlert = "previous_test";
              // } else
              if (session == mockTest[0].sessions[0]._id) {
                allow = true;
              }
            }
          } else {
            if (session == "") {
              if (todayTest == "") {
                allow = true;
              } else {
                freeAlert = "new_test";
              }
            } else {
              if (todayTest == "") {
                freeAlert = "previous_test";
              } else if (session != todayTest[0].sessions[0]._id) {
                freeAlert = "previous_test";
              } else if (session == todayTest[0].sessions[0]._id) {
                allow = true;
              }
            }
          }
        }
      }
    }

    if (!allow) {
      this.setState({
        pricingModal: true,
        freeLimit: freeLimit,
        freeAlert,
      });
    }

    return allow;
  }

  checkLimit(totalQuestions) {
    const { todayTest, userPlan, termsName } = this.state;
    let freeLimit = true;
    if (termsName == "Past Paper") {
      return freeLimit;
    }
    if (userPlan.plan.planId == "free") {
      if (todayTest != "") {
        let totalTime = totalQuestions * 60;
        let totSpentTime = totalTime - todayTest[0].sessions[0].lastTime;
        if (
          totalTime == totSpentTime ||
          totSpentTime >= userPlan.plan.timeBasedTest.min ||
          todayTest[0].question >= userPlan.plan.timeBasedTest.question ||
          todayTest[0].sessions[0].completed
        ) {
          freeLimit = false;
        }
      }
    }
    return freeLimit;
  }

  gotoPayment(e) {
    e.preventDefault();
    history.push("/payment");
  }

  setPricingModal(e, status) {
    e.preventDefault();
    this.setState({ pricingModal: status });
  }

  listTest(type) {
    this.setState({
      termsName: type,
      pricingModal: false,
    });
    switch (type) {
      case "Overall":
        this.getOverallTest();
        break;
      case "Past Paper":
        this.getMockTestSession();
        break;
      case "Model Paper":
        this.getModalPaperTestSession();
        break;
    }
  }

  setTodayTest = () => {
    const { userInfo, userPlan } = this.props;
    if (common.getStorage("testData")) {
      let testData = tools.deCrypt(common.getStorage("testData"), true);
      userInfo &&
        userInfo.items &&
        Object.entries(userInfo.items).map((subject) => {
          if (subject[0] == testData.subjectId) {
            this.setState({
              grade: subject[1].grade,
              terms: subject[1].term,
            });
          }
        });
      this.setState(
        {
          selectedSubjectId: testData.subjectId,
          selectedGradeId: testData.gradeId,
          selectedTermsId: testData.termsId,
          termsName: testData.termName,
          subjectName: testData.subjectName,
          gradeName: testData.gradeName,
          testId: testData.testId,
          testName: testData.testName,
        },
        (_) => {
          if (testData.gradeId && testData.subjectId && testData.termName) {
            if (testData.termName == "Overall") {
              this.getOverallTest();
            } else if (testData.termName == "Past Paper") {
              this.getMockTestSession();
            } else if (testData.termName == "Model Paper") {
              this.getModalPaperTestSession();
            } else {
              this.getTopicTest();
              this.getTodayAttendedTest();
            }
          }
        }
      );
    }
  };

  render() {
    const { userInfo } = this.props;
    const {
      subjectName,
      gradeName,
      termsName,
      grade,
      terms,
      testInfo,
      status,
      userData,
      yearList,
      selectedYear,
      error,
      isModelOpen,
      userPlan,
      pricingModal,
      freeLimit,
      freeAlert,
      mockTest,
      translation,
      tName,
      gName,
      topics,
      topicName,
      userId,
      modeName,
      overallMode,
      yearName,
      paperYear,
      paperYearList,
    } = this.state;
    const { selectedStudent } = this.props;
    let score = 0;
    let totalTime = 0;
    let questions = 0;
    if (userInfo) {
      score = this.scoreProgress();
      totalTime = this.timeProgress();
      questions = this.questionProgress();
    }
    const isEnabled = (termName, mode, sessionLength) => {
      if (
        termName == "Overall" &&
        mode &&
        mode == "time_based" &&
        sessionLength < 1
      ) {
        return true;
      }
      return (
        userData.user.role !== 1 ||
        (userData.user.role !== 4 &&
          userPlan &&
          userPlan.plan &&
          userPlan.plan.planId !== "free" &&
          userPlan.paymentStatus !== "expired")
      );
    };
    let goalTestIds = [];

    this.props.todayGoalList.map((obj) => {
      this.props.todayGoalList.map((obj) => {
        obj.test.map((a) => {
          goalTestIds.push(a.id);
        });
      });
    });

    const isGoal = (testId) => {
      return goalTestIds.includes(testId);
    };
    let mock_test =
      (userPlan.paymentGateway == "free" ||
        userPlan.paymentGateway == "trial") &&
      mockTest.length === 0
        ? true
        : mockTest.length > 0 && !mockTest[0].sessions[0].completed
        ? true
        : false;
    return (
      <div>
        <div className="tab-header  d-flex flex-wrap justify-content-between align-items-center">
          {userData.user.role != 4 ? (
            <h5 className="mb-0">{translation.mytests}</h5>
          ) : (
            <h5>{selectedStudent && selectedStudent.name}</h5>
          )}

          {mock_test && userData.user.role != 4 && (
            <div className="tab-actions">
              <Link to="#" className="mock-exam-btn text-center">
                <p className="mb-0">{translation.one_mock_exam_available_1}</p>
                <p className="mb-0">{translation.one_mock_exam_available_2}</p>
              </Link>
            </div>
          )}
        </div>
        <div className="filter-wrap row mb-2">
          {userData.user.role != 4 && (
            <div className="col-md-4">
              <div className="select-box">
                <div className="select-box__current" tabIndex="1">
                  <div className="select-box__value">
                    <input
                      className="select-box__input"
                      type="radio"
                      id="subject_1"
                      value="2"
                      name="subject"
                      defaultChecked="checked"
                    />
                    <p className="select-box__input-text">
                      {subjectName !== ""
                        ? subjectName
                        : translation.mock_subject}
                    </p>
                  </div>
                  <div className="select-box__icon">
                    <img
                      src={common.loadImg("dropdown-arrow.png")}
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <ul className="select-box__list">
                  {userInfo &&
                    userInfo.items &&
                    Object.entries(userInfo.items).map((item, key) => {
                      return (
                        <li
                          key={key}
                          onClick={(e) =>
                            this.onChangeSubjects(item[0], item[1])
                          }
                        >
                          <label
                            className="select-box__option"
                            htmlFor="subject_0"
                            aria-hidden="aria-hidden"
                          >
                            {item[1].name}
                          </label>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}

          <div className="col-md-4">
            <div className="select-box">
              <div className="select-box__current" tabIndex="2">
                <div className="select-box__value">
                  <input
                    className="select-box__input"
                    type="radio"
                    id="grade_2"
                    value="2"
                    name="grade"
                    defaultChecked="checked"
                  />
                  <p className="select-box__input-text">
                    {gName !== "" ? gName : translation.mock_grade}
                  </p>
                </div>
                <div className="select-box__icon">
                  <img
                    src={common.loadImg("dropdown-arrow.png")}
                    aria-hidden="true"
                  />
                </div>
              </div>
              <ul className="select-box__list">
                {grade &&
                  grade.length > 0 &&
                  grade.map((item, key) => {
                    return (
                      <li
                        key={key}
                        onClick={(e) =>
                          this.onChangeGrade(item, userInfo.names[item])
                        }
                      >
                        <label
                          className="select-box__option"
                          htmlFor="grade_0"
                          aria-hidden="aria-hidden"
                        >
                          {userInfo.names[item]}
                        </label>
                      </li>
                    );
                  })}
                {subjectName !== "" && (
                  <li onClick={(e) => this.onChangeGrade("", "")}>
                    <label
                      className="select-box__option"
                      htmlFor="grade_0"
                      aria-hidden="aria-hidden"
                    >
                      {/* Past/Model Paper */}
                      {translation.past_model_paper}
                    </label>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="select-box">
              <div className="select-box__current" tabIndex="3">
                <div className="select-box__value">
                  <input
                    className="select-box__input"
                    type="radio"
                    id="term_0"
                    value="2"
                    name="term"
                    defaultChecked="checked"
                  />
                  <p className="select-box__input-text">
                    {tName !== "" ? tName : translation.mock_term}
                  </p>
                </div>
                <div className="select-box__icon">
                  <img
                    src={common.loadImg("dropdown-arrow.png")}
                    aria-hidden="true"
                  />
                </div>
              </div>
              <ul className="select-box__list">
                {gradeName != "Past/Model Paper" &&
                  terms &&
                  terms.length > 0 &&
                  terms.map((item, key) => {
                    return (
                      <li
                        key={key}
                        onClick={(e) =>
                          this.onChangeTerm(item, userInfo.names[item])
                        }
                      >
                        <label
                          className="select-box__option"
                          htmlFor="term_0"
                          aria-hidden="aria-hidden"
                        >
                          {userInfo.names[item]}
                        </label>
                      </li>
                    );
                  })}
                {gradeName != "Past/Model Paper" && (
                  <li onClick={(e) => this.onChangeOverall()}>
                    <label
                      className="select-box__option"
                      htmlFor="term_0"
                      aria-hidden="aria-hidden"
                    >
                      {/* Overall */}
                      {translation.overall}
                    </label>
                  </li>
                )}
                {gradeName == "Past/Model Paper" && (
                  <li onClick={(e) => this.onchangeMockTest(e)}>
                    <label
                      className="select-box__option"
                      htmlFor="term_0"
                      aria-hidden="aria-hidden"
                    >
                      {/* Past Paper */}
                      {translation.mock_test}
                    </label>
                  </li>
                )}
                {gradeName == "Past/Model Paper" && (
                  <li onClick={(e) => this.onChangeModalPaper(e)}>
                    <label
                      className="select-box__option"
                      htmlFor="term_0"
                      aria-hidden="aria-hidden"
                    >
                      {/* Model Paper */}
                      {translation.model_paper}
                    </label>
                  </li>
                )}
              </ul>
            </div>
          </div>

          {/* </div>
        <div className="filter-wrap row mb-2"> */}
          {userData.user.role == 4 &&
            userId == "all" &&
            overallMode.length > 0 && (
              <div className="col-md-4">
                <div className="select-box">
                  <div className="select-box__current" tabIndex="1">
                    <div className="select-box__value">
                      <input
                        className="select-box__input"
                        type="radio"
                        id="topics_0"
                        value="2"
                        name="topics"
                        defaultChecked="checked"
                      />
                      <p className="select-box__input-text">
                        {modeName !== "" ? modeName : translation.mock_mode}
                      </p>
                    </div>
                    <div className="select-box__icon">
                      <img
                        src={common.loadImg("dropdown-arrow.png")}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <ul className="select-box__list">
                    {overallMode.map((item, key) => {
                      return (
                        <li key={key} onClick={(e) => this.onChangeModel(item)}>
                          <label
                            className="select-box__option"
                            htmlFor="topics_0"
                            aria-hidden="aria-hidden"
                          >
                            {item.name}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}

          {userData.user.role == 4 && userId == "all" && topics.length > 0 && (
            <div className="col-md-4">
              <div className="select-box">
                <div className="select-box__current" tabIndex="1">
                  <div className="select-box__value">
                    <input
                      className="select-box__input"
                      type="radio"
                      id="topics_0"
                      value="2"
                      name="topics"
                      defaultChecked="checked"
                    />
                    <p className="select-box__input-text">
                      {topicName !== "" ? topicName : translation.mock_topic}
                    </p>
                  </div>
                  <div className="select-box__icon">
                    <img
                      src={common.loadImg("dropdown-arrow.png")}
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <ul className="select-box__list">
                  {topics &&
                    topics.length > 0 &&
                    topics.map((item, key) => {
                      return (
                        <li
                          key={key}
                          onClick={(e) =>
                            this.onChangeTopic(item, userInfo.names[item])
                          }
                        >
                          <label
                            className="select-box__option"
                            htmlFor="topics_0"
                            aria-hidden="aria-hidden"
                          >
                            {userInfo.names[item]}
                          </label>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}

          {userData.user.role == 4 &&
            userId == "all" &&
            termsName == "Past Paper" && (
              <div className="col-md-4">
                <div className="select-box">
                  <div className="select-box__current" tabIndex="1">
                    <div className="select-box__value">
                      <input
                        className="select-box__input"
                        type="radio"
                        id="topics_0"
                        value="2"
                        name="topics"
                        defaultChecked="checked"
                      />
                      <p className="select-box__input-text">
                        {yearName !== "" ? yearName : translation.select_year}
                      </p>
                    </div>
                    <div className="select-box__icon">
                      <img
                        src={common.loadImg("dropdown-arrow.png")}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <ul className="select-box__list">
                    {paperYearList &&
                      paperYearList.length > 0 &&
                      paperYearList.map((item, key) => {
                        return (
                          <li
                            key={key}
                            onClick={(e) => this.onChangeYear(item)}
                          >
                            <label
                              className="select-box__option"
                              htmlFor="topics_0"
                              aria-hidden="aria-hidden"
                            >
                              {item.label}
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            )}
        </div>
        {status !== "" && <StatusBar status={status} />}
        {status == "success" && testInfo && testInfo.length > 0 && (
          <div className="tab-body">
            {userData.user.role != 4 && (
              <div className="task-heading d-flex flex-wrap justify-content-between">
                <h3>{subjectName}</h3>
                <div className="progress-score">
                  <div className="progress-bar-wrap mt-2">
                    <div className="progress-header d-flex justify-content-between">
                      <p className="progress-text-sm mb-0">
                        {translation.score}
                      </p>
                    </div>

                    <div className="progress">
                      <div
                        className="progress-bar bg-info"
                        style={{ width: score + "%" }}
                      >
                        <span
                          className="popOver"
                          data-toggle="tooltip"
                          data-placement="top"
                        >
                          {score}%
                        </span>
                      </div>
                    </div>
                    <p className="mt-2">
                      {translation.time_spent} {common.toHHMMSS(totalTime)} hrs{" "}
                      <br />
                      {questions} {translation.questions}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div id="accordion" className="accordion-block mt-4">
              <div className="card">
                <div className="card-header without-arrow" id="heading-1">
                  <h5 className="mb-0">
                    <a role="button">
                      {topicName
                        ? topicName
                        : modeName
                        ? modeName
                        : yearName
                        ? yearName
                        : tName}
                    </a>
                  </h5>
                </div>
                {userId != "all" && (
                  <div
                    id="collapses-1"
                    className="collapse show"
                    data-parent="#accordion"
                    aria-labelledby="heading-1"
                  >
                    <div className="card-body">
                      <div id="accordion-1">
                        {testInfo.map((item, key) => {
                          return (
                            <div className="card" key={key}>
                              <div
                                className={
                                  (item.sessions && item.sessions.length > 0) ||
                                  Object.keys(item.sessions).length > 0
                                    ? "card-header"
                                    : "card-header without-arrow"
                                }
                                id={"heading-1-1"}
                              >
                                <h5 className="mb-0">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-toggle="collapse"
                                    href={
                                      item.sessions && item.sessions.length > 0
                                        ? "#collapses-1-" + (key + 1)
                                        : ""
                                    }
                                    aria-expanded="false"
                                    aria-controls="collapses-1-1"
                                  >
                                    <span className="testName">
                                      {termsName != "Past Paper" &&
                                      termsName !== "Model Paper"
                                        ? item.mode
                                          ? item.mode == "practice_based"
                                            ? translation.practice_based
                                            : translation.time_based
                                          : userInfo.names[item._id]
                                        : termsName == "Model Paper"
                                        ? item.name
                                        : item.year}{" "}
                                      {isGoal(item._id) && (
                                        <span className="badge badge-danger">
                                          {translation.today_goal}
                                        </span>
                                      )}
                                    </span>
                                  </a>

                                  {item.sessions &&
                                    item.sessions.length > 0 &&
                                    userData.user.role !== 1 &&
                                    userData.user.role !== 4 && (
                                      <span>
                                        <a
                                          href="#"
                                          className="btn-sm start"
                                          onClick={(e) =>
                                            termsName == "Past Paper"
                                              ? this.takeMockTest(e, item)
                                              : this.startTest(e, item)
                                          }
                                        >
                                          {/* {"Start New"} */}
                                          {translation.start_new}
                                        </a>
                                      </span>
                                    )}
                                  {item.sessions &&
                                    item.sessions.length == 0 &&
                                    userData.user.role !== 1 &&
                                    userData.user.role !== 4 && (
                                      <span>
                                        <a
                                          href="#"
                                          className="btn-sm start-now"
                                          onClick={(e) =>
                                            termsName == "Past Paper"
                                              ? this.takeMockTest(e, item)
                                              : this.startTest(e, item)
                                          }
                                        >
                                          {translation.start_now}
                                        </a>
                                      </span>
                                    )}
                                </h5>
                              </div>
                              <div
                                id={
                                  item.sessions && item.sessions.length > 0
                                    ? "collapses-1-" + (key + 1)
                                    : "collapses-1"
                                }
                                className="collapse"
                                data-parent="#accordion-1"
                                aria-labelledby="heading-1-1"
                              >
                                <div className="card-body">
                                  {item.sessions &&
                                    item.sessions.length > 0 &&
                                    item.sessions.map((session, index) => {
                                      let totQuestions = session.completed
                                        ? session.question
                                        : item.totalQuestions;
                                      // let totQuestions =
                                      //   termsName === "Past Paper" ||
                                      //   termsName === "Model Paper"
                                      //     ? session.tQCount
                                      //     : session.completed &&
                                      //       termsName != "Overall"
                                      //     ? session.question
                                      //     : item.totalQuestions;
                                      let correct = Math.round(
                                        (session.answered / totQuestions) * 100
                                      );
                                      let wrong = Math.round(
                                        ((session.question - session.answered) /
                                          totQuestions) *
                                          100
                                      );
                                      let unanswered = 100 - (correct + wrong);
                                      return (
                                        <div
                                          className="test-session-row d-flex flex-wrap justify-content-between"
                                          key={index}
                                        >
                                          <div className="test-session-info">
                                            <p>
                                              {translation.test_session}{" "}
                                              {String(index + 1).padStart(
                                                2,
                                                "0"
                                              )}{" "}
                                              <span>|</span>
                                              {tools.formatDate(session.cOn)}
                                            </p>
                                          </div>
                                          <div className="test-session-report">
                                            <div className="progress-bar-values d-flex">
                                              {correct > 0 && (
                                                <span
                                                  className="text-success"
                                                  style={{
                                                    width: correct + "%",
                                                  }}
                                                ></span>
                                              )}
                                              {wrong > 0 && (
                                                <span
                                                  className="text-danger"
                                                  style={{
                                                    width: wrong + "%",
                                                  }}
                                                ></span>
                                              )}
                                              {unanswered > 0 && (
                                                <span
                                                  className="text-warning"
                                                  style={{
                                                    width: unanswered + "%",
                                                  }}
                                                ></span>
                                              )}
                                            </div>
                                            <div className="progress progress-sm">
                                              {correct > 0 && (
                                                <div
                                                  className="progress-bar bg-success"
                                                  style={{
                                                    width: correct + "%",
                                                  }}
                                                ></div>
                                              )}
                                              {wrong > 0 && (
                                                <div
                                                  className="progress-bar bg-danger"
                                                  style={{
                                                    width: wrong + "%",
                                                  }}
                                                ></div>
                                              )}
                                              {unanswered > 0 && (
                                                <div
                                                  className="progress-bar bg-warning"
                                                  style={{
                                                    width: unanswered + "%",
                                                  }}
                                                ></div>
                                              )}
                                            </div>

                                            <div className="status-review d-flex flex-wrap justify-content-between">
                                              <div className="status-info pr-2 mt-3">
                                                <label className="success">
                                                  <strong>{correct}%</strong>{" "}
                                                  {translation.correct}
                                                </label>
                                                <label className="danger">
                                                  <strong>{wrong}%</strong>{" "}
                                                  {translation.wrong}
                                                </label>
                                                <label className="warning">
                                                  <strong>{unanswered}%</strong>{" "}
                                                  {translation.unaswered}
                                                </label>
                                              </div>
                                              <div className="marks-value">
                                                <label>
                                                  {translation.marks}{" "}
                                                  <span>
                                                    {session.answered &&
                                                      session.answered *
                                                        envConfig.CORRECT_ANSWER_SCORE}
                                                  </span>
                                                </label>
                                                <label>
                                                  {translation.actual_marks}{" "}
                                                  <span>
                                                    {totQuestions &&
                                                      totQuestions *
                                                        envConfig.CORRECT_ANSWER_SCORE}
                                                  </span>
                                                </label>
                                              </div>
                                              {!session.completed ? (
                                                userData.user.role !== 1 &&
                                                userData.user.role !== 4 ? (
                                                  <a
                                                    href="#"
                                                    className="btn-sm take-test"
                                                    onClick={(e) =>
                                                      this.continueTest(
                                                        e,
                                                        item,
                                                        item._id,
                                                        session,
                                                        item.mode
                                                      )
                                                    }
                                                  >
                                                    {translation.continue}
                                                  </a>
                                                ) : (
                                                  userData.user.role === 1 ||
                                                  (userData.user.role == 4 && (
                                                    <a
                                                      href="#"
                                                      onClick={(e) =>
                                                        e.preventDefault()
                                                      }
                                                      className="btn-sm take-test"
                                                    >
                                                      {translation.in_progress}
                                                    </a>
                                                  ))
                                                )
                                              ) : (
                                                <span className="btn-sm completed">
                                                  {translation.completed}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {userId == "all" && (
                  <div
                    id="collapses-1"
                    className="collapse show"
                    data-parent="#accordion"
                    aria-labelledby="heading-1"
                  >
                    <div className="card-body">
                      <div id="accordion-1">
                        {testInfo.map((item, key) => {
                          return (
                            <div className="card" key={key}>
                              {item._id && (
                                <>
                                  <div className="card-body">
                                    {item.sessions &&
                                      item.sessions.length > 0 &&
                                      item.sessions.map((session, index) => {
                                        let totQuestions = session.completed
                                          ? session.question
                                          : item.totalQuestions;
                                        let correct = Math.round(
                                          (session.answered / totQuestions) *
                                            100
                                        );
                                        let wrong = Math.round(
                                          ((session.question -
                                            session.answered) /
                                            totQuestions) *
                                            100
                                        );
                                        let unanswered =
                                          100 - (correct + wrong);
                                        return (
                                          <div
                                            className="test-session-row d-flex flex-wrap justify-content-between"
                                            key={index}
                                          >
                                            <div className="test-session-info">
                                              <p>
                                                {item._id &&
                                                  item._id.name &&
                                                  item._id.name}
                                                <br></br>
                                                {translation.test_session}{" "}
                                                {String(index + 1).padStart(
                                                  2,
                                                  "0"
                                                )}{" "}
                                                <span>|</span>
                                                {tools.formatDate(session.cOn)}
                                              </p>
                                            </div>
                                            <div className="test-session-report">
                                              <div className="progress-bar-values d-flex">
                                                {correct > 0 && (
                                                  <span
                                                    className="text-success"
                                                    style={{
                                                      width: correct + "%",
                                                    }}
                                                  ></span>
                                                )}
                                                {wrong > 0 && (
                                                  <span
                                                    className="text-danger"
                                                    style={{
                                                      width: wrong + "%",
                                                    }}
                                                  ></span>
                                                )}
                                                {unanswered > 0 && (
                                                  <span
                                                    className="text-warning"
                                                    style={{
                                                      width: unanswered + "%",
                                                    }}
                                                  ></span>
                                                )}
                                              </div>
                                              <div className="progress progress-sm">
                                                {correct > 0 && (
                                                  <div
                                                    className="progress-bar bg-success"
                                                    style={{
                                                      width: correct + "%",
                                                    }}
                                                  ></div>
                                                )}
                                                {wrong > 0 && (
                                                  <div
                                                    className="progress-bar bg-danger"
                                                    style={{
                                                      width: wrong + "%",
                                                    }}
                                                  ></div>
                                                )}
                                                {unanswered > 0 && (
                                                  <div
                                                    className="progress-bar bg-warning"
                                                    style={{
                                                      width: unanswered + "%",
                                                    }}
                                                  ></div>
                                                )}
                                              </div>

                                              <div className="status-review d-flex flex-wrap justify-content-between">
                                                <div className="status-info pr-2 mt-3">
                                                  <label className="success">
                                                    <strong>{correct}%</strong>{" "}
                                                    {translation.correct}
                                                  </label>
                                                  <label className="danger">
                                                    <strong>{wrong}%</strong>{" "}
                                                    {translation.wrong}
                                                  </label>
                                                  <label className="warning">
                                                    <strong>
                                                      {unanswered}%
                                                    </strong>{" "}
                                                    {translation.unaswered}
                                                  </label>
                                                </div>
                                                <div className="marks-value">
                                                  <label>
                                                    {translation.marks}{" "}
                                                    <span>
                                                      {session.answered &&
                                                        session.answered *
                                                          envConfig.CORRECT_ANSWER_SCORE}
                                                    </span>
                                                  </label>
                                                  <label>
                                                    {translation.actual_marks}{" "}
                                                    <span>
                                                      {totQuestions &&
                                                        totQuestions *
                                                          envConfig.CORRECT_ANSWER_SCORE}
                                                    </span>
                                                  </label>
                                                </div>
                                                {!session.completed ? (
                                                  userData.user.role !== 1 &&
                                                  userData.user.role !== 4 ? (
                                                    <a
                                                      href="#"
                                                      className="btn-sm take-test"
                                                      onClick={(e) =>
                                                        this.continueTest(
                                                          e,
                                                          item,
                                                          item._id,
                                                          session,
                                                          item.mode
                                                        )
                                                      }
                                                    >
                                                      {translation.continue}
                                                    </a>
                                                  ) : (
                                                    userData.user.role === 1 ||
                                                    (userData.user.role ==
                                                      4 && (
                                                      <a
                                                        href="#"
                                                        onClick={(e) =>
                                                          e.preventDefault()
                                                        }
                                                        className="btn-sm take-test"
                                                      >
                                                        {
                                                          translation.in_progress
                                                        }
                                                      </a>
                                                    ))
                                                  )
                                                ) : (
                                                  <span className="btn-sm completed">
                                                    {translation.completed}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    {item.sessions &&
                                      item.sessions.length == 0 && (
                                        <div className="test-session-row d-flex flex-wrap justify-content-between">
                                          <div className="test-session-info">
                                            <p>
                                              {item._id &&
                                                item._id.name &&
                                                item._id.name}
                                            </p>
                                          </div>
                                          <div className="test-session-report">
                                            <div className="status-review d-flex flex-wrap justify-content-between">
                                              <span className="btn-sm completed">
                                                {"New Test"}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>

                                  {/* </div> */}
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {status != "" &&
          status == "success" &&
          testInfo &&
          testInfo.length == 0 && <StatusBar status={"empty"} />}
        {(subjectName == "" ||
          gradeName == "" ||
          termsName == "" ||
          (topics.length > 0 && topicName == "") ||
          (overallMode.length > 0 && modeName == "") ||
          (paperYearList.length > 0 && paperYear == "")) && (
          <div className="subject-block px-3 mb-5 mt-5">
            <div className="row">
              <div className="col-md-4 offset-md-2">
                <div className="subject-content">
                  <h2>{translation.hai}</h2>
                  <h4>{translation.what_test_today}</h4>
                  <p>{translation.select_grade_sub_term}</p>
                </div>
              </div>
              <div className="col-md-6">
                <img
                  src={common.loadImg("example_subjects_img.png")}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        )}

        <Dialog
          open={isModelOpen}
          onClose={this.closeModal}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
            {"Choose a Past Paper Year"}
          </DialogTitle>
          <DialogContent>
            <div className="card-body">
              <div className="row custom-radio justify-content-center">
                <div className="col-sm-12 justify-content-center">
                  <div className="row justify-content-center">
                    <input
                      type="radio"
                      value={false}
                      id="option2"
                      name="modalOrPastPaper"
                      checked={!this.state.modalOrPastPaper}
                      onChange={(e) => this.onChangeTestSelection(e, false)}
                    />
                    <label className="radio-label" htmlFor="option2">
                      {translation.select_year}
                    </label>
                    <select
                      name="selectedYear"
                      className="form-control"
                      value={selectedYear}
                      onChange={(e) => this.handleYearChange(e)}
                      disabled={this.state.modalOrPastPaper}
                    >
                      <option value="0">
                        {/* Select Year */}
                        {translation.select_year}
                      </option>
                      {yearList.map((data, index) => (
                        <option
                          key={index}
                          value={data.value}
                          title={data.value}
                        >
                          {data.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {error && <p className="session_warning mt-2">{error}</p>}
                <div className="form-actions mt-5 d-flex justify-content-between align-items-center">
                  <a
                    href="#"
                    className="btn bordered-btn-blue"
                    onClick={(e) => this.startTest(e, testInfo[0])}
                  >
                    Start
                  </a>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* Payment plan dialog */}
        <Dialog
          open={pricingModal}
          aria-labelledby="responsive-dialog-title"
          className="payment-plan-popup"
        >
          {/* <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
            Payment
          </DialogTitle> */}
          <DialogContent>
            <div className="card-body">
              <div className="card-options mt-5">
                {freeAlert ? (
                  freeAlert === "new_test" ? (
                    <h6 className="mt-5 mb-5">
                      {/* Allowed only one test per day{" "} */}
                      {translation.allowed_only_one_test_per_day}{" "}
                      {userData.user.role !== 2 && (
                        <React.Fragment>
                          {/* please upgrade your plan to attend more test. */}
                          {
                            translation.please_upgrade_your_plan_attend_more_test
                          }
                        </React.Fragment>
                      )}
                    </h6>
                  ) : freeAlert === "previous_test" ? (
                    <h6 className="mt-5 mb-5">
                      {/* You cannot continue the previous test{" "} */}
                      {translation.cannot_continue_prev_test}{" "}
                      {userData.user.role !== 2 && (
                        <React.Fragment>
                          {/* Please upgrade your plan to continue this test. */}
                          {translation.please_upgrade_to_continue}
                        </React.Fragment>
                      )}
                    </h6>
                  ) : (
                    freeAlert === "one_mock_test" && (
                      <h6 className="mt-5 mb-5">
                        One Past Paper out of all subjects (not each) is
                        available in the Freemium Version.{" "}
                        {userData.user.role !== 2 && (
                          <React.Fragment>
                            {/* Please upgrade your plan to continue this test. */}
                            {translation.please_upgrade_to_continue}
                          </React.Fragment>
                        )}
                      </h6>
                    )
                  )
                ) : (
                  <div>
                    <h6 className="mt-5 mb-5">
                      {(userPlan.paymentGateway == "free" ||
                        userPlan.paymentGateway == "trial") &&
                      freeLimit ? (
                        <span>
                          {/* Your are now free plan so you are eligible only to
                          attend the below tests{" "} */}
                          {translation.free_plan_eligible_only_below_test}{" "}
                          {userData.user.role !== 2 && (
                            <React.Fragment>
                              {/* please upgrade your plan to attend all test. */}
                              {translation.please_upgrade_attend_all_test}
                            </React.Fragment>
                          )}
                        </span>
                      ) : userPlan.paymentGateway == "free" ||
                        userPlan.paymentGateway == "trial" ? (
                        <span>
                          {/* You have reached your daily 10 questions or 20 minutes of user time.{" "} */}
                          {translation.you_have_reached_daily_questions}
                          {userData.user.role !== 2 && (
                            <React.Fragment>
                              {/* please upgrade to get the full access. */}
                              {translation.please_upgrade_get_full_access}
                            </React.Fragment>
                          )}
                        </span>
                      ) : (
                        userPlan.paymentStatus == "expired" && (
                          <span>
                            {/* Your plan has been expired!{" "} */}
                            {translation.your_plan_has_expired}
                            {userData.user.role !== 2 && (
                              <React.Fragment>
                                {/* please upgrade your plan to attend more test. */}
                                {
                                  translation.please_upgrade_your_plan_attend_more_test
                                }
                              </React.Fragment>
                            )}
                          </span>
                        )
                      )}
                    </h6>
                    {(userPlan.paymentGateway == "free" ||
                      userPlan.paymentGateway == "trial") &&
                      freeLimit && (
                        <ul className="eligible-list mb-5">
                          <li onClick={() => this.listTest("Overall")}>
                            {/* Overall (Time Based) */}
                            {translation.overall_time_based}
                          </li>
                          <li onClick={() => this.listTest("Model Paper")}>
                            Model Paper
                          </li>
                        </ul>
                      )}
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="form-actions d-flex justify-content-center align-items-center">
              <a
                href="#"
                className="btn bordered-btn-blue btn-pgreen mr-3"
                onClick={(e) => this.setPricingModal(e, false)}
              >
                {" "}
                Close
              </a>
              {userData.user.role !== 2 && (
                <a
                  href="#"
                  className="btn bordered-btn-blue btn-pgreen"
                  onClick={(e) => this.gotoPayment(e)}
                >
                  {userPlan.paymentGateway == "free" ||
                  userPlan.paymentGateway == "trial"
                    ? "Upgrade"
                    : "Renew"}
                </a>
              )}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default MyTests;
