import React from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import { common } from "helpers";
import { scoreRange, envConfig } from "api/config";
import GradeTable from "./GradeTable";
import { apiServices } from "api";
let translation = common.getTranslation();
class ViewGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: this.props.subject,
      showScore: "",
      totalScore: 0,
      totalTimeSpent: 0,
      selectedDates: [],
      noOfSessions: 0,
      };
  }

  getScoreStatus = (score) => {
    let statusResponse = {
      // Text: "Critical!",
      Text: translation.critical,
      Icon: "critical.png",
    };
    score = score ? score : 0;

    if (score >= scoreRange.Failed.min && score <= scoreRange.Failed.max) {
      statusResponse = {
        // Text: "Critical!",
        Text: translation.critical,
        Icon: "critical.png",
      };
    } else if (
      score >= scoreRange.SimplePass.min &&
      score <= scoreRange.SimplePass.max
    ) {
      statusResponse = {
        Text: "Review! you're missing",
        Icon: "review.png",
      };
    } else if (
      score >= scoreRange.Credit.min &&
      score <= scoreRange.Credit.max
    ) {
      statusResponse = {
        Text: "Average! More work to do",
        Icon: "review.png",
      };
    } else if (score >= scoreRange.Good.min && score <= scoreRange.Good.max) {
      statusResponse = {
        Text: "Little more good.little more work to do",
        Icon: "littlemore_good.png",
      };
    } else if (
      score >= scoreRange.Distinction.min &&
      score <= scoreRange.Distinction.max
    ) {
      Text = "you're in line with the goals";
      statusResponse = {
        Text: "you're in line with the goals",
        Icon: "goals.png",
      };
    }
    return (
      <label>
        <img src={common.loadImg(statusResponse.Icon)} /> {statusResponse.Text}
      </label>
    );
  };

  subOverallTestCount = (subject) => {
    let url = "DB_MY_TEST_OVERALL_LIST";
    let queryParam = `?userId=${this.props.userId}&classId=${subject.id}&topicId=${subject.gradeId}`;

    let data = {
      method: "GET",
      url: url,
      query: queryParam,
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          if (result.length > 0) {
            let noOfSessions = 0;
            result.map((res) => {
              noOfSessions = noOfSessions + res.sessions.length;
            });
            this.setState({
              noOfSessions,
            });
          }else{
            this.setState({
              noOfSessions : 0,
            });
          }
        }else{
          this.setState({
            noOfSessions : 0,
          });
        }
      },
      (error) => {
        this.setState({
          noOfSessions : 0,
        });
      },
      (final) => {}
    );
  };

  expand = (e, idx, subject) => {
    const { showScore } = this.state;
    this.subOverallTestCount(subject);

    let classGrade = subject.name + "_" + subject.gradeName;
    if (showScore == classGrade) {
      classGrade = "";
    }
    if (subject.sDates && subject.sDates.length > 0) {
      this.setState({ selectedDates: subject.sDates });
    }

    this.setState({
      totalScore: common.getScoreCategory(subject.totalScore),
      totalTimeSpent: subject.totalTimeSpent,
      showScore: classGrade,
    });
  };

  // event calendar
  checkUsage = (info) => {
    const { selectedDates } = this.state;
    let sDates = [];
    selectedDates.map((sDate) => {
      sDates.push(moment(sDate).format("YYYY-MM-DD"));
    });

    let currentDate = moment(info.date).format("YYYY-MM-DD");
    if (sDates.includes(currentDate)) {
      return "event-active";
    }
  };

  calSugHours = (grade, hourSpent, goalHour, tests) => {
    let sugHrs = 0;
    let comHrs = 0;
    if (grade == "Distinction") {
      comHrs = hourSpent; //show completed only
    } else if (grade == "Good") {
      if (hourSpent > goalHour) {
        sugHrs = hourSpent + 1;
        comHrs = hourSpent;
      } else {
        sugHrs = goalHour;
        comHrs = hourSpent;
      }
    } else if (grade == "Credit") {
      if (hourSpent > goalHour) {
        sugHrs = hourSpent + 2;
        comHrs = hourSpent;
      } else {
        sugHrs = tests.completed
          ? goalHour == envConfig.defaultSugHrs
            ? goalHour
            : goalHour + 1
          : goalHour;
        comHrs = hourSpent;
      }
    } else if (grade == "SimplePass") {
      if (hourSpent > goalHour) {
        sugHrs = hourSpent + 3;
        comHrs = hourSpent;
      } else {
        sugHrs = tests.completed
          ? goalHour == envConfig.defaultSugHrs
            ? goalHour
            : goalHour + 2
          : goalHour;
        comHrs = hourSpent;
      }
    } else if (grade == "Failed") {
      if (hourSpent > goalHour) {
        sugHrs = hourSpent + 4;
        comHrs = hourSpent;
      } else {
        sugHrs = tests.completed
          ? goalHour == envConfig.defaultSugHrs
            ? goalHour
            : goalHour + 3
          : goalHour;
        comHrs = hourSpent;
      }
    }
    return {
      sugHrs: sugHrs,
      comHrs: comHrs,
    };
  };

  renderGoalSuggestion = (tests) => {
    let LastWeekTimeSpent = tests.LastWeekTimeSpent
      ? tests.LastWeekTimeSpent
      : 0;
    let sugHrs = 0;
    let comHrs = 0;
    let hours = {
      sugHrs: 0,
      comHrs: 0,
    };
    if (!tests.goal) {
      sugHrs = envConfig.defaultSugHrs;
      if (LastWeekTimeSpent == 0 && tests.timeSpent == 0) {
        hours = {
          sugHrs: envConfig.defaultSugHrs,
          comHrs: 0,
        };
      } else if (LastWeekTimeSpent == 0 && tests.timeSpent > 0) {
        let scrPercentage = Math.round(
          (tests.score / (tests.tQCount * envConfig.CORRECT_ANSWER_SCORE)) * 100
        );
        let grade = common.getScoreCategory(scrPercentage);
        comHrs = common.calSecToHours(tests.timeSpent);
        hours = this.calSugHours(grade, comHrs, sugHrs, tests);
      } else if (LastWeekTimeSpent > 0 && tests.timeSpent > 0) {
        let scrPercentage = Math.round(
          (tests.score / (tests.tQCount * envConfig.CORRECT_ANSWER_SCORE)) * 100
        );
        let grade = common.getScoreCategory(scrPercentage);
        comHrs = common.calSecToHours(
          tests.timeSpent > LastWeekTimeSpent
            ? tests.timeSpent
            : LastWeekTimeSpent
        );
        hours = this.calSugHours(grade, comHrs, sugHrs, tests);
      }
    } else if (tests.goal) {
      sugHrs = tests.goal.hourPerWeek;
      if (LastWeekTimeSpent == 0 && tests.timeSpent == 0) {
        comHrs = 0;
        hours = {
          sugHrs: tests.goal.hourPerWeek,
          comHrs: 0,
        };
      } else if (LastWeekTimeSpent == 0 && tests.timeSpent > 0) {
        let scrPercentage = Math.round(
          (tests.score / (tests.tQCount * envConfig.CORRECT_ANSWER_SCORE)) * 100
        );
        let grade = common.getScoreCategory(scrPercentage);
        comHrs = common.calSecToHours(tests.timeSpent);
        hours = this.calSugHours(grade, comHrs, tests.goal.hourPerWeek, tests);
      } else if (LastWeekTimeSpent > 0 && tests.timeSpent > 0) {
        let scrPercentage = Math.round(
          (tests.score / (tests.tQCount * envConfig.CORRECT_ANSWER_SCORE)) * 100
        );
        let grade = common.getScoreCategory(scrPercentage);
        comHrs = common.calSecToHours(
          tests.timeSpent > LastWeekTimeSpent
            ? tests.timeSpent
            : LastWeekTimeSpent
        );
        hours = this.calSugHours(grade, comHrs, tests.goal.hourPerWeek, tests);
      }
    }

    let suggestedHrs = hours;
    let successPer = Math.round(
      (suggestedHrs.comHrs / suggestedHrs.sugHrs) * 100
    );
    let suggestPer = 100 - successPer;
    return suggestedHrs.sugHrs > 0 && suggestedHrs.comHrs > 0 ? (
      <td>
        <div className="goal-sugestion">
          <div className="progress progress-sm">
            <div
              className="progress-bar bg-success"
              style={{ width: successPer + "%" }}
            ></div>
            <div
              className="progress-bar bg-info"
              style={{ width: suggestPer + "%" }}
            ></div>
            <p className="progress-hours">
              <span className="text-success">{suggestedHrs.comHrs} hrs/</span>
              <span className="text-info">{suggestedHrs.sugHrs} hrs</span>
            </p>
          </div>
        </div>
      </td>
    ) : suggestedHrs.sugHrs == 0 && suggestedHrs.comHrs > 0 ? (
      <td>
        <div className="goal-sugestion">
          <div className="progress progress-sm">
            <div
              className="progress-bar bg-success"
              style={{ width: "100%" }}
            ></div>
            {/* <div
              className="progress-bar bg-info"
              style={{ width: "40%" }}
            ></div> */}
            <p className="progress-hours">
              <span className="text-success">{suggestedHrs.comHrs} hrs</span>
              {/* <span className="text-info">8hrs</span> */}
            </p>
          </div>
        </div>
      </td>
    ) : (
      suggestedHrs.sugHrs >= 0 &&
      suggestedHrs.comHrs == 0 && (
        <td>
          <div className="goal-sugestion">
            <div className="progress progress-sm">
              {/* <div
              className="progress-bar bg-success"
              style={{ width: "60%" }}
            ></div> */}
              <div
                className="progress-bar bg-info"
                style={{ width: "100%" }}
              ></div>
              <p className="progress-hours">
                {/* <span className="text-success">6hrs/</span> */}
                <span className="text-info">{suggestedHrs.sugHrs} hrs</span>
              </p>
            </div>
          </div>
        </td>
      )
    );
  };

  render() {
    const {
      subject,
      showScore,
      totalScore,
      totalTimeSpent,
      noOfSessions,
    } = this.state;
    return (
      <div className="tab-body">
        {showScore != "" && (
          <div className="set-goals-wrap">
            <h5 className="text-right mb-0">Hours</h5>
            <GradeTable
              score={{ scoreGrade: totalScore, scoreHour: totalTimeSpent }}
            />
            <div className="keep-perfect-score d-flex flex-wrap justify-content-center align-items-center mt-5 mb-4">
              <img src={common.loadImg("youreontheright_img.png")} />
              <h6>
                You're on the Right path keeps committing to
                <br /> same amount hours to keep up the perfect score.
              </h6>
            </div>
          </div>
        )}
        {/* <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </p> */}
        <div
          id="accordionViewGoals"
          className="accordion-block with-subject mt-5"
        >
          {subject.length > 0 &&
            subject.map((sub, idx) => {
              return (
                <div className="card" key={idx}>
                  <div className="card-header" id={"heading-" + idx}>
                    <h5 className="mb-0">
                      <a
                        role="button"
                        data-toggle="collapse"
                        href={"#collapse-" + idx}
                        aria-expanded="false"
                        aria-controls={"collapse-" + idx}
                        onClick={(e) => {
                          this.expand(e, idx, sub);
                        }}
                      >
                        <span>
                          {sub.name}
                          <h6 className="mb-0">{sub.gradeName}</h6>
                        </span>
                        {this.getScoreStatus(sub.totalScore)}
                      </a>
                    </h5>
                  </div>
                  <div
                    id={"collapse-" + idx}
                    className="collapse"
                    data-parent="#accordionViewGoals"
                    aria-labelledby={"heading-" + idx}
                  >
                    <div className="card-body">
                      <div className="table-responsive mb-3">
                        <table className="table progress-table table-borderless">
                          <thead>
                            <tr>
                              <th width="33%"></th>
                              <th>{translation.current_progress}</th>
                              <th width="30%">{translation.our_goal_sugestion}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sub.testList.map((terms, termIdx) => {
                              return terms.test.map((tests, tIdx) => {                                
                                let tQCount = tests.completed ?  tests.question : tests.tQCount;
                                let correct = tests.correct ? tests.correct : 0;
                                let wrong = tests.question
                                  ? tests.question - tests.correct
                                  : 0;
                                let correctPercentage = Math.round(
                                  (correct / tQCount) * 100
                                );
                                let wrongPercentage = Math.round(
                                  (wrong / tQCount) * 100
                                );
                                let unanswered =
                                  100 - (correctPercentage + wrongPercentage);
                                return (
                                  <tr key={termIdx + tIdx}>
                                    <td>{tests.test}</td>
                                    <td>
                                      <div className="test-session-report">
                                        <div className="progress-bar-values d-flex">
                                          {correctPercentage > 0 && (
                                            <span
                                              style={{
                                                width: correctPercentage + "%",
                                              }}
                                            >
                                              {correctPercentage}%
                                            </span>
                                          )}
                                          {wrongPercentage > 0 && (
                                            <span
                                              style={{
                                                width: wrongPercentage + "%",
                                              }}
                                            >
                                              {wrongPercentage}%
                                            </span>
                                          )}
                                          {unanswered > 0 && (
                                            <span
                                              style={{
                                                width: unanswered + "%",
                                              }}
                                            >
                                              {unanswered}%
                                            </span>
                                          )}
                                        </div>
                                        <div className="progress progress-sm">
                                          {correctPercentage > 0 && (
                                            <div
                                              className="progress-bar bg-success"
                                              style={{
                                                width: correctPercentage + "%",
                                              }}
                                            ></div>
                                          )}
                                          {wrongPercentage > 0 && (
                                            <div
                                              className="progress-bar bg-danger"
                                              style={{
                                                width: wrongPercentage + "%",
                                              }}
                                            ></div>
                                          )}
                                          {unanswered > 0 && (
                                            <div
                                              className="progress-bar bg-warning"
                                              style={{
                                                width: unanswered + "%",
                                              }}
                                            ></div>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    {this.renderGoalSuggestion(tests)}
                                  </tr>
                                );
                              });
                            })}
                            <tr>
                              <td></td>
                              <td>
                                <div className="test-session-report">
                                  <div className="status-info mt-2">
                                    <label className="success">{translation.correct}</label>
                                    <label className="danger">{translation.wrong}</label>
                                    <label className="warning">{translation.unaswered}</label>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="goal-sugestion">
                                  <h5 className="goal-hours">
                                    {translation.goal}: {sub.totGoalHourPerWeek} hrs
                                  </h5>
                                  <h5 className="completed-hours">
                                    {translation.completed}:{" "}
                                    {common.toHHMMSSFormat(sub.totalTimeSpent)}
                                  </h5>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </p> */}

                      <div className="row mt-5">
                        <div className="col-lg-7">
                          <div className="table-responsive">
                            <table className="table table-bordered goals-table text-center">
                              <thead>
                                <tr>
                                  <th>{translation.sudgested_goals}</th>
                                  <th>{translation.completed_goals}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{translation.twelve_hrs_per_week}</td>
                                  <td className="green-color">
                                    {common.toHHMMSSFormat(sub.totalTimeSpent)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Overall test</td>
                                  <td className="green-color">
                                    {noOfSessions} times
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-lg-5">
                          <div className="custom-calendar-2">
                            <FullCalendar
                              defaultView="dayGridMonth"
                              header={{
                                right: "prev,next,title",
                              }}
                              plugins={[dayGridPlugin, interactionPlugin]}
                              weekends={true}
                              dayCellClassNames={(info) =>
                                this.checkUsage(info)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default ViewGoal;
