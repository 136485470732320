import React from "react";
import { common } from "helpers";
import { Link } from "react-router-dom";

import SetGoal from "./includes/SetGoal";
import ViewGoal from "./includes/ViewGoal";
import { apiServices } from "api";
import { envConfig } from "api/config";
import { StatusBar } from "includes";
import TodayGoal from "./includes/TodayGoal";
class MyGoals extends React.Component {
  calendarComponentRef = React.createRef();
  count = 0;
  constructor(props) {
    super(props);
    this.state = {
      userInfo: this.props.userInfo,
      userId: this.props.userId,
      isView: 0,
      viewGoalBySubGrade: [],
      loadedData: 0,
      status: "process",
      userPlan: this.props.userPlan,
      //todayGoalList: this.props.todayGoalList
      translation: common.getTranslation()
    };
  }

  componentDidMount = () => {
    this.formUserSubjects();
  };

  isViewOrSetClick = (e, val) => {
    e.preventDefault();
    // let page = true;
    // if (val == 1) {
    //   page = false;
    // }
    this.setState({
      isView: val,
    });

  };

  formUserSubjects = () => {
    const { userInfo } = this.state;
    let subjectList = [];
    let subjects = userInfo.items;
    let subKey =  subjects ? Object.keys(subjects) : [];

    subKey.map((subject, idx) => {
      userInfo.items[subject].grade.map((grade) => {
        let termsList = [];
        let gradeList = userInfo.items[subject].test;

        if (gradeList[grade]) {
          Object.keys(gradeList[grade]).map((terms) => {
            let testList = [];
            gradeList[grade][terms].map((tests) => {
              testList.push({
                id: tests,
                test: this.getNameById(tests),
                tQCount: this.getQCountById(tests),
                LastWeekTimeSpent: 0,
                timeSpent: 0,
              });
            });

            termsList.push({
              term: { id: terms, name: this.getNameById(terms) },
              test: testList,
              totHrPerDay: 0,
              totHrPerWeek: 0,
            });
          });

          subjectList.push({
            id: subject,
            name: this.getNameById(subject),
            gradeId: grade,
            gradeName: this.getNameById(grade),
            testList: termsList,
            totalTimeSpent: 0,
            totalScore: 0,
            totGoalHourPerWeek: 0,
            sDates: [],
          });
        }
      });
    });
    this.setState(
      {
        viewGoalBySubGrade: subjectList,
      },
      () => {
        this.getTestCurrentProgress();
        this.getTimeSpent();
        this.getGoals();
      }
    );
  };

  getNameById(id) {
    const { userInfo } = this.state;
    return userInfo.names[id];
  }

  getQCountById(id) {
    const { userInfo } = this.state;
    let questions = userInfo.questions;
    return questions[id] ? questions[id].total : 0;
  }

  incCount = () => {
    this.count = this.count + 1;
    if (this.count == 3) {
      this.setState({
        loadedData: 3,
        status: "success",
        isView: 1
      });
    }
  }

  getTestCurrentProgress = () => {
    const { userId, viewGoalBySubGrade, loadedData } = this.state;

    let queryParam = `?userID=${userId}`;

    let data = {
      method: "GET",
      url: "TEST_CURRENT_PROGRESS",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        if (status == "success") {
          let subjectGrade = result.data;

          if (subjectGrade.length > 0) {
            subjectGrade.map((obj, index) => {
              let totalScore = 0;
              let sIdx = viewGoalBySubGrade.findIndex((element) => {
                return (
                  obj._id.class == element.id && obj._id.topic == element.gradeId
                );
              });
              if (sIdx != -1) {
                obj.tests.map((test) => {
                  let totQuesCount = this.getQCountById(test.test);
                  let testScore = test.score > 0 ? test.score : 0;
                  let score =
                    (testScore / (totQuesCount * envConfig.CORRECT_ANSWER_SCORE)) *
                    100; //CONFIRM
                  totalScore = totalScore + Math.round(score);

                  viewGoalBySubGrade[sIdx].testList.map((terms) => {
                    terms.test.map((tests, tIdx) => {
                      if (tests.id == test.test) {
                        terms.test[tIdx] = {
                          id: tests.id,
                          test: tests.test,
                          correct: test.correct,
                          question: test.question,
                          score: test.score,
                          session: test.session,
                          tQCount: tests.tQCount,
                          timeSpent: test.timeSpent,
                          completed: test.completed,
                        };
                      }
                    });
                  });
                });
                viewGoalBySubGrade[sIdx].totalScore = totalScore;
              }
            });
          }
          this.setState(
            {
              viewGoalBySubGrade,
              status: status
            },
            () => {
              this.incCount();
            }
          );
        } else if (status == "empty") {
          this.incCount();
        }
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );
  };

  getTimeSpent = () => {
    const { userId, viewGoalBySubGrade, loadedData } = this.state;
    let queryParam = `?userID=${userId}`;

    let data = {
      method: "GET",
      url: "TEST_TIMESPENT_INWEEK",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        if (status == "success") {
          let subjectGrade = result.data;

          if (subjectGrade.length > 0) {
            subjectGrade.map((obj, index) => {
              let totalTimeSpent = 0;
              let sIdx = viewGoalBySubGrade.findIndex((element) => {
                return (
                  obj._id.class == element.id && obj._id.topic == element.gradeId
                );
              });
              if (sIdx != -1) {
                obj.tests.map((test) => {
                  if (test.timeSpent) {
                    totalTimeSpent = totalTimeSpent + test.timeSpent;
                  }
                  viewGoalBySubGrade[sIdx].testList.map((terms, tIdx) => {
                    terms.test.map((termtest) => {
                      if (termtest.id == test.test) {
                        termtest.LastWeekTimeSpent = test.timeSpent
                          ? test.timeSpent
                          : 0;
                      }
                    });
                  });
                });

                viewGoalBySubGrade[sIdx].totalTimeSpent = totalTimeSpent;
              }
            });
          }

          this.setState(
            {
              viewGoalBySubGrade,
              loadedData: loadedData + 1,
              status: status
            },
            () => {
              this.incCount();
            }
          );
        } else if (status == "empty") {
          this.incCount();
        }
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );
  };

  getGoals = () => {
    const { userId, viewGoalBySubGrade, loadedData } = this.state;
    this.props.getTodayGoalList();
    let queryParam = `?userID=${userId}`;

    let data = {
      method: "GET",
      url: "GET_GOALS",
      query: queryParam,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        if (status == "success") {
          let subjectGrade = result.data;

          if (subjectGrade.length > 0) {
            subjectGrade.map((obj, index) => {
              obj.goals.map((goal) => {
                let sIdx = viewGoalBySubGrade.findIndex((element) => {
                  return (
                    obj._id.class == element.id &&
                    obj._id.topic == element.gradeId
                  );
                });
                if (sIdx != -1) {
                  let totGoalHour = 0;
                  let sDates = [];
                  goal.goals.map((tests) => {
                    viewGoalBySubGrade[sIdx].testList.map((terms, tIdx) => {
                      let totHrPerDay = 0;
                      let totHrPerWeek = 0;
                      //let spentHr = 0;
                      if (terms.term.id == goal._id.skill) {
                        terms.test.map((termtest) => {
                          if (termtest.id == tests.test) {
                            termtest.goal = tests;
                            totHrPerDay = totHrPerDay + tests.hourPerDay;
                            totHrPerWeek = totHrPerWeek + tests.hourPerWeek;
                            if (tests.selectedDates.length > 0) {
                              sDates = sDates.concat(tests.selectedDates);
                            }
                          }
                        });
                      }
                      totGoalHour = totGoalHour + totHrPerWeek;
                      terms.totHrPerDay = totHrPerDay;
                      terms.totHrPerWeek = totHrPerWeek;
                    });
                  });
                  viewGoalBySubGrade[sIdx].sDates = sDates;
                  viewGoalBySubGrade[sIdx].totGoalHourPerWeek = totGoalHour;
                }
              });
            });
          }

          this.setState(
            {
              viewGoalBySubGrade,
              status: status
            },
            () => {
              this.incCount();
            }
          );
        } else if (status == "empty") {
          this.incCount();
        }
      },
      (error) => {
        common.snack("E", common.getLangMessage(error.message));
      },
      (final) => { }
    );
  };

  setGoal = (data) => {
    this.setState(
      {
        isView: 2,
      },
      () => {
        this.SetGoal.setGoalByProgress(data);
      }
    );
  };

  setTodayGoal = (val) => {
    this.setState({ isView: val })
  }

  callMyTest = () => {
    this.props.callMyTest();
  }

  render() {
    const { userInfo, userId, userData, todayGoalList } = this.props;
    const { isView, viewGoalBySubGrade, loadedData, status, userPlan, translation } = this.state;
    return (
      <div>
        <div className="tab-header d-flex flex-wrap justify-content-between align-items-center">
          <h5 className="mb-0">{translation.mygoals}</h5>
          <div className="tab-actions">
            <Link
              to="#"
              className={
                isView == 1 ? "btn-sm green-btn mr-3" : "btn-sm bordered-btn mr-3"
              }
              onClick={(e) => this.isViewOrSetClick(e, 1)}
            >
              {translation.viewgoals_title}
            </Link>
            {userPlan && userPlan.plan && userPlan.plan.planId !== "free" && userPlan.paymentStatus !== "expired" && <Link
              to="#"
              className={isView == 2 ? "btn-sm green-btn" : "btn-sm bordered-btn"}
              onClick={(e) => this.isViewOrSetClick(e, 2)}
            >
              {translation.setgoals_title}
            </Link>}
            {userPlan && userPlan.plan && userPlan.plan.planId !== "free" &&
              userPlan.paymentStatus !== "expired" && todayGoalList.length > 0 &&
              <Link
                to="#"
                className={
                  isView == 3 ? "btn-sm green-btn today-goal" : "btn-sm bordered-btn today-goal"
                }
                onClick={(e) => this.isViewOrSetClick(e, 3)}
              >
                {translation.today_goal}
              </Link>}
          </div>
        </div>
        {isView == 0 && <StatusBar status={status} />}
        {isView == 1 && loadedData == 3 && status == "success" && (
          <ViewGoal subject={viewGoalBySubGrade} userId={userId} />
        )}
        {isView == 2 && loadedData == 3 && status == "success" && userPlan && userPlan.plan && userPlan.plan.planId !== "free" && userPlan.paymentStatus !== "expired" && (
          <SetGoal
            subject={viewGoalBySubGrade}
            userInfo={userInfo}
            userId={userId}
            getGoals={this.getGoals}
            ref={(instance) => {
              this.SetGoal = instance;
            }}
          />
        )}
        {isView == 3 && loadedData == 3 && status == "success" && (
          <TodayGoal subject={viewGoalBySubGrade} userInfo={userInfo}
            callMyTest={() => this.callMyTest()}
            todayGoalList={todayGoalList} userId={userId} />
        )}
      </div>
    );
  }
}
export default MyGoals;
