import moment from "moment";
import { AES, enc } from 'crypto-js';
import { common } from "helpers";

const dateFormat = "DD MMMM YYYY";
const convertFormat = "DD-MM-YYYY hh:mm:ss A";

export const tools = {
  // date
  now() {
    return moment().format(dateFormat);
  },

  formatDate(date, format = 3) {
    let fDate = moment(date).format(dateFormat);
    if (format == 1) {
      fDate = moment(date).format("MMMM DD YYYY");
    } else if (format == 2) {
      fDate = moment(date).format("DD-MMMM-YYYY");
    }
    return fDate;
  },

  utcToLocalFormat(date, format = "D MMM - h:mm a") {
    return moment.utc(date).local().format(format);
  },

  convertToLocal(date) {
    return moment(date, convertFormat).format(dateFormat);
  },

  utcToLocal(date, format = dateFormat) {
    return moment.utc(date, convertFormat).local().format(format);
  },

  dateStatus(date1, date2, type) {
    let status = false;
    if (type == "past") {
      status = moment(date1).isBefore(date2);
    } else if (type == "sAfter") {
      status = moment(date1).isSameOrAfter(date2);
    } else if (type == "after") {
      status = moment(date1).isAfter(date2);
    } else if (type == "same") {
      status = moment(date1).isSame(date2, "date");
    }
    return status;
  },

  // time
  msToDateTime(millisec) {
    var seconds = (millisec / 1000).toFixed(1);
    var minutes = (millisec / (1000 * 60)).toFixed(1);
    var hours = (millisec / (1000 * 60 * 60)).toFixed(1);
    var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) {
      return seconds + " Sec";
    } else if (minutes < 60) {
      return minutes + " Min";
    } else if (hours < 24) {
      return hours + " Hrs";
    } else {
      return days + " Days"
    }
  },

  // input
  ValidateEmail(mail) {
    let regExp = /^[A-Za-z0-9-+]+(\.[A-Za-z0-9-+]+)*(\+[A-Za-z0-9-]+)?@[A-Za-z0-9-.]+(\.[A-Za-z0-9]+)$/; // allow +
    // let regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // disallow +
    if (regExp.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  validateMobile(number) {
    var pattern = new RegExp("^\\d{9}$");
    if (pattern.test(number)) {
      return true;
    } else {
      return false;
    }
  },

  // dom
  scrollTop() {
    window.scrollTo(0, 0);
  },

  scrollEnd(id = "") {
    if (id == "") {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      let ele = document.getElementById(id);
      ele.scrollTo(0, ele.scrollHeight);
    }
  },

  // encode decode
  cryptCode() {
    return "#cErLyPpKt#";
  },

  crypt(str, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, tools.cryptCode()).toString();
  },

  deCrypt(str, json = false) {
    try {
      let bytes = AES.decrypt(str, tools.cryptCode());
      let data = bytes.toString(enc.Utf8);
      if (data == "") {
        common.removeAuthStorage();
        common.redirect("/");
      } else {
        if (json) {
          return JSON.parse(data);
        } else {
          return data;
        }
      }
    } catch (err) {
      common.removeAuthStorage();
      common.redirect("/");
    }
  },

  // array
  ObjectSet(arr, key) {
    let result = {};
    arr.map((item) => {
      Object.assign(result, { [item[key]]: item })
    })
    return result;
  },

  removeItem(array, val) {
    const index = array.indexOf(val);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  },

  getRandomRGB() {
    var num = Math.round(0xffffff * Math.random());
    var r = num >> 16;
    var g = num >> 8 & 255;
    var b = num & 255;
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  },

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  },

  sortObject(obj) {
    return Object.keys(obj).sort().reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
  },

  getRGB(key) {
    let rgb = {
      0: 'rgb(53, 50, 210)',
      1: 'rgb(79, 203, 165)',
      2: 'rgb(211, 200, 119)',
      3: 'rgb(207, 0, 236)',
      4: 'rgb(47, 3, 40)',
      5: 'rgb(186, 179, 110)',
      6: 'rgb(118, 121, 143)',
      7: 'rgb(62, 220, 110)',
      8: 'rgb(242, 42, 125)',
      9: 'rgb(82, 119, 93)',
      10: 'rgb(125, 178, 151)',
      11: 'rgb(147, 20, 221)',
      12: 'rgb(178, 166, 44)',
      13: 'rgb(120, 196, 248)',
      14: 'rgb(38, 161, 176)',
      15: 'rgb(57, 65, 223)',
      16: 'rgb(130, 192, 199)',
      17: 'rgb(231, 131, 198)',
      18: 'rgb(123, 131, 182)',
      19: 'rgb(24, 193, 169)',
      20: 'rgb(121, 74, 87)'
    }
    return rgb[key];
  },

  currencyFormat(num) {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  },

  getPath(key) {
    let path = window.location.pathname;
    return path.split("/")[key];
  },
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
