import React from "react";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
// includes
import { apiServices } from "api";
import { history, common, tools, message } from "helpers";
import { StatusBar, Handler } from "includes";
let translation = common.getTranslation();

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      isLoading: false,
      formSubmit: false,
      fields: {
        name: "",
        screenName: "",
        country: "",
        state: "",
        city: "",
        mobile: "",
        hearAboutUs: "",
        hear_aboutus: "",
        school: "",
        schoolCountryId: "sri-lanka",
        schoolStateId: "",
        schoolCityId: "",
      },
      errors: {
        name: {
          error: "",
          isReq: "Name is required!",
        },
        mobile: {
          error: "",
          isReq: "Mobile number is required!",
        },
        hearAboutUs: {
          error: "",
          isReq: "Hear about us is required!",
        },
        screenName: {
          error: "",
          isReq: "Screen name is required!",
        },
        school: {
          error: "",
          isReq: "School is required!",
        },
      },
      sData: {
        user: [],
        country: [],
        states: [],
        cities: [],
        schoolStates: [],
        schoolCities: [],
        school: [],
      },
      dStatus: {
        country: "",
        states: "",
        cities: "",
        schoolStates: "",
        schoolCities: "",
        school: "",
      },
      userData: common.authInfo(),
    };
  }

  componentDidMount() {
    this.getUserData();
    this.getSchoolStates();
  }

  getUserData = () => {
    const { fields, sData } = this.state;
    let urlKeys = {
      userId: common.getStorage("userId"),
    };
    let data = {
      method: "GET",
      url: "GET_USER_PROFILE",
      key: urlKeys,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.user = common.apiResultData(res);
        let user = sData.user.data;
        if (user.regType) {
          common.setStorage("regType", user.regType);
        }
        console.log("============user", user);
        let fields = {
          name: user.name,
          screenName: user.screenName,
          country: user.userInfo.country,
          state: user.userInfo.state,
          city: user.userInfo.city,
          mobile: user.mobile,
          hearAboutUs: user.hearAboutUs,
          hear_aboutus: user.hearAboutUs,
          schoolCountryId:
            user.userInfo.school && user.userInfo.school.country
              ? user.userInfo.school.country
              : "sri-lanka",
          schoolStateId:
            user.userInfo.school && user.userInfo.school.state
              ? user.userInfo.school.state
              : "",
          schoolCityId:
            user.userInfo.school && user.userInfo.school.city
              ? user.userInfo.school.city
              : "",
          school:
            user.userInfo.school && user.userInfo.school.name
              ? user.userInfo.school.name
              : "",
        };
        this.setState(
          {
            status,
            fields,
            sData,
          },
          () => {
            this.getAllCountry();
            this.getSchoolStates();
          }
        );
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  // city state and country
  getAllCountry = () => {
    this.dataStatus("country", "process");
    const { fields, sData, dStatus } = this.state;
    let data = {
      method: "GET",
      url: "GET_ALL_COUNTRY",
    };
    apiServices.call(
      data,
      (res) => {
        dStatus.country = common.apiStatus(res);
        sData.country = common.apiResultData(res);
        this.setState(
          {
            sData,
            dStatus,
          },
          () => {
            if (fields.country != "") {
              this.getAllStates();
            }
          }
        );
      },
      (error) => {
        this.dataStatus("country", "error");
      },
      (final) => {}
    );
  };

  getAllStates = () => {
    this.dataStatus("states", "process");
    const { fields, sData, dStatus } = this.state;
    let data = {
      method: "GET",
      url: "GET_ALL_STATES",
      query: fields.country,
    };
    apiServices.call(
      data,
      (res) => {
        dStatus.states = common.apiStatus(res);
        sData.states = common.apiResultData(res);
        this.setState(
          {
            sData,
            dStatus,
          },
          () => {
            if (fields.state != "") {
              this.getAllCities();
            }
          }
        );
      },
      (error) => {
        this.dataStatus("states", "error");
      },
      (final) => {}
    );
  };

  getAllCities = () => {
    this.dataStatus("cities", "process");
    const { sData, fields, dStatus } = this.state;
    let data = {
      method: "GET",
      url: "GET_ALL_CITIES",
      query: fields.state,
    };
    apiServices.call(
      data,
      (res) => {
        dStatus.cities = common.apiStatus(res);
        sData.cities = common.apiResultData(res);
        this.setState({
          sData,
          dStatus,
        });
      },
      (error) => {
        this.dataStatus("cities", "error");
      },
      (final) => {}
    );
  };

  getSchoolStates = () => {
    this.dataStatus("schoolStates", "process");
    const { fields, sData, dStatus } = this.state;
    let data = {
      method: "GET",
      url: "GET_ALL_STATES",
      query: fields.schoolCountryId,
    };
    apiServices.call(
      data,
      (res) => {
        dStatus.schoolStates = common.apiStatus(res);
        sData.schoolStates = common.apiResultData(res);
        this.setState(
          {
            sData,
            dStatus,
          },
          () => {
            if (fields.schoolStateId != "") {
              this.getSchoolCities();
            }
          }
        );
      },
      (error) => {
        this.dataStatus("schoolStates", "error");
      },
      (final) => {}
    );
  };

  getSchoolCities = () => {
    this.dataStatus("schoolCities", "process");
    const { sData, fields, dStatus } = this.state;
    let data = {
      method: "GET",
      url: "GET_ALL_CITIES",
      query: fields.schoolStateId,
    };
    apiServices.call(
      data,
      (res) => {
        dStatus.schoolCities = common.apiStatus(res);
        sData.schoolCities = common.apiResultData(res);
        this.setState(
          {
            sData,
            dStatus,
          },
          () => {
            if (fields.schoolCityId != "") {
              this.getAllSchools();
            }
          }
        );
      },
      (error) => {
        this.dataStatus("schoolCities", "error");
      },
      (final) => {}
    );
  };

  getAllSchools = () => {
    this.dataStatus("school", "process");
    const { sData, fields, dStatus } = this.state;
    let queryParam = `?city=${fields.schoolCityId}`;
    let data = {
      method: "GET",
      url: "GET_ALL_SCHOOL",
      query: queryParam,
    };
    apiServices.call(
      data,
      (res) => {
        dStatus.school = common.apiStatus(res);
        sData.school = common.apiResultData(res);
        this.setState({
          sData,
          dStatus,
        });
      },
      (error) => {
        this.dataStatus("school", "error");
      },
      (final) => {}
    );
  };

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const { fields, userData } = this.state;
      // api call
      let params = {
        name: fields.name,
        screenName: fields.screenName,
        userInfo: {
          country: fields.country,
          state: fields.state,
          city: fields.city,
          school: {
            name: fields.school,
            country: fields.schoolCountryId,
            state: fields.schoolStateId,
            city: fields.schoolCityId,
          },
        },
        mobile: fields.mobile,
        hearAboutUs: fields.hearAboutUs,
      };
      let data = {
        method: "PUT",
        url: "USER_UPDATE",
        query: common.getStorage("userId"),
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            userData.user.screenName = fields.screenName;
            common.setStorage("userData", tools.crypt(userData, true));
            common.snack("S", translation.update_success);
            common.redirect("/settings/profile", 2);
          } else {
            if (status == "failure") {
              common.snack("E", translation.update_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.update_failed);
        },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // support function
  dataStatus(name, status) {
    const { dStatus } = this.state;
    dStatus[name] = status;
    this.setState({
      dStatus,
    });
  }

  handlerCallback(name) {
    const { fields, sData, dStatus } = this.state;
    if (name == "country") {
      fields.state = "";
      fields.city = "";
      sData.states = [];
      sData.cities = [];
      dStatus.states = "";
      dStatus.cities = "";
      this.setState(
        {
          fields,
          sData,
          dStatus,
        },
        () => {
          if (fields.country != "") {
            this.getAllStates();
          }
        }
      );
    } else if (name == "state") {
      fields.city = "";
      sData.cities = [];
      dStatus.cities = "";
      this.setState(
        {
          fields,
          sData,
          dStatus,
        },
        () => {
          if (fields.state != "") {
            this.getAllCities();
          }
        }
      );
    } else if (name == "schoolStateId") {
      fields.schoolCityId = "";
      sData.schoolCities = [];
      dStatus.schoolCities = "";
      this.setState(
        {
          fields,
          sData,
          dStatus,
        },
        () => {
          if (fields.schoolStateId != "") {
            this.getSchoolCities();
          }
        }
      );
    } else if (name == "schoolCityId") {
      fields.school = "";
      sData.schools = [];
      dStatus.schools = "";
      this.setState(
        {
          fields,
          sData,
          dStatus,
        },
        () => {
          if (fields.schoolStateId != "") {
            this.getAllSchools();
          }
        }
      );
    }
  }

  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  redirect(e) {
    e.preventDefault();
    history.push("/password/change");
  }

  onChangeEmail(e) {
    e.preventDefault();
    history.push("/change/email");
  }

  onChangeMobile(e) {
    e.preventDefault();
    history.push("/change/mobile");
  }

  render() {
    const {
      userData,
      sData,
      dStatus,
      status,
      isLoading,
      formSubmit,
      fields,
      errors,
    } = this.state;
    console.log("============sData", sData);
    return (
      <div className="card-body">
        {/* <!-- Form Content Wrap --> */}
        <StatusBar status={status} />
        {status == "success" && (
          <div className="settings-right">
            <div className="tab-content"></div>
            <div className="tab-pane active" id="manage-children">
              <div className="card-row">
                <h5 className="mb-4">{translation.profile_settings}</h5>
                {userData.user.role != 2 && (
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          defaultValue={sData.user.data.email}
                          readOnly={true}
                        />
                        {/* <span className="floating-label">{translation.email}</span> */}
                        <span className="floating-label">Email</span>
                      </div>
                    </div>
                    {userData.user.role == 3 && (
                      <div className="col-lg-3">
                        <input
                          className="btn"
                          onClick={(e) => this.onChangeEmail(e)}
                          type="button"
                          value={"Edit"}
                        />
                      </div>
                    )}
                  </div>
                )}
                {userData.user.role != 2 && (
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="form-group">
                        <Input
                          type="number"
                          name="mobile"
                          value={sData.user.data.mobile}
                          className="form-control custom-mobile-field"
                          placeholder=""
                          readOnly={true}
                          inputProps={{
                            maxLength: 9,
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              +94
                            </InputAdornment>
                          }
                        />
                        <span className="floating-label">
                          Enter Mobile Number
                        </span>
                      </div>
                    </div>
                    {userData.user.role == 3 && (
                      <div className="col-lg-3">
                        <input
                          className="btn"
                          onClick={(e) => this.onChangeMobile(e)}
                          type="button"
                          value={"Edit"}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-9">
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        defaultValue={"********"}
                        readOnly={true}
                      />
                      {/* <span className="floating-label">{translation.password}</span> */}
                      <span className="floating-label">Password</span>
                    </div>
                  </div>
                  {userData.user.role != 4 && (
                    <div className="col-lg-3">
                      <input
                        className="btn"
                        onClick={(e) => this.redirect(e)}
                        type="button"
                        value={translation.change_password}
                      />
                    </div>
                  )}
                </div>
                <h6 className="mb-3">{translation.user_information}</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={fields.name}
                        onChange={(e) => this.handler.onChange(e)}
                      />
                      {/* <span className="floating-label">{translation.your_fullname}</span> */}
                      <span className="floating-label">Your Full Name</span>
                      {errors.name.error != "" && formSubmit && (
                        <span className="elk-error">{errors.name.error}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="screenName"
                        className="form-control"
                        value={fields.screenName}
                        onChange={(e) => this.handler.onChange(e)}
                      />
                      {/* <span className="floating-label">{translation.screen_name}</span> */}
                      <span className="floating-label">Screen Name</span>
                      {errors.screenName.error != "" && formSubmit && (
                        <span className="elk-error">
                          {errors.screenName.error}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-row mt-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <select
                        name="country"
                        className="form-control"
                        value={fields.country}
                        onChange={(e) => this.handler.onChange(e, true)}
                      >
                        <option value="">
                          {dStatus.country == "process"
                            ? "Loading..."
                            : "Select"}
                        </option>
                        {dStatus.country == "success" &&
                          sData.country.data.map((item, key) => (
                            <option key={key} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {/* <span className="floating-label">{translation.select_country}</span> */}
                      <span className="floating-label">Select Country</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        name="state"
                        className="form-control"
                        value={fields.state}
                        onChange={(e) => this.handler.onChange(e, true)}
                      >
                        <option value="">
                          {dStatus.states == "process"
                            ? "Loading..."
                            : "Select"}
                        </option>
                        {dStatus.states == "success" &&
                          sData.states.data.map((item, key) => (
                            <option key={key} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {/* <span className="floating-label">{translation.select_state}</span> */}
                      <span className="floating-label">Select State</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        name="city"
                        className="form-control"
                        value={fields.city}
                        onChange={(e) => this.handler.onChange(e, true)}
                      >
                        <option value="">
                          {dStatus.cities == "process"
                            ? "Loading..."
                            : "Select"}
                        </option>
                        {dStatus.cities == "success" &&
                          sData.cities.data.map((item, key) => (
                            <option key={key} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {/* <span className="floating-label">{translation.select_city}</span> */}
                      <span className="floating-label">Select City</span>
                    </div>
                  </div>
                  {/* <div className="col-lg-6">
                    <div className="form-group">
                      <Input
                        type="number"
                        name="mobile"
                        value={fields.mobile}
                        onChange={(e) => this.handler.onChange(e)}
                        className="form-control custom-mobile-field"
                        placeholder=""
                        inputProps={{
                          maxLength: 9,
                        }}
                        startAdornment={
                          <InputAdornment position="start">+94</InputAdornment>
                        }
                      />
                      <span className="floating-label">
                        Enter Mobile Number
                      </span>
                      {errors.mobile.error != "" && formSubmit && (
                        <span className="elk-error">{errors.mobile.error}</span>
                      )}
                    </div>
                  </div> */}
                  {(fields.hear_aboutus == undefined ||
                    fields.hear_aboutus == null) && (
                    <div className="col-lg-6">
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="hearAboutUs"
                          value={fields.hearAboutUs}
                          onChange={(e) => this.handler.onChange(e, true)}
                        >
                          <option value="">{"Select"}</option>
                          <option value="Facebook" key={0}>
                            Facebook
                          </option>
                          <option value="Google" key={1}>
                            Google
                          </option>
                          <option value="Our Website" key={2}>
                            Our Website
                          </option>
                          <option value="YouTube" key={3}>
                            YouTube
                          </option>
                          <option value="Through a friend" key={4}>
                            Through a friend
                          </option>
                          <option value="Through my parents" key={5}>
                            Through my parents
                          </option>
                          <option value="Through my school" key={6}>
                            Through my school
                          </option>
                          <option value="Other" key={7}>
                            Other
                          </option>
                        </select>
                        <span className="floating-label">
                          How did you hear about us?
                        </span>
                        {errors.hearAboutUs.error != "" && formSubmit && (
                          <span className="elk-error">
                            {errors.hearAboutUs.error}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="card-row">
                <h6 className="mb-3">{translation.school_information}</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        name="schoolStateId"
                        className="form-control"
                        value={fields.schoolStateId}
                        onChange={(e) => this.handler.onChange(e, true)}
                      >
                        <option value="">
                          {dStatus.schoolStates == "process"
                            ? "Loading..."
                            : "Select"}
                        </option>
                        {dStatus.schoolStates == "success" &&
                          sData.schoolStates.data.map((item, key) => (
                            <option key={key} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {/* <span className="floating-label">{translation.select_state}</span> */}
                      <span className="floating-label">Select State</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        name="schoolCityId"
                        className="form-control"
                        value={fields.schoolCityId}
                        onChange={(e) => this.handler.onChange(e, true)}
                      >
                        <option value="">
                          {dStatus.schoolCities == "process"
                            ? "Loading..."
                            : "Select"}
                        </option>
                        {dStatus.schoolCities == "success" &&
                          sData.schoolCities.data.map((item, key) => (
                            <option key={key} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {/* <span className="floating-label">{translation.select_city}</span> */}
                      <span className="floating-label">Select City</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <select
                        name="school"
                        className="form-control"
                        value={fields.school}
                        onChange={(e) => this.handler.onChange(e, true)}
                      >
                        <option value="">
                          {dStatus.school == "process"
                            ? "Loading..."
                            : "Select"}
                        </option>
                        {dStatus.school == "success" &&
                          sData.school.data.map((item, key) => (
                            <option key={key} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                      {/* <span className="floating-label">{translation.select_state}</span> */}
                      <span className="floating-label">Select School</span>
                      {errors.school.error != "" && formSubmit && (
                        <span className="elk-error">{errors.school.error}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="card-row last">
                <h6 className="my-3">{translation.email_and_password}</h6>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        defaultValue={sData.user.data.email}
                        readOnly={true}
                      /> */}
              {/* <span className="floating-label">{translation.username}</span> */}
              {/* <span className="floating-label">Username</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        defaultValue={"********"}
                        readOnly={true}
                      />*/}
              {/* <span className="floating-label">{translation.password}</span> */}
              {/* <span className="floating-label">Password</span>
                      <a
                        href="#"
                        className="primary-color mt-2"
                        onClick={(e) => this.redirect(e)}
                      >
                        {translation.change_password}
                      </a>
                    </div>
                  </div>
                </div>
              </div>  */}
              <div className="form-actions mt-3">
                <input
                  className="btn"
                  onClick={() => this.onSubmit()}
                  disabled={isLoading}
                  type="button"
                  value={!isLoading ? "Update" : "Processing..."}
                />
              </div>
            </div>
          </div>
        )}
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
          callBack={(name) => this.handlerCallback(name)}
        />
      </div>
    );
  }
}

export default Profile;
