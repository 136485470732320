import React from "react";
import { Link } from "react-router-dom";
// includes
import { common, message, history, tools } from "helpers";
import { Handler } from "includes";
import { apiServices } from "api";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class Activation extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        activationKey: "",
      },
      errors: {
        activationKey: {
          error: "",
          isReq: translation.activate_code,
        },
      },
      email: "",
      mobile: "",
      type: params.type ? params.type: "",
      regType: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      email: common.getStorage("email") ? tools.deCrypt(common.getStorage("email"), true) : "",
      mobile: common.getStorage("mobile")? tools.deCrypt(common.getStorage("mobile"), true) : ""
    })
  
    // return
    const { fields, errors } = this.state;
    if(common.getStorage("regType") && common.getStorage("regType") !== ""  && common.getStorage("regType") == this.state.type){
            fields["password"]= "";
            fields["confirmPassword"] = "";
            errors["password"] = Object.assign({
                error: "",
                minLength: 6,
                isReq: translation.isReq_new,
                isMinLengthReq: translation.pwd_min_len,
            });
            errors["confirmPassword"] = Object.assign({
                error: "",
                minLength: 6,
                isReq: translation.isReq_confirm,
                isMinLengthReq: translation.pwd_min_len,
                matchPassword: true,
                matchPasswordReq: translation.matchPasswordReq,
            })
        
        this.setState({
            regType: common.getStorage("regType"),
            fields: fields,
            errors: errors
        })
    }
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const { fields, email, mobile, type } = this.state;
      // api call
      let params = {
        activationKey: fields.activationKey,
        type: "update"
      };

      if(email && type == "email") {
        params["email"] = email;
      }

      if(mobile && type == "mobile") {
        params["mobile"] = mobile;                
      }

      if(fields.password) {
        params["password"] = fields.password;                
      }
      if(fields.confirmPassword) {
        params["confirmPassword"] = fields.confirmPassword;                
      }
      // return
      let data = {
        method: "PUT",
        url: "MEMBERSHIP_ACTIVATE",
        body: params,
      };
      
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            common.snack("S", translation.member_activation);
            common.setStorage("email", "");
            common.setStorage("mobile", "")
            history.push("/settings/profile");
          }
        },
        (error) => { },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // event
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  reset = () => {
    let fields = {
      activationKey: "",
      password: "",
      confirmPassword: "",
    };
    this.setState({
      fields,
    });
    history.push("/settings/profile");
  };

  render() {
    const { isLoading, formSubmit, fields, errors, regType } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Activation</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="card card-2">
                  <div className="card-heading primary-bg">
                    <h2>{"Activation"}</h2>
                  </div>
                  <div className="card-body">
                    <p className="help mb-4">
                      {translation.provide_info_to_proceed}{" "}
                      {/* <Link to="/help-center">{translation.click_here_for_help}</Link> */}
                    </p>
                    <form method="POST">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="activationKey"
                              className="form-control"
                              placeholder=" "
                              value={fields.activationKey}
                              onChange={(e) => this.handler.onChange(e)}
                              onKeyDown={this.onEnterPress}
                            />
                            <span className="floating-label">
                              {/* {translation.activation_key}  */}
                              Enter the activation key
                            </span>
                            {errors.activationKey.error != "" && formSubmit && (
                              <span className="elk-error">
                                {errors.activationKey.error}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {(regType == "email" || regType == "mobile") && (
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder=" "
                                value={fields.password}
                                onChange={(e) => this.handler.onChange(e)}
                                onKeyDown={this.onEnterPress}
                              />
                              <span className="floating-label">
                                {/* {translation.enter_password} */}
                                Enter Your Password
                              </span>
                              {errors.password.error != "" && formSubmit && (
                                <span className="elk-error">
                                  {errors.password.error}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                placeholder=" "
                                value={fields.confirmPassword}
                                onChange={(e) => this.handler.onChange(e)}
                                onKeyDown={this.onEnterPress}
                              />
                              <span className="floating-label">
                                {/* {translation.retype_password} */}
                                Retype Password
                              </span>
                              {errors.confirmPassword.error != "" &&
                                formSubmit && (
                                  <span className="elk-error">
                                    {errors.confirmPassword.error}
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-actions d-flex justify-content-between">
                        <input
                          type="reset"
                          className="btn bordered-btn"
                          onClick={() => this.reset()}
                          value="Cancel"
                        />
                        <input
                          type="button"
                          className="btn btn-light-blue"
                          onClick={() => this.onSubmit()}
                          disabled={isLoading}
                          value={isLoading ? "Processing" : "Submit"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default Activation;
