import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { history, common } from "helpers";
import { HomeLayoutRoute } from "./routeLayout";
// ### Demo Components ###
import MyHeaders from "pages/Demo/MyHeaders";
// ### All page Components ###
import Home from "pages/Home/Home";
import About from "pages/About/About";
import Subject from "pages/Subject/Subject";

import TestSession from "pages/Test/TestSession";
import Test from "pages/Test/Test";
import Exam from "pages/Test/Exam";
import Membership from "pages/User/Membership";

import ChangePassword from "pages/User/ChangePassword";
import ForgotUsername from "pages/User/ForgotUsername";
import ForgotPassword from "pages/User/ForgotPassword";
import MembershipActivation from "pages/User/MembershipActivation";
import ResetPassword from "pages/User/ResetPassword";
import Analytics from "pages/Analytics/Analytics";
import AnalyticsInfo from "pages/Analytics/AnalyticsInfo";
import Register from "pages/User/Register";
import ElkRegister from "pages/User/ElkRegister";
import Contact from "pages/Contact/Contact";
import Faq from "pages/Faq/Faq";
import Search from "pages/Search/Search";
import ComingSoon from "pages/Common/ComingSoon";
import LimitExceeded from "pages/Common/LimitExceeded";
import WithoutLogin from "pages/Common/WithoutLogin";
import GreatJob from "pages/Common/GreatJob";
import Payment from "pages/Payment/Payment";
// Support
import SupportList from "pages/Support/SupportList";
import SupportRequest from "pages/Support/SupportRequest";
import SupportTicket from "pages/Support/SupportTicket";
import Settings from "pages/Settings/Settings";
import ChildrenActivate from "pages/Settings/ChildrenActivate";
import TermsConditions from "pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import Login from "pages/User/Login.js";
import Dashboard from "pages/Home/Dashboard.js";
import PricingTrial from "pages/Pricing/PricingTrial.js";
import WhatExampleCanOffer from "pages/WhatExampleCanOffer/WhatExampleCanOffer.js";
// Select Language
import SelectLanguage from "pages/SelectLanguage/SelectLanguage";
// payhere
import PayhereReponse from "pages/Payhere/Reponse";
import ChangeEmail from "pages/User/ChangeEmail";
import Activation from "pages/User/Activation";
import ChangeMobile from "pages/User/ChangeMobile";
class RouterComponent extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          {/* ### Demo ### */}
          <Route exact path="/myheaders" component={MyHeaders} />
          {/* ### All Pages ### */}
          <HomeLayoutRoute exact path="/" component={Home} header={common.getHeader()} auth={common.getAuth()} title="Home" />
          <HomeLayoutRoute exact path="/sign-in" component={Login} header={2} auth={false} title="Sign In" />
          <HomeLayoutRoute exact path="/membership" component={Membership} header={3} auth={false} title="Membership" />
          <HomeLayoutRoute exact path="/about-us" component={About} header={11} auth={null} title="About Us" />
          <HomeLayoutRoute exact path="/subjects" component={Subject} header={3} auth={true} title="Subjects" />
          <HomeLayoutRoute exact path="/subject/:id/:gradeId?" component={Subject} header={3} auth={true} title="Subject" />
          <HomeLayoutRoute exact path="/analytics/:tab/:subTab?" component={Analytics} header={12} auth={null} title="Analytics" />

          <HomeLayoutRoute exact path="/tests/:subId/:testId/:gradeId/:sessionId?" component={Test} header={3} auth={true} page={"test"} title="Test" />
          <HomeLayoutRoute exact path="/exam/:subId/:testId/:gradeId/:sessionId?" component={Exam} header={3} auth={true} page={"test"} title="Exam" />

          <HomeLayoutRoute exact path="/session/:subId/:testId/:gradeId" component={TestSession} header={7} auth={true} title="Session" />
          <HomeLayoutRoute exact path="/analytics-info" component={AnalyticsInfo} header={1} auth={false} title="Analytics Info" />
          <HomeLayoutRoute exact path="/register/:type" component={Register} header={12} auth={false} title="Register" />
          <HomeLayoutRoute exact path="/contact-us" component={Contact} header={5} auth={false} title="Contact Us" />
          <HomeLayoutRoute exact path="/help-center" component={Faq} header={5} auth={null} title="Help Center" />

          <HomeLayoutRoute exact path="/password/change" component={ChangePassword} header={13} auth={true} title="Password Change" />
          <HomeLayoutRoute exact path="/forgot/username" component={ForgotUsername} header={6} auth={false} title="Forgot Username" />
          <HomeLayoutRoute exact path="/forgot/password" component={ForgotPassword} header={6} auth={false} title="Forgot Password" />
          <HomeLayoutRoute exact path="/membership/reset-password/:id/:token" component={ResetPassword} header={6} auth={false} title="Reset Password" />
          <HomeLayoutRoute exact path="/membership/activation" component={MembershipActivation} header={6} auth={false} title="Membership Activation" />
          <HomeLayoutRoute exact path="/coming-soon" component={ComingSoon} header={8} auth={false} title="Coming Soon" />
          <HomeLayoutRoute exact path="/search/:text" component={Search} header={8} auth={null} title="Seach" />
          <HomeLayoutRoute exact path="/limit-exceeded" component={LimitExceeded} header={8} auth={false} title="Limit Exceed" />
          <HomeLayoutRoute exact path="/without-login" component={WithoutLogin} header={8} auth={false} title="Without Login" />
          <HomeLayoutRoute exact path="/great-job" component={GreatJob} header={8} auth={true} title="Great Job" />
          <HomeLayoutRoute exact path="/payment" component={Payment} header={13} auth={true} page={"payment"} title="Payment" />
          {/* Support */}
          <HomeLayoutRoute exact path="/support" component={SupportRequest} header={12} auth={true} title="Support" />
          <HomeLayoutRoute exact path="/support/tickets" component={SupportList} header={12} auth={true} title="Support Tickets" />
          <HomeLayoutRoute exact path="/support/ticket/:id" component={SupportTicket} header={12} auth={true} page={"support_ticket_info"} title="Support Ticket Info" />
          <HomeLayoutRoute exact path="/settings/:type?" component={Settings} header={13} auth={true} title="Settings" />
          <HomeLayoutRoute exact path="/settings/child/activate/:childId" component={ChildrenActivate} header={6} auth={true} title="Child Activation" />
          <HomeLayoutRoute exact path="/terms-conditions" component={TermsConditions} header={5} auth={null} title="Terms and Conditions" />
          <HomeLayoutRoute exact path="/privacy-policy" component={PrivacyPolicy} header={5} auth={null} title="Privacy Policy" />
          <HomeLayoutRoute exact path="/dashboard" component={Dashboard} header={10} auth={true} title="Dashboard" />
          {/* <HomeLayoutRoute exact path="/pricing/:type?" component={PricingTrial} header={1} auth={false} title="Pricing" /> */}
          <HomeLayoutRoute exact path="/what-example-can-offer" component={WhatExampleCanOffer} header={1} auth={null} title="What example can offer" />
          {/* Select Language */}
          <HomeLayoutRoute exact path="/select-language" component={SelectLanguage} header={14} auth={null} title="Select Language" />
          {/* Examplelk */}
          <HomeLayoutRoute exact path="/change/email" component={ChangeEmail} header={12} auth={true} title="Change Email" />
          <HomeLayoutRoute exact path="/change/mobile" component={ChangeMobile} header={12} auth={true} title="Change Mobile" />
          <HomeLayoutRoute exact path="/activation/:type" component={Activation} header={12} auth={true} title="Activation" />
          <HomeLayoutRoute exact path="/CSaf9EKfHAaP3gf/7RrTwEtQDvh6X7NstjqFKXH/3ae4a8N5ufvKCaM/:type" component={ElkRegister} header={12} auth={false} title="Secure Register" />
          {/* Payhere */}
          <Route exact path="/payhere/:type" component={PayhereReponse} title="Payhere Response" />
        </Switch>
      </Router>
    );
  }
}

export default RouterComponent;
