import { envConfig } from "./config";

// User APIs
export const SIGNIN = envConfig.API_CLIENT + "login";
export const SIGNOUT = envConfig.API_CLIENT + "user/logout";
export const STORE_LOG = envConfig.API_CLIENT + "log";
export const USER_CREATE = envConfig.API_CLIENT + "user";
export const USER_UPDATE = envConfig.API_CLIENT + "user/";
export const USER_INFO = envConfig.API_CLIENT + "user/:userId";
export const FORGOT_USERNAME = envConfig.API_CLIENT + "forgot/username";
export const FORGOT_PASSWORD = envConfig.API_CLIENT + "forgot/user";
export const UPDATE_PROFILE = envConfig.API_CLIENT + "user/:user/";
export const GET_USER_PROFILE = envConfig.API_CLIENT + "user/:userId";
export const RESET_PASSWORD = envConfig.API_CLIENT + "reset/user";
export const CHANGE_PASSWORD = envConfig.API_CLIENT + "user/:userId/change/password";
export const USER_ACTIVATION = envConfig.API_CLIENT + "activation/user";
export const GET_ALL_SCHOOL = envConfig.API_CLIENT + "school";
export const GET_ALL_STUDENT = envConfig.API_CLIENT + "/all/user";
export const CHANGE_EMAIL = envConfig.API_CLIENT + "user/:userId/change/email";
export const CHANGE_MOBILE = envConfig.API_CLIENT + "user/:userId/change/mobile";
export const SEND_CODE_EMAIL = envConfig.API_CLIENT + "user/:userId/activation/email";
export const SEND_CODE_MOBILE = envConfig.API_CLIENT + "user/:userId/activation/mobile";

// Common
export const COMMON_CONFIG = envConfig.API_CLIENT + "common/config/info";
export const COMMON_USER_INFO = envConfig.API_CLIENT + "common/user/info";
export const COMMON_USER_ONLINE = envConfig.API_CLIENT + "common/user/online";
export const USER_EXIST = envConfig.API_CLIENT + "user/exists/";

// Upload
export const UPLOAD = envConfig.API_UPLOAD;

// Contact
export const CONTACT = envConfig.API_CLIENT + "contact";

// All language & subjects
export const ALL_LANGUAGE_WITH_SUBJECTS = envConfig.API_CLIENT + "language/subjects";

// User Language, Subject, Grade
export const ALL_LANGUAGE = envConfig.API_CLIENT + "country";
export const GET_CLASS_BY_ID = envConfig.API_CLIENT + "class/:id";
export const CLASS_SUBJECT = envConfig.API_CLIENT + "class/subject";
export const ALL_CLASS_LIST = envConfig.API_CLIENT + "classes-list";
export const GET_GRADE_BY_SUBJECT = envConfig.API_CLIENT + "classes/topic";
export const GET_TOPIC_BY_ID = envConfig.API_CLIENT + "topic/:id";
export const GET_TOPIC_BY_GRADE = envConfig.API_CLIENT + "test/topic";
export const GET_USER_SUBJECT_GRADE = envConfig.API_CLIENT + "user/class/topic/:user";
export const TEST_RELATED_TOPICS = envConfig.API_CLIENT + "test/skill/:testId";

// Test Session
export const GET_ALL_TEST_SESSIONS = envConfig.API_CLIENT + "statistics/test/session/all";
export const GET_TEST_SESSION = envConfig.API_CLIENT + "statistics/test/session";
export const TEST_COMPLETE = envConfig.API_CLIENT + "statistics/test/complete";

// Test Question APIs
export const GET_QUESTION_BY_ID = envConfig.API_CLIENT + "question/:questionId";
export const GET_TEST = envConfig.API_CLIENT + "test/:id";
export const CHECK_ANSWER = envConfig.API_CLIENT + "question/check";
export const GET_QUESTION_BY_TEST = envConfig.API_CLIENT + "question/:testId/test";
export const GET_QUESTION_COUNT = envConfig.API_CLIENT + "question/test/count";

// Support
export const SUPPORT = envConfig.API_CLIENT + "support";
export const SUPPORT_LIST = envConfig.API_CLIENT + "support/list";
export const SUPPORT_MESSAGE = envConfig.API_CLIENT + "support/update-message";
export const SUPPORT_NOTIFICATION = envConfig.API_CLIENT + "support/notification/list";
export const SUPPORT_READ = envConfig.API_CLIENT + "support/update-view";

// Search
export const SEARCH_TEXT = envConfig.API_CLIENT + "search-topic/";

// Membership Activation
export const MEMBERSHIP_ACTIVATION = envConfig.API_CLIENT + "activation/user";
export const GET_CHILD_BY_PARENT = envConfig.API_CLIENT + "user/sub/:parentId";
export const CHILD_ACTIVATION = envConfig.API_CLIENT + "activation/child";
export const GET_CHILD_BY_ID = envConfig.API_CLIENT + "user/:childId";

// Membership Activate
export const MEMBERSHIP_ACTIVATE = envConfig.API_CLIENT + "activate/user";
export const CHILD_ACTIVATE = envConfig.API_CLIENT + "activate/child";

// common
export const GET_ALL_COUNTRY = envConfig.API_CLIENT + "all/countries";
export const GET_ALL_STATES = envConfig.API_CLIENT + "states/";
export const GET_ALL_CITIES = envConfig.API_CLIENT + "cities/";
export const GET_SCHOOL_CITIES = envConfig.API_CLIENT + "cities";

// Analytics
export const STAT_SCORE_TOPIC = envConfig.API_CLIENT + "statistics/score/topic";
export const STAT_TROUBLESPOT = envConfig.API_CLIENT + "statistics/troubleSpot";
export const STAT_QUES_LOG_SES_ALL = envConfig.API_CLIENT + "statistics/questionlogs/session/all";
export const STAT_QUES = envConfig.API_CLIENT + "statistics/questions";
export const STAT_USAGE = envConfig.API_CLIENT + "statistics/usage";
export const STAT_USAGE_CALENDER = envConfig.API_CLIENT + "statistics/usage/calendar";
export const STAT_OVER_ALL_TEST = envConfig.API_CLIENT + "statistics/overall/score/test";
export const STAT_SAMPLE_TEST = envConfig.API_CLIENT + "statistics/sample/score/test";
export const STAT_SCORE_TOPIC_TEST = envConfig.API_CLIENT + "statistics/score/topic/test";
export const STAT_MOCK_TEST = envConfig.API_CLIENT + "statistics/mocktest/score/test";
export const STAT_LANDING_TROUBLESPOT = envConfig.API_CLIENT + "statistics/landing/troubleSpot";
export const STAT_LANDING_PREFERRED = envConfig.API_CLIENT + "statistics/landing/preferred";
export const STAT_LANDING_SCORE = envConfig.API_CLIENT + "statistics/landing/score";
export const STAT_TOPPERS = envConfig.API_CLIENT + "statistics/progression"

// # Dashboard

// My Test
export const DB_MY_TEST_TOPIC_LIST = envConfig.API_CLIENT + "dashboard/mytest/topic/list";
export const DB_MY_TEST_OVERALL_LIST = envConfig.API_CLIENT + "dashboard/mytest/overall/list";
export const DB_MY_TEST_MOCK_LIST = envConfig.API_CLIENT + "dashboard/mytest/mocktest/list";
export const DB_MY_TEST_MODAL_PAPER_LIST = envConfig.API_CLIENT + "dashboard/mytest/modelpaper/list";
export const DB_MY_TEST_TODAY_TEST_LIST = envConfig.API_CLIENT + "dashboard/mytest/today/list";
export const DB_MY_TEST_MOCK_TEST_LIST = envConfig.API_CLIENT + "dashboard/mytest/mock/list";
export const DB_MY_TEST_ALL_STUDENT_TOPIC_LIST = envConfig.API_CLIENT + "dashboard/mytest/all/student/topic/list";
// My Progress
export const DB_MY_PROGRESS_LIST = envConfig.API_CLIENT + "dashboard/myprogress/list";
export const DB_MY_PROGRESS_INFO = envConfig.API_CLIENT + "dashboard/myprogress/info";
export const DB_MY_PROGRESS_TERM_INFO = envConfig.API_CLIENT + "dashboard/myprogress/term/info";

// My Goal
export const TEST_CURRENT_PROGRESS = envConfig.API_CLIENT + "dashboard/goal/currentprogress";
export const TEST_TIMESPENT_INWEEK = envConfig.API_CLIENT + "dashboard/goal/timespent";
export const GET_GOALS = envConfig.API_CLIENT + "dashboard/get/goals";
export const CREATE_GOAL = envConfig.API_CLIENT + "dashboard/goal/create";
export const UPDATE_GOAL = envConfig.API_CLIENT + "dashboard/goal/update/:id";
export const TODAY_GOAL_LIST = envConfig.API_CLIENT + "dashboard/goal/today/list";

// payment
export const PAYMENT_PAYHERE_INFO = envConfig.API_CLIENT + "payment/payhere/info";
export const PAYMENT_PAYHERE_USER_INFO = envConfig.API_CLIENT + "payment/payhere/user/info";
export const PAYMENT_PAYHERE_AUTO_MANUAL = envConfig.API_CLIENT + "payment/payhere/auto/manual";
export const PAYMENT_PAYHERE_AUTO_CANCEL = envConfig.API_CLIENT + "payment/payhere/auto/cancel";


// pricing plan
export const PRICING_PLAN = envConfig.API_CLIENT + "pricing/plan";
export const PRICING_PLAN_LIST = envConfig.API_CLIENT + "pricing/plan/list";

// chat
export const CHAT_CREATE = envConfig.API_CHAT + "create";
export const CHAT_USER_LIST = envConfig.API_CHAT + "user/list";
export const CHAT_UPDATE_READ = envConfig.API_CHAT + "chat/update/read";

// verifyLogin
export const VERIFY_LOGIN = envConfig.API_CLIENT + "verify/login";

// promo code
export const PROMO_CODE = envConfig.API_CLIENT + "promocode/";