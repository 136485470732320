import React from "react";
import { common } from "helpers";
let translation = common.getTranslation();

class RadioOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  componentDidMount = () => {
    window.vcm.formula_call();
  }

  selectAnswer = (e, answer) =>{
    e.preventDefault();
    if(this.props.type != "wrong"){
      this.props.answerUpdate(answer);
    }    
  }

  render() {
    const { answerData, wIdx, activeOptions, type, correctAnswer, selectedAnswer } = this.props;    
    let optionClass = type != "wrong" ? (activeOptions === answerData ? 
        "answer-option active" : "answer-option") : (correctAnswer === answerData ? 
          "wrong-option success" : (selectedAnswer === answerData ? "wrong-option error" : "answer-option"));
          if(type === "wrong" && optionClass == "answer-option"){
            optionClass = "wrong-option un-selectable";
          }
    return (
      <div className={type === "wrong" ? "col-lg-6 un-selectable" : "col-lg-6"}>
        {correctAnswer === answerData ? (
            <p className="answer-status success-label">{translation.co_answer}</p>
          ) : 
          selectedAnswer === answerData ? (
            <p className="answer-status error-label">{translation.sorry_wrong_answer}</p>
          ) : (
            <p className="answer-status"></p>
          )}
        <div className={optionClass}>
          
          <span className="option">{common.padQuestionNumbers(wIdx + 1)}.</span>
          <input
            className="form-check-input"
            type="radio"
            name="option1"
            id={"option" + (wIdx + 1)}
            value={"option" + (wIdx + 1)}
            onClick={(e)=> this.selectAnswer(e, answerData)}
          />
          {answerData.content &&
            Object.keys(answerData.content).length > 0 &&
            Object.keys(answerData.content).map((aItem, aIdx) => {
              const answerContent = answerData.content[aItem];
              return (
                <label key={aIdx} className="form-check-label" htmlFor={"option" + (wIdx + 1)}
                  onClick={(e)=> this.selectAnswer(e, answerData)}             
                  dangerouslySetInnerHTML={{
                  __html: common.removeSpecialChars(answerContent.content),
                }}>
                </label>
              );
            })}
        </div>
      </div>
    );
  }
}
export default RadioOptions;
