import React from "react";
import { Link } from "react-router-dom";
import { common } from "helpers";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class WithoutLogin extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Without Login</title>
        </Helmet>
        {/* <!-- Main Content --> */}
        <div className="main-content">
          <div className="question-wrap d-flex">
            {/* <!-- Question Block --> */}
            <div className="question-block">
              {/* <!-- Question Content --> */}
              <div className="container">
                <div className="question-content-inner alternate">
                  <div className="content-block d-flex align-items-center justify-content-center text-center">
                    <div className="content-info">
                      <img
                        src={common.loadImg("customer-service.png")}
                        className="mb-5"
                        alt="Contact Support"
                      />
                      <h3 className="primary-color">{translation.we_are_sorry}</h3>
                            <p className="mb-0">{translation.contact_support}</p>
                            <Link to="/sign-in" className="btn secondary-btn mt-5">{translation.log_in}</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Question Content End --> */}
            </div>
            {/* <!-- Question Block End --> */}
          </div>
        </div>
        {/* <!-- Main Content End --> */}
      </div>
    );
  }
}

export default WithoutLogin;
