import React from "react";
import { common, tools } from "helpers";
import { apiServices } from "api";
import { StatusBar } from "includes";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default class SupportList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "process",
      sData: {
        ticket: []
      },
      filter: {
        code: "",
      },
      paging: {
        pageSize: 1000,
        pageIndex: 1
      },
      translation: common.getTranslation(),
    };
  }

  componentDidMount() {
    this.socketIO();
    this.getSupportList();
  }

  componentWillUnmount = () => {
    window.socket.removeListener('userTicket');
  }


  getSupportList = () => {
    const { sData, filter } = this.state;
    // api call
    let param = `?userId=${common.getStorage("userId")}&search=${filter.code}`;

    let data = {
      method: "GET",
      url: "SUPPORT_LIST",
      query: param,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.ticket = common.apiResultData(res);
        this.setState({
          status,
          sData
        })
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => { }
    );
  };

  // filter
  filterByTicketId = (e) => {
    if (e.key === "Enter") {
      this.setState({
        status: "process",
      }, () => {
        this.getSupportList();
      })

    }
  }

  resetFilter = () => {
    let filter = {
      code: ""
    }
    let paging = {
      pageSize: 10,
      pageIndex: 1
    }
    this.setState({
      filter,
      paging,
      status: "process",
    }, () => {
      this.getSupportList();
    })
  }

  // events
  onFilterChange = (event) => {
    const { filter } = this.state;
    const { name, value } = event.target;
    filter[name] = value;
    this.setState({ filter })
  }

  pageTotal() {
    const { sData, paging } = this.state;
    let count = 1;
    if (sData.ticket.page) {
      count = Math.ceil(sData.ticket.page.count / paging.pageSize)
    }
    return count;
  }

  handlePageChange(event, value) {
    const { paging } = this.state;
    let pageNumber = value;
    if (paging.pageIndex != pageNumber) {
      paging.pageIndex = pageNumber;
      this.setState(
        {
          paging,
          status: "process",
        },
        () => {
          this.getSupportList();
        }
      );
    }
  }

  // support
  getStatus(item, type) {
    let name = "";
    let cls = "";
    if (item.status && !item.pickTicket.isPicked) {
      name = "Waiting";
      cls = "bg-warning"
    } else if (item.status) {
      name = "Open";
      cls = "bg-success"
    } else {
      name = "Closed";
      cls = "bg-danger"
    }
    if (type == "name") {
      return name;
    } else {
      return cls;
    }
  }

  // socket
  socketIO() {
    window.socket.on('userTicket', (result) => {
      const { status } = this.state;
      if (result.userId == common.getStorage("userId")) {
        if (status == "success") {
          this.getSupportList();
        }
      }
    })
  }

  render() {
    const { status, sData, filter, translation } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Support List</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Support Form Wrap --> */}
        <div className="support-form-wrap">
          <div className="container">
            
            <div className="row pb-3">
              <div className="col-lg-12">
                <div className="card card-4">
                  <div className="card-heading tertiary-bg d-flex justify-content-between align-items-center">
                  <div>
                    <h2 className="mb-3">{translation.support_title}</h2>
                    <h4>{translation.my_ticket}</h4>
                  </div>
                    
                    <Link to="/support" className="btn bordered-btn">
                      {translation.create_ticket}
                      </Link>
                  </div>
                  <div className="card-body p-0">
                    <div className="search-code">
                      <div className="form-group has-search d-flex align-items-center justify-content-between">
                        <img
                          src={common.loadImg("search-icon.png")}
                          alt="Search"
                          className="icon-search"
                        />
                        <input
                          type="text"
                          name="code"
                          className="form-control"
                          placeholder={translation.search_code}
                          value={filter.code}
                          onChange={(e) => this.onFilterChange(e)}
                          onKeyPress={(e) => this.filterByTicketId(e)}
                        />
                        <Link to="#" onClick={() => this.resetFilter()} className="link-text">
                         {translation.reset_search}
                          </Link>
                      </div>
                    </div>
                    <StatusBar status={status} />
                    {status == "success" && (
                      <div className="table-responsive mb-4">
                        <table className="table table-2">
                          <thead>
                            <tr>
                              <th>{translation.code}</th>
                              <th>{translation.date}</th>
                              <th>{translation.request_for}</th>
                              <th>{translation.description}</th>
                              <th className="text-center">{translation.status}</th>
                              <th className="text-center">{translation.action}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sData.ticket.data.map((item, key) => (
                              <tr key={key}>
                                <td>{item.token}</td>
                                <td>{tools.formatDate(item.cOn, 2)}</td>
                                <td>{item.requestFor}</td>
                                <td>{item.messageFirst.message}</td>
                                <td className="text-center">
                                  <label className={`btn ${this.getStatus(item, "class")}`}>{this.getStatus(item, "name")}</label>
                                </td>
                                <td className="text-center">
                                  <div>
                                    <Link to={`/support/ticket/${item.ticketId}`} className="link-text">{translation.view}</Link>
                                    {item.unread > 0 && (
                                      <span className="ticket-unread">{item.unread}</span>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* <Pagination
                          color="primary"
                          className="elk-pagination"
                          count={this.pageTotal()}
                          page={(paging.pageIndex)}
                          onChange={this.handlePageChange.bind(this)}
                        /> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Support Form Wrap End --> */}
      </div>
    );
  }
}