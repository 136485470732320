import React from "react";
import moment from "moment";
// includes
import { common } from "helpers";
import { apiServices } from "api";
import { StatusBar } from "includes";
import { envConfig } from "api/config";
let translation = common.getTranslation();

class AnalyticsSampleTopper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      filter: {
        userId: ""
      },
      sData: {
        topperList: [],
      },
      userData: common.authInfo(),
    };
  }

  componentDidMount() {
    const { filter, userData } = this.state;

    filter.userId = this.props.selUserId
    if (this.props.selUserId) {
      this.setState({
        filter: filter
      }, () => {
        this.getTopperList();
      })
    }
  }

  getTopperList = () => {
    const { sData, filter } = this.state;
    this.setStatus("process");
    this.rgb = 0;
    this.count = 1;
    // api call
    let param = `?userID=${filter.userId}`;

    let data = {
      method: "GET",
      url: "STAT_TOPPERS",
      query: param,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        sData.topperList = result;
        status = this.dataStatus(status, result);
        this.setState({
          status,
          sData
        })
      },
      (error) => {
        this.setStatus("error");
      },
      (final) => { }
    );
  };

  dataStatus(status, result) {
    if (status == "success") {
      status = "empty";
      status = result.length > 0 ? "success" : "empty";
    }
    return status;
  }

  // handler
  onChange(e) {
    const { name, value } = e.target;
    const { filter } = this.state;
    filter[name] = value;

    this.setState({
      filter
    }, () => {
      this.getTopperList();
    })
  }

  // filter
  resetFilter() {

    const { filter } = this.state;

    this.setState({
      filter: {
        userId: filter.userId
      }
    }, () => {
      this.getTopperList();
    })
  }

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setStatus(status) {
    this.setState({ status: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  render() {
    const {
      status,
      sData,
      filter,
      userData,
    } = this.state;
    return (
      <div>
        <div
          className="tab-panel-content tab-pane fade show active"
          id="tab2"
          role="tabpanel"
          aria-labelledby="tab-2"
        >
          <div className="tab-filters d-flex flex-wrap justify-content-between align-items-center">
            <h5 className="primary-color mb-0">{translation.contest_toppers_list}</h5>
            <div className="filter">
              <form className="form-inline">
                {
                  userData &&
                  userData.user &&
                  userData.user.role &&
                  userData.user.role == 1 && (
                    <div className="form-group">
                      <label>{translation.overall_child}</label>
                      <select
                        name="userId"
                        onChange={(e) => this.onChange(e)}
                        value={filter.userId}
                      >
                        {
                          this.props.childrens.length > 0 && this.props.childrens.map((item, key) => (
                            <option value={item._id} key={key}>{item.name}</option>
                          ))
                        }
                      </select>
                    </div>
                  )
                }
              </form>
            </div>
          </div>
          <StatusBar status={status} />
          {status == "success" && (
            <div>
              <div className="test-card">
                <div className="test-header d-flex justify-content-between align-items-center mb-5">
                  <h5 className="mb-0">{translation.contest_toppers}</h5>
                </div>

                <div className="table-responsive mt-5">
                  <table className="table table-3">
                    <thead>
                      <tr>
                        {/* <th></th> */}
                        <th>{translation.student}</th>
                        <th>{translation.medium_single}</th>
                        <th>{translation.score}</th>
                        <th>{translation.last_practiced}</th>
                        <th>{translation.time_spent}</th>
                        <th>{translation.rank}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sData.topperList.map((data, key) => (
                        <tr key={key} className={data._id === filter.userId ? "stu-highlight" : ""}>
                          {/* <td>{this.count++}</td> */}
                          <td>{data.name}</td>
                          <td>{common.getLangName(data.country)}</td>
                          <td>{data.score}</td>
                          <td>{moment(data.date).format("DD MMM YYYY")}</td>
                          <td>{common.toHHMMSS(envConfig.timeDuration - data.time)}</td>
                          <td>{key + 1}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AnalyticsSampleTopper;
