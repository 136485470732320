import React from "react";
import { Link } from "react-router-dom";
// includes
import { common } from "helpers";
// pages
import TestScores from "./includes/TestScores";
import TargetArea from "./includes/TargetArea";
import RevisionQuestions from "./includes/RevisionQuestions";
import Usage from "./includes/Usage";
let translation = common.getTranslation();

class TopicWise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selSubTab: this.props.selSubTab ? this.props.selSubTab : "",
            subjects: common.authInfo().subject ? (common.authInfo().subject.filter(element => {
                return element.grade != undefined;
              })) : [],
            dateRange: [
                translation.today,
                translation.this_week,
                translation.last_seven_days,
                translation.this_month,
                translation.last_thirty_days,
                translation.this_year,
                translation.all_time,
            ],
            subMenus: [
                { key: "testscore", value: translation.test_scores },
                { key: "targetarea", value: translation.target_area },
                { key: "revisionquestion", value: translation.revision_questions },
                { key: "usage", value: translation.usage }
            ],
            status: "",
            selSubIndex: "",
            selGradeIndex: "",
            selDateRangeIndex: "",
            testId: "",
            sessionId: "",
            userData: common.authInfo(),
            selUserId: ""
        };    
    }

    onChangeSelectOption = (e) => {
        const { name, value } = e.target;
        let selIndex = e.target.options.selectedIndex;
        switch (name) {
            case "subject":
                if (value == "") {
                    this.setState({
                        selSubIndex: value,
                        selGradeIndex: value
                    }, () => { this.childRefresh() });
                } else {
                    this.setState({ selSubIndex: value }, () => { this.childRefresh() });
                }
                break;
            case "dateRange":
                this.setState({ selDateRangeIndex: value }, () => { this.childRefresh() });
                break;
            case "grade":
                this.setState({ selGradeIndex: value }, () => { this.childRefresh() });
                break;
            case "selUserId":
                this.setState({ 
                    selUserId: value ,
                    selSubIndex: "",
                    selGradeIndex: "",
                    selDateRangeIndex: "",
                    testId: "",
                    sessionId: "",
                    subjects: this.props.childrens[selIndex].class
                }, () => { 
                    this.childRefresh() 
                });
            break;
            default: break;
        }
    }

    resetFilter = () => {
        this.setState({
            selSubIndex: "",
            selGradeIndex: "",
            selDateRangeIndex: "",
            testId: "",
            sessionId: ""
        }, () => {
            this.childRefresh()
        })
    }

    onClickSubTab = (tab) => {
        this.setState({
            selSubTab: tab,
            selSubIndex: "",
            selGradeIndex: "",
            selDateRangeIndex: "",
            testId: "",
            sessionId: ""
        })
    }

    updateTestId = (testId, subjectId, subMenu, sessionId = "") => {

        const { subjects } = this.state;

        let subInd = "";
        subjects.forEach((element, eleInx) => {
            if (subjectId == element._id) {
                subInd = eleInx;
            }
        });

        this.setState({
            selSubTab: subMenu,
            selSubIndex: subInd,
            selGradeIndex: "",
            selDateRangeIndex: "",
            testId: testId,
            sessionId: sessionId
        })
    }

    componentDidMount() {
        const { userData } = this.state;
        if (userData.user && userData.user && userData.user.role && userData.user.role == 1) {
            let childrens = this.props.childrens
            if (childrens && childrens.length > 0) {
                this.setState({
                    subjects: childrens[0].class.filter(element => {
                        return element.grade.length > 0;
                      })
                    });
            }
        }
        this.setState({
            selUserId: this.props.selUserId
        })
    }

    renderTopicSubCom = () => {

        const { selSubTab, selSubIndex, subjects, selGradeIndex, selDateRangeIndex, testId, sessionId, selUserId } = this.state;

        let selSubId = selSubIndex === "" ? "" : subjects[selSubIndex]._id
        let selGradeId = selGradeIndex === "" ? "" : subjects[selSubIndex].grade[selGradeIndex]._id

        switch (selSubTab) {
            case "testscore":
                return (<TestScores
                    setChildRefreshMethod={this.setChildRefreshMethod}
                    selSubId={selSubId}
                    selGradeId={selGradeId}
                    selDateRangeId={selDateRangeIndex}
                    childrens={this.props.childrens}
                    selUserId={selUserId}
                ></TestScores>)
                break;
            case "targetarea":
                return (<TargetArea
                    setChildRefreshMethod={this.setChildRefreshMethod}
                    selSubId={selSubId}
                    selGradeId={selGradeId}
                    selDateRangeId={selDateRangeIndex}
                    updateTestId={this.updateTestId}
                    childrens={this.props.childrens}
                    selUserId={selUserId}
                ></TargetArea>)
                break;
            case "revisionquestion":
                return (<RevisionQuestions
                    setChildRefreshMethod={this.setChildRefreshMethod}
                    selSubId={selSubId}
                    selGradeId={selGradeId}
                    selDateRangeId={selDateRangeIndex}
                    testId={testId}
                    sessionId={sessionId}
                    childrens={this.props.childrens}
                    selUserId={selUserId}
                ></RevisionQuestions>)
                break;
            case "usage":
                return (<Usage
                    setChildRefreshMethod={this.setChildRefreshMethod}
                    selSubId={selSubId}
                    selGradeId={selGradeId}
                    selDateRangeId={selDateRangeIndex}
                    updateTestId={this.updateTestId}
                    childrens={this.props.childrens}
                    selUserId={selUserId}
                ></Usage>)
                break;
            default:
                return <></>
        }
    }

    childRefresh = () => { } // initial value for childRefresh
    setChildRefreshMethod = (refrshMethod) => {
        this.childRefresh = refrshMethod;
    }

    getSubMenuTitle = () => {
        const { selSubTab, subMenus } = this.state;
        if (selSubTab == "") {
            return "Test Scores"
        } else {
            let selMenu = subMenus.filter((data) => {
                return data.key == selSubTab
            });
            if (selMenu.length > 0) {
                return selMenu[0].value;
            } else {
                return "Test Scores"
            }
        }

    }

    render() {

        const {
            selSubTab,
            subjects,
            subMenus,
            selSubIndex,
            dateRange,
            userData,
            selUserId,
            selDateRangeIndex,
            selGradeIndex
        } = this.state;

        return (
            <div
                className="tab-pane fade show active"
                id="tab1"
                role="tabpanel"
                aria-labelledby="tab-1"
            >
                <ul
                    className="nav nav-tabs sub-menu justify-content-center"
                    id="myTab"
                    role="tablist"
                >
                    {
                        subMenus.map((subMenuData, subMenuInd) => (
                            <li className="nav-item" key={subMenuInd}>
                                <Link
                                    className={selSubTab == subMenuData.key ? "nav-link active" : "nav-link"}
                                    to={"/analytics/topic/" + subMenuData.key}
                                    onClick={() => this.onClickSubTab(subMenuData.key)}
                                >
                                    {subMenuData.value}
                                </Link>
                            </li>
                        ))
                    }
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div
                        className="tab-panel-content tab-pane fade show active"
                        id="subTab1"
                        role="tabpanel"
                        aria-labelledby="tab-1"
                    >
                        <div className="tab-filters d-flex flex-wrap justify-content-between align-items-center">
                            <h5 className="primary-color mb-0">
                                {this.getSubMenuTitle()}
                            </h5>
                            <div className="filter">
                                <form className="form-inline">
                                    {
                                        userData && 
                                        userData.user &&
                                        userData.user.role &&
                                        userData.user.role == 1 && (
                                            <div className="form-group">
                                                <label>{translation.child}</label>
                                                <select 
                                                    name="selUserId" 
                                                    onChange={(e) => this.onChangeSelectOption(e)}
                                                    value={selUserId}
                                                >
                                                    {
                                                        this.props.childrens.length > 0 && this.props.childrens.map((item, key) => (
                                                            <option value={item._id} key={key}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        )
                                    }
                                    <div className="form-group">
                                        <label>{translation.mock_subject}</label>
                                        <select 
                                        name="subject" 
                                        onChange={(e) => this.onChangeSelectOption(e)}
                                        value={selSubIndex}
                                        >
                                            <option value="">{translation.select_option}</option>
                                            {
                                                subjects.length > 0 && subjects.map((sub, subInd) => (
                                                    <option value={subInd} key={subInd}>{sub.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>{translation.mock_grade}</label>
                                        <select 
                                        name="grade" 
                                        onChange={(e) => this.onChangeSelectOption(e)}
                                        value={selGradeIndex}
                                        >
                                            <option value="">{translation.select_option}</option>
                                            {
                                                subjects.length > 0 &&
                                                selSubIndex != "" &&
                                                subjects[selSubIndex].grade.map((gradeData, gradeInd) => (
                                                    <option value={gradeInd} key={gradeInd}>{gradeData.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>{translation.mock_date_range}</label>
                                        <select 
                                        name="dateRange" 
                                        value={selDateRangeIndex} 
                                        onChange={(e) => this.onChangeSelectOption(e)}>
                                            <option value="">{translation.select_option}</option>
                                            {
                                                dateRange.map((dateData, dateInd) => (
                                                    <option value={dateInd + 1} key={dateInd}>{dateData}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <input
                                        type="reset"
                                        value={translation.reset}
                                        className="btn-sm"
                                        onClick={() => this.resetFilter()}
                                    />
                                </form>
                            </div>
                        </div>
                        {
                            selUserId !== "" && (
                                this.renderTopicSubCom()
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default TopicWise;