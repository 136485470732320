import React from "react";
import { common, history, tools } from "helpers";
import { Link } from "react-router-dom";
// includes
import { apiServices } from "api";
import { StatusBar, Handler } from "includes";
// pages
import MyTests from "./MyTests.js";
import MyProgress from "./MyProgress.js";
import MyGoals from "./MyGoals.js";
import { Helmet } from "react-helmet";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
let translation = common.getTranslation();

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: common.getStorage("userId"),
      status: "process",
      activeTab: "myTests",
      childData: [],
      userInfo: [],
      userData: common.authInfo(),
      userPlan: this.props.userPlan,
      todayGoalList: [],
      todayGoalCount: 0,
      selectedSubject: "",
      studentList: [],
      teacherSchool: "",
      selectedStudent: {},
      allStudent: "",
      isVerifyModelOpen: false,
      fields: {
        mobile: "",
        email: "",
        activationKey: "",
        password: "",
        confirmPassword: "",
      },
      errors: {},
      acceptVerification: false,
      isLoading: false,
      verificationSubmit: false,
      isActivation: false,
    };
  }

  componentDidMount = () => {
    const { userData, fields } = this.state;
    console.log("==========userData", userData);
    if (userData.user.isVerify !== undefined && userData.user.isVerify == false) {
      fields["email"] = userData.user.email;
      fields["mobile"] = userData.user.mobile;
      this.setState({
        isVerifyModelOpen: true,
        fields
      });
    } else {
      this.initLoad();
    }
  };

  initLoad = () => {
    const { userData } = this.state;
    this.getTodayGoalList();
      if (userData.user.role === 1) {
        this.getChildList();
      } else {
        this.getUserInfo();
      }
      if (
        userData.user &&
        userData.user.userInfo &&
        userData.user.userInfo.school
      ) {
        this.setState({
          teacherSchool:
            userData.user.userInfo.school.name != ""
              ? userData.user.userInfo.school.name
              : "",
        });
      }
  }

  getUserInfo = () => {
    const { userId } = this.state;
    let query = `?userId=${userId}`;
    let data = {
      method: "GET",
      url: "COMMON_USER_INFO",
      query
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResult(res);
        if (status == "success") {
          this.setState({
            status: "success",
            userInfo: result
          });
        } else {
          this.setState({ status: "error" });
        }
      },
      (error) => {
        this.setState({ status: "error" });
      },
      (final) => { }
    );
  }

  getTodayGoalList = () => {
    const { userId } = this.state;
    let query = `?userId=${userId}`;
    let data = {
      method: "GET",
      url: "TODAY_GOAL_LIST",
      query
    };
    this.setState({
      todayGoalList: []
    })
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        if (status == "success") {
          let result = common.apiResult(res);
          let count = 0;
          result.map((goals) => {
            count = count + goals.test.length;
          });
          this.setState({
            // status: "success",
            todayGoalList: result,
            todayGoalCount: count
          });
        } else {
          this.setState({
            todayGoalList: [],
            todayGoalCount: 0
          });
        }
      },
      (error) => {
        this.setState({ status: "error" });
      },
      (final) => { }
    );
  }

  getChildList = () => {
    const { userId } = this.state;
    let urlKeys = {
      parentId: userId
    }

    let data = {
      method: "GET",
      url: "GET_CHILD_BY_PARENT",
      key: urlKeys
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        if (status == "success") {
          let childId = result.data[0]._id;
          this.setState({
            userId: childId,
            childData: result
          }, () => {
            this.getUserInfo();
          })
        } else {
          this.setState({ status })
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => { }
    );
  }

  getSubject() {
    const { userData } = this.state;
    const subject = [];
    userData.subject.map((item, key) => {
      subject.push(item.name);
    });
    return subject.join(", "); 
  }

  onChildChange(e) {
    const { value } = e.target;
    const { userData, studentList } = this.state;
    
      this.setState(
        {
          status: value != "all" ? "process" : "success",
          userId: value,
        },
        () => {
          if (value != "all") {
          this.getUserInfo();
          this.getTodayGoalList();
          if (userData.user.role == 4) {
            studentList &&
              studentList.length > 0 &&
              studentList.map((item) => {
                if (item._id == value) {
                  this.setState({
                    selectedStudent: item,
                  });
                }
              });
          }
        } else {
          this.setState({
            selectedStudent: [],
          });
        }
        }
      );
  }
  onSubjectChange(e) {
    const { value } = e.target;
    if(value){
    this.setState({
      selectedSubject: value
    },
    _=>{
      this.getStudentList();
    })
  } else {
    this.setState({
      selectedSubject: "",
      selectedStudent: {},
      studentList: []
    })
  }
  }

  setTab(e, tab) {
    e.preventDefault();
    this.setState({
      activeTab: tab
    });
    // this.setGoalTab(0)
  }

  onSetGoal(data) {
    this.setState({ activeTab: "myGoals" })
    this.MyGoals.setGoal(data);
  }

  setGoalTab = (val) => {
    this.setState({ activeTab: "myGoals" })
    this.MyGoals.setTodayGoal(val);
  }

  callMyTest = () => {
    this.setState({ activeTab: "myTests" });
    this.MyTests.setTodayTest();
  }

  takeExam = (e) => {
    e.preventDefault();
    let testURL = common.getLangSampleTestURL(this.state.userData);
    history.push(testURL);
  }

  getStudentList = () => {
    const {selectedSubject, teacherSchool } = this.state;
    if (selectedSubject && teacherSchool) {
      let query = `?class=${selectedSubject}&school=${teacherSchool}`;
      let data = {
        method: "GET",
        url: "GET_ALL_STUDENT",
        query,
      };
      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResult(res);
          let stuStr = "";
            result.length > 0 && result.map((stu, i) => {
                stuStr += stu._id;
                if(result.length > i+1){
                  stuStr += ","
                }
            })      
            this.setState({
              studentList: result,
              allStudent: stuStr
            });
        },
        (error) => {
          this.setState({
            status: "error",
          });
        },
        (final) => {}
      );
    }
  }

  closeVerifyModal = () => {
    let data = {
      method: "POST",
      url: "SIGNOUT",
      body: { online: false },
    };

    apiServices.call(
      data,
      (response) => {
        let status = common.apiStatus(response);
        if (status == "success") {
          let msg = "Logout successfully";
          common.snack("S", msg);
          common.removeAuthStorage();
          common.redirect("/sign-in", 1);
          this.setState(
            {
              isVerifyModelOpen: false,
              acceptVerification: false,
              isActivation: false,
              verificationSubmit: false,
            },
          );
        }
      },
      (error) => {
        let msg = common.getLangMessage(error.message);
        common.snack("E", msg);
      },
      (final) => { }
    );    
  };

  callVerify = (e) => {
    e.preventDefault();
    const { errors, userData } = this.state;
    if (userData && userData.user.regType == "email") {
      errors["email"] = {
        error: "",
        isReq: translation.email_req,
        isValidEmail: true,
        isValidEmailReq: translation.valid_email,
      };
    } else {
      errors["mobile"] = {
        error: "",
        isReq: translation.mobile_req,
      };
    }
    this.setState({
      acceptVerification: true,
      errors,
    });
  };

  onVerifySubmit = () => {
    const { userData } = this.state;
    if (userData.user.regType == "email") {
      this.emailSubmit();
    } else if (userData.user.regType == "mobile") {
      this.mobileSubmit();
    }
  };

  emailSubmit = () => {
    const { fields } = this.state;
    this.setState({
      verificationSubmit: true,
    });
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setState({isLoading: true});
      // api call
      let params = {
        email: fields.email,
      };
      let urlKeys = {
        userId: common.getStorage("userId"),
      };

      let data = {
        method: "PUT",
        url: "SEND_CODE_EMAIL",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResultData(res);
          if (status == "success") {
            common.setStorage("email", tools.crypt(fields.email, true));
            common.setStorage("mobile", "");
            common.snack("S", result.message);
            this.openKeyVerify();
          } else {
            if (status == "failure") {
              common.snack("E", translation.key_send_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.key_send_failed);
        },
        (final) => {
          this.setState({isLoading: false});
        }
      );
    }
  };

  mobileSubmit = () => {
    const { fields } = this.state;
    this.setState({
      verificationSubmit: true,
    });
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setState({isLoading: true});
      // api call
      let params = {
        mobile: fields.mobile,
      };
      let urlKeys = {
        userId: common.getStorage("userId"),
      };

      let data = {
        method: "PUT",
        url: "SEND_CODE_MOBILE",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResultData(res);
          if (status == "success") {
            common.setStorage("mobile", tools.crypt(fields.mobile, true));
            common.setStorage("email", "");
            common.snack("S", result.message);
            this.openKeyVerify();
          } else {
            if (status == "failure") {
              common.snack("E", translation.update_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.update_failed);
        },
        (final) => {
          this.setState({isLoading: false});
        }
      );
    }
  };

  openKeyVerify = () => {
    const { errors } = this.state;
    errors["activationKey"] = {
      error: "",
      isReq: translation.activate_code,
    };
    errors["password"] = {
      error: "",
      minLength: 6,
      isReq: translation.isReq_new,
      isMinLengthReq: translation.pwd_min_len,
    };
    errors["confirmPassword"] = {
      error: "",
      minLength: 6,
      isReq: translation.isReq_confirm,
      isMinLengthReq: translation.pwd_min_len,
      matchPassword: true,
      matchPasswordReq: translation.matchPasswordReq,
    };
    this.setState({
      verificationSubmit: false,
      isActivation: true,
      errors,
    });
  };

  onKeySubmit(e) {
    e.preventDefault();
    this.setState({
      verificationSubmit: true,
    });
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setState({isLoading: true});
      const { fields } = this.state;
      // api call
      let params = {
        activationKey: fields.activationKey,
        password: fields.password,
        confirmPassword: fields.confirmPassword,
      };

      if(fields.email != ""){
        params["email"] = fields.email
      }

      if(fields.mobile != ""){
        params["mobile"] = fields.mobile
      }

      let data = {
        method: "PUT",
        url: "MEMBERSHIP_ACTIVATE",
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            common.snack("S", translation.member_activation);
            common.setStorage("email", "");
            common.setStorage("mobile", "");
            this.setState({
              isVerifyModelOpen: false,
              acceptVerification: false,
              isActivation: false,
              verificationSubmit: false,
            });
            this.getUserData();
          }
        },
        (error) => {},
        (final) => {
          this.setState({isLoading: false});
        }
      );
    }
  }

  getUserData = () => {
    let urlKeys = {
      userId: common.getStorage("userId"),
    };
    let data = {
      method: "GET",
      url: "GET_USER_PROFILE",
      key: urlKeys,
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = common.apiResultData(res);
        this.loginData(result.data);
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  };

  loginData(user) {
    let userData = common.authInfo();
    userData.user.email = user.role != 2  ? user.email : user.parentUserId.email;
    userData.user.mobile = user.mobile;
    userData.user.isVerify = user.isVerify;
    common.setStorage("userData", tools.crypt(userData, true));
    this.initLoad();
  }

  render() {
    const { fields, formSubmit, verificationSubmit, isLoading, isActivation, acceptVerification, errors, isVerifyModelOpen, allStudent, selectedStudent, selectedSubject, studentList, status, userData, userInfo, userId, childData, activeTab, userPlan, todayGoalList, todayGoalCount, teacherSubjects } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- User Landing Wrap --> */}
        <div className="user-landing-wrap pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-11 mx-auto">
                <div className="card card-4 alternate">
                  <div className="card-heading primary-bg d-flex flex-row justify-content-between align-items-center">
                    <div className="user-info">
                      <div className="container">
                        <div className="row">
                          {userData.user.role !== 1 &&
                            userData.user.role != 4 &&
                            userData.subject.map((item, key) => {
                              return (
                                <div className="col-lg-4">
                                  <ul className="all-subjects">
                                    <li>{item.name}</li>
                                  </ul>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      {childData != "" && userData.user.role != 4 && (
                        <div className="child-filter">
                          <div className="row">
                            <div className="col-4">
                              <select
                                className="form-control"
                                value={userId}
                                onChange={(e) => this.onChildChange(e)}
                              >
                                {childData.data.map((item, key) => (
                                  <option key={key} value={item._id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {userData.user.role == 4 && (
                        <div className="child-filter">
                          <div className="row">
                            <div className="col-4">
                              <select
                                className="form-control"
                                value={selectedSubject}
                                onChange={(e) => this.onSubjectChange(e)}
                              >
                                <option value="">Select Subject</option>
                                {userData.subject.map((item, key) => {
                                  return (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="col-4">
                              <select
                                className="form-control"
                                value={userId}
                                onChange={(e) => this.onChildChange(e)}
                              >
                                <option value="">Select Student</option>
                                {studentList.length > 0 &&
                                  <option value="all">All Student</option>
                                }
                                {studentList.map((item, key) => {
                                  return (
                                    <option key={key} value={item._id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {todayGoalCount > 0 && (
                        // <p className="tdy-goal mt-3">{"Today "} {userData.user.role === 1 ? "child" : "you"} {" have a Goal - "}
                        <p className="tdy-goal mt-3">
                          {" "}
                          {userData.user.role === 1
                            ? translation.parent_goal
                            : translation.child_goal}{" "}
                          {" - "}
                          <Link
                            className="notification"
                            to="#"
                            onClick={() => this.setGoalTab(3)}
                          >
                            {todayGoalCount} {translation.goals}
                          </Link>
                        </p>
                      )}
                    </div>
                    {userData.user.role !== 1 && userPlan && //Commented temporarily as per Manula request
                      userPlan.plan &&
                      userPlan.plan.planId !== "free" &&
                      userPlan.paymentStatus !== "expired" &&
                      <Link className="takeExam" to="#" onClick={(e) => this.takeExam(e)}>{translation.contest_win}</Link>
                    }

                    {/* <div className="user-img text-center">
                      {userData.user.role !== 1 ?
                        <img src={common.userImage()} /> :
                        childData && childData.data && childData.data.length > 0 && <img src={common.childImage(childData, userId)} />}
                      <Link to="#" className="btn mt-3">
                        {userData.user.name}
                      </Link>
                    </div> */}
                  </div>

                  <div className="card-body">
                    <div className="card-body-inner">
                      <StatusBar status={status} />
                      {status == "success" && (
                        <div className="dashboard-block mt-3">
                          <ul
                            className="nav nav-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className={
                                  activeTab == "myTests"
                                    ? "nav-link show active"
                                    : "nav-link"
                                }
                                id="tab-1"
                                data-toggle="tab"
                                href="#tab1"
                                role="tab"
                                aria-controls="tab1"
                                aria-selected="true"
                                onClick={(e) => this.setTab(e, "myTests")}
                              >
                                  {userData.user.role != 4 ? translation.mytests : translation.tests}
                              </a>
                            </li>
                            {userId != "all" && 
                            <li className="nav-item">
                              <a
                                className={
                                  activeTab == "myProgress"
                                    ? "nav-link show active"
                                    : "nav-link"
                                }
                                id="tab-2"
                                data-toggle="tab"
                                href="#tab2"
                                role="tab"
                                aria-controls="tab2"
                                aria-selected="false"
                                onClick={(e) => this.setTab(e, "myProgress")}
                              >
                                {userData.user.role != 4 ? translation.myprogress : translation.progress}
                              </a>
                            </li>
                            }
                            {userData.user.role != 4 && (
                              <li className="nav-item">
                                <a
                                  className={
                                    activeTab == "myGoals"
                                      ? "nav-link show active"
                                      : "nav-link"
                                  }
                                  id="tab-3"
                                  data-toggle="tab"
                                  href="#tab3"
                                  role="tab"
                                  aria-controls="tab3"
                                  aria-selected="false"
                                  onClick={(e) => this.setTab(e, "myGoals")}
                                >
                                  {translation.mygoals}
                                </a>
                              </li>
                            )}
                          </ul>

                          <div
                            className="tab-content border-tab"
                            id="myTabContent"
                          >
                            <div
                              className={
                                activeTab == "myTests"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="tab1"
                              role="tabpanel"
                              aria-labelledby="tab-1"
                            >
                              <MyTests
                                userId={userId}
                                userInfo={userInfo}
                                userData={userData}
                                userPlan={userPlan}
                                todayGoalList={todayGoalList}
                                ref={(instance) => {
                                  this.MyTests = instance;
                                }}
                                selectedSubject={selectedSubject}
                                selectedStudent={selectedStudent}
                                allStudent={allStudent}
                                studentList={studentList}
                              />
                            </div>

                            {userId != "all" && 
                            <div
                              className={
                                activeTab == "myProgress"
                                  ? "tab-pane fade show active"
                                  : "tab-pane fade"
                              }
                              id="tab2"
                              role="tabpanel"
                              aria-labelledby="tab-2"
                            >
                                  <MyProgress
                                    userPlan={userPlan}
                                    userId={userId}
                                    userInfo={userInfo}
                                    onSetGoal={(data) => this.onSetGoal(data)}
                                    selectedSubject={selectedSubject}
                                    selectedStudent={selectedStudent}
                                  />
                            </div>
                            }
                            {userData.user.role != 4 && (
                              <div
                                className={
                                  activeTab == "myGoals"
                                    ? "tab-pane fade show active"
                                    : "tab-pane fade"
                                }
                                id="tab3"
                                role="tabpanel"
                                aria-labelledby="tab-3"
                              >
                                <MyGoals
                                  userData={userData}
                                  userPlan={userPlan}
                                  userId={userId}
                                  userInfo={userInfo}
                                  ref={(instance) => {
                                    this.MyGoals = instance;
                                  }}
                                  todayGoalList={todayGoalList}
                                  getTodayGoalList={() =>
                                    this.getTodayGoalList()
                                  }
                                  callMyTest={() => this.callMyTest()}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- User Landing Wrap End --> */}
        {/* verify modal */}
        <Dialog
          open={isVerifyModelOpen}
          // onClose={this.closeVerifyModal}
          aria-labelledby="responsive-dialog-title"
          className="explanation-model"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="card-heading tertiary-bg"
          >
           {!isActivation && !acceptVerification ? translation.verification_message 
           : 
            acceptVerification && userData.user.regType == "email" ? "Verify Your Email" : "Verify Your Mobile"}
          </DialogTitle>
          <DialogContent>
            {!isActivation && !acceptVerification ?
            <div className="card-body verify-body">Hi {userData.user.name}, {`Your example account is ready.`}<br /> {`Please verify your username  to start enjoying xample.lk`}</div>
            :
            !isActivation && acceptVerification ?
            <div className="card-body verify-body">{`Please confirm your ${userData.user.regType == "email" ? "email" : "Mobile Number"} to send the verfication code`}</div>
            :
            <div className="card-body verify-body">{`Please enter the verification code sent to ${userData.user.regType == "email" ? fields.email : fields.mobile}`} <br /> {`and create a new password`}</div>

  }
            {!isActivation && !acceptVerification && (
              <div className="form-actions d-flex justify-content-around align-items-center mb-5">
                <a
                  href="#"
                  className="btn bordered-btn-blue"
                  onClick={() => this.closeVerifyModal()}
                >
                  {" "}
                  {translation.header_logout}
                </a>
                <a
                  href="#"
                  className="btn bordered-btn-blue"
                  onClick={(e) => this.callVerify(e)}
                >
                  {"Verify Now"}
                </a>
              </div>
            )}
            {!isActivation && acceptVerification && (
              // <div className="card-body">
              <form method="POST" className="form-body">
                {userData &&
                  userData.user.regType &&
                  userData.user.regType == "mobile" && (
                    <div className="form-group">
                      <Input
                        type="number"
                        name="mobile"
                        value={fields.mobile}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                        className="form-control custom-mobile-field"
                        placeholder=""
                        inputProps={{
                          maxLength: 9,
                        }}
                        startAdornment={
                          <InputAdornment position="start">+94</InputAdornment>
                        }
                      />
                      <span className="floating-label">
                        Enter Mobile Number
                      </span>
                      {errors.mobile &&
                        errors.mobile.error != "" &&
                        formSubmit && (
                          <span className="elk-error">
                            {errors.mobile.error}
                          </span>
                        )}
                    </div>
                  )}

                {userData &&
                  userData.user.regType &&
                  userData.user.regType == "email" && (
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={fields.email}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                        className="form-control"
                        placeholder=" "
                      />
                      <span className="floating-label">
                        Enter Email Address
                      </span>
                      {errors.email &&
                        errors.email.error != "" &&
                        verificationSubmit && (
                          <span className="elk-error">
                            {errors.email.error}
                          </span>
                        )}
                    </div>
                  )}

                <div className="form-actions d-flex justify-content-between">
                  <input
                    onClick={() => this.closeVerifyModal()}
                    type="button"
                    className="btn bordered-btn"
                    value={translation.header_logout}
                    disabled={isLoading}
                  />
                  <input
                    onClick={() => this.onVerifySubmit()}
                    type="button"
                    className="btn btn-light-blue"
                    disabled={isLoading}
                    value={isLoading ? "Processing..." : userData.user.regType == "email"  ? "Verify Email" : "Verify Mobile"}
                  />
                </div>
              </form>
              // </div>
            )}
            {isActivation && (
              <form method="POST" className="form-body">
                <div className="form-group">
                  <input
                    type="text"
                    name="activationKey"
                    className="form-control"
                    placeholder=" "
                    value={fields.activationKey}
                    onChange={(e) => this.handler.onChange(e)}
                    onKeyDown={this.onEnterPress}
                  />
                  <span className="floating-label">
                    {/* {translation.activation_key}  */}
                    Verification Code
                  </span>
                  {errors.activationKey &&
                    errors.activationKey.error != "" &&
                    verificationSubmit && (
                      <span className="elk-error">
                        {errors.activationKey.error}
                      </span>
                    )}
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder=" "
                        value={fields.password}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                      />
                      <span className="floating-label">
                        {/* {translation.enter_password} */}
                        Create New Password
                      </span>
                      {errors.password &&
                        errors.password.error != "" &&
                        verificationSubmit && (
                          <span className="elk-error">
                            {errors.password.error}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder=" "
                        value={fields.confirmPassword}
                        onChange={(e) => this.handler.onChange(e)}
                        onKeyDown={this.onEnterPress}
                      />
                      <span className="floating-label">
                        {/* {translation.retype_password} */}
                        Confirm New Password
                      </span>
                      {errors.confirmPassword &&
                        errors.confirmPassword.error != "" &&
                        verificationSubmit && (
                          <span className="elk-error">
                            {errors.confirmPassword.error}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="form-actions d-flex justify-content-between">
                  <input
                    type="reset"
                    className="btn bordered-btn"
                    onClick={() => this.closeVerifyModal()}
                    value={translation.header_logout}
                  />
                  <input
                    type="button"
                    className="btn btn-light-blue"
                    onClick={(e) => this.onKeySubmit(e)}
                    disabled={isLoading}
                    value={isLoading ? "Processing" : translation.submit}
                  />
                </div>
              </form>
            )}
          </DialogContent>
        </Dialog>

        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default Dashboard;
