import React from "react";
import { envConfig } from "../../../api/config";
import { common } from "helpers";
let translation = common.getTranslation();

class Review extends React.Component {
  constructor(props) {
    super(props);
  }

  continue = (e) => {
    e.preventDefault();
    this.props.continue();
  };

  render() {
    const { result } = this.props;
    let correct = Math.round((result.CorrectAnswer / result.totalQuestion) * 100);
    let wrong = Math.round((result.WrongAnswer / result.totalQuestion) * 100);    
    let remaining = 100 - (correct + wrong);
    return (
      <div>
        <div className="page-title px-80 mt-5">
          <h5 className="primary-color mb-4">{translation.your_review}</h5>
        </div>

        <div className="review-overview px-80">
          <div className="progress-bar-values d-flex">
            {correct > 0 && (
              <span className="text-success" style={{ width: correct + "%" }}>
                {correct}%
              </span>
            )}
            {wrong > 0 && (
              <span className="text-danger" style={{ width: wrong + "%" }}>
                {wrong}%
              </span>
            )}
            {remaining > 0 && (
              <span className="text-warning" style={{ width: remaining + "%" }}>
                {remaining}%
              </span>
            )}
          </div>
          <div className="progress progress-lg">
            {correct > 0 && (
              <div
                className="progress-bar bg-success"
                style={{ width: correct + "%" }}
              >
              </div>
            )}
            {wrong > 0 && (
              <div
                className="progress-bar bg-danger"
                style={{ width: wrong + "%" }}
              >
              </div>
            )}
            {remaining > 0 && (
              <div
                className="progress-bar bg-warning"
                style={{ width: remaining + "%" }}
              >
              </div>
            )}
          </div>
          <div className="status-info d-flex justify-content-between mt-3">
            <label className="success">{translation.correct}</label>
            <label className="danger">{translation.wrong}</label>
            <label className="warning">{translation.unaswered}</label>
          </div>
        </div>

        <div className="review-report d-flex px-80">
          <div className="mark-col d-flex align-items-center justify-content-between">
            <h6 className="mb-0">{translation.marks}</h6>
            <span className="primary-bg">{result.score}</span>
          </div>
         
          <div className="mark-col d-flex align-items-center justify-content-between">
            <h6 className="mb-0">{translation.actual_marks}</h6>
            <span className="secondary-bg">{result.totalQuestion * envConfig.CORRECT_ANSWER_SCORE}</span>
          </div>
        </div>
        <div className="btn-actions px-80 text-center">
          <a
            href="#"
            className="btn secondary-btn mt-5"
            onClick={(e) => this.continue(e)}
          >
            {translation.continue}
          </a>
        </div>
      </div>
    );
  }
}
export default Review;
