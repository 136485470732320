import { BehaviorSubject, Observable } from "rxjs";

const subject = new BehaviorSubject();

export const webSharedService = {
  updateSubjects: (subjects) => subject.next(subjects),
  clearSubjects: () => subject.next(),
  getSubject: () => subject.asObservable(),
  // siteWebOptions // userData
};
