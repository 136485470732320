import React from "react";
import { Link } from "react-router-dom";
// includes
import { Handler } from "includes";
import { common, message, history, tools } from "helpers";
import { apiServices } from "api";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class ChangeEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formSubmit: false,
      fields: {
        email: "",
      },
      errors: {
        email: {
            error: "",
            isReq: translation.email_req,
            isValidEmail: true,
            isValidEmailReq: translation.valid_email,
        },
      },
      userData: common.authInfo(),
    };
  }

  // handler
  onSubmit() {
    this.setSubmit(true);
    let isValid = this.handler.validateForm();
    if (isValid) {
      this.setLoading(true);
      const{ fields, userData} = this.state; 
      // api call
      let params = {
        email: fields.email,
      };
      let urlKeys = {
        userId: common.getStorage("userId"),
      }
      
      let data = {
        method: "PUT",
        url: "CHANGE_EMAIL",
        key: urlKeys,
        body: params,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          let result = common.apiResultData(res);
          if (status == "success") {
            // userData.user.screenName = fields.screenName;
            // common.setStorage("userData", tools.crypt(userData, true));
            common.setStorage("email", tools.crypt(fields.email, true));
            common.setStorage("mobile", "");
            common.snack("S", result.message);
            if(result.data !== true){
              history.push("/activation/email");
            }
          } else {
            if (status == "failure") {
              common.snack("E", translation.update_failed);
            }
          }
        },
        (error) => {
          common.snack("E", translation.update_failed);
        },
        (final) => {
          this.setLoading(false);
        }
      );
    }
  }

  // event
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  // support function
  setSubmit(status) {
    this.setState({ formSubmit: status });
  }

  setLoading(status) {
    this.setState({ isLoading: status });
  }

  redirect() {
    history.push("/settings/profile");
  }

  render() {
    const { isLoading, formSubmit, fields, errors } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Change Email</title>
        </Helmet>
        <Link to="#" className="custom-link">
          {/* {translation.xample_plaform_is_a} <span>{translation.novelwall}</span> {translation.product} */}
          XAMPLE PLAFORM IS A <span>NOVELWALL</span> PRODUCT
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <div className="form-content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="card card-2">
                  <div className="card-heading primary-bg">
                    <h2>{translation.change_email}</h2>
                  </div>
                  <div className="card-body">
                    <form method="POST">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          value={fields.email}
                          onChange={(e) => this.handler.onChange(e)}
                          onKeyDown={this.onEnterPress}
                          className="form-control"
                          placeholder=" "
                        />
                        {/* <span className="floating-label">
                          {translation.enter_old_password}
                        </span> */}
                        <span className="floating-label">
                          Enter New Email Address
                        </span>
                        {errors.email.error != "" && formSubmit && (
                          <span className="elk-error">
                            {errors.email.error}
                          </span>
                        )}
                      </div>
                      
                      <div className="form-actions d-flex justify-content-between">
                        <input
                          onClick={() => this.redirect()}
                          type="button"
                          className="btn bordered-btn"
                          value="Cancel"
                          disabled={isLoading}
                        />
                        <input
                          onClick={() => this.onSubmit()}
                          type="button"
                          className="btn btn-light-blue"
                          disabled={isLoading}
                          value={isLoading ? "Processing..." : "Change Email"}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Form Content Wrap End --> */}
        <Handler
          ref={(instance) => { this.handler = instance; }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div>
    );
  }
}
export default ChangeEmail;
