import React from "react";
import { common } from "helpers";
import { apiServices } from "api";
import { StatusBar } from "includes";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
class Search extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;

    this.state = {
      searchResult: [],
      searchText: params.text ? params.text: "",
      status: "process",
      userData: common.authInfo(),
    };
  }

  componentDidMount = () => {
    const{ searchText } = this.state;
    if(searchText !== "") {
      this.getSearchResult();
    }
  }

  getSearchResult = () => {
    const { searchText, userData } = this.state;
    this.setState({ status: "process" });
    let queryParam = `${searchText}?country=${userData.user.country}`;

    let data = {
      method: "GET",
      headerFlag: true,
      url: "SEARCH_TEXT",
      query: queryParam ,
      authorization: "none",
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result = [];

        if (status == "success") {
          result = common.apiResult(res);
        }
        
        if (result.length > 0) {
          this.setState(
            {
              searchResult: result,
              status: "success"
            }
          );
        } else {
          this.setState({
            status: "empty"
          })
        }
      },
      (error) => {
        this.setState({
          status: "error",
        });
      },
      (final) => {}
    );
  }

  onChangeHandle = (e) => {
    this.setState({
      searchText: e.target.value
    },
    _=> {
      this.getSearchResult()
    })
  }

  render() {
    const{ searchText, searchResult, status } = this.state;
    return (
      <div className="main-content">
        <Helmet>
          <title>Search</title>
        </Helmet>
        <Link to="#" className="custom-link">
          Xample platform is a <span>Novelwall</span> product
        </Link>
        {/* <!-- Form Content Wrap --> */}
        <ul className="nav nav-tabs lined flex-column flex-sm-row text-center pl-200">
          <li className="custom-search">
            <form id="searchForm" role="search">
            <div className="form-group has-search mb-0">
              <img src={common.loadImg("search-icon.png")} alt="Search" className="icon-search" />
              <input
                type="text"
                className="form-control"
                placeholder="What are you looking for?"
                value={searchText}
                onChange={(e) => this.onChangeHandle(e)}
              />
            </div>
            </form>
          </li>
        </ul>

        <div className="search-results-wrap mt-4">
          <div className="container">
            <div className="card card-3 pb-5">
              <div className="card-heading">
                <h2>Search Results</h2>
              </div>
              <StatusBar status={status} />
              {status == "success" && (
              <div className="card-body">
                <ul className="search-results">
                  {searchResult.length > 0 && searchResult.map((item, key) => {
                    return (
                      <div key={key}>
                        {item.test && item.test.length > 0 && item.test.map((test, key) => {
                          return (
                            <li key={key}>
                              <Link to={
                                "/session/" +
                                test.class +
                                "/" +
                                test._id +
                                "/" +
                                test.topic
                              } >
                                <h5>
                                  {item._id.name}
                                </h5>
                                <p className="mb-0">
                                  {test.name}
                                </p>
                              </Link>
                            </li>
                          )
                        })
                        }
                      </div>
                    )
                  })}
                  {/* <li>
                    <h5>
                      <Link to="#">Physics 101</Link>
                    </h5>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis vel malesuada leo, quis aliquet massa. Mauris at
                      felis vestibulum, dictum ligula at, vehicula tellus Lorem
                      ipsum dolor sit amet
                    </p>
                  </li>
                  <li>
                    <h5>
                      <Link to="#">Physics - Quantumn Physics Basic 10</Link>
                    </h5>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis vel malesuada leo, quis aliquet massa.
                    </p>
                  </li>
                  <li>
                    <h5>
                      <Link to="#">Physics 101</Link>
                    </h5>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis vel malesuada leo, quis aliquet massa. Mauris at
                      felis vestibulum, dictum ligula at, vehicula tellus Lorem
                      ipsum dolor sit amet
                    </p>
                  </li>
                  <li>
                    <h5>
                      <Link to="#">Physics - Quantumn Physics Basic 10</Link>
                    </h5>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Duis vel malesuada leo, quis aliquet massa.
                    </p>
                  </li> */}
                </ul>
              </div>
              )}
            </div>
          </div>
        </div>
        {/* <!-- Search Results Wrap End --> */}
      </div>
    );
  }
}
export default Search;
