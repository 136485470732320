import React from "react";
import { common, tools } from "helpers";
import { Link } from "react-router-dom";
import { Handler } from "includes";
import { apiServices } from "api";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
let translation = common.getTranslation();
class Usage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      usgaeRecord: {},
      dayEvents: [],
      status: "",
      enableLoadMore: false,
      eventsDate: [],
      userData: common.authInfo(),
    };
  }

  componentDidMount() {
    if (this.props.setChildRefreshMethod) {
      this.props.setChildRefreshMethod(this.refresh);
    }
    this.getRecords();
    this.getUsageRecords();
  }

  refresh = () => {
    this.setState(
      {
        records: [],
        usgaeRecord: {},
        dayEvents: [],
        eventsDate: []
      },
      () => {
        this.getRecords();
        this.getUsageRecords();
      }
    );
  };

  onClickTest = (testObj) => {
    this.props.updateTestId(
      testObj.test[0],
      testObj.class,
      "revisionquestion",
      testObj._id
    );
  };

  //Services
  getRecords = () => {
    this.setState({ 
      status: "process" ,
      eventsDate: []
    });

    var queryParam = "?";
    queryParam = queryParam + "userID=" + this.props.selUserId;
    queryParam = queryParam + "&classID=" + this.props.selSubId;
    queryParam = queryParam + "&topicID=" + this.props.selGradeId;
    queryParam = queryParam + "&period=" + this.props.selDateRangeId;

    let data = {
      method: "GET",
      headerFlag: false,
      url: "STAT_USAGE_CALENDER",
      authorization: "token",
      query: String(queryParam),
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;
        if (status == "success") {
          result = common.apiResult(res);
          
        }
        if (result.length > 0) {

          var filteretdDate = [];
          
          result.forEach(element => {
            if (!filteretdDate.includes(element.start)) {
              filteretdDate.push(element.start);
            }
          });

          this.setState({
            records: result,
            status: "success",
            eventsDate: filteretdDate
          });
        }
      },
      (error) => {
        this.setState({ status: "error" });
      },
      (final) => {}
    );
  };

  getUsageRecords = () => {
    this.setState({ status: "process" });

    var queryParam = "?";
    queryParam = queryParam + "userID=" + this.props.selUserId;
    queryParam = queryParam + "&classID=" + this.props.selSubId;
    queryParam = queryParam + "&topicID=" + this.props.selGradeId;
    queryParam = queryParam + "&period=" + this.props.selDateRangeId;

    let data = {
      method: "GET",
      headerFlag: false,
      url: "STAT_USAGE",
      authorization: "token",
      query: String(queryParam),
    };
    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        let result;
        if (status == "success") {
          result = common.apiResult(res);
        }
        if (result) {
          this.setState({
            usgaeRecord: result,
            status: "success",
          });
        }
      },
      (error) => {
        this.setState({ status: "error" });
      },
      (final) => {}
    );
  };

  handleDateClick = (e) => {
    const { records } = this.state;
    let dayEvent = [];

    if (records.length > 0) {
      for (var i = 0; i < records.length; i++) {
        let eventDay = new Date(records[i].start).toString();
        if (records[i].start == e.dateStr) {
          dayEvent.push(records[i]);
        }
      }
    }

    this.setState({
      dayEvents: dayEvent,
    });
  };

  addClassToEvent = (info) => {
    const { eventsDate } = this.state;
    let formateDate = tools.utcToLocal(new Date(info.date), "YYYY-MM-DD")

    if (eventsDate.includes(formateDate)) {
      return "event-active"
    }
    return ""
  }

  render() {
    const { records, usgaeRecord, dayEvents, userData } = this.state;

    return (
      <>
        <div className="usage-row d-flex">
          <div className="usage-col d-flex align-items-center">
            <img src={common.loadImg("usage_answeredquestions.png")} />
            <div className="usage-info">
              <h4>{usgaeRecord.question ? usgaeRecord.question : 0}</h4>
              <h6>{translation.ans_question}</h6>
            </div>
          </div>
          <div className="usage-col d-flex align-items-center">
            <img src={common.loadImg("usage_timespent.png")} />
            <div className="usage-info">
              <h4>{usgaeRecord.time ? usgaeRecord.time : 0} MIN</h4>
              <h6>{translation.time_spent}</h6>
            </div>
          </div>
          <div className="usage-col d-flex align-items-center">
            <img src={common.loadImg("usage_madeprogress.png")} />
            <div className="usage-info">
              <h4>{usgaeRecord.test ? usgaeRecord.test : 0}</h4>
              <h6>{translation.made_progress_in_test}</h6>
            </div>
          </div>
        </div>

        <div className="custom-calendar">
          <h5 className="text-center">{translation.practice_test_sessions}</h5>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dateClick={this.handleDateClick}
            dayCellClassNames={ info => this.addClassToEvent(info) }
          />
          <div className="test-list mt-3 pt-5">
            {dayEvents.map((dateEve, dateEveInd) => (
              <div
                className="test-item d-flex align-items-center justify-content-between flex-wrap"
                key={dateEveInd}
              >
                <p className="test-date">{new Date(dateEve.start).getDate()}</p>
                <div className="test-details">
                  {/* Feburary 2021 - 22 Monday */}
                  <h5>
                    {tools.utcToLocal(new Date(dateEve.start), "MMMM YYYY - DD dddd")}
                  </h5>
                  <p>{dateEve.title}</p>
                  <p>
                    <span>{dateEve.activeMin} min active practice</span>{" "}
                    <span>{dateEve.questions} questions</span>{" "}
                    <span>{dateEve.testNames.name}</span>
                  </p>
                </div>
                {userData.user &&
                  userData.user.role &&
                  userData.user.role != 1 && (
                    <Link to={"/analytics/topic/revisionquestion"} onClick={() => this.onClickTest(dateEve)}>
                      <img src={common.loadImg("usage_arrow.png")} />
                    </Link>
                  )}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Usage;
