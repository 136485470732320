import React, { Component } from "react";
import { common, tools } from "helpers";
import allLangText from "assets/img/all-lang-text.png";
import { Helmet } from "react-helmet";
let translation = common.getTranslation();
class SelectLanguage extends Component {

setLanguage = (event, selectedLanguage) => {
  event.preventDefault();
  common.setStorage("lngPage", false);
  common.saveLanguage(selectedLanguage)
  common.redirect("/");
}

componentDidMount() {
  common.setStorage("lngPage", true);
}

componentWillUnmount() {
  common.setStorage("lngPage", false);
}

  render() {
    return (
      <div className="select-language-wrap">
        <Helmet>
          <title>Select Language</title>
        </Helmet>
        <div className="container">
          <div className="ls-content-wrap">
            <div className="mb-5 text-center">
              <img
                //src={common.loadImg("ls-banner-logo.png")}
                src={common.loadImg("example-logo.png")}
                alt=""
                className="img-fluid example-logo"
              />
            </div>

            <div className="lang-list-content">
              <ul className="row language-list g-5 mb-5">
                <li className="col-md-4">
                  <a href="#" onClick={(e) => this.setLanguage(e, "si")}>
                    <div className="language-info">
                      <img
                        src={common.loadImg("sinhala.jpg")}
                        alt=""
                        className="img-fluid"
                      />
                      <h3 className="language-name text-center">{translation.lang_sinhala}</h3>
                    </div>
                  </a>
                </li>
                <li className="col-md-4">
                  <a href="#" onClick={(e) => this.setLanguage(e,"en")}>
                    <div className="language-info">
                      <img
                        src={common.loadImg("english.jpg")}
                        alt=""
                        className="img-fluid"
                      />
                      <h3 className="language-name text-center text-transform eng-lang">{translation.lang_english}</h3>
                    </div>
                  </a>
                </li>
                <li className="col-md-4">
                  <a href="#" onClick={(e) => this.setLanguage(e, "ta")}>
                    <div className="language-info">
                      <img
                        src={common.loadImg("tamil.jpg")}
                        alt=""
                        className="img-fluid"
                      />
                      <h3 className="language-name text-center">{translation.lang_tamil}</h3>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            {/* all-lang-text.png */}
            <p className="lc-para text-center">
              {/* Do your O/L and A/L exams with your eyes closed like an
              <br />

              expert with 4 simple steps! */}
              <img src={allLangText} className="all-lang-text" alt="all-lang-text" />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectLanguage;
