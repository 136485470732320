
//Locale
import en from "localization/en";
import ta from "localization/ta";
import si from "localization/si";

export default {
   en,
   ta,
   si
  }