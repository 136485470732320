import React from 'react';
import { tools } from "helpers";
import { common } from "helpers";

export class Handler extends React.Component {
    constructor(props) {
        super(props);
    }

    // handler
    onChange = (event, callback = false) => {
        const { fields } = this.props.state;
        const { name, value } = event.target;
        fields[name] = value;
        if(name == "mobile"){
            fields[name] = value.substring(0, 9);
        }
        this.props.setState(fields);
        if (callback) {
            this.props.callBack(name);
        }
        this.validateForm();
    }

    onCheck = (event) => {
        const { fields } = this.props.state;
        const { name, value, checked } = event.target;
        if (typeof (fields[name]) == "object") {
            if (checked) {
                fields[name].push(value);
            } else {
                fields[name] = tools.removeItem(fields[name], value);
            }
        } else {
            fields[name] = value;
        }
        this.props.setState(fields);
        this.validateForm();
    }

    // Validation
    validateForm() {
        const { fields, errors } = this.props.state;
        let isValid = true;

        for (var key of Object.keys(errors)) {
            errors[key].error = "";
            if (fields[key] == "" || fields[key] == null) {
                errors[key].error = errors[key].isReq;
                isValid = false;
            } else if (errors[key].minLength && fields[key].length < errors[key].minLength) {
                errors[key].error = errors[key].isMinLengthReq;
                isValid = false;
            } else if (errors[key].maxLength && fields[key].length > errors[key].maxLength) {
                errors[key].error = errors[key].isMinLengthReq;
                isValid = false;
            } else if (errors[key].isValidEmail && !tools.ValidateEmail(fields[key])) {
                errors[key].error = errors[key].isValidEmailReq;
                isValid = false;
            } else if (errors[key].matchPassword) {
                if (fields[key] !== fields["password"]) {
                    errors[key].error = errors[key].matchPasswordReq;
                    isValid = false;
                }
            }
        }
        this.props.setState({
            errors,
        });
        return isValid;
    }

    showError() {
        const { errors } = this.props.state;
        for (var key of Object.keys(errors)) {
            if (errors[key].error != "") {
                let msg = common.getLangMessage(errors[key].error);
                common.snack("E",msg);
                break;
            }
        }
    }

    render() { return null }
}