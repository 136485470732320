import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
// includes
import { StatusBar, Handler } from "includes";
import { apiServices } from "api";
import { common, tools } from "helpers";

export default class ChatWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChat: false,
      isLoggedIn: common.authInfo(),
      fields: {
        message: "",
      },
      errors: {
        message: {
          error: "",
          isReq: "Message is required!",
        },
      },
      sData: {
        userChat: []
      }
    };
  }

  // life
  componentDidMount() {
    this.getChatUserList();
  }

  // api
  getChatUserList = () => {
    const { sData } = this.state;
    let query = `?userId=${common.getStorage("userId")}`;
    let data = {
      method: "GET",
      url: "CHAT_USER_LIST",
      query
    };

    apiServices.call(
      data,
      (res) => {
        let status = common.apiStatus(res);
        sData.userChat = common.apiResultData(res);
        if (status == "success") {
          this.setState({
            status,
            sData
          })
        }
        this.setStatus(status);
      },
      (error) => { },
      (final) => { }
    );
  }

  // submit handler
  onSubmit = (e) => {
    e.preventDefault();
    let isValid = this.handler.validateForm();
    if (isValid) {
      const { params, fields } = this.state;
      let qParams = {
        user: common.getStorage("userId"),
        admin: "60a4e66ba7e52a082615218b",
        type: "user",
        message: fields.message,
        session: ""
      }
      let mKey = this.setMessage("process", 0);
      // reset scroll
      this.resetForm();
      this.scrollToBottom();

      // api call
      let data = {
        method: "POST",
        url: "CHAT_CREATE",
        body: qParams,
      };

      apiServices.call(
        data,
        (res) => {
          let status = common.apiStatus(res);
          if (status == "success") {
            this.setMessage("success", mKey);
          } else {
            this.setMessage("failure", mKey);
          }
        },
        (error) => {
          this.setMessage("failure", mKey);
        },
        (final) => {
        }
      );
    }
  };

  // support
  setMessage(status, key) {
    const { sData, fields } = this.state;
    let data = sData.userChat.data[key];
    let total = sData.userChat.data.length;
    if (status == "process") {
      sData.userChat.data.push({
        _id: "",
        user: common.getStorage("userId"),
        type: "user",
        session: "",
        message: fields.message,
        cOn: moment().toDate(),
      });
    } else {
      data.status = status;
      sData.userChat.data[key] = data;
    }
    this.setState({ sData });
    return total;
  }

  setChat(status) {
    this.setState({ isChat: status })
  }

  resetForm() {
    const { fields } = this.state;
    fields.message = "";
    this.setState({
      fields
    })
  }

  scrollToBottom = () => {
    setTimeout(() => {
      let ele = document.getElementById("messages");
      ele.scrollTo(0, ele.scrollHeight);
    }, 0);
  }

  // common
  setStatus(status) {
    this.setState({ status: status })
  }

  setSubmit(status) {
    this.setState({ formSubmit: status })
  }

  setLoading(status) {
    this.setState({ isLoading: status })
  }

  render() {
    const { isChat, status, fields, sData } = this.state;
    return (
      <div>
        {/* Chat Widget Custom */}
        <div class="rcw-widget-container">
          {isChat ? (
            <React.Fragment>
              <div class="rcw-conversation-container active" aria-live="polite"><div class="rcw-header">
                <button class="rcw-close-button">
                  <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=" class="rcw-close" alt="close"></img>
                </button>
                <h4 class="rcw-title">Examplelk</h4><span>Chat Support</span></div>
                <div id="messages" class="rcw-messages-container">
                  {/* Default messae */}
                  <div class="rcw-message">
                    <img src={common.loadImg("live_chat_icon.png")} class="rcw-avatar" alt="profile"></img>
                    <div class="rcw-response">
                      <div class="rcw-message-text">
                        <p>Hi, Welcome to examplelk chat support</p>
                      </div>
                    </div>
                  </div>
                  {status == "success" && (
                    sData.userChat.data.map((item, key) => {
                      if (item.type == "user") {
                        return (<div class="rcw-message">
                          <div class="rcw-client"><div class="rcw-message-text">
                            <p>{item.message}</p>
                          </div>
                            <span class="rcw-timestamp">
                              {item.status == "process" ? (
                                <span>Message sending...</span>
                              ) : item.status == "failure" ? (
                                <span>Message failed</span>
                              ) : (
                                    moment(item.cOn).format("MMMM DD YYYY hh:mm:ss A")
                                  )}
                            </span>
                          </div>
                        </div>)
                      } else {
                        return (<div class="rcw-message">
                          <img src={common.loadImg("live_chat_icon.png")} class="rcw-avatar" alt="profile"></img>
                          <div class="rcw-response">
                            <div class="rcw-message-text">
                              <p>{item.message}</p>
                            </div>
                            <span class="rcw-timestamp">{moment(item.cOn).format("MMMM DD YYYY hh:mm:ss A")}</span>
                          </div>
                        </div>)
                      }
                    })
                  )}

                </div>
                <form class="rcw-sender" onSubmit={(e) => this.onSubmit(e)}>
                  <input
                    type="text"
                    name="message"
                    className="rcw-new-message"
                    placeholder="Type Your Message Here..."
                    autocomplete="off"
                    value={fields.message}
                    onChange={(e) => this.handler.onChange(e)}
                  />
                  <button type="submit" class="rcw-send">
                    <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTM1LjUgNTM1LjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUzNS41IDUzNS41OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9InNlbmQiPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw0OTcuMjUgNTM1LjUsMjY3Ljc1IDAsMzguMjUgMCwyMTYuNzUgMzgyLjUsMjY3Ljc1IDAsMzE4Ljc1ICAgIiBmaWxsPSIjY2JjYmNiIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" class="rcw-send-icon" alt="Send"></img>
                  </button>
                </form>
              </div>
              <button type="button" class="rcw-launcher rcw-hide-sm" aria-controls="rcw-chat-container" onClick={() => this.setChat(false)}>
                <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=" class="rcw-close-launcher" alt="Open chat"></img>
              </button>
            </React.Fragment>
          ) : (
              <button type="button" class="rcw-launcher" aria-controls="rcw-chat-container" onClick={() => this.setChat(true)}>
                <img src={common.loadImg("live_chat_icon.png")} class="rcw-open-launcher" alt="Close chat"></img>
              </button>
            )}
        </div>

        {/* Chat Widget Package */}
        {/* <Widget
          handleNewUserMessage={this.handleNewUserMessage}
          launcherCloseLabel={"Close"}
          profileAvatar={common.loadImg("rcw-avatar.png")}
          titleAvatar={common.loadImg("rcw-avatar.png")}
          launcherOpenImg={common.loadImg("rcw-avatar.png")}
          launcherCloseImg={common.loadImg("rcw-avatar.png")}
        /> */}
        {/* <!-- Support Form Wrap End --> */}

        <Handler
          ref={(instance) => {
            this.handler = instance;
          }}
          state={this.state}
          setState={(state) => this.setState({ state })}
        />
      </div >
    );
  }
}
